export class IbanFormatHelper {

    static transform(value: string, deletion: boolean): string {
        const regex: RegExp = /[0-9A-Z]/mg;
        const lettersRegex: RegExp = /[A-Z]/mg;
        const matches = value.toUpperCase().match(regex);
        let result = '';

        if (matches && matches.length) {
            matches.forEach((val, i) => {
                if ((i < 2 && val.match(lettersRegex)) || (i >= 2 && !val.match(lettersRegex))) {
                    if (i <= 15) {
                        result = result + val;
                    }
                    if ((i === 3 && (matches.length > 4 || !deletion)) ||
                        (i === 7 && (matches.length > 8 || !deletion)) ||
                        (i === 11 && (matches.length > 12 || !deletion))) {
                        result = result + ' ';
                    }
                }
            });
        }

        return result;
    }
}
