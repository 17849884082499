import { ChildBenefitsApiService } from './../../../../../core/files/services/child-benefits-files-api.service';
import { take } from 'rxjs/operators';
import { ReactiveTelephoneValidator } from './../../../../../shared/infrastructure/validation/validators/telephone.validator';
import { ReactiveEmailValidator } from './../../../../../shared/infrastructure/validation/validators/email.validator';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserInfoModel } from './../../../../../core/portal-user/models/user-info.model';
import { PortalUserService } from './../../../../../core/portal-user/services/portal-user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';
import { datalayerPush } from 'src/app/shared/helpers/datalayer.helper';
import { ChildBenefitsStateStore } from 'src/app/core/files/data/child-benefits-state.store';

@Component({
    selector: 'app-data-enrichment',
    templateUrl: './data-enrichment.component.html',
})
export class DataEnrichmentComponent implements OnInit {
    @Input() userInfo: UserInfoModel;

    submitted = false;
    form: FormGroup;
    translations: {
        addPersonalDetails: string,
        addPersonalDetailsDescription: string,
        emailLabel: string,
        emailPlaceholder: string,
        phoneLabel: string,
        phonePlaceholder: string,
        skip: string,
        addData: string,
        privacyConsent: string,
        privacyConsentUrl: string,
        privacyConsentRequired: string,
        validEmail: string,
        validPhone: string,
    };

    get f() { return this.form.controls; }

    constructor(
        private translate: TranslateService,
        private activeModal: NgbActiveModal,
        private portalUserService: PortalUserService,
        private childBenefitsStateStore: ChildBenefitsStateStore,
        private childBenefitsApiService: ChildBenefitsApiService,
        private fb: FormBuilder) { }

    dismiss() {
        this.activeModal.dismiss();
    }

    submit() {
        this.submitted = true;

        if (this.form.invalid) {
            return;
        }

        this.childBenefitsStateStore.state$.pipe(take(1)).subscribe((childBenefitsState) => {
            this.portalUserService.updateUserInfo({
                email: this.form.value.email || null,
                phone: this.form.value.phone || null,
                regions: childBenefitsState.files.map(file => file.region)
            }).subscribe(
                () => {
                    this.activeModal.close();
                    datalayerPush({ event: 'add_accountinfo' });
                    this.refreshData();
                },
                (e) => {
                    this.activeModal.close();
                    this.refreshData();
                });
        });
    }

    isFieldValid(field: string) {
        return !this.form.get(field).valid && this.form.get(field).touched;
    }

    ngOnInit(): void {
        const firstName = this.userInfo.firstName || '';

        const config: { [key: string]: FormControl } = {};

        if (!(this.userInfo.email || this.userInfo.emailEnriched)) {
            config.email = this.fb.control('', ReactiveEmailValidator);
        }

        if (!(this.userInfo.phone || this.userInfo.phoneEnriched)) {
            config.phone = this.fb.control('', ReactiveTelephoneValidator);
        }

        config.acceptTerms = this.fb.control(false, Validators.requiredTrue);

        this.form = this.fb.group(config);

        this.translations = {
            addPersonalDetails: this.translate.instant('Infino.Portal.User.DataEnrichment.AddDetails', {}),
            addPersonalDetailsDescription: this.translate.instant('Infino.Portal.User.DataEnrichment.AddDetailsDescription', { name: firstName }),
            emailLabel: this.translate.instant('Infino.Portal.User.DataEnrichment.EmailLabel'),
            emailPlaceholder: this.translate.instant('Infino.Portal.User.DataEnrichment.EmailPlaceholder'),
            phoneLabel: this.translate.instant('Infino.Portal.User.DataEnrichment.PhoneLabel'),
            phonePlaceholder: this.translate.instant('Infino.Portal.User.DataEnrichment.PhonePlaceholder'),
            skip: this.translate.instant('Infino.Portal.User.DataEnrichment.Skip'),
            addData: this.translate.instant('Infino.Portal.User.DataEnrichment.AddData'),
            privacyConsent: this.translate.instant('Infino.Portal.User.DataEnrichment.PrivacyConsent.Label'),
            privacyConsentUrl: this.translate.instant('Infino.Portal.User.DataEnrichment.PrivacyConsentUrl.Label'),
            privacyConsentRequired: this.translate.instant('Infino.Portal.User.DataEnrichment.PrivacyConsent.Required'),
            validEmail: this.translate.instant('Infino.Portal.User.DataEnrichment.ValidEmail'),
            validPhone: this.translate.instant('Infino.Portal.User.DataEnrichment.ValidPhone'),
        };
    }

    private refreshData() {
        this.portalUserService.refetch();
        this.childBenefitsApiService.initialize();
    }
}
