import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { map, shareReplay, takeWhile, last } from 'rxjs/operators';
import { AuthenticationRefresherMapper } from './authentication-refresher.mapper';
import { AuthenticationRefresherModel } from './authentication-refresher.model';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { PortalAuthenticationService } from '../../services/portal-authentication.service';
import { timer } from 'rxjs';

@Component({
    selector: 'app-authentication-refresher',
    templateUrl: './authentication-refresher.component.html',
})
export class AuthenticationRefresherComponent implements OnInit {
    public model: AuthenticationRefresherModel;

    constructor(
        private auth: PortalAuthenticationService,
        private router: Router,
        private localize: LocalizeRouterService,
        private mapper: AuthenticationRefresherMapper,
        private activeModal: NgbActiveModal
    ) { }

    ngOnInit(): void {
        this.model = this.mapper.map();
        const model = this.model;
        const refreshExpires: number = this.auth.getRefreshToken().exp * 1000 - new Date().getTime();
        const refreshExpiresSoon = refreshExpires < (90 * 1000);

        if (refreshExpiresSoon) {
            model.authenticationRequired = true;
        } else {
            const expiresAt = new Date().getTime() + 60 * 1000;
            model.expiresIn = timer(0, 1000).pipe(
                map(() => Math.floor((expiresAt - new Date().getTime()) / 1000)),
                takeWhile(v => v >= 0),
                shareReplay(1));

            // upon completion of the timer, no more refreshing is possible
            model.expiresIn.pipe(last()).subscribe(() => {
                if (!model.cancelled) {
                    this.auth.logoutAndRedirect();
                    model.authenticationRequired = true;
                }
            });
        }
    }

    logout() {
        this.auth.logoutAndRedirect();
        this.activeModal.close();
        this.model.cancelled = true;
    }

    refresh() {
        this.model.loading = true;
        this.auth.refreshToken().subscribe(() => {
            this.model.cancelled = true;
            this.model.loading = false;
            this.activeModal.close();
        });
    }
}
