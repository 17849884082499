import { Component, OnInit } from '@angular/core';
import { Observable, of, combineLatest } from 'rxjs';
import { PortalDocumentsViewModel } from '../viewmodels/portal-documents.viewmodel';
import { PortalDocumentsMapper } from '../mappers/portal-documents.mapper';
import { ValidationHelper } from 'src/app/shared/infrastructure/validation/validation.helper';
import { ChildBenefitsStateStore } from 'src/app/core/files/data/child-benefits-state.store';
import { DocumentService } from '../services/document.service';
import { map } from 'rxjs/operators';
import { DocumentViewModel } from '../viewmodels/portal-documents-document.viewmodel';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-portal-documents',
  templateUrl: './portal-documents.component.html'
})
export class PortalDocumentsComponent implements OnInit {
  public model: PortalDocumentsViewModel;
  public validationErrors: any = {};
  public showPanel: boolean;

  constructor(
    private store: ChildBenefitsStateStore,
    private service: DocumentService,
    private mapper: PortalDocumentsMapper
  ) {
    this.model = mapper.mapEmpty();
    combineLatest([this.store.state$, this.service.GetDocuments()])
      .pipe(map(([state, documents]) => this.mapper.map(state, documents)))
      .subscribe(m => {
        this.model = m;
      });
  }

  ngOnInit(): void {
  }

  public onSelect(selected: DocumentViewModel): void {
    if (this.model.selectedDocument === selected) {
      this.model.selectDocument(undefined);
      this.showPanel = false;
    } else {
      this.model.selectDocument(selected);
      this.showPanel = !!selected;
    }
  }

  public onDownload(document: DocumentViewModel): void {
    this.service.Download(document).subscribe(response => {
      let fileExtension = document.documentMimeType;
      if (document.documentMimeType.split('/').length > 1) {
        fileExtension = document.documentMimeType.split('/')[1];
      }
      const fileName = `${document.documentType}.${fileExtension}`;
      const blob: any = new Blob([response], { type: `${document.documentMimeType}` });
      fileSaver.saveAs(blob, fileName);
    },
      error => console.warn(`Error occured while downloading document ${document.documentId}`)
    );
  }

  public submit(): void {
    const validationResult = ValidationHelper.validate(this.model.getValidators());

    if (!validationResult.isValid) {
      this.validationErrors = validationResult.getErrorObject();
      return;
    } else {
      this.resetValidation();
    }

    this.model.loading = true;

    this.model.applyFilters();

    this.model.loading = false;
  }

  public validateItem(fieldName: string) {
    const validationResult = ValidationHelper.validateField(fieldName, this.model.getValidators());

    if (validationResult.isValid) {
      this.resetItemValidation(fieldName);
    } else {
      this.validationErrors = validationResult.extendErrorObject(this.validationErrors);
    }
  }

  public resetValidation() {
    this.model.getValidators().forEach(v => this.resetItemValidation(v.name));
  }

  public resetItemValidation(fieldName: string) {
    this.validationErrors = ValidationHelper.removeFromErrorObject(fieldName, this.validationErrors);
  }

  public resetForm() {
    // this.model$ = of(this.mapper.map());
    // combineLatest([this.store.state$, this.service.GetDocuments()])
    //   .pipe(map(([state, documents]) => this.mapper.map(state, documents)))
    //   .subscribe(m => {
    //     this.model = m;
    //   });
    this.model.form = {
      fileId: 'any',
      statusId: 'any',
      from: null,
      to: null
    };

    this.model.applyFilters();
    this.validationErrors = {};
  }

  onNext() {
    this.model.nextDocumentDetails();
  }

  onPrevious() {
    this.model.previousDocumentDetails();
  }
}
