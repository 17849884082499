import { Component, Input } from '@angular/core';
import { IconColorEnum } from '../enums/icon-color.enum';

@Component({
  selector: 'app-icon-document',
  templateUrl: './document.component.html',
})
export class IconDocumentComponent {
  @Input() color: IconColorEnum = IconColorEnum.Blue40;

  public get classes(): string {
    return `${this.color.replace('stroke', 'fill')}`;
  }
}
