import { ClientAdvisorCtaModule } from '../components/client-advisor-cta/client-advisor-cta.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LocalizedDateModule } from '../../../shared/pipes/localized-date/localized-date.module';
import { SocialSecurityNumberModule } from '../../../shared/pipes/social-security-number/social-security-number.module';
import { SmoothHeightModule } from '../../../shared/components/smooth-height/smooth-height.module';
import { InputsModule } from 'src/app/shared/components/inputs/inputs.module';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';
import { IconsModule } from 'src/app/shared/components/icons/icons.module';
import { DetailPaneModule } from '../components/detail-pane/detail-pane.module';
import { SidepanelModule } from 'src/app/shared/components/sidepanel/sidepanel.module';
import { UserModule } from '../user/user.module';
import { PortalSeparFilesComponent } from './pages/separ-files.component';
import { SeparChildrenOverviewComponent } from './components/separ-children-overview/separ-children-overview.component';
import { SeparFileTabsComponent } from './components/separ-file-tabs/separ-file-tabs.component';
import { SeparSchoolYearDetailComponent } from './components/separ-school-year-detail/separ-school-year-detail.component';
import { SeparChildDetailComponent } from './components/separ-child-detail/separ-child-detail.component';
import { SeparPageViewModelMapper } from './mappers/separ-page.viewmodel.mapper';

@NgModule({
  declarations: [
    PortalSeparFilesComponent,
    SeparChildrenOverviewComponent,
    SeparFileTabsComponent,
    SeparSchoolYearDetailComponent,
    SeparChildDetailComponent,
  ],
  imports: [
    ClientAdvisorCtaModule,
    CommonModule,
    LocalizeRouterModule,
    TranslateModule,
    IconsModule,
    DetailPaneModule,
    SidepanelModule,
    InputsModule,
    FormsModule,
    RouterModule,
    SmoothHeightModule,
    LocalizedDateModule,
    SocialSecurityNumberModule,
    UserModule,
  ],
  exports: [
  ],
  providers: [
    SeparPageViewModelMapper
  ]
})
export class SeparFilesModule { }
