import { ChildBenefitsFile } from './../../../../../core/files/models/child-benefits-file.model';
import { ChildBenefitsStateStore } from './../../../../../core/files/data/child-benefits-state.store';
import { RegionEnum } from './../../../../../core/payments/models/region-enum';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-file-tabs',
    templateUrl: './file-tabs.component.html',
})
export class FileTabsComponent implements OnInit {
    translations: {
        fileLabel: string,
        region: (region: RegionEnum) => string
    };

    hovering: string;

    constructor(private translate: TranslateService, public store: ChildBenefitsStateStore) { }

    ngOnInit(): void {
        this.translations = {
            fileLabel: this.translate.instant('Infino.Portal.Files.FileLabel'),
            region: (region: RegionEnum) => this.translate.instant(`Infino.Portal.Regions.${region}.Label`)
        };
    }

    children(file: ChildBenefitsFile): string {
        return file?.children
            .sort((a, b) => new Date(a.dateOfBirth).getTime() - new Date(b.dateOfBirth).getTime())
            .map(child => child.firstname).join(', ');
    }

    onSelect(fileId: string) {
        this.store.select(fileId);
    }

    hover(active: string) {
        this.hovering = active;
    }
}
