import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ModalService } from './modal.service';
import { SingletonModule } from '../../modules/singleton.module';

@NgModule({
    providers: [ModalService],
    imports: []
})
export class ModalModule extends SingletonModule {
    constructor(@Optional() @SkipSelf() module: ModalModule) {
        super(module);
    }
}
