import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CsamLoginResponse } from './portal-api.service.models';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private translate: TranslateService
  ) {
  }

  public get apiUrl() {
    return environment.portalApiUrl;
  }

  public requestResetPassword(login: string): Observable<object> {
    const headers = new HttpHeaders().set('Language', this.translateService.currentLang);

    return this.http.post<CsamLoginResponse>(`${this.apiUrl}/auth/RequestResetPassword`, {
      login,
      language: this.translate.currentLang,
    }, {
      headers
    });
  }

  public resetPassword(login: string, password: string, token: string, initial = false): Observable<object> {
    const headers = new HttpHeaders().set('Language', this.translateService.currentLang);

    return this.http.post<CsamLoginResponse>(`${this.apiUrl}/auth/ResetPassword`, {
      login,
      password,
      token,
      initial,
    }, {
      headers
    });
  }
}
