import { PaymentsCacheStore } from './../data/payments-cache.store';
import { PaymentsQuery } from './../models/payments-query';
import { RegionEnum } from 'src/app/core/payments/models/region-enum';
import { ChildBenefitsPaymentOverview } from './../models/child-benefits-payments-overview';
import { asapScheduler, concat, EMPTY, from, Observable, of, ReplaySubject, scheduled, combineLatest } from 'rxjs';
import { Injectable } from '@angular/core';
import { ChildBenefitsPayment } from '../models/child-benefits-payment';
import { PaymentsService } from './payments.service';
import * as dayjs from 'dayjs';
import { flatMap, skip, take, toArray, mergeMap, shareReplay, map, concatAll, filter, count, startWith } from 'rxjs/operators';

@Injectable()
export class PaymentsPaginationService {
  // private yearCache: { [key: number]: Observable<ChildBenefitsPayment[]> } = {};
  private totalCount$: Observable<number>;
  private get startYear(): number { return dayjs().year(); }
  private query$ = new ReplaySubject<PaymentsQuery>(1);

  // View Properties
  public itemsPerPage$: ReplaySubject<number> = new ReplaySubject(1);
  public currentPage$: ReplaySubject<number> = new ReplaySubject(1);
  public totalPages$: Observable<number>;
  public pageNumbers$: Observable<number[]>;
  public payments$: Observable<ChildBenefitsPayment[]>;
  public allPayments$: Observable<ChildBenefitsPayment>;
  public filteredPayments$: Observable<ChildBenefitsPayment>;
  public regions$: Observable<RegionEnum[]>;

  constructor(private paymentsService: PaymentsService, private cache: PaymentsCacheStore) {
    this.init();
  }

  public init() {
    this.allPayments$ = this.getYearPayments(this.startYear, 0);

    // Since flanders api counts are off (contains duplicates compared to our logic), we first make an assumption about the count.
    // Once we have all the data, this gets adjusted. This allows the page count to be estimated before it's available
    this.totalCount$ = combineLatest([this.getPaymentsCount(), this.allPayments$.pipe(count(), startWith(-1))]).pipe(
      map(([counts, realCount]) => {
        if (realCount >= 0) {
          return realCount;
        }

        return Object.keys(counts).reduce<number>((v, k) => {
          // underestimate flanders' count until all results are fetched since there are duplicates
          if (k === RegionEnum.Flanders) {
            return v + Math.ceil(counts[k] / 2);
          }

          return v + counts[k];
        }, 0);
      }),
      shareReplay(1),
    );

    this.filteredPayments$ = this.query$.pipe(
      flatMap(query => this.allPayments$.pipe(filter(payment => this.applySearch(payment, query))))
    );

    this.totalPages$ = combineLatest([this.totalCount$, this.itemsPerPage$, this.query$]).pipe(
      flatMap(([total, itemsPerPage, query]) => {
        if (this.isEmptyQuery(query)) {
          return of(Math.ceil(total / itemsPerPage));
        }

        return this.allPayments$.pipe(
          filter(value => this.applySearch(value, query)),
          count(),
          map(amt => {
            return Math.ceil(amt / itemsPerPage);
          })
        );
      })
    );

    this.pageNumbers$ = this.totalPages$.pipe(
      map(pages => Array(pages).fill(0).map((_, i) => i + 1))
    );

    this.payments$ = combineLatest([this.totalCount$, this.itemsPerPage$, this.query$]).pipe(
      flatMap(([total, itemsPerPage, query]) => {
        if (total === 0) {
          return of([]);
        }

        return this.currentPage$.pipe(flatMap(page => {
          return scheduled([of(null), this.getPage(page - 1, itemsPerPage, total, query)], asapScheduler).pipe(concatAll());
        }));
      })
    );


    this.regions$ = this.getRegions().pipe(shareReplay(1));
    this.query$.next(undefined);
  }

  public setItemsPerPage(amount: number) {
    this.itemsPerPage$.next(amount > 0 ? amount : 0);
  }

  public setPageNumber(page: number) {
    this.pageNumbers$.subscribe(pages => {
      if (pages.includes(page)) {
        this.currentPage$.next(page);
      }
    });
  }

  public prevPage() {
    this.currentPage$.pipe(take(1)).subscribe(v => this.setPageNumber(v - 1));
  }

  public nextPage() {
    this.currentPage$.pipe(take(1)).subscribe(v => this.setPageNumber(v + 1));
  }

  public setQuery(query: PaymentsQuery) {
    this.query$.next(query);
    this.setPageNumber(1);
  }

  private isEmptyQuery(query: PaymentsQuery): boolean {
    return !query || !(Object.keys(query).some(v => !!query[v] && v !== 'regions') || !!query.regions);
  }

  private getPage = (page: number, itemsPerPage: number, totalItems: number, query: PaymentsQuery): Observable<ChildBenefitsPayment[]> => {
    return this.allPayments$.pipe(
      filter(value => this.applySearch(value, query)),
      skip(page * itemsPerPage),
      take(itemsPerPage),
      toArray(),
    );
  }

  private applySearch = (predicate: ChildBenefitsPayment, query: PaymentsQuery): boolean => {
    if (this.isEmptyQuery(query)) {
      return true;
    }
    if (query.amountFrom && predicate.totalAmount < query.amountFrom) {
      return false;
    }
    if (query.amountUntil && predicate.totalAmount > query.amountUntil) {
      return false;
    }
    if (query.dateFrom && dayjs(predicate.paymentDate).unix() < dayjs(query.dateFrom).unix()) {
      return false;
    }
    if (query.dateUntil && dayjs(predicate.paymentDate).unix() > dayjs(query.dateUntil).unix()) {
      return false;
    }
    // if any region is selected, but not the predicate's one, then filter it out
    if (Object.values(query.regions).some(v => v) && !query.regions[predicate.region]) {
      return false;
    }
    return true;
  }

  private getRegions = (): Observable<RegionEnum[]> => {
    return this.paymentsService
      .getPaymentsOverview(dayjs(0).startOf('year').toDate(), dayjs().endOf('year').toDate(), 0, 0)
      .pipe(map(overviews => overviews.map(o => o.region)));
  }

  private getPaymentsCount = (): Observable<{ [key in keyof typeof RegionEnum]: number }> => {
    if (!this.cache.getOverview()) {
      const stream = this.paymentsService.getCompleteOverview();
      this.cache.setOverview(stream);
    }

    return this.cache.getOverview().pipe(
      map(overviews => overviews.reduce(
        (col, overview) => ({ ...col, [overview.region]: overview.totalPaymentLinesForDateRange }), {
        [RegionEnum.Brussels]: 0,
        [RegionEnum.Federal]: 0,
        [RegionEnum.Flanders]: 0,
        [RegionEnum.Wallonia]: 0,
        [RegionEnum.Abroad]: 0,
      })
      )
    );
  }
  /* tslint:disable */
  private getYearPayments = (year: number, count: number, totalItems: number = undefined): Observable<ChildBenefitsPayment> => {
    return this.getYear(year).pipe(
      mergeMap(items => {
        const items$ = from(items);
        count = count + items.length;
        const hasMore = (totalItems ? count < totalItems : true) && year > 2017;
        const next$ = hasMore ? this.getYearPayments(year - 1, count, totalItems) : EMPTY;
        return concat(items$, next$);
      }),
      filter(payment => !!payment)
    );
  }
  /* tslint:enable */

  private getYear(year: number) {
    // if (!this.yearCache[year]) {
    if (!this.cache.getCacheYear(year)) {

      // merge overviews into an list of payments, descending by date
      const yearPayments = this.fetchYearPaymentOverview(year).pipe(
        map(overviews => {
          const payments = overviews.reduce<ChildBenefitsPayment[]>((list, overview) => list.concat(overview.payments), []);
          return payments.sort((a, b) => dayjs(b?.paymentDate).unix() - dayjs(a?.paymentDate).unix());
        }),
        // shareReplay(1),
      );

      // this.yearCache[year] = yearPayments;
      this.cache.setCacheYear(year, yearPayments);
    }

    // return this.yearCache[year]
    return this.cache.getCacheYear(year);
  }

  private fetchYearPaymentOverview(year: number): Observable<ChildBenefitsPaymentOverview[]> {
    const fromDate = dayjs().year(year).startOf('year').toDate();
    const untilDate = dayjs().year(year).endOf('year').toDate();

    const fetchAmount = 100;

    const overviews$ = this.paymentsService.getPaymentsOverview(fromDate, untilDate, 0, fetchAmount);
    const result = overviews$.pipe(
      flatMap(overviews => {
        // if the requested range has more values than we fetched,
        // fetch the full amount instead
        const missingAmount = overviews.reduce((counter, overview) => {
          if (overview.totalPaymentLinesForDateRange < counter) {
            return counter;
          }

          if (overview.totalPaymentLinesForDateRange > fetchAmount) {
            return overview.totalPaymentLinesForDateRange;
          }

          return counter;
        }, 0);

        if (missingAmount > 0) {
          return this.paymentsService.getPaymentsOverview(fromDate, untilDate, 0, missingAmount);
        }

        return of(overviews);
      })
    );
    return result;
  }
}
