import { Component, EventEmitter, forwardRef, Output, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TwoWayBindingInputComponent } from '../two-way-binding-input.component';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownComponent),
            multi: true
        }
    ]
})
export class DropdownComponent extends TwoWayBindingInputComponent<any>  {
    @Input() labelClass = '';
    @Input() selectClass = '';
    @Input() options: { value: any, label: string}[];
    @Input() isFormControl = true;

    public valueChanged($event: any) {
        this.resetValidation();
        this.value = $event;
        this.propagateChange(this.value);
        setTimeout(() => this.startValidation(), 1);
    }
}
