import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { datalayerPush } from 'src/app/shared/helpers/datalayer.helper';
import { HeaderColorMode } from '../../../components/detail-pane/detail-pane-header.component';
import { ChildDetailLabelsViewModel } from '../../viewmodels/portal-files-child-detail-labels.viewmodel';
import { ChildViewModel } from '../../viewmodels/portal-files-child.viewmodel';
import { ChildBasicAllowanceStatusEnum } from 'src/app/core/files/models/child-basic-allowance-status-enum';
import { IconColorEnum } from 'src/app/shared/components/icons/enums/icon-color.enum';

@Component({
    selector: 'app-child-detail',
    templateUrl: './child-detail.component.html',
})
export class ChildDetailComponent implements OnChanges {

    public childBasicAllowanceStatusEnum = ChildBasicAllowanceStatusEnum;

    @Input() public child: ChildViewModel;
    @Input() public canShowPrevious = false;
    @Input() public canShowNext = true;
    @Output() public onPrevious = new EventEmitter();
    @Output() public onNext = new EventEmitter();
    @Output() public onClose = new EventEmitter();
    @Input() labels: ChildDetailLabelsViewModel;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['child']
            && changes['child'].currentValue
            && changes['child'].currentValue !== changes['child'].previousValue) {
            datalayerPush({ event: 'view_detail', type: 'child' });
        }
    }

    public isLoaded(): boolean {
        return this.child != null && this.child !== undefined;
    }

    public next(): void {
        this.onNext.emit();
    }

    public previous(): void {
        this.onPrevious.emit();
    }

    public getSubtitle(): string {
        if (this.isLoaded()) {
            switch (this.child.childBasicAllowance?.status) {
                case ChildBasicAllowanceStatusEnum.Suspended:
                    return this.labels.suspendedLabel;
                case ChildBasicAllowanceStatusEnum.Closed:
                    return this.labels.inactiveLabel;
                default:
            }
        }

        return this.labels.activeLabel;
    }

    public getDetailHeaderColorMode(): HeaderColorMode {
        if (this.isLoaded()) {
            switch (this.child.childBasicAllowance?.status) {
                case ChildBasicAllowanceStatusEnum.Suspended:
                    return HeaderColorMode.Yellow;
                case ChildBasicAllowanceStatusEnum.Closed:
                    return HeaderColorMode.Grey;
                default:
            }
        }

        return HeaderColorMode.Dark;
    }

    public getTextColorMode(): string {
        if (this.isLoaded()) {
            switch (this.child.childBasicAllowance?.status) {
                case ChildBasicAllowanceStatusEnum.Suspended:
                case ChildBasicAllowanceStatusEnum.Closed:
                    return '';
                default:
            }
        }

        return 'text-white';
    }

    public getIconColorMode(): IconColorEnum {
        if (this.isLoaded()) {
            switch (this.child.childBasicAllowance?.status) {
                case ChildBasicAllowanceStatusEnum.Suspended:
                case ChildBasicAllowanceStatusEnum.Closed:
                    return IconColorEnum.White;
                default:
            }
        }

        return IconColorEnum.Blue40;
    }

    public getIconPath(): string {
        if (this.isLoaded()) {
            switch (this.child.childBasicAllowance?.status) {
                case ChildBasicAllowanceStatusEnum.Suspended:
                    return '/assets/svg/icon-alert-triangle-red.svg';
                default:
            }
        }

        return;
    }
}
