export class OnboardingFormModel {
    public childDateOfBirth = '';
    public childrenAmount = 1;
    public multipleChildren = false;
    public childCertificate: File[] = [];
    public childCertificateNotReceived = false;
    public mother: MotherFormModel = new MotherFormModel();
    public father: FatherFormModel = new FatherFormModel();
    public accountNumberMotherCheck = true;
    public accountNumberMother = '';
    public accountNumberMotherPermission = false;
    public accountNumberBothCheck = true;
    public accountNumberBoth = '';
    public accountNumberBothPermission = false;
    public foreignAccountCheck = false;
    public foreignAccountNumber = '';
    public circularChequeCheck = false;
    public knownAccountCheck = false;
    public noAccountNumberCheck = false;
    public includeOtherChildren = false;
    public emailConsent = false;
    public privacyConsent = false;
    public brusselsLegal = false;
    public referrer?: string;
    public hasFiles = false;
    public utmSource = '';
    public utmTerm = '';
    public utmCampaign = '';
    public utmContent = '';
    public utmMedium = '';
    public gclid = '';

    constructor(init?: Partial<OnboardingFormModel>) {
        Object.assign(this, init);
    }
}

export class PersonFormModel {
    public firstName = '';
    public lastName = '';
    public nationalID = '';
    public nationalIDUnknown = false;
    public dateOfBirth = '';
    public worksAbroad = false;
    public workCountry = '';
    public worksInternational = false;
    public internationalWork = '';
    public signature: File;

    constructor(init?: Partial<PersonFormModel>) {
        Object.assign(this, init);
    }
}

export class FatherFormModel extends PersonFormModel {
    public unknown = false;

    constructor(init?: Partial<FatherFormModel>) {
        super(init);
        Object.assign(this, init);
    }
}

export class MotherFormModel extends PersonFormModel {
    public postalCode = '';
    public email = '';
    public telephone = '';

    constructor(init?: Partial<MotherFormModel>) {
        super(init);
        Object.assign(this, init);
    }
}
