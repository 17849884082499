import { Validators } from './../../../../shared/infrastructure/validation/validators';
import { IValidationField } from './../../../../shared/infrastructure/validation/validation-field.interface';
import { TCreatedPdf } from 'pdfmake/build/pdfmake';
import { SafeUrl } from '@angular/platform-browser';

export class PortalCertificateModel {
    translations: {
        generateAndDownload: string,
        generateAndDownloadSubtitle: string,
        pickStartDate: string,
        pickEndDate: string,
        generateCertificate: string,
        generateCertificateSubtitle: string,
        iWantToMailIt: string,
        enterAnEmail: string,
        email: string,
        certificateReadyClickIfNothingHappens: string,
        noData: string,
        certificateWithPayments: string,
        certificateWithoutPayments: string,
    };

    loading: boolean;
    success: boolean;
    noData: boolean;
    certificate: TCreatedPdf;
    certificateUrl: SafeUrl;
    certificateFilename: string;

    form: {
        dateFrom?: string,
        dateUntil?: string,
        sendByMail?: boolean,
        email?: string,
    };

    constructor(init?: Partial<PortalCertificateModel>) {
        Object.assign(this, init);
    }

    public getValidators(): IValidationField[] {
        return [
            {
                name: 'certificateDateFrom',
                value: this.form.dateFrom,
                validators: [
                    Validators.required()
                ]
            },
            {
                name: 'certificateDateUntil',
                value: this.form.dateUntil,
                validators: [
                    Validators.required()
                ]
            },
            {
                name: 'certificateEmail',
                value: this.form.email,
                validators: this.form.sendByMail ? [
                    Validators.required(),
                    Validators.email(),
                ] : []
            },
        ];
    }
}
