import { IconColorEnum } from './../../../../shared/components/icons/enums/icon-color.enum';
import { IconDirectionEnum } from './../../../../shared/components/icons/enums/icon-direction.enum';
import { PaymentsQuery } from './../../../../core/payments/models/payments-query';
import { RegionEnum } from './../../../../core/payments/models/region-enum';
import { TranslateService } from '@ngx-translate/core';
import { ChildBenefitsPayment } from './../../../../core/payments/models/child-benefits-payment';
import { Component, OnInit } from '@angular/core';
import { PaymentsPaginationService } from './../../../../core/payments/services/payments-pagination.service';
import { skipWhile, takeWhile, last, take, map, takeLast, filter } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import { datalayerPush } from 'src/app/shared/helpers/datalayer.helper';

@Component({
  selector: 'app-portal-payments',
  templateUrl: './portal-payments.component.html',
  providers: [PaymentsPaginationService],
})
export class PortalPaymentsComponent implements OnInit {
  showPanel: boolean;
  showSearch: boolean;
  public searchModel: PaymentsQuery = {
    regions: {
      Brussels: false,
      Flanders: false,
      Wallonia: false,
      Federal: false
    }
  };
  selected: ChildBenefitsPayment;
  translations: {
    history: string;
    search: string;
    searchPayment: string;
    amountFrom: string;
    amountUntil: string;
    dateFrom: string;
    dateUntil: string;
    clear: string;
    lookup: string;
    loadingPayments: string;
    regions: { [key in keyof typeof RegionEnum]: string }
    noPaymentsFound: string;
    result: string;
  };
  chevronColor = IconColorEnum.Brown100;
  chevronColorBrown = IconColorEnum.Brown40;
  chevronUp = IconDirectionEnum.Up;
  chevronDown = IconDirectionEnum.Down;
  chevronLeft = IconDirectionEnum.Left;
  chevronRight = IconDirectionEnum.Right;

  constructor(public payments: PaymentsPaginationService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.payments.setPageNumber(1);
    this.payments.setItemsPerPage(10);
    this.payments.allPayments$.pipe(takeLast(1)).subscribe();

    this.translations = {
      history: this.translate.instant('Infino.Portal.Payments.History'),
      search: this.translate.instant('Infino.Portal.Payments.Search'),
      searchPayment: this.translate.instant('Infino.Portal.Payments.SearchPayment'),
      amountFrom: this.translate.instant('Infino.Portal.Payments.AmountFrom'),
      amountUntil: this.translate.instant('Infino.Portal.Payments.AmountUntil'),
      dateFrom: this.translate.instant('Infino.Portal.Payments.DateFrom'),
      dateUntil: this.translate.instant('Infino.Portal.Payments.DateUntil'),
      clear: this.translate.instant('Infino.Portal.Payments.Clear'),
      lookup: this.translate.instant('Infino.Portal.Payments.Lookup'),
      loadingPayments: this.translate.instant('Infino.Portal.Payments.LoadingPayments'),
      regions: {
        Flanders: this.translate.instant('Infino.Portal.Regions.Flanders.Label'),
        Brussels: this.translate.instant('Infino.Portal.Regions.Brussels.Label'),
        Wallonia: this.translate.instant('Infino.Portal.Regions.Wallonia.Label'),
        Federal: this.translate.instant('Infino.Portal.Regions.Federal.Label'),
        Abroad: this.translate.instant('Infino.Portal.Regions.Abroad.Label'),
      },
      noPaymentsFound: this.translate.instant('Infino.Portal.Payments.NoPaymentsFound'),
      result: this.translate.instant('Infino.Portal.Payments.Result'),
    };
  }

  onSelect = (selected: ChildBenefitsPayment) => {
    if (this.selected === selected && selected.isRegularisation) {
      this.selected = undefined;
      return;
    }

    this.selected = selected;
    this.showPanel = !!selected && !selected.isRegularisation;
  }

  hasPrevious = (): Observable<ChildBenefitsPayment> => {
    return this.payments.filteredPayments$.pipe(
      takeWhile(value => value !== this.selected),
      filter(value => !value.isRegularisation),
      takeLast(1),
      map(value => value === this.selected ? undefined : value),
    );
  }

  onPrevious = () => {
    this.hasPrevious().subscribe(value => this.selected = value);
  }

  hasNext = (): Observable<ChildBenefitsPayment> => {
    return this.payments.filteredPayments$.pipe(
      skipWhile(value => value !== this.selected),
      filter(value => !value.isRegularisation),
      take(2),
      takeLast(1),
      map(value => value === this.selected ? undefined : value),
    );
  }

  onNext = () => {
    this.hasNext().subscribe(value => this.selected = value);
  }

  onClose = () => {
    this.onSelect(undefined);
  }

  toggleSearch = () => {
    this.showSearch = !this.showSearch;
  }

  search = () => {
    this.payments.setQuery(this.searchModel);
    datalayerPush({ event: 'search_payment' });
  }

  clearSearch = () => {
    this.searchModel = {
      regions: {
        Brussels: false,
        Flanders: false,
        Wallonia: false,
        Federal: false
      }
    };
    this.payments.setQuery(undefined);
  }

  trimmedPagination(selected: number, pagenumbers: number[]): (number | undefined)[] {
    if (!pagenumbers) {
      return undefined;
    }

    if (pagenumbers.length <= 7) {
      return pagenumbers;
    }

    if (selected <= 5) {
      return [...pagenumbers.slice(0, 5), undefined, pagenumbers[pagenumbers.length - 1]];
    }

    if (selected >= pagenumbers.length - 4) {
      return [pagenumbers[0], undefined, ...pagenumbers.slice(pagenumbers.length - 5, pagenumbers.length)];
    }

    return [
      pagenumbers[0],
      undefined,
      ...pagenumbers.slice(selected - 2, selected + 1),
      undefined,
      pagenumbers[pagenumbers.length - 1]
    ];
  }

  resultsCountBracket(pageItemCount: number): Observable<string> {
    return combineLatest([this.payments.currentPage$, this.payments.itemsPerPage$]).pipe(
      map(([currentPage, itemsPerPage]) => {
        const start = (currentPage - 1) * itemsPerPage + 1;
        const end = start + pageItemCount - 1;
        return `${start} - ${end}`;
      })
    );
  }
}
