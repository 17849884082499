import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'socialSecurityNumberFormat' })
export class SocialSecurityNumberPipe implements PipeTransform {

    constructor() {
    }

    transform(value: number | string): string {
        const localValue = value?.toString();

        if (localValue?.length !== 11) {
            return localValue;
        }

        return localValue.substring(0, 2) + '.' + localValue.substring(2, 4) + '.' + localValue.substring(4, 6) + '-' + localValue.substring(6, 9) + '.' + localValue.substring(9, 11);
    }
}
