export class PortalSeparConfiguration {
    public mainDescription: string;
    public familyIncomeCompletedInfo: string;
    public familyIncomeCompletedError: string;
    public incomeLimitInfo: string;
    public incomeLimitErrorUpperTreshold: string;
    public incomeLimitErrorUnknown: string;
    public cadastralIncomeInfo: string;
    public cadastralIncomeErrorUnknown: string;
    public cadastralIncomeErrorUpperTreshold: string;
    public separCalculationBlockedInfo: string;
    public separCalculationBlockedError: string;
    public childNationalityError: string;
    public childAgeError: string;
    public childPedagogicError: string;

    constructor(init?: Partial<PortalSeparConfiguration>) {
        Object.assign(this, init);
    }
}
