import { ChildBasicAllowanceStatusEnum } from './child-basic-allowance-status-enum';

export class ChildBenefitsChild {
    public firstname: string;
    public lastname: string;
    public socialSecurityNumber: string;
    public dateOfBirth: string;
    public allowances: { name: string, amount: string }[];
    public childStatus: string;
    public childPoints: string;
    public childBasicAllowance: { status: ChildBasicAllowanceStatusEnum, startDate: string, reason: string };


    constructor(init?: Partial<ChildBenefitsChild>) {
        Object.assign(this, init);
    }
}
