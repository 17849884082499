import { Injectable } from '@angular/core';
import { Store } from '../../../shared/infrastructure/state/store';
import { PortalConfiguration } from '../models/portal-configuration.model';

@Injectable()
export class PortalConfigurationStore extends Store<PortalConfiguration> {
    protected getInitialState(): PortalConfiguration {
        return new PortalConfiguration();
    }

    protected getStoreName(): string {
        return 'portal-configuration-store';
    }
}
