import { Component, Input } from '@angular/core';
import { IconColorEnum } from '../enums/icon-color.enum';
import { IconDirectionEnum } from '../enums/icon-direction.enum';

@Component({
  selector: 'app-icon-email',
  templateUrl: './email.component.html',
})
export class IconEmailComponent {
  @Input() color: IconColorEnum = IconColorEnum.Blue40;

  public get classes(): string {
    return `${this.color.replace('stroke', 'fill')}`;
  }
}
