import '@govflanders/vl-widget-polyfill';

import * as Client from '@govflanders/vl-widget-client';

import { Observable, ReplaySubject } from 'rxjs';
import { Widget, WidgetContext } from '@govflanders/vl-widget-core';
import { filter, map, shareReplay, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PortalBurgerProfielService {
    private $authUrl: Observable<string>;
    private $bootstrapped: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    public bootstrap(authenticated: boolean, $authUrl: Observable<string>) {
        (window as any).C = Client;

        this.$authUrl = $authUrl.pipe(shareReplay(1));
        this.$authUrl.subscribe();

        this.mountWidget();
        this.setupWidget(authenticated, true);
    }

    public updateState(authenticated: boolean) {
        this.setupWidget(authenticated);
    }

    private get headerMountElement() {
        return document.getElementById('burgerprofiel-header');
    }

    private get headerWrapperElement() {
        return document.getElementById('burgerprofiel-header-wrapper');
    }

    public show() {
        if (this.headerWrapperElement) {
            this.getHeader((widget) => {
                !widget.isMounted() && widget.mount();
            });
            // this.headerWrapperElement.style.display = 'block';
        }
    }

    public hide() {
        if (this.headerWrapperElement) {
            this.getHeader((widget) => {
                widget.isMounted() && widget.unmount();
            });
            // this.headerWrapperElement.style.display = 'none';
        }
    }

    public logout() {
        // only run after bootstrap
        this.$bootstrapped.pipe(filter(v => v), take(1)).subscribe(() => {
            Client.capture(f => {
                if (f.getPluginTypeId() === 'global_header') {
                    f.getExtension('citizen_profile.session').then((session) => {
                        (session as any).logout();
                    });
                }
            });
        });
    }

    private mountWidget() {
        Client.bootstrap(environment.headerWidget)
            .then((widget) => {
                return (
                    widget
                        // Zoek het DOM-element met ID 'global-header' welke we gaan
                        // gebruiken om de widget aan de koppelen. Zodra men de widget
                        // zijn 'mount' gedrag triggered zal het DOM-element vervangen
                        // worden maar niet verwijderd. Het 'umount' gedrag zal het
                        // originele DOM-element opnieuw herstellen.
                        .setMountElement(this.headerMountElement)
                    // Geef instructie aan de widget instantie om 'mount' gedrag
                    // uit te voeren.
                    // .mount()
                );
            })
            .catch(console.error.bind(console));
    }

    private setupWidget(authenticated: boolean, bootstrap = false) {
        this.$authUrl.pipe(
            map((authUrl) => {
                this.getHeader(widget => {
                    widget
                        .getExtension('citizen_profile.session')
                        .then((session) => {
                            // This variable is purely to indicate which values are allowed (true / false).
                            const websiteHasAuthenticatedSession = authenticated;
                            // Inform the session extension about the current session state of the website.
                            return (session as any).configure({
                                active: websiteHasAuthenticatedSession,
                                endpoints: {
                                    loginUrl: authUrl,
                                    loginRedirectUrl: '/',
                                    logoutUrl: '/acmlogout',
                                    // Pad welke volgende specificatie implementeert:
                                    // https://authenticatie.vlaanderen.be/docs/beveiligen-van-toepassingen/integratie-methoden/oidc/technische-info/gericht-aanmelden/
                                    // switchCapacityUrl: '/wisselen-van-account'
                                },
                            });
                        })
                        .then(() => {
                            if (bootstrap) {
                                setTimeout(() => {
                                    this.$bootstrapped.next(true);
                                }, 500);
                            }
                        });
                });
            })).subscribe();
    }

    private getHeader(cb: (widget: Widget<WidgetContext>) => void) {
        // if (this.headerWidget) {
        //     cb(this.headerWidget);
        //     return;
        // }

        Client.capture((widget) => {
            if (widget.getPluginTypeId() === 'global_header') {
                cb(widget);
            }
        });
    }
}
