import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverviewPageModel } from './overview-page.model';

@Injectable()
export class OverviewPageMapper {

    constructor(
        private translate: TranslateService,
    ) { }

    public map(): OverviewPageModel {

        return new OverviewPageModel({
            description: this.translate.instant('Infino.Portal.Overview.OverviewSubtitle'),
            noPaymentsFound: this.translate.instant('Infino.Portal.Payments.NoPaymentsFound'),
            recentPaymentsLabel: this.translate.instant('Infino.Portal.Payments.RecentPaymentsLabel'),
            allPaymentsButton: this.translate.instant('Infino.Portal.Payments.AllPaymentsButton'),
            goodMorningLabel: this.translate.instant('Infino.Portal.Payments.GoodMorningLabel'),
            goodAfternoonLabel: this.translate.instant('Infino.Portal.Payments.GoodAfternoonLabel'),
            goodEveningLabel: this.translate.instant('Infino.Portal.Payments.GoodEveningLabel'),
            goodNightLabel: this.translate.instant('Infino.Portal.Payments.GoodNightLabel'),
        });
    }
}
