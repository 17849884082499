import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TwoWayBindingInputComponent } from '../two-way-binding-input.component';

@Component({
    selector: 'app-phone-number-input',
    templateUrl: './phone-number-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneNumberInputComponent),
            multi: true
        }
    ]
})
export class PhoneNumberInputComponent extends TwoWayBindingInputComponent<string>  {

    public valueChanged($event: any) {
        this.value = $event;
        this.propagateChange($event);
    }
}
