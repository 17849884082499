<div *ngIf="isLoaded">
    <div class="text-overline text-uppercase">
        {{ labels.schoolYearDetailTitle }} {{ model.schoolYear }}
    </div>
    <div class="bg-white border border-yellow-dark mt-2">
        <div class="m-3 row">
            <div class="col-lg mb-3">
                <div class="line-height-normal text-overline text-muted d-flex text-white">{{ labels.familyIncomeCompletedLabel }} 
                    <a [routerLink]="" (click)="openInfoModal('familyIncomeCompleted')" class="nav-link bg-transparent px-0 py-0 small align-items-center position-relative" style="margin-top: -2px;">
                        <app-icon-info-circle class="icon-16 d-flex mx-1"></app-icon-info-circle>
                    </a>
                </div>
                <div *ngIf="model.familyIncomeCompleted; else familyIncomeIncompleted">
                    <small class="line-height-normal font-weight-bold d-flex mt-2">
                        <app-icon-check class="icon-16 mr-1"  style="margin-top: -2px;"></app-icon-check>
                        {{ labels.completeLabel }}
                    </small>
                </div>
                <ng-template #familyIncomeIncompleted>
                    <small class="line-height-normal font-weight-bold d-flex mt-2">
                        <app-icon-alert-triangle class="icon-16 mr-1"  style="margin-top: -2px;"></app-icon-alert-triangle>
                        {{ labels.incompleteLabel }}
                    </small>
                    <div class="line-height-normal mt-2">
                        <small [innerHtml]="configuration.familyIncomeCompletedError"></small>
                    </div>
                </ng-template>
            </div>
            <div class="col-lg mb-3">
                <div class="line-height-normal text-overline text-muted d-flex text-white">{{ labels.realEstateIncomeCheckLabel }}
                    <a [routerLink]="" (click)="openInfoModal('realEstateIncomeCheck')" class="nav-link bg-transparent px-0 py-0 small align-items-center position-relative" style="margin-top: -2px;">
                        <app-icon-info-circle class="icon-16 d-flex mx-1"></app-icon-info-circle>
                    </a>
                </div>
                <div *ngIf="model.realEstateValid(); else realEstateInvalid">
                    <small class="line-height-normal font-weight-bold d-flex mt-2">
                        <app-icon-check class="icon-16 mr-1"  style="margin-top: -2px;"></app-icon-check>
                        {{ labels.okLabel }}
                    </small>
                </div>
                <ng-template #realEstateInvalid>
                    <small class="line-height-normal font-weight-bold d-flex mt-2">
                        <app-icon-alert-triangle class="icon-16 mr-1"  style="margin-top: -2px;"></app-icon-alert-triangle>
                        {{ labels.nokLabel }}
                    </small>
                    <div class="line-height-normal mt-2">
                        <small [innerHtml]="getRealEstateIncomeInvalidMessage()"></small>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="m-3 row">
            <div class="col-lg mb-3">
                <div class="line-height-normal text-overline text-muted d-flex text-white">{{ labels.incomeLimitLabel }}
                    <a [routerLink]="" (click)="openInfoModal('incomeLimit')" class="nav-link bg-transparent px-0 py-0 small align-items-center position-relative" style="margin-top: -2px;">
                        <app-icon-info-circle class="icon-16 d-flex mx-1"></app-icon-info-circle>
                    </a>
                </div>
                <div *ngIf="model.incomeLimitValid(); else incomeLimitInvalid">
                    <small class="line-height-normal font-weight-bold d-flex mt-2">
                        <app-icon-check class="icon-16 mr-1"  style="margin-top: -2px;"></app-icon-check>
                        {{ labels.okLabel }}
                    </small>
                </div>
                <ng-template #incomeLimitInvalid>
                    <small class="line-height-normal font-weight-bold d-flex mt-2">
                        <app-icon-alert-triangle class="icon-16 mr-1"  style="margin-top: -2px;"></app-icon-alert-triangle>
                        {{ labels.nokLabel }}
                    </small>
                    <div class="line-height-normal mt-2">
                        <small [innerHtml]="getIncomeLimitInvalidMessage()"></small>
                    </div>
                </ng-template>
            </div>
            <div class="col-lg mb-3">
                <div class="line-height-normal text-overline text-muted d-flex text-white">{{ labels.separCalculationBlockedLabel }}
                    <a [routerLink]="" (click)="openInfoModal('separCalculationBlocked')" class="nav-link bg-transparent px-0 py-0 small align-items-center position-relative" style="margin-top: -2px;">
                        <app-icon-info-circle class="icon-16 d-flex mx-1"></app-icon-info-circle>
                    </a>
                </div>
                <div *ngIf="!model.separCalculationBlocked; else separCalculationBlocked">
                    <small class="line-height-normal font-weight-bold d-flex mt-2">
                        <app-icon-check class="icon-16 mr-1"  style="margin-top: -2px;"></app-icon-check>
                        {{ labels.noLabel }}
                    </small>
                </div>
                <ng-template #separCalculationBlocked>
                    <small class="line-height-normal font-weight-bold d-flex mt-2">
                        <app-icon-alert-triangle class="icon-16 mr-1"  style="margin-top: -2px;"></app-icon-alert-triangle>
                        {{ labels.yesLabel }}
                    </small>
                    <div class="line-height-normal mt-2">
                        <small [innerHtml]="configuration.separCalculationBlockedError"></small>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>