import { DataEnrichmentComponent } from './../components/data-enrichment/data-enrichment.component';
import { PortalUserService } from './../../../../core/portal-user/services/portal-user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PortalAuthenticationService } from '../../authentication/services/portal-authentication.service';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, flatMap } from 'rxjs/operators';
import { AuthenticationState } from '../../authentication/services/portal-authentication.model';

@Injectable({ providedIn: 'root' })
export class DataEnrichmentService implements OnDestroy {
    private subscription: Subscription;

    constructor(private auth: PortalAuthenticationService, private userService: PortalUserService, private modalService: NgbModal) { }

    public run() {
        if (this.subscription) {
            return;
        }

        // upon login, if user has missing info, we ask them for it!
        this.subscription = this.auth.authState.pipe(
            filter(state => state === AuthenticationState.LoggedIn),
            filter(() => !this.auth.userIsBackoffice()),
            flatMap(() => this.userService.getUserInfo()),
            filter(info => !(info.email || info.emailEnriched) || !(info.phone || info.phoneEnriched))
        ).subscribe(info => {
            const modalRef = this.modalService.open(DataEnrichmentComponent);
            modalRef.componentInstance.userInfo = info;
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
