export class DigitalSignatureModel {

    public motherSignature: SignatureDataModel;
    public partnerSignature: SignatureDataModel;

    constructor() {

    }
}

export class SignatureDataModel {
    public isMother: boolean;
    public signature: File;
    public title: string;
    public subText: string;
    public placeholder: string;
    public cancelText: string;
    public nextText: string;
    public showNextChevron: boolean;
    public showPreviousChevron: boolean;
    public isSkipable: boolean;
    public skipText: string;
    public optionalLabel: string;

    constructor(data: Partial<SignatureDataModel>) {
        Object.assign(this, data);
    }
}
