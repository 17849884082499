import { Component, Input, OnInit } from '@angular/core';
import { FileViewModel } from '../../viewmodels/portal-files-file.viewmodel';

@Component({
  selector: 'app-beneficiary-overview',
  templateUrl: './beneficiary-overview.component.html',
})
export class BeneficiaryOverviewComponent {
  @Input() public file: FileViewModel;
  @Input() public primaryEmail: string;

  public get isLoaded(): boolean {
    return this.file != null;
  }
}
