import { PortalPaymentAllowanceLineComponent } from './components/portal-payment-allowance-line/portal-payment-allowance-line.component';
import { SmoothHeightModule } from './../../../shared/components/smooth-height/smooth-height.module';
import { ClientAdvisorCtaModule } from './../components/client-advisor-cta/client-advisor-cta.module';
import { InputsModule } from './../../../shared/components/inputs/inputs.module';
import { FormsModule } from '@angular/forms';
import { IconsModule } from '../../../shared/components/icons/icons.module';
import { SidepanelModule } from './../../../shared/components/sidepanel/sidepanel.module';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { RouterModule } from '@angular/router';
import { PortalPaymentsComponent } from './pages/portal-payments.component';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentsDataModule } from './../../../core/payments/payments-data.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalFuturePaymentsComponent } from './components/portal-future-payments/portal-future-payments.component';
import { PortalPaymentComponent } from './components/portal-payment/portal-payment.component';
import { PortalPaymentsListComponent } from './components/portal-payments-list/portal-payments-list.component';
import { PortalPaymentDetailComponent } from './components/portal-payment-detail/portal-payment-detail.component';
import { DetailPaneModule } from '../components/detail-pane/detail-pane.module';
import { LocalizedDateModule } from './../../../shared/pipes/localized-date/localized-date.module';


@NgModule({
  declarations: [
    PortalFuturePaymentsComponent,
    PortalPaymentsComponent,
    PortalPaymentComponent,
    PortalPaymentsListComponent,
    PortalPaymentDetailComponent,
    PortalPaymentAllowanceLineComponent,
  ],
  exports: [
    PortalFuturePaymentsComponent,
    PortalPaymentDetailComponent,
    PortalPaymentsListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    PaymentsDataModule,
    TranslateModule,
    RouterModule,
    LocalizeRouterModule,
    SidepanelModule,
    IconsModule,
    DetailPaneModule,
    InputsModule,
    ClientAdvisorCtaModule,
    SmoothHeightModule,
    LocalizedDateModule
  ]
})
export class PaymentsModule { }
