<div *ngIf="model">
    <div class="modal-header bg-blue-dark text-white align-items-center">
        {{ model.translations.title }}

        <div class="ml-auto h-100 cursor-pointer" (click)="close()">
            <app-icon-close></app-icon-close>
        </div>
    </div>
    <div class="modal-body bg-ecru p-4" *ngIf="!model.form.success">
        <p>{{ model.translations.introduction }}</p>

        <fieldset class="shadow-sm py-3">
            <app-checkbox
                [label]="model.translations.emailChangeLabel"
                [id]="'modify-email'"
                [(ngModel)]="model.form.emailEnabled"
                [disabled]="model.form.loading"></app-checkbox>

            <app-email-input
                [id]="'modify-email-input'"
                [placeholder]="model.translations.emailPlaceholder"
                [(ngModel)]="model.form.email"
                [validationErrors]="validationErrors['email']"
                (validate)="validateItem('email')"
                (resetValidate)="resetItemValidation('email')"
                [disabled]="model.form.loading"
                *ngIf="model.form.emailEnabled">
            </app-email-input>
        </fieldset>
        <fieldset class="shadow-sm py-3">
            <app-checkbox
                [label]="model.translations.phoneChangeLabel"
                [id]="'modify-phone'"
                [(ngModel)]="model.form.phoneEnabled"
                [disabled]="model.form.loading"></app-checkbox>
            <app-phone-number-input
                [id]="'modify-phone-input'"
                [placeholder]="model.translations.phonePlaceholder"
                [(ngModel)]="model.form.phone"
                [validationErrors]="validationErrors['phone']"
                (validate)="validateItem('phone')"
                (resetValidate)="resetItemValidation('phone')"
                [disabled]="model.form.loading"
                *ngIf="model.form.phoneEnabled">
            </app-phone-number-input>
        </fieldset>
        <fieldset class="shadow-sm py-3" *ngIf="model.activeRegion.toString() !== 'Flanders'">
            <app-checkbox
                [label]="model.translations.bankAccountChangeLabel"
                [id]="'modify-bankaccount'"
                [(ngModel)]="model.form.bankAccountEnabled"
                [disabled]="model.form.loading">
            </app-checkbox>
            <div *ngIf="model.form.bankAccountEnabled" class="ml-2">
                <fieldset class="shadow-sm py-3">
                    <app-radio-input
                        [label]="model.translations.bankAccountMotherLabel"
                        [id]="'accountnumbermother-check'"
                        [radioValue]="'accountNumberMotherCheck'"
                        [(ngModel)]="selectedRadio"
                        [name]="'bankAccountType'">
                    </app-radio-input>
                    <div
                        class="bg-brown-10-alpha p-3 mb-4"
                        *ngIf="model.form.bankAccountMotherCheck"
                        [@collapseAnimation]>
                        <app-iban-input
                            [id]="'bankAccountNumber'"
                            [label]="model.translations.bankAccountInputLabel"
                            [autocomplete]="'cc-number'"
                            [required]="model.form.bankAccountMotherCheck"
                            [placeholder]="model.translations.bankAccountPlaceholder"
                            [(ngModel)]="model.form.bankAccountNumberMother"
                            [validationErrors]="validationErrors['bankAccountNumberMother']"
                            (validate)="validateItem('bankAccountNumberMother')"
                            (resetValidate)="resetItemValidation('bankAccountNumberMother')">
                        </app-iban-input>
                        <app-checkbox
                            [label]="model.translations.bankAccountConsentLabel"
                            [id]="'bankAccountMotherHasConsent'"
                            [(ngModel)]="model.form.bankAccountMotherHasConsent"
                            [validationErrors]="validationErrors['bankAccountMotherHasConsent']"
                            (validate)="validateItem('bankAccountMotherHasConsent')"
                            (resetValidate)="resetItemValidation('bankAccountMotherHasConsent')">
                        </app-checkbox>
                    </div>
                </fieldset>

                <fieldset class="shadow-sm py-3">
                    <app-radio-input
                        [label]="model.translations.bankAccountBothLabel"
                        [id]="'accountnumberboth-check'"
                        [radioValue]="'accountNumberBothCheck'"
                        [(ngModel)]="selectedRadio"
                        [name]="'bankAccountType'">
                    </app-radio-input>
                    <div
                        class="bg-brown-10-alpha p-3 mb-4"
                        *ngIf="model.form.bankAccountBothCheck"
                        [@collapseAnimation]>
                        <app-iban-input
                            [id]="'bankAccountNumberBoth'"
                            [label]="model.translations.bankAccountInputLabel"
                            [autocomplete]="'cc-number'"
                            [required]="model.form.bankAccountBothCheck"
                            [placeholder]="model.translations.bankAccountPlaceholder"
                            [(ngModel)]="model.form.bankAccountNumberBoth"
                            [validationErrors]="validationErrors['bankAccountNumberBoth']"
                            (validate)="validateItem('bankAccountNumberBoth')"
                            (resetValidate)="resetItemValidation('bankAccountNumberBoth')">
                        </app-iban-input>
                        <app-checkbox
                            [label]="model.translations.bankAccountConsentLabel"
                            [id]="'bankAccountBothHasConsent'"
                            [(ngModel)]="model.form.bankAccountBothHasConsent"
                            [validationErrors]="validationErrors['bankAccountBothHasConsent']"
                            (validate)="validateItem('bankAccountBothHasConsent')"
                            (resetValidate)="resetItemValidation('bankAccountBothHasConsent')">
                        </app-checkbox>
                    </div>
                </fieldset>
            </div>
        </fieldset>

        <div class="text-danger mt-3" *ngIf="(model.form.result || this.hasGeneralError) && !model.form.success">
            {{ model.translations.somethingWentWrong }}
        </div>
    </div>

    <div class="modal-body bg-ecru p-4" *ngIf="model.form.success">
        <p>
            {{ model.translations.submittedExplanation }}
        </p>
    </div>

    <div class="d-flex bg-white p-4">
        <button
            type="button"
            class="btn btn-primary w-50 ml-3 w-100"
            (click)="onSubmit()"
            [disabled]="model.form.loading"
            *ngIf="!model.form.success">
            <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                *ngIf="model.form.loading"></span>

            {{ model.form.loading ? model.translations.submitting : model.translations.submit }}
        </button>

        <button type="button" class="btn btn-primary w-50 ml-3 w-100" (click)="close()" *ngIf="model.form.success">
            {{ model.translations.close }}
        </button>
    </div>
</div>
