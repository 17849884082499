import { Injectable } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Injectable()
export class ModalService {

    constructor(private ngbModal: NgbModal, private ngbActiveModal: NgbActiveModal) { }

    public openModal(componentType: any, centered = false, closable = false): any {
        if (closable) {
            return this.ngbModal.open(componentType, { windowClass: 'animated slideInUp', centered }).componentInstance;
        }
        else {
            //Backdrop static and keyboard false make it so the modal doesn't close when you press ESC or click outside of the modal
            return this.ngbModal.open(componentType, { windowClass: 'animated slideInUp', centered, backdrop: 'static', keyboard: false }).componentInstance;
        }

    }

    public closeModal(): void {
        this.ngbActiveModal.close();
    }
}
