export class PortalAlertConfigurationModel {
    public alertGeneral: string;
    public alertFlanders: string;
    public alertWallonia: string;
    public alertBrussels: string;

    constructor(init?: Partial<PortalAlertConfigurationModel>) {
        Object.assign(this, init);
    }
}
