<div class="nav-select-language--fixed position-relative">
    <div class="w-100 d-flex flex-column trigger" [ngClass]="{'collapsed': !isOpen}">
        <a class="btn border-0 font-weight-semi-bold bg-white nav-select-header d-flex align-content-between justify-content-center btn btn-secondary d-flex align-items-center text-large text-secondary"
            (click)="toggleOpen($event)"><span class="mr-2">
                {{selectedLanguage.name}}</span>
            <app-icon-chevron [direction]="!isOpen ? iconDirectionDown : iconDirectionUp" [color]="iconColorBlue">
            </app-icon-chevron>
        </a>
        <div *ngIf="isOpen" class="position-relative bg-white w-100 z-index-10">
            <ul class="list-unstyled p-0 m-0">
                <li *ngFor="let language of languages; let last = last" class="p-0 m-0">
                    <a (click)="selectLanguage(language)"
                        class="btn w-100 text-left text-capitalize font-weight-semi-bold cursor-pointer">
                        {{language.name}}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
