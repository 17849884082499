import { ChildBenefitsState } from './../../../../../core/files/models/child-benefits-state.model';
import { ModifyUserDataModalModel } from './modify-user-data-modal.model';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { distinctValues } from '../../../../../shared/helpers/distinct-values';

@Injectable()
export class ModifyUserDataModalMapper {
  constructor(private translate: TranslateService) { }

  public map(childBenefitsState: ChildBenefitsState): ModifyUserDataModalModel {
    const currentFile = childBenefitsState.files.find(file => file.fileId === childBenefitsState.selectedId);
    let personFullName = "";
    if (currentFile.beneficiaries.length > 0) {
      personFullName = currentFile.beneficiaries[0].firstname + " " + currentFile.beneficiaries[0].lastname;
    }

    return new ModifyUserDataModalModel({
      form: {
        emailEnabled: false,
        email: '',
        phoneEnabled: false,
        phone: '',
        bankAccountEnabled: false,
        bankAccountNumberMother: '',
        bankAccountMotherCheck: false,
        bankAccountMotherHasConsent: false,
        bankAccountBothCheck: false,
        bankAccountBothHasConsent: false,
        bankAccountNumberBoth: '',
        loading: false,
        success: false,
      },
      personFullName: personFullName,
      activeRegion: currentFile.region,
      regions: distinctValues(childBenefitsState.files.map(file => file.region)),
      activeFileId: childBenefitsState.selectedId,
      translations: {
        title: this.translate.instant('Infino.Portal.ModifyUserDataModal.Title'),
        introduction: this.translate.instant('Infino.Portal.ModifyUserDataModal.Introduction'),
        emailChangeLabel: this.translate.instant('Infino.Portal.ModifyUserDataModal.EmailChangeLabel'),
        emailPlaceholder: this.translate.instant('Infino.Portal.ModifyUserDataModal.EmailPlaceholder'),
        phoneChangeLabel: this.translate.instant('Infino.Portal.ModifyUserDataModal.PhoneChangeLabel'),
        phonePlaceholder: this.translate.instant('Infino.Portal.ModifyUserDataModal.PhonePlaceholder'),
        bankAccountChangeLabel: this.translate.instant('Infino.Portal.ModifyUserDataModal.BankAccountLabel'),
        bankAccountInputLabel: this.translate.instant('Infino.Portal.ModifyUserDataModal.BankAccountInputLabel'),
        bankAccountMotherLabel: this.translate.instant('Infino.Portal.ModifyUserDataModal.BankAccountMotherLabel'),
        bankAccountBothLabel: this.translate.instant('Infino.Portal.ModifyUserDataModal.BankAccountBothLabel'),
        bankAccountConsentLabel: this.translate.instant('Infino.Portal.ModifyUserDataModal.BankAccountConsentLabel'),
        bankAccountPlaceholder: this.translate.instant('Infino.Portal.ModifyUserDataModal.BankAccountPlaceholder'),
        submit: this.translate.instant('Infino.Portal.ModifyUserDataModal.Submit'),
        submitting: this.translate.instant('Infino.Portal.ModifyUserDataModal.Submitting'),
        submittedExplanation: this.translate.instant('Infino.Portal.ModifyUserDataModal.SubmittedExplanation'),
        somethingWentWrong: this.translate.instant('Infino.Portal.ModifyUserDataModal.SomethingWentWrong'),
        close: this.translate.instant('Infino.Portal.ModifyUserDataModal.Close'),
      }
    });
  }
}
