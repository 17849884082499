import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CsamLoginResponse } from './portal-api.service.models';

@Injectable({
  providedIn: 'root'
})
export class PortalApiService {

  constructor(
    private http: HttpClient,

  ) {
  }

  public get apiUrl() {
    return environment.portalApiUrl;
  }

  public login(login: string, password: string): Observable<CsamLoginResponse> {
    return this.http.post<CsamLoginResponse>(`${this.apiUrl}/auth/login`, {
      login,
      password
    });
  }

  public loginBackoffice(login: string, password: string, socialSecurityNumber: string): Observable<CsamLoginResponse> {
    return this.http.post<CsamLoginResponse>(`${this.apiUrl}/auth/loginBackoffice`, {
      login,
      password,
      socialSecurityNumber
    });
  }

  public csamUrl(): Observable<string> {
    return this.http
      .get<{ oauthUrl: string }>(`${this.apiUrl}/auth/CsamOAuthUrl`)
      .pipe(map(v => v.oauthUrl));
  }

  public csamLogoutUrl(): Observable<string> {
    return this.http.get<{ url: string }>(`${this.apiUrl}/auth/CsamLogoutUrl`).pipe(map(v => v.url));
  }

  public csamLogin(code: string): Observable<CsamLoginResponse> {
    return this.http.get<CsamLoginResponse>(`${this.apiUrl}/auth/csamauth`, {
      params: { code }
    });
  }

  public acmUrl(): Observable<string> {
    return this.http
      .get<{ oauthUrl: string }>(`${this.apiUrl}/auth/AcmOAuthUrl`)
      .pipe(map(v => v.oauthUrl));
  }

  public acmLogin(code: string): Observable<CsamLoginResponse> {
    return this.http.get<CsamLoginResponse>(`${this.apiUrl}/auth/acmoauth`, {
      params: { code }
    });
  }

  public refreshToken(refreshToken: string): Observable<CsamLoginResponse> {
    return this.http.post<CsamLoginResponse>(`${this.apiUrl}/auth/refreshtoken`, {
      refreshToken
    });
  }
}
