import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SeparStateStore } from 'src/app/core/separ/data/separ-state.store';
import { SeparPageViewModel } from '../viewmodels/separ-page.viewmodel';
import { SeparPageViewModelMapper } from '../mappers/separ-page.viewmodel.mapper';

@Component({
  selector: 'app-separ-files',
  templateUrl: './separ-files.component.html',
})
export class PortalSeparFilesComponent implements OnInit {
   model$: Observable<SeparPageViewModel>;

  constructor(
    private mapper: SeparPageViewModelMapper,
    private store: SeparStateStore,
  ) {
  }

  ngOnInit(): void {
    this.model$ = this.store.state$.pipe(map(state => this.mapper.map(state)));
  }
}
