import { Injectable } from '@angular/core';
import { PortalModule } from '../../portal.module';

const TOKEN_KEY = 'auth_token';
const REFRESH_TOKEN_KEY = 'auth_refresh_token';

export interface Token {
  aud: string;
  exp: number;
  iat: string;
  iss: string;
  jti: string;
  sub: string;
  ssn: string;
  unique_name: string;
  id_token?: string;
  role: string | string[];
}

export enum TokenIssuer {
  DEFAULT, CSAM, ACM, BACKOFFICE
}

@Injectable()
export class TokenStorageService {
  constructor() { }

  public clearTokens(): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(REFRESH_TOKEN_KEY);
  }

  public setToken(token: string): void {
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  public getTokenParsed(): Token | null {
    const token = this.getToken();
    return this.parseToken(token);
  }

  public setRefreshToken(refreshToken: string): void {
    window.localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }

  public getRefreshToken(): string {
    return window.localStorage.getItem(REFRESH_TOKEN_KEY);
  }


  public getRefreshTokenParsed(): Token | null {
    const token = this.getRefreshToken();
    if (!token) {
      return null;
    }

    return JSON.parse(atob(token.split('.')[1]));
  }

  public parseToken(token: string): Token | null {
    if (!token) {
      return null;
    }

    return JSON.parse(atob(token.split('.')[1]));
  }

  public parseTokenIssuer(token: Token): TokenIssuer {
    const issuer = token?.iss || '';
    if (issuer.endsWith('/csam')) {
      return TokenIssuer.CSAM;
    }
    if (issuer.endsWith('/acm')) {
      return TokenIssuer.ACM;
    }
    if (issuer.endsWith('/backoffice')) {
      return TokenIssuer.BACKOFFICE;
    }
    return TokenIssuer.DEFAULT;
  }
}
