<div class="bg-white border-top border-bottom border-yellow-dark" *ngIf="isLoaded && file.hasMediator()">
    <div class="pt-1 pb-2 px-3 d-flex justify-content-between m-2 align-items-center">
        <div class="font-weight-semi-bold line-height-normal">
            {{file.mediator.firstName}} {{file.mediator.lastName}}
        </div>
        <div class="text-large">
            {{file.mediator.account}}
        </div>
    </div>
    <div class="bg-ecru text-medium rounded p-3 d-flex m-2 font-weight-semi-bold flex-column flex-md-row">
        <div class="col d-flex flex-column line-height-normal">
            <span>{{file.mediator.street}}</span>
            <span>{{file.mediator.zipCode}} {{file.mediator.city}}</span>
            <span>{{file.mediator.country}}</span>
        </div>
    </div>
</div>