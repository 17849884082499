import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconColorEnum } from 'src/app/shared/components/icons/enums/icon-color.enum';
import { IconDirectionEnum } from 'src/app/shared/components/icons/enums/icon-direction.enum';
import { ChildViewModel } from '../../viewmodels/portal-files-child.viewmodel';
import { FileViewModel } from '../../viewmodels/portal-files-file.viewmodel';
import { ChildBasicAllowanceStatusEnum } from '../../../../../core/files/models/child-basic-allowance-status-enum';

import { ChildBenefitsApiService } from './../../../../../core/files/services/child-benefits-files-api.service';
@Component({
    selector: 'app-children-overview',
    templateUrl: './children-overview.component.html',
})
export class ChildrenOverviewComponent implements OnInit {
    public isLoaded = false;
    public childBasicAllowanceStatusEnum = ChildBasicAllowanceStatusEnum;
    public iconDirectionRight = IconDirectionEnum.Right;

    @Input() public file: FileViewModel;
    @Input() public selectedChild: ChildViewModel;
    @Input() public yearLabel: (amount: number) => string;
    @Input() public statuses: ChildBasicAllowanceStatusEnum[];
    @Input() public iconPath?: string;

    @Output() public childSelected = new EventEmitter<ChildViewModel>();

    constructor(
        private childBenefitsApiService: ChildBenefitsApiService,
    ) {
    }

    ngOnInit(): void {
        this.isLoaded = this.file != null;

        this.childBenefitsApiService.log({
            socialSecurityNumber: this.file.primaryBeneficiary.socialSecurityNumber,
            region: this.file.region,
            firstNames: this.file.children.map(a => a.firstname),
            lastNames: this.file.children.map(a => a.lastname),
            apiCall: 'files/all'
        }).subscribe();
    }

    public isSelected(child: ChildViewModel): boolean {
        return child === this.selectedChild && this.selectedChild !== undefined;
    }

    public selectChild(child: ChildViewModel) {
        this.childSelected.emit(child);
    }

    public getChildrenByStatus(): ChildViewModel[] {
        return this.file.children.filter(child => child.childBasicAllowance != null && this.statuses.includes(child.childBasicAllowance.status));
    }

    public iconColorByChildBasicAllowanceStatus(status: ChildBasicAllowanceStatusEnum, isSelected: boolean): IconColorEnum {
        if (isSelected) {
            switch (status) {
                case ChildBasicAllowanceStatusEnum.Suspended:
                case ChildBasicAllowanceStatusEnum.Closed:
                    return IconColorEnum.Black;
                default:
                    return IconColorEnum.Blue100;
            }
        }
        else {
            return IconColorEnum.Brown40;
        }
    }
}
