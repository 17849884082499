import { Injectable } from '@angular/core';
import { SeparChild } from '../models/separ-child.model';

@Injectable()
export class SeparChildMapper {

    public map(data: any): SeparChild {
        return new SeparChild({
            dateOfBirth: data.birthDate,
            firstname: data.firstname,
            lastname: data.lastname,
            meetingAgeRequirements: data.meetingAgeRequirements,
            meetingNationalityRequirements: data.meetingNationalityRequirements,
            meetingPedagogicRequirements: data.meetingPedagogicRequirements,
            socialSecurityNumber: data.socialSecurityNumber,
        });
    }
}
