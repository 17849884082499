import { PortalChildStatusLink } from './../models/portal-childstatuslink.model';
import { Injectable } from '@angular/core';
import { Store } from '../../../shared/infrastructure/state/store';
import { PortalConfiguration } from '../models/portal-configuration.model';

@Injectable()
export class PortalChildStatusLinksStore extends Store<PortalChildStatusLink[]> {
    protected getInitialState(): PortalChildStatusLink[] {
        return [];
    }

    protected getStoreName(): string {
        return 'portal-childstatuslinks-store';
    }
}
