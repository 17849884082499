import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { datalayerPush } from 'src/app/shared/helpers/datalayer.helper';
import { HeaderColorMode } from '../../../components/detail-pane/detail-pane-header.component';
import { SeparChildDetailLabelsViewModel } from '../../viewmodels/separ-child.labels';
import { SeparChildViewModel } from '../../viewmodels/separ-child.viewmodel';
import { IconColorEnum } from 'src/app/shared/components/icons/enums/icon-color.enum';
import { PortalSeparConfiguration } from 'src/app/core/configuration/models/portal-separ-configuration.model';

@Component({
  selector: 'app-separ-child-detail',
  templateUrl: './separ-child-detail.component.html',
})
export class SeparChildDetailComponent implements OnChanges {

  public detailHeaderColorMode: HeaderColorMode = HeaderColorMode.Dark;

  @Input() public fileId: string;
  @Input() public child: SeparChildViewModel;
  @Input() configuration: PortalSeparConfiguration;

  @Input() public canShowPrevious: false;
  @Input() public canShowNext: true;

  @Output() public onPrevious = new EventEmitter();
  @Output() public onNext = new EventEmitter();
  @Output() public onClose = new EventEmitter();

  @Input() labels: SeparChildDetailLabelsViewModel;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['child']
      && changes['child'].currentValue
      && changes['child'].currentValue !== changes['child'].previousValue) {
      datalayerPush({ event: 'view_detail', type: 'child' });
    }
  }

  public get isLoaded(): boolean {
    return this.child != null && this.child !== undefined;
  }

  public next(): void {
    this.onNext.emit();
  }

  public previous(): void {
    this.onPrevious.emit();
  }

  public getDetailHeaderColorMode(): HeaderColorMode {
    if (this.isLoaded && this.child.hasErrors) {
      return HeaderColorMode.Yellow;
    }

    return HeaderColorMode.Dark;
  }

  public getTextColorMode(): string {
    if (this.isLoaded && this.child.hasErrors) {
      return '';
    }

    return 'text-white';
  }

  public getIconColorMode(): IconColorEnum {
    if (this.isLoaded && this.child.hasErrors) {
      return IconColorEnum.White;
    }

    return IconColorEnum.Blue40;
  }

  public getIconPath(): string {
    if (this.isLoaded && this.child.hasErrors) {
      return '/assets/svg/icon-alert-triangle-red.svg';
    }

    return '';
  }
}
