import { PortalBurgerProfielVisibilityService } from './../../authentication/services/portal-burgerprofiel-visibility.service';
import { PortalApiService } from './../../authentication/services/portal-api.service';
import { PortalBurgerProfielService } from './../../authentication/services/portal-burgerprofiel.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { PaymentsPaginationService } from 'src/app/core/payments/services/payments-pagination.service';
import { PortalAuthenticationRefresherService } from './../../authentication/services/portal-authentication-refresher.service';
import { PortalUserService } from 'src/app/core/portal-user/services/portal-user.service';
import { take, catchError, map } from 'rxjs/operators';
import { combineLatest, of, Subscription } from 'rxjs';
import { PaymentsService } from './../../../../core/payments/services/payments.service';
import { PortalRootModel } from './portal-root.model';
import { PortalRootMapper } from './portal-root.mapper';
import { fadeOut } from './../../../../shared/infrastructure/animations/animation.constant';
import { ChildBenefitsStateService } from './../../../../core/files/services/child-benefits-state.service';
import { SeparStateService } from 'src/app/core/separ/services/separ-state.service';
import { PortalConfigurationService } from './../../../../core/configuration/services/portal-configuration.service';
import { DataEnrichmentService } from '../../user/services/data-enrichment.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PortalAuthenticationService } from '../../authentication/services/portal-authentication.service';
import { AuthenticationState } from '../../authentication/services/portal-authentication.model';
import { TranslateService } from '@ngx-translate/core';
import { PortalNotificationsService } from 'src/app/core/notifications/services/portal-notification.service';
import { datalayerPush } from 'src/app/shared/helpers/datalayer.helper';
import { SeparStateStore } from 'src/app/core/separ/data/separ-state.store';
import { IconColorEnum } from 'src/app/shared/components/icons/enums/icon-color.enum';

@Component({
  selector: 'app-portal-root-overview',
  templateUrl: './portal-root.component.html',
  animations: [fadeOut]
})
export class PortalRootComponent implements OnInit, OnDestroy {
  public model: PortalRootModel;
  public hasSeparFiles = false;
  public showMoreMenu = false;
  public iconColorMoreMenu: IconColorEnum = IconColorEnum.Brown100;

  // minimum duration in ms that the loading screen should be visible
  private minLoading = 800;
  private separStateSubscription: Subscription;

  constructor(
    public auth: PortalAuthenticationService,
    private dataEnrich: DataEnrichmentService,
    private portalConfigurationService: PortalConfigurationService,
    private childBenefitsStateService: ChildBenefitsStateService,
    private separStateService: SeparStateService,
    private paymentsService: PaymentsService,
    private paymentsPaginationService: PaymentsPaginationService,
    private portalUserService: PortalUserService,
    private mapper: PortalRootMapper,
    private translateService: TranslateService,
    private authRefresher: PortalAuthenticationRefresherService,
    private notification: PortalNotificationsService,
    private localize: LocalizeRouterService,
    private burgerprofiel: PortalBurgerProfielService,
    private api: PortalApiService,
    private burgerProfielVisibility: PortalBurgerProfielVisibilityService,
    private separStateStore: SeparStateStore,
  ) { }

  public ngOnInit(): void {
    this.dataEnrich.run();
    this.initialize();
    this.initializeUser();

    this.translateService.onLangChange.subscribe(() => {
      this.initialize();
    });

    // en-GB is disabled for now - translations not in place.
    if (this.translateService.currentLang === 'en-GB') {
      this.localize.changeLanguage('nl-BE');
    }

    this.burgerprofiel.bootstrap(this.auth.authenticated(), this.api.acmUrl());
    this.burgerProfielVisibility.initialize();
  }

  public ngOnDestroy(): void {
    this.separStateSubscription.unsubscribe();
  }

  public openMoreMenu(): void {
    this.showMoreMenu = true;
  }

  public closeMoreMenu(): void {
    this.showMoreMenu = false;
  }

  private initialize(): void {
    this.portalConfigurationService.initialize();
    this.model = this.mapper.map();
  }

  public logout(): void {
    this.auth.logoutAndRedirect();
  }

  private initializeUser(): void {
    if (this.auth.authenticated()) {
      this.bootstrapUser();
    }

    this.auth.authState.subscribe(state => {
      if (state === AuthenticationState.LoggedIn) {
        this.bootstrapUser();
        this.burgerprofiel.updateState(true);

        datalayerPush({
          event: 'login',
          method: !!this.auth.token()?.id_token ? 'csam' : 'email'
        });

        this.subscribeToSeparState();
      }

      if (state === AuthenticationState.Refreshed) {
        this.authRefresher.initialize();
      }

      if (state === AuthenticationState.LoggedOut) {
        datalayerPush({
          event: 'logout',
        });
      }
    });

    this.subscribeToSeparState();
  }

  private subscribeToSeparState(): void {
    this.separStateSubscription = this.separStateStore.loaded$.subscribe(state => {
      this.hasSeparFiles = (state.files && state.files.length > 0);
    });
  }

  private bootstrapUser(): void {
    this.model.loading = true;
    const startLoading = new Date().getTime();

    this.authRefresher.initialize();

    combineLatest([
      this.childBenefitsStateService.initialize().pipe(catchError(err => {
        this.notification.addDanger(this.translateService.instant('Client.Portal.Errors.ClientBenefits.SomethingWentWrong'));
        return of(undefined);
      })),
      this.separStateService.initialize().pipe(catchError(err => {
        this.notification.addDanger(this.translateService.instant('Client.Portal.Errors.Separ.SomethingWentWrong'));
        return of(undefined);
      })),
      this.paymentsService.initialize().pipe(
        map(() => this.paymentsPaginationService.init(),
          catchError(err => {
            this.notification.addDanger(this.translateService.instant('Client.Portal.Errors.Payments.SomethingWentWrong'));
            return of(undefined);
          }))),
      this.portalUserService.initialize().pipe(catchError(err => {
        this.notification.addDanger(this.translateService.instant('Client.Portal.Errors.PortalUser.SomethingWentWrong'));
        return of(undefined);
      })),
    ]).pipe(take(1)).subscribe(() => {
      // make sure the loading screen is at least visible
      // for the defined amount before hiding it
      const duration = new Date().getTime() - startLoading;
      const remaining = this.minLoading - duration;
      setTimeout(() => this.model.loading = false, remaining < 0 ? 0 : remaining);
    });
  }
}
