import { Component, Input } from '@angular/core';
import { IconColorEnum } from '../enums/icon-color.enum';

@Component({
  selector: 'app-icon-check',
  templateUrl: './check.component.html',
})
export class IconCheckComponent {
  @Input() color: IconColorEnum;

  public get classes(): string {
    return `${this.color}`;
  }
}
