import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Validators } from '../../../infrastructure/validation/validators';
import { ValidationHelper } from '../../../infrastructure/validation/validation.helper';
import { IValidator } from '../../../infrastructure/validation/validator.interface';

export enum InputType {
    Text,
    Number,
    Email,
    Phone,
    Postalcode,
    NationalInsuranceNumber,
    Date,
    Iban
}

@Component({
    selector: 'app-text-input-toremove',
    templateUrl: './configurable-text-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ConfigurableTextInputComponent),
            multi: true
        }
    ]
})
export class ConfigurableTextInputComponent implements ControlValueAccessor, OnChanges {
    @Input() public label: string;
    @Input() public placeholder = '';
    @Input() public subText: string;
    @Input() public id: string;
    @Input() public inputType: InputType = InputType.Text;
    @Input() public disabled = false;
    @Input() public required = true;

    @Output() updated = new EventEmitter<string>();
    @Output() validated = new EventEmitter<boolean>();

    public localValue: string;
    public fullWidth = false;
    public type = 'text';
    public mode = 'text';
    public validationErrors: any = {};

    private validators: IValidator[];

    constructor() {
        this.configureField();
    }

    ngOnChanges() {
        this.configureField();
    }

    public update(value?: any) {
        if (value) {
            this.localValue = value;
        }
        if (this.validate(this.localValue)) {
            this.notify();
        }
    }

    public writeValue(value: any): void {
        if (value && value !== '') {
            this.update(value);
        }
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any): void {}

    private configureField(): void {
        // Configure field width
        this.fullWidth = ![InputType.Postalcode, InputType.Date].includes(this.inputType);

        // Set default validation
        this.validators = (this.required && !this.disabled) ? [Validators.required()] : [];

        // Configure field type & mode
        switch (this.inputType) {
            case InputType.Email:
                this.type = 'email';
                this.mode = 'email';
                this.validators.push(Validators.email());
                break;
            case InputType.Phone:
                this.type = 'tel';
                this.mode = 'tel';
                this.validators.push(Validators.telephone());
                break;
            case InputType.NationalInsuranceNumber:
                this.mode = 'decimal';
                this.validators.push(Validators.nationalInsuranceNumber());
                break;
            case InputType.Number:
            case InputType.Postalcode:
                this.mode = 'decimal';
                this.validators.push(Validators.postalCode());
                break;
            case InputType.Date:
                this.mode = 'decimal';
                this.validators.push(Validators.dateRequiredNotZero());
                this.validators.push(Validators.dateFormat());
                break;
            case InputType.Iban:
                this.mode = 'tel';
                this.validators.push(Validators.Iban());
                break;
            case InputType.Text:
            default:
                this.mode = 'text';
                this.validators.push(Validators.alphabet());
                break;
        }
    }

    private validate(inputValue: string): boolean {
        const validationFields = [{
            name: 'inputValue',
            value: inputValue,
            validators: this.validators
        }];

        const validation = ValidationHelper.validate(validationFields);
        if (!validation.isValid) {
            this.validationErrors = validation.getErrorObject();
        } else {
            this.validationErrors = {};
        }

        const isValid = validation.isValid;
        this.validated.emit(isValid);
        return isValid;
    }

    public propagateChange = (_: any) => {};

    private notify(): void {
        const val = this.localValue;

        if (val && val.length) {
            this.propagateChange(val);
            this.updated.emit(this.localValue);
        }
    }
}
