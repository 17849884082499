
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError, config, Observer } from 'rxjs';
import { environment } from './../../../../environments/environment';
import { FlowConfiguration } from '../models/flow-configuration.model';
import { FlowConfigurationMapper } from '../mappers/flow-configuration.mapper';
import { map, catchError } from 'rxjs/operators';
import { FlowConfigurationStore } from '../data/flow-configuration.store';
import { UserService } from '../../user/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { UserStore } from '../../user/data/user.store';
import { User } from '../../user/models/user.model';
import { ActivatedRoute, Router, PRIMARY_OUTLET } from '@angular/router';
import { Location } from '@angular/common';

@Injectable()
export class FlowConfigurationService {

    constructor(
        private httpClient: HttpClient,
        private mapper: FlowConfigurationMapper,
        private flowConfigurationStore: FlowConfigurationStore,
        private userService: UserService,
        private translateService: TranslateService,
        private userStore: UserStore,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
    ) { }

    public initialize(): void {
        const urlTree = this.router.parseUrl(this.location.path());
        const urlSegments = urlTree.root.children[PRIMARY_OUTLET]?.segments;

        if (!urlSegments || urlSegments.length < 2) {
            return;
        }

        const currentFlow = urlSegments[1].path;
        const region = this.userService.getRegion();
        const subscription = this.getConfiguration(currentFlow, region, this.translateService.currentLang)
            .subscribe((configuration: FlowConfiguration) => {
                this.flowConfigurationStore.update(configuration);
                subscription.unsubscribe();
            });
    }

    public getConfiguration(flowCode: string, region: string, lang: string): Observable<FlowConfiguration> {
        const headers = new HttpHeaders()
            .set('language', lang);
        const params = new HttpParams()
            .set('code', flowCode)
            .set('region', region);

        return this.httpClient
            .get(`${environment.apiUrl}/Transfer/Configuration`, {
                headers,
                params,
            })
            .pipe(
                map((configuration) => {
                    return this.mapper.map(configuration);
                }),
                catchError((error) => {
                    throw error;
                })
            );
    }
}
