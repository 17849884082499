import { ValidatorError } from './validator-errors';

export class ValidationResult {
    constructor(public isValid: boolean, public errorFields: ValidatorError[]) {}

    public getErrorObject(): any {
        const errors = {};
        for (const errorField of this.errorFields) {
            if (errors[errorField.field] === undefined) {
                errors[errorField.field] = [errorField.error];
                continue;
            }

            errors[errorField.field].push(errorField.error);
        }

        return errors;
    }

    public extendErrorObject(errorObject: any): any {
        for (const errorField of this.errorFields) {
            if (errorObject[errorField.field] === undefined) {
                errorObject[errorField.field] = [errorField.error];
                continue;
            }

            if (!errorObject[errorField.field].some(error => error === error)) {
                errorObject[errorField.field].push(errorField.error);
            }

        }

        return errorObject;
    }

    public getFieldErrorObject(): any {
        const errors = [];
        for (const errorField of this.errorFields) {
            errors.push(errorField.error);
        }

        return errors;
    }
}
