import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IconColorEnum } from 'src/app/shared/components/icons/enums/icon-color.enum';
import { IconDirectionEnum } from 'src/app/shared/components/icons/enums/icon-direction.enum';
import { DocumentViewModel } from '../../viewmodels/portal-documents-document.viewmodel';

@Component({
  selector: 'app-portal-document',
  templateUrl: './portal-document.component.html'
})
export class PortalDocumentComponent implements OnInit {
  @Input() document: DocumentViewModel;
  @Input() active: DocumentViewModel;
  @Output() selected: EventEmitter<any> = new EventEmitter();
  @Output() download: EventEmitter<DocumentViewModel> = new EventEmitter();

  constructor() { }

  public chevronDirection = IconDirectionEnum.Right;
  public iconColorBrown = IconColorEnum.Brown40;
  public iconColorBlue = IconColorEnum.Blue100;
  public hovering: boolean;
  public hoveringDownload: boolean;

  ngOnInit(): void {
  }

  isActive(document: DocumentViewModel = this.document): boolean {
    return document === this.active;
  }

  hover(active: boolean) {
    this.hovering = active;
  }

  hoverDownload(active: boolean) {
    this.hoveringDownload = active;
  }

  onClickDownload(event) {
    event.stopPropagation();
    this.download.emit(this.document);
  }
}
