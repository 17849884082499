import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageModel } from './language.model';


@Injectable()
export class LanguagesMapper {
  constructor(
    public translateService: TranslateService
  ) { }

  public map(): LanguageModel[] {
    return [
      new LanguageModel({
        name: this.translateService.instant('Infino.Portal.Languages.Dutch'),
        languageCode: 'nl-BE'
      }),
      new LanguageModel({
        name: this.translateService.instant('Infino.Portal.Languages.French'),
        languageCode: 'fr-BE'
      }),
      // en-GB is disabled for now - translations not in place.
      // new LanguageModel({
      //   name: this.translateService.instant('Infino.Portal.Languages.English'),
      //   languageCode: 'en-GB'
      // }),
    ];
  }
}
