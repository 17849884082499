import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { InputsModule } from '../../inputs/inputs.module';
import { DigitalSignatureComponent } from './digital-signature.component';
import { DigitalSignatureMapper } from './digital-signature.mapper';

@NgModule({
    declarations: [DigitalSignatureComponent],
    providers: [DigitalSignatureMapper],
    imports: [InputsModule, TranslateModule, CommonModule]
})
export class DigitalSignatureModule { }
