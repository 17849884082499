import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import Cookies from 'universal-cookie';

import { CookieBannerService } from '../services/CookieBanner.service';
import { CookieBannerConfiguration } from './../models/CookieBannerConfiguration';
import { CookieCategory } from './../models/CookieCategory';

/* tslint:disable-next-line */
declare var dataLayer: any;

@Component({
    selector: 'app-cookie-banner',
    templateUrl: './cookie-banner.component.html',
})
export class CookieBannerComponent implements OnInit {
    public shouldEnableManagement: boolean;
    public categories: CookieCategory[];
    public configuration: CookieBannerConfiguration;
    public consentConfigured: boolean;

    constructor(private cookieBannerService: CookieBannerService) {
        this.consentConfigured = new Cookies().get('cookieConsent');
    }

    public ngOnInit(): void {
        this.cookieBannerService.getConfiguration().subscribe((config: CookieBannerConfiguration) => {
            this.configuration = config;
            this.categories = config.cookieCategories;
        });
    }

    public acceptAllCookies(): void {
        this.acceptCookies(true);
    }

    public enableManagement(): void {
        this.shouldEnableManagement = true;
    }

    public acceptCookies(acceptAll = false): void {
        const cookies = new Cookies();

        cookies.set('cookieConsent', 'true', { path: '/', maxAge: 31556926, secure: true, domain: environment.portalCookieDomain }); // MaxAge => Valid for 1 year
        for (const category of this.configuration.cookieCategories) {
            if (category.hasConcent || acceptAll) {
                cookies.set(category.cookieName, 'true', { path: '/', maxAge: 31556926, secure: true, domain: environment.portalCookieDomain }); // MaxAge => Valid for 1 year
                if (dataLayer) {
                    dataLayer.push({ event: category.cookieName });
                }
            }
            else {
                cookies.set(category.cookieName, 'false', { path: '/', maxAge: 31556926, secure: true, domain: environment.portalCookieDomain }); // MaxAge => Valid for 1 year
            }
        }
        this.consentConfigured = true;

        const consent = {
            functionality_storage: true,
            security_storage: true,
            analytics_storage: acceptAll,
            ad_storage: acceptAll,
            personalization_storage: acceptAll,
        };

        this.configuration.cookieCategories.map((c) => {
            if (c.cookieName === "analytics-cookies" && !acceptAll) {
                consent.analytics_storage = c.hasConcent;
            }

            if (c.cookieName === "marketing-cookies" && !acceptAll) {
                consent.ad_storage = c.hasConcent;
                consent.personalization_storage = c.hasConcent;
            }
        });

        if (dataLayer) {
            dataLayer.push({
                event: "consent_given",
                consent: consent,
            });
        }

        //refresh page so that other tracking scripts will be activated potentially
        window.location.reload();
    }

    public changeCategoryConsent(category: CookieCategory, accepted: boolean): void {
        category.hasConcent = accepted;
        this.categories.find(cat => cat.cookieName === category.cookieName).hasConcent = accepted;
    }
}
