import { Component, Input } from '@angular/core';
import { IconColorEnum } from '../enums/icon-color.enum';

@Component({
  selector: 'app-icon-info-circle',
  templateUrl: './info-circle.component.html',
})
export class IconInfoCircleComponent {
  @Input() color: IconColorEnum = IconColorEnum.Blue100;

  public get classes(): string {
    return `${this.color}`;
  }
}
