<div>
    <app-detail-pane-header 
        [colorMode]="detailHeaderColorMode" 
        [title]="document.documentType" 
        (onPrevious)="previous.emit($event)" 
        (onNext)="next.emit($event)" 
        (onClose)="close.emit($event)" 
        [canShowNext]="hasNext" 
        [canShowPrevious]="hasPrev">
    </app-detail-pane-header>

    <div class="mx-4 my-3 d-flex flex-wrap">
        <div class="w-50 mb-3">
            <div class="line-height-normal text-overline text-muted text-uppercase">{{ model.filesLabel }}
            </div>
            <small class="line-height-normal ">{{ document.regionLabel }} - {{ document.children }}</small>
        </div>
        <div class="w-50 mb-3">
            <div class="line-height-normal text-overline text-muted text-uppercase">{{ model.statusLabel }}
            </div>
            <small class="line-height-normal">{{ document.status }}</small>
        </div>
        <div class="w-50 mb-3">
            <div class="line-height-normal text-overline text-muted text-uppercase">{{ model.dateSentLabel }}</div>
            <small class="line-height-normal">{{ document.dateSent | localizedDate: 'd MMMM y' }}</small>
        </div>
        <div class="w-50 mb-3">
            
        </div>
        <div class="w-50 mb-3">
            <div class="line-height-normal text-overline text-muted text-uppercase">{{ model.periodFromLabel }}
            </div>
            <small class="line-height-normal ">{{ document.periodFrom | localizedDate: 'd MMMM y' }}</small>
        </div>
        <div class="w-50 mb-3">
            <div class="line-height-normal text-overline text-muted text-uppercase">{{ model.periodToLabel }}
            </div>
            <small class="line-height-normal ">{{ document.periodTo | localizedDate: 'd MMMM y' }}</small>
        </div>
    </div>
</div>