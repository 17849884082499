import { environment } from '../../../environments/environment';

export class LogHelper {
    public static log(message: string, value: any): void {
        if (environment.production) {
            return;
        }

        if (typeof value === 'object') {
            value = [value];
        }

        // use if logging should be color coded
        // console.log(`%c» ${event}`, 'color:' + eventType + ';font-weight:bold;', message, value);
    }
}
