import { Injectable } from '@angular/core';
import { config } from 'rxjs';
import { ChildBenefitsBeneficiary } from '../models/child-benefits-beneficiary.model';


@Injectable()
export class ChildBenefitsBeneficiaryMapper {

    public map(data: any): ChildBenefitsBeneficiary {
        return new ChildBenefitsBeneficiary({
            firstname: data.firstname,
            lastname: data.lastname,
            socialSecurityNumber: data.socialSecurityNumber,
            bankAccount: data.bankAccount,
            birthDate: data.birthDate,
            phone: data.phone,
            mobilePhone: data.mobilePhone,
            languageCode: data.languageCode,
        });
    }
}
