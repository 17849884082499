import { IValidator } from '../validator.interface';
import { ValidationError } from '../validation-error.enum';

/**
 * Expects date of format ddmmyyyy and validates of it is after given date
 */
export class MinTextDateValidator implements IValidator {

    constructor(private minYear: number, private minMonth: number, private minDay: number) { }
    public validate(value: string): string {
        if (value === undefined || value === null || value.length === 0 || typeof value === 'object') {
            return undefined;
        }

        const day = +value.substring(0, 2);
        const month = +value.substring(3, 5);
        const year = +value.substring(6, 10);

        const minDate = new Date(this.minYear, this.minMonth - 1, this.minDay).toJSON().slice(0, 10);
        const currentDate = new Date(year, month - 1, day).toJSON().slice(0, 10);

        if (minDate > currentDate) {
            return ValidationError.MinTextDate;
        }

        return undefined;
    }
}
