import { EventHandlerVars } from '@angular/compiler/src/compiler_util/expression_converter';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
})
export class CardComponent implements OnInit {

  @Input() title: string;
  @Input() buttonText: string;
  @Input() imageLink: string;

  @Output() onAction = new EventEmitter();

  public slopeDirection: SlopeDirection;

  constructor() { }

  public ngOnInit(): void {
    switch (Math.floor(Math.random() * (2 + 1))) {
      case 0:
        this.slopeDirection = SlopeDirection.Top;
        break;
      case 1:
        this.slopeDirection = SlopeDirection.TopLeft;
        break;
      case 2:
        this.slopeDirection = SlopeDirection.Bottom;
        break;
    }
  }
}

export enum SlopeDirection {
  Top = 'card-slope-top',
  Bottom = 'card-slope-bottom',
  TopLeft = 'card-slope-top-left',
}
