import { NgModule, Optional, SkipSelf } from '@angular/core';
import { OnboardingStore } from './onboarding.store';
import { SingletonModule } from '../../../shared/modules/singleton.module';

@NgModule({
    providers: [OnboardingStore]
})

// seperated store module to make sure this is defined at app module level
// so every component can make use of the store
export class OnboardingStoreModule extends SingletonModule {
    constructor(@Optional() @SkipSelf() module: OnboardingStoreModule) {
        super(module);
    }
}
