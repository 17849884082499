import { FancyRocketComponent } from './components/fancy-rocket/fancy-rocket.component';
import { PortalRootMapper } from './pages/portal-wrapper/portal-root.mapper';
import { OverviewModule } from './overview/overview.module';
import { QuickLinksModule } from './components/quick-links/quick-links.module';
import { PortalUserModule } from './../../core/portal-user/portal-user.module';
import { UserModule } from './user/user.module';
import { SidepanelModule } from './../../shared/components/sidepanel/sidepanel.module';
import { PaymentsModule } from './payments/payments.module';
import { NavigationModule } from './../../shared/components/navigation/navigation.module';
import { TokenStorageService } from './services/token-storage/token-storage.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalRoutingModule } from './portal-routing.module';
import { PortalRootComponent } from './pages/portal-wrapper/portal-root.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ChildBenefitsFilesModule } from './child-benefits-files/child-benefits-files.module';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { LanguageCodeMapper } from './mappers/language-code.mapper';
import { IconsModule } from 'src/app/shared/components/icons/icons.module';
import { CardsModule } from '../../shared/components/cards/card.module';
import { InputsModule } from 'src/app/shared/components/inputs/inputs.module';
import { PortalAuthenticationModule } from './authentication/portal-authentication.module';
import { LanguageToggleModule } from './components/language-toggle/language-toggle.module';
import {PortalNotificationsComponentModule} from '../../shared/components/notifications/portal-notifications-component.module';
import { PortalCertificateModule } from './certificate/portal-certificate.module';
import { PortalDocumentsModule } from './documents/portal-documents.module';
import { SeparFilesModule } from './separ/separ.module';

@NgModule({
  declarations: [
    PortalRootComponent,
    FancyRocketComponent
  ],
  imports: [
    PortalRoutingModule,
    CommonModule,
    LocalizeRouterModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NavigationModule,
    ChildBenefitsFilesModule,
    PaymentsModule,
    PortalAuthenticationModule,
    SidepanelModule,
    IconsModule,
    PortalUserModule,
    UserModule,
    CardsModule,
    InputsModule,
    OverviewModule,
    QuickLinksModule,
    LanguageToggleModule,
    PortalNotificationsComponentModule,
    PortalCertificateModule,
    PortalDocumentsModule,
    SeparFilesModule
  ],
  providers: [
    TokenStorageService,
    LanguageCodeMapper,
    PortalRootMapper,
  ]
})
export class PortalModule { }
