import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChildBenefitsFileStore } from '../data/child-benefits-file.store';
import { ChildBenefitsFileMapper } from '../mappers/child-benefits-file.mapper';
import { ChildBenefitsFile, } from '../models/child-benefits-file.model';
import { LogModel } from '../models/log.model';

@Injectable({
    providedIn: 'root'
})
export class ChildBenefitsApiService {

    constructor(
        private http: HttpClient,
        private childBenefitsMapper: ChildBenefitsFileMapper,
        private childBenefitsFileStore: ChildBenefitsFileStore,
    ) {
    }

    public get apiUrl() {
        return environment.portalApiUrl;
    }

    public initialize() {
        const subscription = this.getFiles()
            .subscribe((files: ChildBenefitsFile[]) => {
                this.childBenefitsFileStore.update(files);
                subscription.unsubscribe();
            });
    }

    public getFiles(): Observable<ChildBenefitsFile[]> {
        return this.http.get(`${this.apiUrl}/Files/All`)
            .pipe(
                map((data: Array<any>) => {
                    return data.map(file => this.childBenefitsMapper.map(file));
                }),
                catchError((error) => {
                    throw error;
                })
            );
    }

    public log(data: LogModel) {
        return this.http.post(`${this.apiUrl}/Files/Log`, data);
    }

}
