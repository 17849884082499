<app-file-tabs></app-file-tabs>
<div class="h-100 bg-yellow">
    <div class="d-flex flex-row container p-4">
        <div class="p-4 mt-2 d-none d-lg-block">
            <img src="assets/svg/groeipakket-big.svg" alt="groeipakket">
        </div>
        <div class="mt-2 ml-lg-4 col-12 col-lg-8">
            <h3 class="mt-2 mb-4">{{ model.translations.generateAndDownload }}
                {{ fileId }}
            </h3>
            <p class="pb-3">{{ model.translations.generateAndDownloadSubtitle }}</p>
            <form class="my-4 rounded bg-ecru p-5 w-100" (ngSubmit)="submit()">
                <div class="d-flex flex-column flex-md-row justify-stretch">
                    <div class="form-group d-flex flex-column mb-2 mr-4 w-100">
                        <label for="fromamount" class="line-height-normal">
                            {{ model.translations.pickStartDate }}
                        </label>
                        <input type="date" class="form-control form-control-sm rounded"
                            [(ngModel)]="model.form.dateFrom" name="dateFrom"
                            [class.text-brown-40]="!model.form.dateFrom"
                            [ngClass]="[validationErrors['certificateDateFrom'] ? 'is-invalid': '' ]"
                            (ngModelChange)="validateItem('certificateDateFrom')" [disabled]="model.loading"/>
                        <div class="invalid-feedback">
                            <span *ngFor="let error of validationErrors['certificateDateFrom']">
                                {{'Infino.ClientPortal.Validation.' + error | translate }}
                            </span>
                        </div>
                    </div>
                    <div class="form-group d-flex flex-column mb-2 w-100">
                        <label for="fromamount" class="line-height-normal">
                            {{ model.translations.pickEndDate }}
                        </label>
                        <input type="date" class="form-control form-control-sm rounded"
                            [(ngModel)]="model.form.dateUntil" name="certificateDateUntil"
                            [class.text-brown-40]="!model.form.dateUntil"
                            [ngClass]="[validationErrors['certificateDateUntil'] ? 'is-invalid': '' ]"
                            (ngModelChange)="validateItem('certificateDateUntil')" [disabled]="model.loading"/>
                        <div class="invalid-feedback">
                            <span *ngFor="let error of validationErrors['certificateDateUntil']">
                                {{'Infino.ClientPortal.Validation.' + error | translate }}
                            </span>
                        </div>
                    </div>
                </div>
                <!--
                <div class="d-flex flex-column flex-md-row">
                    <div class="form-group d-flex flex-row mb-2 mr-4 w-100">
                        <input type="radio"  id="showAmountTrue" name="showAmount" [(ngModel)]="showAmount" [value]="true" class="form-control mb-2">
                        <label for="showAmountTrue" class="line-height-normal mt-2">
                            {{ model.translations.certificateWithPayments }}
                        </label>
                    </div>
                    <div class="form-group d-flex flex-row  mb-2 w-100">
                        <input type="radio" id="showAmountFalse" name="showAmount" [(ngModel)]="showAmount" [value]="false" class="form-control mb-2">
                        <label for="showAmountFalse" class="line-height-normal mt-2">
                            {{ model.translations.certificateWithoutPayments }}
                        </label>
                    </div>
                </div>
                -->
                <div class="form-group border-top border-warning">
                    <div class="custom-control custom-checkbox my-2">
                        <input class="custom-control-input" type="checkbox" id="sendByEmail" name="sendByEmail"
                            [(ngModel)]="model.form.sendByMail" [disabled]="model.loading"/>
                        <label class="custom-control-label font-weight-normal" for="sendByEmail">
                            {{model.translations.iWantToMailIt}}
                        </label>
                    </div>
                </div>
                <div class="p-3 bg-ecru-dark-25 mb-5" *ngIf="model.form.sendByMail">
                    <app-email-input id="certificateEmail" name="certificateEmail"
                        [label]="model.translations.enterAnEmail" [placeholder]="model.translations.email"
                        [(ngModel)]="model.form.email" [validationErrors]="validationErrors['certificateEmail']"
                        (validate)="validateItem('certificateEmail')"
                        (resetValidate)="resetItemValidation('certificateEmail')" [disabled]="model.loading"></app-email-input>
                </div>
                <button type="submit" class="btn btn-primary w-100 mb-3 mt-3">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                        *ngIf="model.loading"></span>
                    {{ model.translations.generateCertificate }}</button>
                <button type="button" class="p-0 my-3 btn btn-text text-primary" *ngIf="model.certificate" (click)="downloadCertificate()">
                    {{ model.translations.certificateReadyClickIfNothingHappens }}
                </button>

                <p class="text-danger" *ngIf="model.noData">{{ model.translations.noData }}</p>

                <p class="m-0">{{ model.translations.generateCertificateSubtitle }}</p>
            </form>
        </div>
    </div>
</div>
