import { ChildBenefitsPaymentDetail } from './../../../../../core/payments/models/child-benefits-payment-detail';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input } from '@angular/core';
import { ChildBenefitsAllowance } from 'src/app/core/payments/models/child-benefits-allowance';

@Component({
    selector: 'app-portal-payment-allowance-line',
    templateUrl: './portal-payment-allowance-line.component.html',
})
export class PortalPaymentAllowanceLineComponent {
    @Input() details: ChildBenefitsPaymentDetail;
    @Input() isRegularisation = false;

    constructor(private translate: TranslateService) { }

    get allowances() {
        if (this.isRegularisation) {
            return this.sorted(this.details.allowances.filter(allowance =>
                allowance.amount !== 0 || this.currentAmount(allowance) !== this.previousAmount(allowance)
            ));
        } else {
            return this.sorted(this.withAmount(this.details.allowances));
        }
    }

    allowanceTranslation(type: string): string {
        return this.translate.instant('Infino.Portal.Allowance.Type.' + type);
    }

    withAmount<T extends { amount: number }>(items: T[]): T[] {
        return items.filter(item => item.amount !== 0);
    }

    previousAmount(allowance: ChildBenefitsAllowance): number {
        return allowance.unentitledAmount || 0;
    }

    currentAmount(allowance: ChildBenefitsAllowance): number {
        return allowance.entitledAmount || 0;
    }

    sorted(allowances: ChildBenefitsAllowance[]): ChildBenefitsAllowance[] {
        return allowances.sort((a, b) => a.allowanceType > b.allowanceType ? 1 : -1);
    }
}
