<div *ngIf="isLoaded">
    <app-detail-pane-header
        [colorMode]="getDetailHeaderColorMode()"
        [iconColorMode]="getIconColorMode()"
        [textColorMode]="getTextColorMode()"
        [title]="child.fullName"
        [iconPath]="getIconPath()"
        [canShowPrevious]="canShowPrevious"
        [canShowNext]="canShowNext"
        (onPrevious)="previous()"
        (onNext)="next()"
        (onClose)="onClose.emit()">
    </app-detail-pane-header>

    <div class="m-3 row">
        <div class="col-lg mb-3">
            <div class="line-height-normal text-overline text-muted">{{ labels.socialSecurityNumberLabel }}</div>
            <small class="line-height-normal font-weight-bold">{{ child.socialSecurityNumber | socialSecurityNumberFormat }}</small>
        </div>
    </div>
    <div class="m-3 row">
        <div class="col-lg mb-3">
            <div class="line-height-normal text-overline text-muted">{{ labels.nationalityLabel }}</div>
            <div *ngIf="child.meetingNationalityRequirements; else notMetNationalityRequirements">
                <small class="line-height-normal font-weight-bold d-flex mt-2">
                    <app-icon-check class="icon-16 mr-1"  style="margin-top: -2px;"></app-icon-check>
                    {{ labels.okLabel }}
                </small>
            </div>
            <ng-template #notMetNationalityRequirements>
                <small class="line-height-normal font-weight-bold d-flex mt-2">
                    <app-icon-alert-triangle class="icon-16 mr-1"  style="margin-top: -2px;"></app-icon-alert-triangle>
                    {{ labels.nokLabel }}
                </small>
                <div class="line-height-normal mt-2">
                    <small [innerHtml]="configuration.childNationalityError"></small>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="m-3 row">
        <div class="col-lg mb-3">
            <div class="line-height-normal text-overline text-muted">{{ labels.ageLabel }}</div>
            <div *ngIf="child.meetingAgeRequirements; else notMetAgeRequirements">
                <small class="line-height-normal font-weight-bold d-flex mt-2">
                    <app-icon-check class="icon-16 mr-1"  style="margin-top: -2px;"></app-icon-check>
                    {{ child.getAge() }} {{ labels.yearLabel(child.getAge()) }}
                </small>
            </div>
            <ng-template #notMetAgeRequirements>
                <small class="line-height-normal font-weight-bold d-flex mt-2">
                    <app-icon-alert-triangle class="icon-16 mr-1"  style="margin-top: -2px;"></app-icon-alert-triangle>
                    {{ child.getAge() }} {{ labels.yearLabel(child.getAge()) }}
                </small>
                <div class="line-height-normal mt-2">
                    <small [innerHtml]="configuration.childAgeError"></small>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="m-3 row">
        <div class="col-lg mb-3">
            <div class="line-height-normal text-overline text-muted">{{ labels.pedagogicLabel }}</div>
            <div *ngIf="child.meetingPedagogicRequirements; else notMetPedagogicRequirements">
                <small class="line-height-normal font-weight-bold d-flex mt-2">
                    <app-icon-check class="icon-16 mr-1"  style="margin-top: -2px;"></app-icon-check>
                    {{ labels.okLabel }}
                </small>
            </div>
            <ng-template #notMetPedagogicRequirements>
                <small class="line-height-normal font-weight-bold d-flex mt-2">
                    <app-icon-help-circle class="icon-16 mr-1"  style="margin-top: -2px;"></app-icon-help-circle>
                    {{ labels.nokLabel }}
                </small>
                <div class="line-height-normal mt-2">
                    <small [innerHtml]="configuration.childPedagogicError"></small>
                </div>
            </ng-template>
        </div>
    </div>

    <div class="bg-ecru p-3 d-flex mx-4 mt-3 mb-4 rounded">
        <img src="assets/icons/buoy.svg" />
        <div class="ml-3 mb-2 text-medium">
            <p>
                <span class="font-weight-semi-bold">{{ labels.questionAboutThis }}</span>
                {{ labels.thenContactYourFileManager }}
            </p>
            <app-client-advisor-cta [fileId]="fileId"></app-client-advisor-cta>
        </div>
    </div>
</div>