import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DocumentViewModel } from '../../viewmodels/portal-documents-document.viewmodel';

@Component({
  selector: 'app-portal-documents-list',
  templateUrl: './portal-documents-list.component.html'
})
export class PortalDocumentsListComponent implements OnInit {
  @Input() documents: DocumentViewModel[];
  @Input() active: DocumentViewModel;
  @Output() selected: EventEmitter<DocumentViewModel> = new EventEmitter();
  @Output() download: EventEmitter<DocumentViewModel> = new EventEmitter();
  @Input() darkSeparator = false;

  constructor() { }

  ngOnInit(): void {
  }

  borderTopActive(document: DocumentViewModel, first: boolean, i: number): boolean {
    // first item doesn't have a blue border on top when active
    if (!first && document === this.active) {
      return true;
    }

    // if the previous item is selected, the border between the two (this item's top border) has to be blue
    return i > 0 && this.documents[i - 1] === this.active;
  }

}
