import { RegionEnum } from 'src/app/core/payments/models/region-enum';

export class CertificateModel {
    showAmount: boolean;
    region: RegionEnum;
    fileId: string;
    headerAddress: string;
    footer: string;
    recipient: {
        firstname: string,
        lastname: string,
        street: string,
        postalCode: string,
        city: string,
        country: string,
    };
    translations: {
        headerCity: string,
        filenumber: string,
        confirmationOfChildBenefitPayments: string,
        infinoPaidChildBenefitsTo: string,
        wePaidFromTill: string,
        nameAndFirstname: string,
        month: string,
        amount: string,
        kindRegards: string,
    };
    payments: {
        childName: string,
        month: string,
        amount: number
    }[];
    signature: {
        signer: string,
        signerPosition: string,
        signatureUrl: string,
        signatureSize: [number, number],
    };
}
