import { Injectable } from '@angular/core';
import { SeparSchoolYearDetails } from '../models/separ-school-year-details.model';
import { SeparChildMapper } from './separ-child.mapper';

@Injectable()
export class SeparSchoolYearDetailsMapper {
    constructor(
        public childMapper: SeparChildMapper
    ) {
    }

    public map(data: any): SeparSchoolYearDetails {
        return new SeparSchoolYearDetails({
            children: data.children
                //.sort((a, b) => new Date(a.dateOfBirth).getTime() - new Date(b.dateOfBirth).getTime())
                //temporary sorting due to missing field on dateOfBirth
                .sort((a, b) => a.socialSecurityNumber - b.socialSecurityNumber)
                .map(child => this.childMapper.map(child)),
            familyIncomeCompleted: data.familyIncomeCompleted,
            incomeLimit: data.incomeLimit,
            realEstateIncomeCheck: data.realEstateIncomeCheck,
            schoolYear: data.schoolYear,
            separCalculationBlocked: data.separCalculationBlocked,
        });
    }
}
