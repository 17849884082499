import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { UserService } from '../../core/user/services/user.service';
import { environment } from './../../../environments/environment';

@Injectable()
export class KenticoResourceStringLoader implements TranslateLoader {

    constructor(
        private httpClient: HttpClient,
        private userService: UserService
    ) { }

    public getTranslation(lang: string): Observable<any> {
        return new Observable((observer) => {
            observer.next();
            observer.complete();
        });
    }

    public loadTranslation(lang: string, resourceStringKey: string): Observable<any> {
        const headers = new HttpHeaders()
            .set('language', lang)
            .set('region', this.userService.getRegion());
        const params = new HttpParams()
            .set('filter', resourceStringKey);

        return this.httpClient
            .get(`${environment.apiUrl}/ResourceString/GetResourceStringsByFilter`, {
                headers,
                params,
            })
            .pipe(
                catchError((error) => {
                    if (environment.production) {
                        // @TODO handle and show errors in a nice way
                        return throwError(error);
                    }
                    return of([]);
                })
            );
    }
}
