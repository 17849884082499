import { Component, Input } from '@angular/core';
import { IconColorEnum } from '../enums/icon-color.enum';

@Component({
  selector: 'app-icon-close',
  templateUrl: './icon-close.component.html',
})
export class IconCloseComponent {
  @Input() color: IconColorEnum;

  public get classes(): string {
    return this.color;
  }
}
