import { ComplexPasswordValidator } from './validators/complex-password.validator';
import { IValidator } from './validator.interface';
import { AlphaNumericValidator } from './validators/alpha-numeric.validator';
import { AlphabetValidator } from './validators/alphabet.validator';
import { DateRequiredNotZeroValidator } from './validators/date-required-not-zero.validator';
import { DateFormatValidator } from './validators/dateformat.validator';
import { EmailValidator } from './validators/email.validator';
import { MaxLenghtValidator } from './validators/max-length.validator';
import { PasswordMinLengthValidator } from './validators/password-min-length.validator';
import { MinLengthValidator } from './validators/min-length.validator';
import { MinValueValidator } from './validators/min-value.validator';
import { NationalInsuranceNumberValidator } from './validators/national-insurance-number.validator';
import { PostalcodeValidator } from './validators/postalcode.validator';
import { RequiredValidator } from './validators/required.validator';
import { TelephoneValidator } from './validators/telephone.validator';
import { IbanValidator } from './validators/iban.validator';
import { MinTextDateValidator } from './validators/min-text-date.validator';
import { MaxTextDateValidator } from './validators/max-text-date.validator';
import { CheckedValidator } from './validators/checked.validator';

export class Validators {
    public static required(): IValidator {
        return new RequiredValidator();
    }

    public static checked(): IValidator {
        return new CheckedValidator();
    }

    public static minimumLength(minimumLength: number): IValidator {
        return new MinLengthValidator(minimumLength);
    }

    public static passwordMinimumLength(minimumLength: number): IValidator {
        return new PasswordMinLengthValidator(minimumLength);
    }

    public static minimumValue(minimumValue: number): IValidator {
        return new MinValueValidator(minimumValue);
    }

    public static maximumLenght(maximumLenght: number): IValidator {
        return new MaxLenghtValidator(maximumLenght);
    }

    public static alphabet(): IValidator {
        return new AlphabetValidator();
    }

    public static alphaNumeric(): IValidator {
        return new AlphaNumericValidator();
    }

    public static dateRequiredNotZero(): IValidator {
        return new DateRequiredNotZeroValidator();
    }

    public static postalCode(): IValidator {
        return new PostalcodeValidator();
    }

    public static email(): IValidator {
        return new EmailValidator();
    }

    public static telephone(): IValidator {
        return new TelephoneValidator();
    }

    public static nationalInsuranceNumber(): IValidator {
        return new NationalInsuranceNumberValidator();
    }

    public static dateFormat(): IValidator {
        return new DateFormatValidator();
    }

    public static Iban(): IValidator {
        return new IbanValidator();
    }

    public static minTextDateValidator(minYear: number, minMonth: number, minDay: number): IValidator {
        return new MinTextDateValidator(minYear, minMonth, minDay);
    }

    public static maxTextDateValidator(maxYear: number, maxMonth: number, maxDay: number): IValidator {
        return new MaxTextDateValidator(maxYear, maxMonth, maxDay);
    }

    // requires at least 1 lowercase, 1 uppercase, 1 digit, 1 special character
    public static complexPassword(): IValidator {
        return new ComplexPasswordValidator();
    }
}
