import { RouterModule } from '@angular/router';
import { AuthenticationRefresherMapper } from './components/authentication-refresher/authentication-refresher.mapper';
import { AuthenticationRefresherComponent } from './components/authentication-refresher/authentication-refresher.component';
import { PortalUserModule } from '../../../core/portal-user/portal-user.module';
import { UserModule } from '../user/user.module';
import { AuthInterceptorProviders } from './helpers/auth-interceptor.helper';
import { TokenStorageService } from '../services/token-storage/token-storage.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalAuthenticationComponent } from './pages/overview/portal-authentication.component';
import { PortalForeignAuthenticationComponent } from './pages/foreign/portal-foreign-authentication.component';
import { PortalBackofficeAuthenticationComponent } from './pages/backoffice/portal-backoffice-authentication.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { CardsModule } from '../../../shared/components/cards/card.module';
import { InputsModule } from 'src/app/shared/components/inputs/inputs.module';
import { PortalApiService } from './services/portal-api.service';
import { PortalForeignAuthenticationMapper } from './pages/foreign/portal-foreign-authentication.mapper';
import { PortalBackofficeAuthenticationMapper } from './pages/backoffice/portal-backoffice-authentication.mapper';
import { PortalAuthenticationMapper } from './pages/overview/portal-authentication.mapper';
import { PortalForgotPasswordMapper } from './pages/forgotPassword/portal-forgot-password.mapper';
import { PortalForgotPasswordComponent } from './pages/forgotPassword/portal-forgot-password.component';
import { NewAccountComponent } from './components/new-account/new-account.component';
import { ResetPasswordService } from './services/reset-password.service';
import { PortalResetPasswordComponent } from './pages/resetPassword/portal-reset-password.component';
import { PortalResetPasswordMapper } from './pages/resetPassword/portal-reset-password.mapper';
import { PortalAuthenticationRefresherService } from './services/portal-authentication-refresher.service';
import { PortalBurgerProfielService } from './services/portal-burgerprofiel.service';

@NgModule({
  declarations: [
    NewAccountComponent,
    PortalAuthenticationComponent,
    PortalForeignAuthenticationComponent,
    PortalBackofficeAuthenticationComponent,
    PortalForgotPasswordComponent,
    PortalResetPasswordComponent,
    AuthenticationRefresherComponent
  ],
  imports: [
    CommonModule,
    LocalizeRouterModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    PortalUserModule,
    UserModule,
    CardsModule,
    InputsModule,
    RouterModule,
  ],
  providers: [
    TokenStorageService,
    AuthInterceptorProviders,
    PortalApiService,
    ResetPasswordService,
    PortalForeignAuthenticationMapper,
    PortalBackofficeAuthenticationMapper,
    PortalAuthenticationMapper,
    PortalForgotPasswordMapper,
    PortalResetPasswordMapper,
    PortalAuthenticationRefresherService,
    AuthenticationRefresherMapper,
  ]
})
export class PortalAuthenticationModule { }
