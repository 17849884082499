import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SingletonModule } from '../../shared/modules/singleton.module';
import { UserStore } from './data/user.store';
import { RegionMapper } from './mappers/region.mapper';
import { UserService } from './services/user.service';

@NgModule({
    providers: [
        UserStore,
        UserService,
        RegionMapper,
    ]
})
export class UserModule extends SingletonModule {
    constructor(@Optional() @SkipSelf() module: UserModule) {
        super(module);
    }
}
