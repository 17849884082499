import { IValidator } from '../validator.interface';
import { ValidationError } from '../validation-error.enum';

/**
 * Validates if a property is different from undefined or null
 */
export class RequiredValidator implements IValidator {

    public validate(value: any): string {
        if (value === undefined || value == null || value.trim() === '') {
            return ValidationError.Required;
        }

        return undefined;
    }
}
