import { Validators } from '../../../../../shared/infrastructure/validation/validators';
import { IValidationField } from '../../../../../shared/infrastructure/validation/validation-field.interface';

export class PortalResetPasswordModel {
    public authenticateWithLoginEmailLabel: string;
    public authenticateWithLoginEmailPlaceholder: string;

    public resetPasswordNewPasswordTitle: string;
    public resetPasswordNewPasswordLabel: string;
    public resetPasswordNewPasswordPlaceholder: string;
    public resetPasswordConfirmPasswordLabel: string;
    public resetPasswordConfirmPasswordPlaceholder: string;
    public resetPasswordSubmitLabel: string;

    constructor(partial?: Partial<PortalResetPasswordModel>) {
        Object.assign(this, partial);
    }
}
