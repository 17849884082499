<div *ngIf="config$ | async as config" class="bg-blue-dark text-white">
    <div class="modal-header align-items-center border-0">
        <app-icon-info-circle [color]="iconColorWhite" class="icon-24"></app-icon-info-circle> {{ config.title }}

        <div class="ml-auto h-100 cursor-pointer" (click)="close()">
            <app-icon-close></app-icon-close>
        </div>
    </div>
    <div class="modal-body px-4 pt-0 text-large">
        <p *ngIf="config.isInnerHtml; else textInline" [innerHTML]="config.description"></p>
        <ng-template #textInline>
            <p>{{ config.description }}</p>
        </ng-template>
    </div>
</div>