import { PaymentsCacheStore } from './data/payments-cache.store';
import { SingletonModule } from '../../shared/modules/singleton.module';
import { PaymentsService } from './services/payments.service';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  providers: [
    PaymentsService,
    PaymentsCacheStore
  ]
})
export class PaymentsDataModule {}
