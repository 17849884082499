<div class="form-group">
    <label [for]="id">
        {{ label }}
        <span *ngIf="!required" class="text-danger">{{
            "Infino.ClientPortal.Validation.optional" | translate
        }}</span>
    </label>

    <input
        class="form-control input"
        #input
        [id]="id"
        inputmode="date"
        type="date"
        [placeholder]="placeholder"
        [disabled]="disabled"
        [required]="required"
        [ngModel]="dateValue"
        (ngModelChange)="dateValueChanged($event)"
        [ngModelOptions]="{ standalone: true }"
        (blur)="startValidation()"
        [ngClass]="[validationErrors ? 'is-invalid' : '']"
    />

    <div class="invalid-feedback">
        <span *ngFor="let error of validationErrors">
            {{ "Infino.ClientPortal.Validation." + error | translate }}
        </span>
    </div>
    <small class="form-text">{{ subText }}</small>
</div>
