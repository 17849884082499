import { IValidator } from '../validator.interface';
import { ValidationError } from '../validation-error.enum';

/**
 * Validates if the input is not empty and checks if the string only has alpahetic characters
 */
export class AlphabetValidator implements IValidator {

    private alphabetValid: RegExp = /^[a-zA-Z\-\'\s\u00C0-\u017F]{1,}$/;

    public validate(value: string): string {
        if (value === undefined || value === null || value.length === 0 || typeof value === 'object') {
            return undefined;
        }

        const matches = value.match(this.alphabetValid);

        if (matches && matches.length) {
            return undefined;
        }
        return ValidationError.Alphabet;
    }

}
