import { PortalNotificationsComponent } from './portal-notifications.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [PortalNotificationsComponent],
  imports: [
    CommonModule,
    NgbToastModule,
  ],
  exports: [PortalNotificationsComponent]
})
export class PortalNotificationsComponentModule { }
