import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationConfiguration } from 'src/app/core/configuration/models/authentication-configuration.model';
import { PortalBackofficeAuthenticationModel } from './portal-backoffice-authentication.model';

@Injectable()
export class PortalBackofficeAuthenticationMapper {

    constructor(
        private translateService: TranslateService,
        private localize: LocalizeRouterService,
    ) { }

    public map(configuration: AuthenticationConfiguration): PortalBackofficeAuthenticationModel {
        return new PortalBackofficeAuthenticationModel({
            backofficeAuthenticationTitle: configuration.backofficeAuthenticationTitle,
            backofficeAuthenticationSocialSecurityNumberLabel: configuration.backofficeAuthenticationSocialSecurityNumberLabel,
            backofficeAuthenticationSocialSecurityNumberPlaceholder: configuration.backofficeAuthenticationSocialSecurityNumberPlaceholder,
            backofficeAuthenticationAccountLabel: configuration.backofficeAuthenticationAccountLabel,
            backofficeAuthenticationAccountPlaceholder: configuration.backofficeAuthenticationAccountPlaceholder,
            backofficeAuthenticationPasswordLabel: configuration.backofficeAuthenticationPasswordLabel,
            backofficeAuthenticationPasswordPlaceholder: configuration.backofficeAuthenticationPasswordPlaceholder,
            authenticateWithLoginEmailLabel: configuration.authenticateWithLoginEmailLabel,
            authenticateWithLoginEmailPlaceholder: configuration.authenticateWithLoginEmailPlaceholder,
            authenticateWithLoginPasswordLabel: configuration.authenticateWithLoginPasswordLabel,
            authenticateWithLoginPasswordPlaceholder: configuration.authenticateWithLoginPasswordPlaceholder,
            authenticateWithLoginButton: configuration.authenticateWithLoginButton,
            forgotPassword: this.translateService.instant('Infino.Portal.Backoffice.ForgotPassword'),
            forgotPasswordUrl: this.localize.translateRoute('/portal/auth/forgotPassword') as string,
        });
    }
}
