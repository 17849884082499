export class NationalInsuranceNumberFormatHelper {

    static transform(value: string, isDeletion: boolean): string {
        const numbersRegex: RegExp = /[0-9]/mg;
        const matches = value.match(numbersRegex);
        let result = '';

        if (matches && matches.length) {
            matches.forEach((val, i) => {
                if (i <= 10) {
                    result += val;
                }

                if ((i === 1 && (matches.length > 2 || !isDeletion))
                    || (i === 3 && (matches.length > 4 || !isDeletion))
                    || (i === 8 && (matches.length > 9 || !isDeletion))) {
                    result += '.';
                }

                if (i === 5 && (matches.length > 6 || !isDeletion)) {
                    result += '-';
                }
            });
        }

        return result;
    }
}
