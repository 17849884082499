import { ExpansionCase } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'app-redirect-with-queryparams',
  template: 'redirecting',
})
export class RedirectWithQueryParamsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localize: LocalizeRouterService
  ) { }


  ngOnInit(): void {
    const redirectTo = this.route.snapshot.data['redirectTo'];
    if (!redirectTo) {
      throw new Error('RedirectWithQueryParamsComponent needs data: { redirectTo } to be defined');
    }
    const localizedRoute = this.localize.translateRoute(redirectTo);
    this.route.queryParams.subscribe(params => {
      this.router.navigate([localizedRoute], { queryParams: params });
    });
  }

}
