import { LocalizedDatePipe } from './localized-date.pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        TranslateModule,
    ],
    declarations: [
        LocalizedDatePipe
    ],
    exports: [
        LocalizedDatePipe
    ]
})
export class LocalizedDateModule { }
