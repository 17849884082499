import { Component, Input, OnInit } from '@angular/core';
import { FileViewModel } from '../../viewmodels/portal-files-file.viewmodel';

@Component({
  selector: 'app-administrator-overview',
  templateUrl: './administrator-overview.component.html'
})
export class AdministratorOverviewComponent {
  @Input() public file: FileViewModel;

  public get isLoaded(): boolean {
    return this.file != null;
  }
}
