import { ChildViewModel } from './portal-files-child.viewmodel';
import { FileViewModel } from './portal-files-file.viewmodel';
import { ChildDetailLabelsViewModel } from './portal-files-child-detail-labels.viewmodel';
import { ChildBasicAllowanceStatusEnum } from 'src/app/core/files/models/child-basic-allowance-status-enum';

export class PortalFilesViewModel {
    public isLoaded: boolean;

    public files: FileViewModel[];
    public selectedFile: FileViewModel;

    public fileLabel: string;
    public beneficiariesTitle: string;
    public mediatorTitle: string;
    public administratorTitle: string;
    public changeDataLabel: string;
    public childrenActiveTitle: string;
    public childrenSuspendedTitle: string;
    public childrenClosedTitle: string;
    public openOnboadingFlowButton: string;
    public childOnTheWay: string;
    public primaryEmail: string;

    public yearLabel: (amount: number) => string;

    public selectedChild: ChildViewModel;

    public childDetailLabels: ChildDetailLabelsViewModel;

    public hasNextChild: boolean;
    public hasPreviousChild: boolean;

    constructor(init?: Partial<PortalFilesViewModel>) {
        Object.assign(this, init);

        if (this.files && this.files.length > 0) {
            this.selectedFile = this.files[0];
        }
    }

    public selectFileId(fileId: string): void {
        const file = this.files.find(f => f.fileId === fileId);
        this.selectFile(file);
    }

    public selectFile(file: FileViewModel): void {
        this.selectedFile = file;
    }

    public isFileSelected(): boolean {
        return this.selectedFile != null;
    }

    public hasSelectedFileChildrenWithBasicAllowanceStatus(status: ChildBasicAllowanceStatusEnum): boolean {
        return this.selectedFile != null
            && this.selectedFile.children != null
            && this.selectedFile.children.length > 0
            && this.selectedFile.children.filter(child => child.childBasicAllowance?.status === status)?.length > 0;
    }

    public selectChild(child: ChildViewModel) {
        this.selectedChild = child;
        if (child != null) {
            const index = this.indexOfChild(child);
            this.hasNextChild = (index + 1) < this.selectedFile.children.length;
            this.hasPreviousChild = index > 0;
        }

    }

    public hideDetail() {
        this.selectedChild = null;
    }

    public nextChildDetails() {
        let newChild: ChildViewModel = null;
        if (this.selectedChild != null) {
            const nextIndex = this.indexOfChild(this.selectedChild) + 1;

            if ((nextIndex) < this.selectedFile.children.length) {
                newChild = this.selectedFile.children[nextIndex];
            }
        }
        this.selectChild(newChild);
    }

    public previousChildDetails() {
        let newChild: ChildViewModel;
        if (this.selectedChild != null) {
            const previousIndex = this.selectedFile.children.map((c) => c.getFullName()).indexOf(this.selectedChild.getFullName()) - 1;
            if (previousIndex >= 0) {
                newChild = this.selectedFile.children[previousIndex];
            }
        }
        this.selectChild(newChild);
    }

    private indexOfChild(child: ChildViewModel): number {
        return this.selectedFile.children.map((c) => c.getFullName()).indexOf(this.selectedChild.getFullName());
    }
}
