import { filter, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ChildBenefitsState } from './../models/child-benefits-state.model';
import { Injectable } from '@angular/core';
import { Store } from '../../../shared/infrastructure/state/store';

@Injectable()
export class ChildBenefitsStateStore extends Store<ChildBenefitsState> {
    public readonly loaded$: Observable<ChildBenefitsState> =
        this.state$.pipe(filter(state => state.loading === false), take(1));

    protected getInitialState(): ChildBenefitsState {
        return {
            loading: true,
            files: undefined,
            selectedId: undefined,
        };
    }

    protected getStoreName(): string {
        return 'child-benefits-state-store';
    }


    public select(fileId: string) {
        this.update({
            ...this.getState(),
            selectedId: fileId,
        });
    }
}
