import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Observable } from 'rxjs';

import { OnboardingStore } from '../../modules/onboarding/data/onboarding.store';

@Injectable()
export class OnboardingFlowDirectAccessGuard implements CanActivate {
    constructor(
        private router: Router,
        private store: OnboardingStore,
        private localize: LocalizeRouterService,
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const formData = this.store.getOnboardingForm();
        if (formData.childDateOfBirth === '') {
            const translatedPath: any = this.localize.translateRoute(
                '/onboarding/1'
            );
            this.router.navigate([translatedPath]);
            return false;
        }
        return true;
    }
}
