<div>
    <div class="modal-header bg-blue-dark text-white">
        {{ model.authenticationRequired ? model.translations.titleExpired : model.translations.title }}
    </div>
    <div class="modal-body bg-ecru p-4">
        <p>{{ model.authenticationRequired ?model.translations.descriptionExpired : model.translations.description }}
        </p>
    </div>
    <div class="d-flex bg-white p-4">
        <button type="button" class="btn btn-outline-primary w-50" (click)="logout()" [disabled]="model.loading">
            {{ model.authenticationRequired ? model.translations.loginAgain : model.translations.logout }}
        </button>
        <button type="button" class="btn btn-primary w-50 ml-3" (click)="refresh()" *ngIf="!model.authenticationRequired"
            [disabled]="model.loading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                *ngIf="model.loading"></span>
            {{ model.translations.stayLoggedIn }} {{ !model.loading ? '(' + (model.expiresIn | async) + ')' : ''}}
        </button>
    </div>
</div>