<div class="h-100 d-flex flex-column justify-content-center">
    <div class="container">
        <div class="d-flex flex-column flex-lg-row align-content-between align-items-stretch py-4">
            <div class="col">
                <div class="py-4 py-lg-0 d-flex flex-column align-items-stretch">
                    <form name="login" (ngSubmit)="login()">
                        <p class="h4 pb-3" [innerHtml]="model.authenticateWithLoginTitle"></p>
                        <app-text-input [id]="'email'" [name]="'email'" [placeholder]="model.authenticateWithLoginEmailPlaceholder"
                            [label]="model.authenticateWithLoginEmailLabel" [(ngModel)]="formModel.email"
                            [validationErrors]="validationErrors['email']" (validate)="validateItem('email')"
                            (resetValidate)="resetItemValidation('email')" [feedbackSpacing]="true">
                        </app-text-input>
                        <app-password-input [id]="'password'" [name]="'password'"
                            [placeholder]="model.authenticateWithLoginPasswordPlaceholder"
                            [label]="model.authenticateWithLoginPasswordLabel" [(ngModel)]="formModel.password"
                            [validationErrors]="validationErrors['password']" (validate)="validateItem('password')"
                            (resetValidate)="resetItemValidation('password')"
                            (onPasswordForgotten)="showResetPassword()"
                            [passwordResetText]="model.authenticateWithLoginForgotPasswordButton" [feedbackSpacing]="true">
                        </app-password-input>
                        <div  *ngIf="this.errors && this.errors.length > 0">
                            <div class="alert alert-danger" *ngFor="let error of errors" [innerHTML]="error | translate">
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary my-3 text-white form-control" [disabled]="loading">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                            {{model.authenticateWithLoginButton}}
                        </button>
                        <div class="d-flex justify-content-center">
                            <a
                                [href]="getAuthenticationOverviewUrl()"><u>{{model.authenticateWithLoginOpenCSAMButton}}</u></a>
                        </div>
                    </form>
                </div>
            </div>
            <div class="border-left border-gray mx-5 d-none d-lg-block" 
                    *ngIf="model.authenticateWithLoginNewAccountPageUrl !== ''"></div>
            <div class="col" *ngIf="model.authenticateWithLoginNewAccountPageUrl !== ''">
                <app-new-account></app-new-account>
            </div>
        </div>
    </div>
</div>