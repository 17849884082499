import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

import { DateFormatHelper } from '../../../helpers/date-format.helper';
import { IbanFormatHelper } from '../../../helpers/iban-format.helper';
import { NationalInsuranceNumberFormatHelper } from '../../../helpers/national-insurance-number-format.helper';
import { InputType } from './configurable-text-input.component';

@Directive({ selector: '[appCustomTextFormat]' })
export class ConfigurableTextInputDirective implements OnInit {

    @Input() inputType: InputType;

    private el: HTMLInputElement;

    constructor(private elementRef: ElementRef) {
        this.el = this.elementRef.nativeElement;
    }

    public ngOnInit(): void {
        this.format(this.el.value, undefined);
    }

    @HostListener('keyup', ['$event.target.value', '$event.keyCode'])
    public onKeyup(value: string, keyCode: number): void {
        this.format(value, keyCode);
    }

    private format(value: string, keyCode: number) {
        const deletion = keyCode === 8;
        if (this.inputType === InputType.Date) {
            this.el.value = DateFormatHelper.transform(value, deletion);
        }
        if (this.inputType === InputType.NationalInsuranceNumber) {
            this.el.value = NationalInsuranceNumberFormatHelper.transform(value, deletion);
        }
        if (this.inputType === InputType.Iban) {
            this.el.value = IbanFormatHelper.transform(value, deletion);
        }
    }
}
