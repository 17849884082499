import { Injectable } from '@angular/core';
import { Store } from '../../../shared/infrastructure/state/store';
import { ChildBenefitsFile} from '../models/child-benefits-file.model';

@Injectable()
export class ChildBenefitsFileStore extends Store<ChildBenefitsFile[]> {
    protected getInitialState(): ChildBenefitsFile[] {
        return [];
    }

    protected getStoreName(): string {
        return 'child-benefits-file-store';
    }
}
