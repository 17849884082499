import { IValidator } from '../validator.interface';
import { ValidationError } from '../validation-error.enum';

/**
 * Validates if the input is not empty and checks if the string only has alpahetic and/or numeric characters
 */
export class AlphaNumericValidator implements IValidator {

    private alphaNumericValid: RegExp = /^[a-zA-Z0-9ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿ +]+$/;

    public validate(value: string): string {
        if (value === undefined || value === null || value.length === 0 || typeof value === 'object') {
            return undefined;
        }

        const matches = value.match(this.alphaNumericValid);

        if (matches && matches.length) {
            return undefined;
        }

        return ValidationError.AlphaNumeric;
    }
}
