import { PortalChildStatusLink } from './../models/portal-childstatuslink.model';
import { RegionMapper } from './../../user/mappers/region.mapper';
import { Injectable } from '@angular/core';

@Injectable()
export class PortalChildStatusLinksMapper {
    constructor(private regionMapper: RegionMapper) {}

    public map(data: {Items: any[]} | undefined): PortalChildStatusLink[] {
        return data?.Items.map(item => new PortalChildStatusLink({
            keys: item.Keys || [],
            link: item.Link || null,
        })) || [];
    }
}
