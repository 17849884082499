import { PortalAuthenticationService } from './../../../authentication/services/portal-authentication.service';
import { PortalUserStore } from './../../../../../core/portal-user/data/portal-user.store';
import { Component, OnInit } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { takeLast } from 'rxjs/operators';
import { ChildBenefitsPayment } from 'src/app/core/payments/models/child-benefits-payment';
import { PaymentsPaginationService } from 'src/app/core/payments/services/payments-pagination.service';
import { UserInfoModel } from 'src/app/core/portal-user/models/user-info.model';
import { IconColorEnum } from 'src/app/shared/components/icons/enums/icon-color.enum';
import { IconDirectionEnum } from 'src/app/shared/components/icons/enums/icon-direction.enum';
import { OverviewPageMapper } from './overview-page.mapper';
import { OverviewPageModel } from './overview-page.model';
import { PortalAlertConfigurationStore } from './../../../../../core/configuration/data/portal-alert-configuration.store';
import { PortalAlertConfigurationModel } from './../../../../../core/configuration/models/portal-alert-configuration.model';
import { RegionEnum } from 'src/app/core/payments/models/region-enum';

@Component({
    selector: 'app-portal-overview',
    templateUrl: './portal-overview.component.html',
})
export class PortalOverviewComponent implements OnInit {
    public model: OverviewPageModel = new OverviewPageModel();

    public selected: ChildBenefitsPayment;
    public showPanel: boolean;
    public iconColorBrown = IconColorEnum.Brown100;
    public iconDirectionRight = IconDirectionEnum.Right;
    public showAlerts: boolean;

    constructor(
        public payments: PaymentsPaginationService,
        public overviewPageMapper: OverviewPageMapper,
        private localize: LocalizeRouterService,
        private userStore: PortalUserStore,
        private auth: PortalAuthenticationService,
        private portalAlertConfigurationStore: PortalAlertConfigurationStore
    ) { }

    ngOnInit(): void {
        this.payments.setPageNumber(1);
        this.payments.setItemsPerPage(2);
        this.payments.allPayments$.pipe(takeLast(1)).subscribe();

        this.model = this.overviewPageMapper.map();

        this.model.alerts = [];

        this.payments.regions$.subscribe(regions => {
            for (let index = 0; index < regions.length; index++) {
                this.setAlerts(regions[index]);
            }
        });

        this.userStore.state$.subscribe((userInfo: UserInfoModel) => {
            this.model.firstname = userInfo.firstName;
        });
    }

    public onSelect(selected: ChildBenefitsPayment) {
        if (this.selected === selected && selected.isRegularisation) {
            this.selected = undefined;
            return;
        }

        this.selected = selected;
        this.showPanel = !!selected && !selected.isRegularisation;
    }

    public onClose() {
        this.onSelect(undefined);
    }

    public getAllPaymentsLink(): string {
        const link: string = this.localize.translateRoute('/portal/payments') as string;

        return link;
    }

    public getGreeting(): string {
        let greeting = this.model.goodNightLabel;

        const date = new Date();
        const hours = date.getHours();
        if (hours > 6 && hours < 12) {
            greeting = this.model.goodMorningLabel;
        } else if (hours >= 12 && hours < 18) {
            greeting = this.model.goodAfternoonLabel;
        } else if (hours >= 18 && hours < 24) {
            greeting = this.model.goodEveningLabel;
        }

        return greeting;
    }

    public logout() {
        this.auth.logoutAndRedirect();
    }

    public setAlerts(region: RegionEnum) {

        this.portalAlertConfigurationStore.state$.subscribe(
            (alertConfiguration: PortalAlertConfigurationModel) => {
                let alert: string;

                if (region === RegionEnum.Brussels) {
                    alert = alertConfiguration.alertBrussels;

                } else if (region === RegionEnum.Wallonia) {
                    alert = alertConfiguration.alertWallonia;

                } else if (region === RegionEnum.Flanders) {
                    alert = alertConfiguration.alertFlanders;
                }

                if (!alert) {
                    // Alert is stil empty, check if a general alert is found
                    region = RegionEnum.Federal;
                    alert = alertConfiguration.alertGeneral;
                }

                if (alert && alert.length > 0) {
                    const allertSessionStorage = sessionStorage.getItem('showAlert-' + region);
                    const showAlert = !allertSessionStorage || allertSessionStorage !== alert.length.toString();
                    this.model.alerts.push({ region: region, message: alert, show: showAlert });

                    if (showAlert) {
                        this.showAlerts = true;
                    }
                }
            }
        );
    }

    public hideAlert(alert: { region: RegionEnum, message: string }) {
        sessionStorage.setItem('showAlert-' + alert.region, alert.message?.length.toString());

        const updateAlert = this.model.alerts.find(a => a.region === alert.region);
        const index = this.model.alerts.indexOf(updateAlert);
        this.model.alerts[index].show = false;

        if (!this.model.alerts.some(a => a.show)) {
            this.showAlerts = false;
        }
    }
}
