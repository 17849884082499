import { IValidator } from '../validator.interface';
import { ValidationError } from '../validation-error.enum';

/**
 * Date required and day/month/year values > 0
 */
export class DateRequiredNotZeroValidator implements IValidator {

    public validate(value: any): string {
        if (value === undefined || value == null || value === '') {
            return ValidationError.Required;
        }

        const dateParts = value.split('/');
        const theDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        if (
            theDate.getDate() > 0 &&
            theDate.getMonth() >= 0 &&
            theDate.getFullYear() > 0
        ) {
            return undefined;
        }
        return ValidationError.Required; // don't return 'zero' or other, but return 'required' (copy)
    }
}
