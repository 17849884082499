<div [ngClass]="[isFormControl ? 'form-group' : '']">
    <label [for]="id" [ngClass]="labelClass">
        {{label}}
        <span *ngIf="!required" class="text-danger">{{'Infino.ClientPortal.Validation.optional' | translate}}</span>
    </label>

    <select 
        class="input w-100"
        #input
        [id]="id"
        [disabled]="disabled"
        [required]="required"
        [(ngModel)]="value"
        (ngModelChange)="valueChanged($event)"
        (blur)="startValidation()"
        [ngClass]="[validationErrors?.length > 0 ? 'is-invalid': '' ] + selectClass + [isFormControl ? ' form-control' : '']"
    >
        <option *ngIf="placeholder" value="" [selected]="!this.value" [disabled]="true">{{ placeholder }}</option>
        <option *ngFor="let option of options" [ngValue]="option.value" [selected]="this.value === option.value">{{ option.label }}</option>
    </select>
    
    <div class="invalid-feedback">
        <span *ngFor="let error of validationErrors">
            {{'Infino.ClientPortal.Validation.' + error | translate}}
        </span>
    </div>
    <small class="form-text">{{subText}}</small>
</div>
