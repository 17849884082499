import { SeparChild } from './separ-child.model';

export class SeparSchoolYearDetails {
    public children: SeparChild[];
    public familyIncomeCompleted: boolean;
    public incomeLimit: string;
    public realEstateIncomeCheck: string;
    public schoolYear: string;
    public separCalculationBlocked: boolean;

    constructor(init?: Partial<SeparSchoolYearDetails>) {
        Object.assign(this, init);
    }
}
