import { ChildBenefitsPaymentOverview } from './../models/child-benefits-payments-overview';
import { ChildBenefitsPayment } from './../models/child-benefits-payment';
import { PaymentsCacheModel } from './../models/payments-cache.model';
import { shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '../../../shared/infrastructure/state/store';

@Injectable()
export class PaymentsCacheStore extends Store<PaymentsCacheModel> {
    protected getInitialState(): PaymentsCacheModel {
        return {
            futurePayments: undefined,
            paymentsCache: {},
            overview: undefined
        };
    }

    protected getStoreName(): string {
        return 'payments-cache-store';
    }

    public getCacheYear(year: number): Observable<ChildBenefitsPayment[]> {
        return this.getState().paymentsCache[year];
    }

    public setCacheYear(year: number, stream: Observable<ChildBenefitsPayment[]>) {
        const state = this.getState();
        this.update({
            ...state,
            paymentsCache: {
                ...state.paymentsCache,
                [year]: stream.pipe(shareReplay(1))
            }
        });
    }

    public getOverview(): Observable<ChildBenefitsPaymentOverview[]> {
        return this.getState().overview;
    }

    public setOverview(stream: Observable<ChildBenefitsPaymentOverview[]>) {
        const state = this.getState();
        this.update({
            ...state,
            overview: stream.pipe(shareReplay(1))
        });
    }

    public getFuturePayments(): Observable<ChildBenefitsPayment[]> {
        return this.getState().futurePayments;
    }

    public setFuturePayments(stream: Observable<ChildBenefitsPayment[]>) {
        const state = this.getState();
        this.update({
            ...state,
            futurePayments: stream.pipe(shareReplay(1))
        });
    }
}
