import { Injectable } from '@angular/core';
import { ChildBenefitsFile } from '../models/child-benefits-file.model';
import { ChildBenefitsBeneficiaryMapper } from './child-benefits-beneficiary.mapper';
import { ChildBenefitsChildMapper } from './child-benefits-child.mapper';
import { TokenStorageService } from '../../../modules/portal/services/token-storage/token-storage.service';

@Injectable()
export class ChildBenefitsFileMapper {
    constructor(
        public beneficiaryMapper: ChildBenefitsBeneficiaryMapper,
        public childMapper: ChildBenefitsChildMapper,
        private tokenStorageService: TokenStorageService
    ) { }

    public map(data: any): ChildBenefitsFile {
        if (!data) {
            return null;
        }

        const currentSSN = this.tokenStorageService.getTokenParsed().ssn;

        return new ChildBenefitsFile({
            region: data.region,
            fileId: data.fileId,
            street: data.street,
            city: data.city,
            zipcode: data.zipcode,
            country: data.country,
            beneficiaries: data.beneficiaries.map(b => {
                //hide social Security Number of beneficiaries who is not logged in person.
                if (b.socialSecurityNumber !== currentSSN) {
                    b.socialSecurityNumber = "";
                }

                return this.beneficiaryMapper.map(b);
            }),
            children: data.children.map(child => this.childMapper.map(child)),
            fileManagerEmail: data.fileManagerEmail,
            fileManagerFirstName: data.fileManagerFirstName,
            fileManagerLastName: data.fileManagerLastName,
            fileManagerPhone: data.fileManagerPhone,
            officeAddressCity: data.officeAddressCity,
            officeAddressCountry: data.officeAddressCountry,
            officeAddressInfo: data.officeAddressInfo,
            officeAddressStreet: data.officeAddressStreet,
            officeAddressZipCode: data.officeAddressZipCode,
            mediatorAccount: data.mediatorAccount,
            mediatorAddressCity: data.mediatorAddressCity,
            mediatorAddressCountry: data.mediatorAddressCountry,
            mediatorAddressStreet: data.mediatorAddressStreet,
            mediatorAddressZipCode: data.mediatorAddressZipCode,
            mediatorFirstName: data.mediatorFirstName,
            mediatorLastName: data.mediatorLastName,
            administratorAccount: data.administratorAccount,
            administratorAddressCity: data.administratorAddressCity,
            administratorAddressCountry: data.administratorAddressCountry,
            administratorAddressStreet: data.administratorAddressStreet,
            administratorAddressZipCode: data.administratorAddressZipCode,
            administratorFirstName: data.administratorFirstName,
            administratorLastName: data.administratorLastName,
        });
    }
}
