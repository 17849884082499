import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CookieBannerComponent } from './cookie-banner/cookie-banner.component';
import { CookieCategoryManageComponent } from './cookie-category-manage/cookie-category-manage.component';
import { CookieCategoryComponent } from './cookie-category/cookie-category.component';
import { CookieBannerService } from './services/CookieBanner.service';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
    ],
    declarations: [
      CookieBannerComponent,
      CookieCategoryComponent,
      CookieCategoryManageComponent,
    ],
    exports: [
      CookieBannerComponent,
      CookieCategoryComponent,
      CookieCategoryManageComponent,
    ],
    providers: [
      CookieBannerService,
    ]
})
export class CookiesModule { }
