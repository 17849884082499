import { IValidator } from '../validator.interface';
import { ValidationError } from '../validation-error.enum';

/**
 * Validates a string to be complex. Requires the following:
 *  - at least 1 lowercase letter
 *  - at least 1 uppercase letter
 *  - at least 1 number
 *  - at least 1 non-alphanumeric character
 */
export class ComplexPasswordValidator implements IValidator {

    constructor() {}

    public validate(value: string): string {
        if (value === undefined || value === null || value.length === 0 || typeof value === 'object') {
            return undefined;
        }

        const characters = value.split('');

        const hasLowercase = characters.some(c => this.isAlphaNumeric(c) && c === c.toLowerCase());
        const hasUppercase = characters.some(c => this.isAlphaNumeric(c) && c === c.toUpperCase());
        const hasDigit = value.split('').some(c => '0123456789'.includes(c));
        const hasSpecialCharacter = value.split('').some(c => !this.isAlphaNumeric(c));

        const isComplex = hasLowercase && hasUppercase && hasDigit && hasSpecialCharacter;

        if (!isComplex) {
            return ValidationError.ComplexPassword;
        }

        return undefined;
    }

    private isAlphaNumeric(str) {
        let code: number;
        let i: number;
        let len: number;

        for (i = 0, len = str.length; i < len; i++) {
            code = str.charCodeAt(i);
            if (!(code > 47 && code < 58) && // numeric (0-9)
                !(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123)) { // lower alpha (a-z)
                return false;
            }
        }
        return true;
    }
}
