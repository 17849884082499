import { Injectable } from '@angular/core';
import { AuthenticationConfiguration } from '../models/authentication-configuration.model';


@Injectable()
export class AuthenticationConfigurationMapper {

  public map(data: any): AuthenticationConfiguration {
    return new AuthenticationConfiguration({
      overviewTitle: data.OverviewTitle,
      overviewDescription: data.OverviewDescription,
      authenticationCSAMTitle: data.AuthenticationCSAMTitle,
      authenticationCSAMDescription: data.AuthenticationCSAMDescription,
      authenticationCSAMIcon: data.AuthenticationCSAMIconUrl,
      authenticationCSAMButtonText: data.AuthenticationCSAMButtonText,
      authenticationLoginTitle: data.AuthenticationLoginTitle,
      authenticationLoginDescription: data.AuthenticationLoginDescription,
      authenticationLoginIcon: data.AuthenticationLoginIconUrl,
      authenticationLoginButtonText: data.AuthenticationLoginButtonText,
      authenticateWithLoginTitle: data.AuthenticateWithLoginTitle,
      authenticateWithLoginEmailLabel: data.AuthenticateWithLoginEmailLabel,
      authenticateWithLoginEmailPlaceholder: data.AuthenticateWithLoginEmailPlaceholder,
      authenticateWithLoginPasswordLabel: data.AuthenticateWithLoginPasswordLabel,
      authenticateWithLoginPasswordPlaceholder: data.AuthenticateWithLoginPasswordPlaceholder,
      authenticateWithLoginForgotPasswordButton: data.AuthenticateWithLoginForgotPasswordButton,
      authenticateWithLoginButton: data.AuthenticateWithLoginButton,
      authenticateWithLoginOpenCSAMButton: data.AuthenticateWithLoginOpenCSAMButton,
      authenticateWithLoginNewAccountTitle: data.AuthenticateWithLoginNewAccountTitle,
      authenticateWithLoginNewAccountDescription: data.AuthenticateWithLoginNewAccountDescription,
      authenticateWithLoginNewAccountButton: data.AuthenticateWithLoginNewAccountButton,
      authenticateWithLoginNewAccountPageUrl: data.AuthenticateWithLoginNewAccountPageUrl,
      resetPasswordTitle: data.ResetPasswordTitle,
      resetPasswordButton: data.ResetPasswordButton,
      resetPasswordSuccessMessage: data.ResetPasswordSuccessMessage,
      resetPasswordReturnToLoginButton: data.ResetPasswordReturnToLoginButton,
      resetPasswordNewPasswordTitle: data.ResetPasswordNewPasswordTitle,
      resetPasswordNewPasswordLabel: data.ResetPasswordNewPasswordLabel,
      resetPasswordNewPasswordPlaceholder: data.ResetPasswordNewPasswordPlaceholder,
      resetPasswordConfirmPasswordLabel: data.ResetPasswordConfirmPasswordLabel,
      resetPasswordConfirmPasswordPlaceholder: data.ResetPasswordConfirmPasswordPlaceholder,
      backofficeAuthenticationTitle: data.BackofficeAuthenticationTitle,
      backofficeAuthenticationSocialSecurityNumberLabel: data.BackofficeAuthenticationSocialSecurityNumberLabel,
      backofficeAuthenticationSocialSecurityNumberPlaceholder: data.BackofficeAuthenticationSocialSecurityNumberPlaceholder,
      backofficeAuthenticationAccountLabel: data.BackofficeAuthenticationAccountLabel,
      backofficeAuthenticationAccountPlaceholder: data.BackofficeAuthenticationAccountPlaceholder,
      backofficeAuthenticationPasswordLabel: data.BackofficeAuthenticationPasswordLabel,
      backofficeAuthenticationPasswordPlaceholder: data.BackofficeAuthenticationPasswordPlaceholder,
      notFoundTransferButton: data.NotFoundTransferButton,
      notFoundTransferDescription: data.NotFoundTransferDescription,
    });
  }
}
