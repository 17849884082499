import { CookieBannerMapper } from './../mappers/CookieBanner.mapper';
import { CookieBannerConfiguration } from './../models/CookieBannerConfiguration';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class CookieBannerService {

    constructor(
        private http: HttpClient,
        private translateService: TranslateService
    ) { }

    public getConfiguration(): Observable<CookieBannerConfiguration> {
      const headers = new HttpHeaders()
          .set('language', this.translateService.currentLang);

      return this.http
          .get(`${environment.apiUrl}/CookieBanner/Config`, {headers})
          .pipe(
              map((configuration) => {
                  return CookieBannerMapper.map(configuration);
              }),
              catchError((error) => {
                  throw error;
              })
          );
      }
}
