import { Component, ElementRef, EventEmitter, forwardRef, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TwoWayBindingInputComponent } from '../two-way-binding-input.component';

@Component({
    selector: 'app-postal-code-input',
    templateUrl: './postal-code-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PostalCodeInputComponent),
            multi: true
        }
    ]
})
export class PostalCodeInputComponent extends TwoWayBindingInputComponent<string>  {

    public valueChanged($event: any) {
        this.value = $event;
        this.propagateChange($event);
    }
}
