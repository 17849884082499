import { animate, state, style, transition, trigger } from '@angular/animations';

export const collapse = trigger(
  'collapseAnimation', [
  transition(':enter', [
    style({ 'max-height': 0, opacity: 0 }),
    animate('250ms', style({ 'max-height': 'initial', opacity: 1 }))
  ]),
  transition(':leave', [
    style({ 'max-height': 'initial', opacity: 1 }),
    animate('250ms', style({ 'max-height': 0, opacity: 0 }))
  ])
]
);


export const rotateUpDown =
  trigger('upDown', [
    state('up', style({ transform: 'rotateZ(90deg)' })),
    state('down', style({ transform: 'rotateZ(270deg)' })),
    transition('up <=> down', [
      animate('0.15s')
    ]),
  ]);

export const fadeOut =
  trigger('fade', [
    transition('void => *', [
      style({ opacity: 0 }),
      animate(0, style({ opacity: 1 }))
    ]),
    transition('* => void', [
      animate(150, style({ opacity: 0, transform: 'scale(1.5)' }))
    ])
  ]);


export const fadeInOut =
trigger(
  'fadeInOut',
  [
    transition(
      ':enter',
      [
        style({ opacity: 0 }),
        animate('0.150s ease-out',
          style({ opacity: 1 }))
      ]
    ),
    transition(
      ':leave',
      [
        style({ opacity: 1 }),
        animate('0.150s ease-in',
          style({ opacity: 0 }))
      ]
    )
  ]
);
