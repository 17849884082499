<div class="form-group">
    <label [for]="id">
        {{label}}
        <span *ngIf="!required" class="text-danger">{{'Infino.ClientPortal.Validation.optional' | translate}}</span>
    </label>
    <input class="form-control input"
        appCustomTextFormat
        [inputType]="this.inputType"
        [ngClass]="[validationErrors.inputValue ? 'is-invalid' : '', fullWidth ? '' : 'w-auto']"
        [type]="type"
        [attr.inputmode]="mode"
        [id]="id"
        [placeholder]="placeholder"
        [disabled]="disabled"
        [required]="required"
        [ngModel]="localValue"
        [ngModelOptions]="{standalone: true}"
        (blur)="update($event.target.value)"
        (keyup)="update($event.target.value)"
        (ngModelChange)="update($event)"/>
    <div *ngIf="validationErrors['inputValue']" class="invalid-feedback">
        <span *ngFor="let error of validationErrors['inputValue']">
            {{'Infino.ClientPortal.Validation.' + error | translate}}
        </span>
    </div>
    <small class="form-text text-muted">{{subText}}</small>
</div>
