import { UserInfoModel } from './../models/user-info.model';
import { Injectable } from '@angular/core';
import { Store } from '../../../shared/infrastructure/state/store';

@Injectable({ providedIn: 'root' })
export class PortalUserStore extends Store<UserInfoModel> {
    protected getInitialState(): UserInfoModel {
        return {} as UserInfoModel;
    }

    protected getStoreName(): string {
        return 'portal-user-store';
    }
}
