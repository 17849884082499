import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PortalAuthenticationService } from '../../authentication/services/portal-authentication.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Injectable({
  providedIn: 'root'
})
export class PortalNoAuthGuard implements CanActivate {

  constructor(private router: Router, private auth: PortalAuthenticationService, private localize: LocalizeRouterService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!this.auth.authenticated()) {
      return true;
    }

    const localizedRoute = this.localize.translateRoute('/portal');
    return this.router.createUrlTree([localizedRoute]);
  }
}
