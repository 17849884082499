<div class="px-4 text-dark">
    <div *ngIf="isRegularisation" class="mt-2 mb-2 d-flex flex-row">
        <div class="text-overline text-muted w-50">Regularisatie</div>
        <div class="text-overline text-muted w-25 pr-4 text-right">Voor</div>
        <div class="text-overline text-muted w-25 pr-4 text-right">Na</div>
    </div>

    <div *ngFor="let allowance of allowances"
        class="text-dark d-flex flex-row align-items-center mt-2 mb-2">
        <div class="d-flex flex-column w-50">
            <div class="line-height-normal mt-1 text-capitalize text-medium">
                {{ allowanceTranslation( allowance.allowanceType ) }}
            </div>
            <small class="line-height-normal text-muted mb-2 text-capitalize">
                {{ allowance.allowanceType }}
            </small>
        </div> 

        <!-- Normal payment line -->
       <ng-container *ngIf="!isRegularisation">
            <div class="line-height-normal d-flex mr-5 text-large ml-auto">
                {{ allowance.amount }}
                <span class="text-overline tiny pl-1 mb-1 text-muted">EUR</span>
            </div>
        </ng-container>

        <!-- Regularised payment line -->
        <ng-container *ngIf="isRegularisation">
            <div class="line-height-normal d-flex text-large w-25 d-flex justify-content-end">
                {{ previousAmount(allowance) }}
                <span class="text-overline tiny pl-1 mb-1 text-muted">EUR</span>
            </div>
            <div class="line-height-normal d-flex text-large w-25 justify-content-end"
                [class.text-green]="currentAmount(allowance) > previousAmount(allowance)"
                [class.text-danger]="currentAmount(allowance) < previousAmount(allowance)">
                {{ currentAmount(allowance) }}
                <span class="text-overline tiny pl-1 mb-1 text-muted">EUR</span>
            </div>
        </ng-container> 
    </div>
</div>