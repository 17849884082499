import { Component, Input, OnInit } from '@angular/core';
import { FlowConfigurationStore } from 'src/app/core/configuration/data/flow-configuration.store';
import { FlowConfiguration } from 'src/app/core/configuration/models/flow-configuration.model';

@Component({
  selector: 'app-simple-navigation',
  templateUrl: './simple-navigation.component.html',
})
export class SimpleNavigationComponent implements OnInit {
  public websiteUrl = '';
  @Input() public showShadow = true;

  constructor(private flowConfigurationStore: FlowConfigurationStore) { }

  ngOnInit(): void {
    this.flowConfigurationStore.state$.subscribe((value: FlowConfiguration) => { this.websiteUrl = value.websiteUrl; });
  }
}
