import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PortalSeparConfigurationService } from 'src/app/core/configuration/services/portal-separ-configuration.service';
import { SeparChild } from 'src/app/core/separ/models/separ-child.model';
import { SeparFile } from 'src/app/core/separ/models/separ-file.model';
import { SeparSchoolYearDetails } from 'src/app/core/separ/models/separ-school-year-details.model';
import { SeparState } from 'src/app/core/separ/models/separ-state.model';
import { pluralizedTranslation } from 'src/app/shared/helpers/translations';
import { SeparChildViewModel } from '../viewmodels/separ-child.viewmodel';
import { SeparFileViewModel } from '../viewmodels/separ-file.viewmodel';
import { SeparPageViewModel } from '../viewmodels/separ-page.viewmodel';
import { SeparSchoolYearDetailsViewModel } from '../viewmodels/separ-school-year-details.viewmodel';

@Injectable()
export class SeparPageViewModelMapper {
    private translationKey = 'Infino.Portal.Separ.';

    constructor(
        private portalSeparConfigurationService: PortalSeparConfigurationService,
        private translateService: TranslateService,
    ) {
    }

    public mapEmpty(): SeparPageViewModel {
        const viewmodel = new SeparPageViewModel({
            translations: {
                title: this.translate(`Title`),
                childrenTitle: this.translate(`ChildrenTitle`),
                childTranslations: {
                    socialSecurityNumberLabel: this.translate(`SocialSecurityNumberLabel`),
                    nationalityLabel: this.translate(`NationalityLabel`),
                    ageLabel: this.translate(`AgeLabel`),
                    pedagogicLabel: this.translate(`PedagogicLabel`),
                    questionAboutThis: this.translate(`QuestionAboutThis`),
                    thenContactYourFileManager: this.translate(`ThenContactYourFileManager`),
                    okLabel: this.translate(`OkLabel`),
                    nokLabel: this.translate(`NokLabel`),
                    yearLabel: pluralizedTranslation(this.translateService, 'Infino.Portal.Years'),
                },
                schoolYearSelectionLabel: this.translate(`SchoolYearSelectionLabel`),
                schoolYearDetailTranslations: {
                    schoolYearDetailTitle: this.translate(`SchoolYearDetailTitle`),
                    familyIncomeCompletedLabel: this.translate(`FamilyIncomeCompletedLabel`),
                    incomeLimitLabel: this.translate(`IncomeLimitLabel`),
                    realEstateIncomeCheckLabel: this.translate(`RealEstateIncomeCheckLabel`),
                    separCalculationBlockedLabel: this.translate(`CalculationBlockedLabel`),
                    okLabel: this.translate(`OkLabel`),
                    nokLabel: this.translate(`NokLabel`),
                    completeLabel: this.translate(`CompleteLabel`),
                    incompleteLabel: this.translate(`IncompleteLabel`),
                    yesLabel: this.translate(`YesLabel`),
                    noLabel: this.translate(`NoLabel`),
                    yearLabel: pluralizedTranslation(this.translateService, 'Infino.Portal.Years'),
                }
            },
        });

        viewmodel.configuration = this.portalSeparConfigurationService.getConfiguration();

        return viewmodel;
    }

    public map(state: SeparState): SeparPageViewModel {
        const viewmodel = this.mapEmpty();
        viewmodel.isLoaded = !state.loading;

        if (viewmodel.isLoaded) {
            viewmodel.files = state.files.map(file => this.mapFile(file));
            if (state.selectedId) {
                viewmodel.selectedFile = this.mapFile(state.files.find(file => file.fileId === state.selectedId));
                if (state.selectedSchoolYear) {
                    viewmodel.selectedFile.selectedSchoolYear = viewmodel.selectedFile.schoolYearDetails.find(schoolYearDetails => schoolYearDetails.schoolYear === state.selectedSchoolYear);
                }
                else {
                    const today = new Date();
                    viewmodel.selectedFile.schoolYearDetails.forEach(schoolYearDetail => {

                        const schoolyears = schoolYearDetail.schoolYear.split('-');

                        const from = new Date(parseInt(schoolyears[0], 10), 7 - 1, 1);  // schoolYear period July -> July
                        const to = new Date(parseInt(schoolyears[1], 10), 7 - 1, 1);  // -1 because months are from 0 to 11

                        if (today > from && today < to) {
                            viewmodel.selectedFile.selectedSchoolYear = schoolYearDetail;
                        }
                    });

                    if (!viewmodel.selectedFile.selectedSchoolYear && viewmodel.selectedFile.schoolYearDetails.length > 0) {
                        viewmodel.selectedFile.selectedSchoolYear = viewmodel.selectedFile.schoolYearDetails[0];
                    }
                }
            }
        }

        return viewmodel;
    }

    private mapFile(file: SeparFile): SeparFileViewModel {
        return new SeparFileViewModel({
            region: file.region,
            fileId: file.fileId,
            schoolYearDetails: file.schoolYearDetails.map(schoolYearDetail => this.mapSchoolYearDetails(schoolYearDetail)),
        });
    }

    private mapSchoolYearDetails(separSchoolYearDetail: SeparSchoolYearDetails): SeparSchoolYearDetailsViewModel {
        return new SeparSchoolYearDetailsViewModel({
            children: separSchoolYearDetail.children.map(child => this.mapChild(child)),
            familyIncomeCompleted: separSchoolYearDetail.familyIncomeCompleted,
            incomeLimit: separSchoolYearDetail.incomeLimit,
            realEstateIncomeCheck: separSchoolYearDetail.realEstateIncomeCheck,
            schoolYear: separSchoolYearDetail.schoolYear,
            separCalculationBlocked: separSchoolYearDetail.separCalculationBlocked,
        });
    }

    private mapChild(child: SeparChild): SeparChildViewModel {
        return new SeparChildViewModel({
            dateOfBirth: child.dateOfBirth,
            firstname: child.firstname,
            lastname: child.lastname,
            meetingAgeRequirements: child.meetingAgeRequirements,
            meetingNationalityRequirements: child.meetingNationalityRequirements,
            meetingPedagogicRequirements: child.meetingPedagogicRequirements,
            socialSecurityNumber: child.socialSecurityNumber,
        });
    }

    private translate(key: string): string {
        return this.translateService.instant(this.translationKey + key);
    }
}
