import { Injectable } from '@angular/core';
import { Store } from '../../../shared/infrastructure/state/store';
import { SeparFile} from '../models/separ-file.model';

@Injectable()
export class SeparFileStore extends Store<SeparFile[]> {
    protected getInitialState(): SeparFile[] {
        return [];
    }

    protected getStoreName(): string {
        return 'separ-file-store';
    }
}
