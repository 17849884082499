import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidepanel',
  templateUrl: './sidepanel.component.html',
  styleUrls: ['./sidepanel.component.scss'],
})
export class SidepanelComponent implements OnInit {
  @Input() open: boolean;
  @Input() backgroundColorClass = 'bg-white';

  constructor() { }

  ngOnInit(): void {
  }

  public get classes(): string {
    return this.backgroundColorClass;
  }

}
