import { TranslateService } from '@ngx-translate/core';
import { AuthenticationRefresherModel } from './authentication-refresher.model';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthenticationRefresherMapper {
    constructor(private translate: TranslateService) { }

    public map(): AuthenticationRefresherModel {
        const model = new AuthenticationRefresherModel();

        model.translations = {
            title: this.translate.instant('Infino.Portal.SessionAboutToExpire.Title'),
            description: this.translate.instant('Infino.Portal.SessionAboutToExpire.Description'),
            titleExpired: this.translate.instant('Infino.Portal.SessionExpired.Title'),
            descriptionExpired: this.translate.instant('Infino.Portal.SessionExpired.Description'),
            logout: this.translate.instant('Infino.Portal.SessionAboutToExpire.Logout'),
            loginAgain: this.translate.instant('Infino.Portal.SessionAboutToExpire.LoginAgain'),
            stayLoggedIn: this.translate.instant('Infino.Portal.SessionAboutToExpire.StayLoggedIn'),
        };

        return model;
    }
}
