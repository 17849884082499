<div class="bg-blue-light slope-top slope-blue mt-5" *ngIf="model$ | async as model">
    <div class="container d-flex flex-column  pb-4" [ngClass]="model.multiple() ? '' : 'flex-md-row'">
        <div class="py-3" [ngClass]="model.multiple() ? 'order-1 col-12' : 'col-md-6'">
            <div class="py-3 text-white text-overline text-uppercase">{{ model.translations.getToWorkYourself }}</div>
            <div class="bg-blue-25 p-3 d-flex flex-column border-top border-bottom border-blue-dark">
                <a class="text-medium text-dark line-height-normal cursor-pointer m-2 d-flex font-weight-semi-bold"
                    [routerLink]="model.certificateLink">
                    {{ model.translations.generateCertificate }}
                    <app-icon-chevron [color]="chevronColor" [direction]="chevronDirection" class="ml-auto">
                    </app-icon-chevron>
                </a>
                <a class="text-medium text-dark line-height-normal cursor-pointer m-2 d-flex font-weight-semi-bold"
                    [href]="model.faqLink">
                    {{ model.translations.checkoutFAQ }}
                    <app-icon-chevron [color]="chevronColor" [direction]="chevronDirection" class="ml-auto">
                    </app-icon-chevron>
                </a>
            </div>
        </div>
        <div class="py-3" [ngClass]="model.multiple() ? 'order-0 col-12' : 'col-md-6'"
            [class.flex-order-0]="model.multiple()">
            <div class="py-3 text-white text-overline text-uppercase">{{ model.translations.aSpecificQuestion }}</div>
            <div class="bg-blue-25 p-3 d-flex flex-column border-top border-bottom border-blue-dark">
                <app-quick-link-filemanager *ngIf="!model.fileManagers" [loading]="!model.fileManagers">
                </app-quick-link-filemanager>
                <app-quick-link-filemanager *ngFor="let mgr of model.fileManagers" [firstname]="mgr.firstname"
                    [lastname]="mgr.lastname" [link]="mgr.questionLink" [phone]="mgr.phone"
                    [horizontal]="model.multiple()" [kids]="mgr.kids" [id]="mgr.id" [show]="mgr.show">
                </app-quick-link-filemanager>
            </div>
        </div>
    </div>
</div>
