import { IValidator } from '../validator.interface';
import { ValidationError } from '../validation-error.enum';

/**
 * Validates if the input is a number (or convertible to a number) and checks if that number is equal or bigger than the min value
 */
export class MinValueValidator implements IValidator {

    constructor(private minValue: number) {}

    public validate(value: any): string {
        try {
            const valueNumber = +value;
            if (valueNumber >= this.minValue) {
                return undefined;
            }

            return ValidationError.MinValue;

        } catch (error) {
            return ValidationError.MinValue;
        }
    }
}
