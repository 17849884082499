<div class="d-flex px-4 py-2 align-items-center" [ngClass]="headerClasses()">
    <div class="d-flex flex-column justify-content-center mr-auto" [class.mb-1]="subtitle">
        <div class="line-height-normal h5 text-capitalize font-weight-normal" [class.mb-0]="!subtitle">
            {{title}}
        </div>
        <small *ngIf="subtitle" class="line-height-normal"> {{ subtitle }}</small>
    </div>
    <div class="pr-4" *ngIf="iconPath">
        <img src="{{iconPath}}" />
    </div>

    <button class="btn btn-link" (click)="canShowPrevious && previous()" [class.opacity-0]="!canShowPrevious">
        <app-icon-chevron [color]="iconColorMode" [direction]="iconDirectionLeft"></app-icon-chevron>
    </button>
    <button class="btn btn-link" (click)="canShowNext && next()" [class.opacity-0]="!canShowNext">
        <app-icon-chevron [color]="iconColorMode" [direction]="iconDirectionRight"></app-icon-chevron>
    </button>
    <button class="btn btn-link" (click)="close()">
        <app-icon-close [ngClass]="getIconColorEnumForClose()"></app-icon-close>
    </button>
</div>