import { PortalConfigurationStore } from './../../../../core/configuration/data/portal-configuration.store';
import { ChildBenefitsFile } from 'src/app/core/files/models/child-benefits-file.model';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QuickLinksViewModel } from './quick-links.viewmodel';
import { capitalize } from 'src/app/shared/helpers/string.helper';
import { ChildBasicAllowanceReasonEnum } from 'src/app/core/files/models/child-basic-allowance-reason-enum';
import { ChildBasicAllowanceStatusEnum } from 'src/app/core/files/models/child-basic-allowance-status-enum';

@Injectable()
export class QuickLinksMapper {
    private translationKey = 'Infino.Portal.Help.';

    constructor(
        private translate: TranslateService,
        private localize: LocalizeRouterService,
        private configStore: PortalConfigurationStore
    ) { }

    map(files: ChildBenefitsFile[]): QuickLinksViewModel {
        const model = new QuickLinksViewModel({
            translations: {
                aSpecificQuestion: this.translate.instant(this.translationKey + 'ASpecificQuestion'),
                askQuestionAboutYourFile: this.translate.instant(this.translationKey + 'AskQuestionAboutYourFile'),
                checkoutFAQ: this.translate.instant(this.translationKey + 'CheckoutFAQ'),
                generateCertificate: this.translate.instant(this.translationKey + 'GenerateCertificate'),
                getToWorkYourself: this.translate.instant(this.translationKey + 'GetToWorkYourself'),
            },
            faqLink: this.configStore.getState().faqPageUrl,
            certificateLink: this.localize.translateRoute('/portal/certificate')
        });

        if (files) {
            model.fileManagers = files.map(file => {
                let show = true;

                if (file.children.length === 1 && file.children.some(c =>
                    c.childBasicAllowance.status === ChildBasicAllowanceStatusEnum.Closed
                    && c.childBasicAllowance.reason === ChildBasicAllowanceReasonEnum.Deceased)) {
                    show = false;
                }

                return {
                    firstname: capitalize(file.fileManagerFirstName?.toLowerCase()),
                    lastname: capitalize(file.fileManagerLastName?.toLowerCase()),
                    phone: file.fileManagerPhone,
                    questionLink: this.localize.translateRoute('/portal/help'),
                    kids: file.children.filter(c =>
                        !(c.childBasicAllowance.status === ChildBasicAllowanceStatusEnum.Closed
                        && c.childBasicAllowance.reason === ChildBasicAllowanceReasonEnum.Deceased))
                        .map(child => child.firstname),
                    id: file.fileId,
                    show: show
                };
            });
        }

        return model;
    }
}
