import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, config } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { AuthenticationConfigurationMapper } from '../mappers/authentication-configuration.mapper';
import { AuthenticationConfigurationStore } from '../data/authentication-configuration.store';
import { Injectable } from '@angular/core';
import { PortalAlertConfigurationMapper } from './../mappers/portal-alert-configuration.mapper';
import { PortalAlertConfigurationStore } from '../data/portal-alert-configuration.store';
import { PortalChildStatusLinksMapper } from './../mappers/portal-childstatuslinks.mapper';
import { PortalChildStatusLinksStore } from './../data/portal-childstatuslinks.store';
import { PortalConfigurationMapper } from './../mappers/portal-configuration.mapper';
import { PortalConfigurationStore } from '../data/portal-configuration.store';
import { PortalSeparConfigurationMapper } from '../mappers/portal-separ-configuration.mapper';
import { PortalSeparConfigurationStore } from '../data/portal-separ-configuration.store';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

@Injectable()
export class PortalConfigurationService {

    constructor(
        private httpClient: HttpClient,
        private authMapper: AuthenticationConfigurationMapper,
        private generalMapper: PortalConfigurationMapper,
        private childStatusLinksMapper: PortalChildStatusLinksMapper,
        private separMapper: PortalSeparConfigurationMapper,
        private portalConfigurationStore: PortalConfigurationStore,
        private portalAlertConfigurationStore: PortalAlertConfigurationStore,
        private portalAlertConfigurationMapper: PortalAlertConfigurationMapper,
        private authenticationConfigurationStore: AuthenticationConfigurationStore,
        private childStatusLinksStore: PortalChildStatusLinksStore,
        private portalSeparConfigurationStore: PortalSeparConfigurationStore,
        private translateService: TranslateService,
        private titleService: Title,
    ) { }

    public initialize(): void {
        this.getConfiguration(this.translateService.currentLang, 'AuthenticationConfiguration')
            .pipe(take(1), map(this.authMapper.map))
            .subscribe(configuration => this.authenticationConfigurationStore.update(configuration));

        this.getConfiguration(this.translateService.currentLang, 'GeneralConfiguration')
            .pipe(take(1), map(data => this.generalMapper.map(data)))
            .subscribe(configuration => {
                this.portalConfigurationStore.update(configuration);

                if (configuration.pageName) {
                    this.titleService.setTitle(configuration.pageName);
                }
            });

        this.getConfiguration(this.translateService.currentLang, 'ChildStatuses')
            .pipe(take(1), map(this.childStatusLinksMapper.map))
            .subscribe(configuration => this.childStatusLinksStore.update(configuration));

        this.getConfiguration(this.translateService.currentLang, 'AlertConfiguration')
            .pipe(take(1), map(this.portalAlertConfigurationMapper.map))
            .subscribe(configuration => {
                this.portalAlertConfigurationStore.update(configuration);
            });

        this.getConfiguration(this.translateService.currentLang, 'SeparConfiguration')
            .pipe(take(1), map(this.separMapper.map))
            .subscribe(configuration => this.portalSeparConfigurationStore.update(configuration));
    }

    private getConfiguration(lang: string, name: string): Observable<object> {
        const headers = new HttpHeaders()
            .set('language', lang);

        return this.httpClient
            .get(`${environment.apiUrl}/PortalConfiguration/${name}`, {
                headers
            })
            .pipe(catchError((error) => {
                throw error;
            }));
    }
}
