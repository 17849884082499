import { Injectable } from '@angular/core';
import { PortalAlertConfigurationModel } from '../models/portal-alert-configuration.model';

@Injectable()
export class PortalAlertConfigurationMapper {
    public map(data: any): PortalAlertConfigurationModel {
        return new PortalAlertConfigurationModel({
            alertGeneral: data?.AlertGeneral,
            alertBrussels: data?.AlertBrussels,
            alertFlanders: data?.AlertFlanders,
            alertWallonia: data?.AlertWallonia,
        });
    }
}
