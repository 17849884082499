import { Component, OnInit } from '@angular/core';
import { AuthenticationConfiguration } from 'src/app/core/configuration/models/authentication-configuration.model';
import { AuthenticationConfigurationStore } from 'src/app/core/configuration/data/authentication-configuration.store';

@Component({
  selector: 'app-new-account',
  templateUrl: './new-account.component.html',
})
export class NewAccountComponent implements OnInit {

  public authenticateWithLoginNewAccountTitle: string;
  public authenticateWithLoginNewAccountDescription: string;
  public authenticateWithLoginNewAccountButton: string;
  public authenticateWithLoginNewAccountPageUrl: string;

  constructor(
    private authenticationConfigurationStore: AuthenticationConfigurationStore) {
  }

  ngOnInit(): void {
    this.authenticationConfigurationStore.state$.subscribe((configuration: AuthenticationConfiguration) => {
      this.authenticateWithLoginNewAccountTitle = configuration.authenticateWithLoginNewAccountTitle;
      this.authenticateWithLoginNewAccountDescription = configuration.authenticateWithLoginNewAccountDescription;
      this.authenticateWithLoginNewAccountButton = configuration.authenticateWithLoginNewAccountButton;
      this.authenticateWithLoginNewAccountPageUrl = configuration.authenticateWithLoginNewAccountPageUrl;
    });
  }
}
