<div class="c-cookiebanner" *ngIf="!consentConfigured && configuration">
  <section class="container-fluid" id="cookie">
    <div class="fixed-bottom bg-white rounded m-md-4 px-3 py-4 c-cookiebanner-container">
      <div class="d-flex flex-wrap flex-column flex-lg-row flex-wrap justify-content-between">
        <div class="flex-grow-1 w-100 px-2">
          <h6 class="h6-md text-subtitle mb-2 mb-md-3">{{configuration.title}}</h6>
        </div>

        <ng-container *ngIf="!shouldEnableManagement">
          <app-cookie-category class="mw-lg-25" *ngFor="let category of categories" [cookieCategory]="category">
          </app-cookie-category>
        </ng-container>
        <ng-container *ngIf="shouldEnableManagement">
          <div class="flex-grow-1 px-2 d-flex flex-column">
            <app-cookie-category-manage *ngFor="let category of categories" [cookieCategory]="category"
              [cookieBannerConfiguration]="configuration" (consentChanged)="changeCategoryConsent(category, $event)">
            </app-cookie-category-manage>
          </div>
        </ng-container>

        <div class="flex-grow-1 order-md-last w-100 px-2">

          <ng-container *ngIf="!shouldEnableManagement">
            <p class="pt-2 text-smallest mb-0" [innerHTML]="configuration.cookieAndPrivacyInformation"></p>
          </ng-container>
          <ng-container *ngIf="shouldEnableManagement">
            <p class="pt-2 text-smallest mb-0" [innerHTML]="configuration.additionalInformation"></p>
          </ng-container>

        </div>
        <div class="align-content-end align-items-start d-flex flex-column justify-content-center mw-lg-25 px-2 align-items-lg-center">

          <ng-container *ngIf="!shouldEnableManagement">
            <span class="btn btn-primary mt-3 mt-md-3" (click)="acceptAllCookies()">
              {{configuration.acceptButtonText}}
            </span>
            <a class="text-secondary link-chevron d-block pt-2 text-small btn px-0" (click)="enableManagement()">
              {{configuration.otherSettingsButtonText}}
            </a>
          </ng-container>
          <ng-container *ngIf="shouldEnableManagement">
            <span class="btn btn-outline-primary mt-3 mt-md-3" (click)="acceptCookies()">
              {{configuration.acceptOtherSettingsButtonText}}
            </span>
          </ng-container>

        </div>
      </div>
    </div>
  </section>
</div>
