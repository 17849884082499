export class ChildDetailLabelsViewModel {
    public socialSecurityNumberLabel: string;
    public birthDateNumberLabel: string;
    public capacityLabel: string;
    public typeLabel: string;
    public activeLabel: string;
    public inactiveLabel: string;
    public suspendedLabel: string;
    public childBasicAllowanceSuspendedDateLabel: string;
    public childBasicAllowanceSuspendedReasonLabel: string;
    public childBasicAllowanceClosedDateLabel: string;
    public childBasicAllowanceClosedReasonLabel: string;
    public allowanceType: (name: string) => string;

    constructor(init?: Partial<ChildDetailLabelsViewModel>) {
        Object.assign(this, init);
    }
}
