import { ChildBenefitsStateStore } from './../../../../../core/files/data/child-benefits-state.store';
import { ModifyUserDataModalModel } from './modify-user-data-modal.model';
import { ModifyUserDataModalMapper } from './modify-user-data-modal.mapper';
import { Injectable } from '@angular/core';

@Injectable()
export class ModifyUserDataModalService {
    constructor(
        private mapper: ModifyUserDataModalMapper,
        private childBenefitsStateStore: ChildBenefitsStateStore
    ) {}

    public getModel(): ModifyUserDataModalModel {
        const childBenefitsState = this.childBenefitsStateStore.getState();
        return this.mapper.map(childBenefitsState);
    }
}
