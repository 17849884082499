import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PortalAuthenticationModel } from './portal-authentication.model';
import { AuthenticationConfiguration } from '../../../../../core/configuration/models/authentication-configuration.model';

@Injectable()
export class PortalAuthenticationMapper {

    constructor(
        private translateService: TranslateService,
    ) { }

    public map(configuration: AuthenticationConfiguration): PortalAuthenticationModel {
        return new PortalAuthenticationModel({
            overviewTitle: configuration.overviewTitle,
            overviewDescription: configuration.overviewDescription,
            authenticationCSAMTitle: configuration.authenticationCSAMTitle,
            authenticationCSAMDescription: configuration.authenticationCSAMDescription,
            authenticationCSAMIcon: configuration.authenticationCSAMIcon,
            authenticationCSAMButtonText: configuration.authenticationCSAMButtonText,
            authenticationLoginTitle: configuration.authenticationLoginTitle,
            authenticationLoginDescription: configuration.authenticationLoginDescription,
            authenticationLoginIcon: configuration.authenticationLoginIcon,
            authenticationLoginButtonText: configuration.authenticationLoginButtonText,
            notFoundTransferButton: configuration.notFoundTransferButton,
            notFoundTransferDescription: configuration.notFoundTransferDescription,
        });
    }
}
