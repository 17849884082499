import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxComponent } from '../checkbox/checkbox.component';

@Component({
    selector: 'app-privacy-policy-checkbox',
    templateUrl: './privacy-policy-checkbox.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PrivacyPolicyCheckboxComponent),
            multi: true
        }
    ]
})
export class PrivacyPolicyCheckboxComponent extends CheckboxComponent implements ControlValueAccessor {

    @Input() public labelClass = '';
    @Input() public showValidationErrors = true;

    @Input() public policyLinkLabel = '';
    @Input() public policyLinkUrl = '';

    @Output() public updated: EventEmitter<boolean> = new EventEmitter<boolean>();

    public valueChanged($event: boolean) {
        this.value = $event;
        this.propagateChange($event);

        this.updated.emit($event);
    }
}
