<div>
    <app-detail-pane-header [colorMode]="detailHeaderColorMode" [title]="paymentTranslation(payment.paymentType)"
        [subtitle]="getDate(payment.year, payment.month) | localizedDate: 'MMMM y'" (onPrevious)="previous.next()"
        (onNext)="next.next()" (onClose)="close.next()" [canShowNext]="hasNext" [canShowPrevious]="hasPrev">
    </app-detail-pane-header>

    <div class="bg-yellow-25 border border-warning p-3 d-flex mx-4 mt-3 mb-4 rounded align-items-center" *ngIf="hasPaymentWarningMessage(payment.paymentType)">
        <app-icon-warning-round class="mr-2"></app-icon-warning-round>
        <div class="text-medium">
            <p class="mb-0">
                <span class="font-weight-semi-bold">{{ "Infino.Portal.Payment.Type." + payment.paymentType | translate }}</span> 
                : {{ "Infino.Portal.Payment.WarningMessage." + payment.paymentType | translate }}
            </p>
        </div>
    </div>

    <div class="mx-4 my-3 d-flex flex-wrap">
        <div class="w-50 mb-3">
            <div class="line-height-normal text-overline text-muted text-uppercase">{{ translations.accountNumber }}
            </div>
            <small class="line-height-normal d-inline-block w-50">{{ getPaymentMethod() }}</small>
        </div>
        <div class="w-50 mb-3">
            <div class="line-height-normal text-overline text-muted text-uppercase">{{ translations.paymentDate }}
            </div>
            <small class="line-height-normal">{{ payment.paymentDate | localizedDate: 'd MMMM y' }}</small>
        </div>
        <div class="w-50 mb-3">
            <div class="line-height-normal text-overline text-muted text-uppercase">{{ translations.region }}</div>
            <small class="line-height-normal">{{ "Infino.Portal.Regions." + payment.region + ".Label" | translate }}</small>
        </div>
    </div>

    <div *ngIf="payment.paymentDetails.length && payment.paymentDetails[0].foreignDetails !== null" class="border rounded ml-4 mr-4 my-2 border-yellow-40 ">
        <!-- Header -->
        <div class="bg-ecru px-4 py-2 rounded-top line-height-normal text-overline text-uppercase">
            {{ translations.foreignFundsTitle }}
        </div>

        <!-- foreign details -->
        <div class="mx-4 my-3 d-flex flex-wrap">
            <div class="w-50 mb-3">
                <div class="line-height-normal text-overline text-muted text-uppercase">{{ translations.foreignFundsOrganisation }}
                </div>
                <small class="line-height-normal">{{ payment.paymentDetails[0].foreignDetails.organisation }}</small>
            </div>
            <div class="w-50 mb-3">
                <div class="line-height-normal text-overline text-muted text-uppercase">{{ translations.foreignFundsReference }}
                </div>
                <small class="line-height-normal">{{ payment.paymentDetails[0].foreignDetails.fileReference }}</small>
            </div>
            <div class="w-50 mb-3">
                <div class="line-height-normal text-overline text-muted text-uppercase">{{ translations.foreignFundsCountry }}</div>
                <small class="line-height-normal">{{ payment.paymentDetails[0].foreignDetails.organisationCountry }}</small>
            </div>
            <div class="w-25 mb-3">
                <div class="line-height-normal text-overline text-muted text-uppercase">{{ translations.foreignFundsStartDate }}</div>
                <small class="line-height-normal">{{ payment.paymentDetails[0].foreignDetails.startDate | localizedDate: 'dd/MM/yy' }}</small>
            </div>
            <div class="w-25 mb-3">
                <div class="line-height-normal text-overline text-muted text-uppercase">{{ translations.foreignFundsEndDate }}</div>
                <small class="line-height-normal">{{ payment.paymentDetails[0].foreignDetails.endDate | localizedDate: 'dd/MM/yy' }}</small>
            </div>
        </div>
    </div>

    <div *ngFor="let details of payment.paymentDetails.sort(sortByAge)"
        class="border rounded ml-4 mr-4 my-2 text-secondary" [class.border-blue-10]="!isOpen(details)"
        [class.border-blue]="isOpen(details)">
        <!-- Header -->
        <div class="bg-blue-10 px-4 py-2 rounded-top d-flex justify-content-between align-items-center cursor-pointer"
            (click)="toggle(details)">
            <div>
                <div class="line-height-normal mt-1">
                    {{ details.childFirstName }}
                    {{ details.childLastName }}
                </div>
                <div class="line-height-normal mb-2 text-medium" *ngIf="details.childBirthDate">
                    {{ age(details.childBirthDate) + " " + translations.years(age(details.childBirthDate)) }}
                </div>
            </div>
            <div class="line-height-normal d-flex ml-auto mr-2 pr-1" [class.text-danger]="details.allowance < 0">
                {{ details.allowance }}
                <span class="text-overline tiny pl-1 mb-1">EUR</span>
            </div>

            <app-icon-chevron class="py-1 px-2 d-flex justify-content-center"
                [direction]="isOpen(details) ? iconUp : iconDown" [class.stroke-blue-40]="!isOpen(details)"
                [class.stroke-blue-100]="isOpen(details)"></app-icon-chevron>
        </div>

        <!-- Kids -->
        <ng-container *ngIf="isOpen(details)">
            <ng-container *ngIf="isSepar(); else generalDetails">
                 <!-- SEPAR Details -->
                <div class="px-4 mb-2">
                    <a class="border-bottom border-blue text-medium font-weight-semi-bold"
                        [routerLink]="'/portal/separ' | localize" (click)="setActiveYear(payment.fileID, payment.paymentDate)">
                        {{ translations.viewSepar }}
                    </a>
                </div>
            </ng-container>
            <ng-template #generalDetails>
                <app-portal-payment-allowance-line [details]="details" [isRegularisation]="isRegularisation">
                </app-portal-payment-allowance-line>
                <div class="px-4 mb-2">
                    <a class="border-bottom border-blue text-medium font-weight-semi-bold"
                        [routerLink]="'/portal/dossier' | localize" (click)="setActiveFile(payment.fileID)">
                        {{ translations.viewFile }}
                    </a>
                </div>
            </ng-template>            
        </ng-container>

    </div>

    <div class="mx-4 px-4 d-flex" *ngIf="payment.totalRetained > 0">
        <div class="line-height-normal mt-1 text-capitalize text-medium">
            {{ 'Inhouding' }}
        </div>
        <div class="line-height-normal d-flex mr-5 text-large ml-auto">
            <span class="text-danger">{{ payment.totalRetained }}</span>
            <span class="text-overline tiny pl-1 mb-1 text-muted">EUR</span>
        </div>
    </div>
    <div class="mx-4 d-flex justify-content-between align-items-center text-capitalize">
        <span class="ml-4">{{ translations.total }}</span>
        <div class="d-flex mw-small text-larger text-transform-bold d-flex align-items-center mr-5 pr-4"
            [class.text-danger]='getPaymentAmount() < 0' [class.text-green]='getPaymentAmount() >= 0'>
            {{ getPaymentAmount() }}
            <span class="text-overline text-muted text-transform-normal pl-1">EUR</span>
        </div>
    </div>

    <div class="bg-ecru p-3 d-flex mx-4 mt-3 mb-4 rounded">
        <img src="assets/icons/buoy.svg" />
        <div class="ml-3 mb-2 text-medium">
            <p><span class="font-weight-semi-bold">{{ translations.questionAboutThisPayment }}</span> {{
                translations.thenContactYourFileManager }}</p>
            <app-client-advisor-cta [fileId]="payment.fileID"></app-client-advisor-cta>
        </div>
    </div>
</div>