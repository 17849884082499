import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LanguageCodeMapper {
    private translationKey = 'Infino.Portal.Languages.';

    private languageLabelNL: string;
    private languageLabelFR: string;
    private languageLabelEN: string;

    constructor(private translateService: TranslateService) {
        this.languageLabelNL = this.translateService.instant(this.translationKey + `NL`);
        this.languageLabelFR = this.translateService.instant(this.translationKey + `FR`);
        this.languageLabelEN = this.translateService.instant(this.translationKey + `EN`);
    }

    public getLanguage(languageCode: string) {
        let language: string;
        switch (languageCode) {
            case 'N':
            case 'NL':
                language = this.languageLabelNL;
                break;
            case 'F':
            case 'FR':
                language = this.languageLabelFR;
                break;
            case 'E':
            case 'EN':
                language = this.languageLabelEN;
                break;
        }

        return language;
    }
}
