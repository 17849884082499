import { ChildBenefitsFile } from './../models/child-benefits-file.model';
import { take, last, shareReplay, retry } from 'rxjs/operators';
import { ChildBenefitsStateStore } from './../data/child-benefits-state.store';
import { ChildBenefitsApiService } from './child-benefits-files-api.service';
import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ChildBenefitsStateService {
    constructor(private apiService: ChildBenefitsApiService, private store: ChildBenefitsStateStore) { }

    public initialize(): Observable<ChildBenefitsFile[]> {
        this.store.resetState();
        const files$ = this.apiService.getFiles()
            .pipe(retry(3), take(1), shareReplay(1));

        files$.subscribe(files => {
            this.store.update({
                loading: false,
                files,
            });
            this.store.select(files.length ? files[0].fileId : undefined);
        });

        return files$;
    }
}
