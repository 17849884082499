export class PortalForgotPasswordModel {
    public resetPasswordTitle: string;
    public resetPasswordButton: string;
    public resetPasswordSuccessMessage: string;
    public resetPasswordReturnToLoginButton: string;
    public resetPasswordNewPasswordTitle: string;
    public resetPasswordNewPasswordLabel: string;
    public resetPasswordNewPasswordPlaceholder: string;
    public resetPasswordConfirmPasswordLabel: string;
    public resetPasswordConfirmPasswordPlaceholder: string;
    public authenticateWithLoginNewAccountPageUrl: string;
    public authenticateWithLoginEmailLabel: string;
    public authenticateWithLoginEmailPlaceholder: string;

    constructor(partial?: Partial<PortalForgotPasswordModel>) {
        Object.assign(this, partial);
    }
}
