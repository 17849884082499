import { Observable } from 'rxjs';

export class AuthenticationRefresherModel {
    authenticationRequired: boolean;
    expiresIn: Observable<number>;
    loading: boolean;
    cancelled: boolean;

    translations: {
        title: string;
        description: string;
        titleExpired: string;
        descriptionExpired: string;
        logout: string;
        loginAgain: string;
        stayLoggedIn: string;
    };
}
