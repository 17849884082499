import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Observable } from 'rxjs';
import { TransferStore } from '../../modules/transfer/data/transfer.store';

@Injectable()
export class TransferFlowDirectAccessGuard implements CanActivate {
    constructor(
        private router: Router,
        private store: TransferStore,
        private localize: LocalizeRouterService,
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const formData = this.store.getTransferData().transferForm;
        if (formData.children.length === 0) {
            const translatedPath: any = this.localize.translateRoute(
                '/transfer/infochildren'
            );
            this.router.navigate([translatedPath]);
            return false;
        }
        return true;
    }
}
