import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SingletonModule } from '../../shared/modules/singleton.module';
import { HttpClientModule } from '@angular/common/http';
import { PortalNotificationsStore } from './data/portal-notifications.store';
import {PortalNotificationsService} from './services/portal-notification.service';

@NgModule({
    imports: [
        HttpClientModule,
    ],
    providers: [
        PortalNotificationsStore,
        PortalNotificationsService
    ]
})
export class NotificationsModule extends SingletonModule {
    constructor(@Optional() @SkipSelf() module: NotificationsModule) {
        super(module);
    }
}
