import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from '../services/logging.service';

@Injectable()
export class ApplicationInsightsErrorHandlerService extends ErrorHandler {

  constructor(private loggingService: LoggingService) {
    super();
  }

  handleError(error: Error) {
    this.loggingService.logException(error); // Manually log exception
  }
}
