import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationConfiguration } from 'src/app/core/configuration/models/authentication-configuration.model';
import { PortalForeignAuthenticationModel } from './portal-foreign-authentication.model';

@Injectable()
export class PortalForeignAuthenticationMapper {

    constructor(
        private translateService: TranslateService,
    ) { }

    public map(configuration: AuthenticationConfiguration): PortalForeignAuthenticationModel {
        return new PortalForeignAuthenticationModel({
            authenticateWithLoginTitle: configuration.authenticateWithLoginTitle,
            authenticateWithLoginEmailLabel: configuration.authenticateWithLoginEmailLabel,
            authenticateWithLoginEmailPlaceholder: configuration.authenticateWithLoginEmailPlaceholder,
            authenticateWithLoginPasswordLabel: configuration.authenticateWithLoginPasswordLabel,
            authenticateWithLoginPasswordPlaceholder: configuration.authenticateWithLoginPasswordPlaceholder,
            authenticateWithLoginForgotPasswordButton: configuration.authenticateWithLoginForgotPasswordButton,
            authenticateWithLoginButton: configuration.authenticateWithLoginButton,
            authenticateWithLoginOpenCSAMButton: configuration.authenticateWithLoginOpenCSAMButton,
            authenticateWithLoginNewAccountPageUrl: configuration.authenticateWithLoginNewAccountPageUrl,
        });
    }
}
