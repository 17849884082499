import { RegionEnum } from 'src/app/core/payments/models/region-enum';

export class OverviewPageModel {

    public description: string;

    public noPaymentsFound: string;
    public recentPaymentsLabel: string;
    public allPaymentsButton: string;

    public goodMorningLabel: string;
    public goodAfternoonLabel: string;
    public goodEveningLabel: string;
    public goodNightLabel: string;

    public firstname: string;

    public alerts: {
        region: RegionEnum,
        message: string,
        show: boolean
    }[];

    constructor(partial?: Partial<OverviewPageModel>) {
        Object.assign(this, partial);
    }
}
