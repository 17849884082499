import { IValidationField } from './validation-field.interface';
import { ValidationResult } from './validation-result';
import { ValidatorError } from './validator-errors';

export class ValidationHelper {
    public static validate(validationFields: IValidationField[]): ValidationResult {
        const failedValidations: ValidatorError[] = [];
        for (const field of validationFields) {
            if (field.skipValidation) {
                continue;
            }
            for (const validator of field.validators) {
                const errorMessage = validator.validate(field.value);
                if (errorMessage) {
                    failedValidations.push(new ValidatorError(errorMessage, field.name));
                }
            }
        }

        return new ValidationResult(failedValidations.length <= 0, failedValidations);
    }

    public static validateField(fieldName: string, validationFields: IValidationField[]): ValidationResult {
        const failedValidations: ValidatorError[] = [];
        for (const field of validationFields) {
            if (field.skipValidation) {
                continue;
            }

            if (field.name !== fieldName) {
                continue;
            }

            for (const validator of field.validators) {
                const errorMessage = validator.validate(field.value);
                if (errorMessage) {
                    failedValidations.push(new ValidatorError(errorMessage, field.name));
                }
            }
        }

        return new ValidationResult(failedValidations.length <= 0, failedValidations);
    }

    public static merge(...errorObjects: any): any {
        return errorObjects.reduce((merge, errorObject) => [...merge, ...errorObject]);
    }

    public static removeFromErrorObject(fieldName: string, errorObject: any): any {
        delete errorObject[fieldName];
        return errorObject;
    }
}
