import { IValidator } from '../validator.interface';
import { ValidationError } from '../validation-error.enum';

/**
 * Validates if the input is a string (or convertible to a string) and checks if the string has a certain minimum length
 */
export class MinLengthValidator implements IValidator {

    constructor(private minLength: number) { }

    public validate(value: any): string {
        try {
            const valueString = value.toString();
            if (valueString.length < this.minLength) {
                return ValidationError.MinLength;
            }

            return undefined;
        } catch (error) {
            return ValidationError.MinLength;
        }
    }
}
