import * as dayjs from 'dayjs';
import { ChildBasicAllowanceStatusEnum } from './../../../../core/files/models/child-basic-allowance-status-enum';
import { ChildBasicAllowanceReasonEnum } from './../../../../core/files/models/child-basic-allowance-reason-enum';

export class ChildViewModel {
    public firstname: string;
    public lastname: string;
    public socialSecurityNumber: string;
    public dateOfBirth: string;

    public medical: string;
    public capacity: string;
    public status: string;
    public statusLink?: string;
    public points: string;
    public active: boolean;
    public childBasicAllowance: { status: ChildBasicAllowanceStatusEnum, startDate: string, reason: string };

    public allowances: { name: string, amount: string }[];

    public capacityLabel: string;
    public childBenefitsTypeLabel: string;
    public medicalLabel: string;

    constructor(
        init?: Partial<ChildViewModel>
    ) {
        Object.assign(this, init);
    }

    public getAge(): number {
        let age: number;
        const parsedDate = dayjs(this.dateOfBirth);

        if (this.dateOfBirth && parsedDate.isValid()) {
            age = dayjs().diff(parsedDate, 'year', false);
        }

        if (isNaN(age)) {
            return undefined;
        }

        return age;
    }

    public isActive(): boolean {
        return this.childBasicAllowance?.status === ChildBasicAllowanceStatusEnum.Active;
    }

    public isSuspended(): boolean {
        return this.childBasicAllowance?.status === ChildBasicAllowanceStatusEnum.Suspended;
    }

    public isClosed(): boolean {
        return this.childBasicAllowance?.status === ChildBasicAllowanceStatusEnum.Closed;
    }

    public isDeceased(): boolean {
        return this.isClosed() && this.childBasicAllowance?.reason === ChildBasicAllowanceReasonEnum.Deceased;
    }


    public getFullName(): string {
        return `${this.firstname} ${this.lastname}`;
    }

    public getDateOfBirthParsed(): string {
        if (this.dateOfBirth && dayjs(this.dateOfBirth).isValid()) {
            return dayjs(this.dateOfBirth).format('DD/MM/YYYY');
        }
    }

    public getChildBasicAllowanceStartDateParsed(): string {
        if (this.childBasicAllowance && this.childBasicAllowance.startDate && dayjs(this.childBasicAllowance.startDate).isValid()) {
            return dayjs(this.childBasicAllowance.startDate).format('DD/MM/YYYY');
        }
    }
}
