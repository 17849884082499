import { Validators } from '../../../../../shared/infrastructure/validation/validators';
import { IValidationField } from '../../../../../shared/infrastructure/validation/validation-field.interface';

export class PortalResetPasswordFormModel {
    public email: string;
    public password: string;
    public confirmPassword: string;

    public getValidators(): IValidationField[] {
        const date = new Date();
        const maxdate = new Date();
        maxdate.setMonth(date.getMonth() + 9);

        return [
            {
                name: 'confirmPassword',
                value: this.confirmPassword,
                validators: [
                    Validators.required(),
                    Validators.passwordMinimumLength(12),
                    Validators.complexPassword(),
                ]
            },
            {
                name: 'email',
                value: this.email,
                validators: [
                    Validators.required(),
                ]
            },
            {
                name: 'password',
                value: this.password,
                validators: [
                    Validators.required(),

                ]
            }
        ];
    }
}
