import { Injectable } from '@angular/core';
import { PortalSeparConfiguration } from '../models/portal-separ-configuration.model';
import { PortalSeparConfigurationStore } from './../data/portal-separ-configuration.store';

@Injectable()
export class PortalSeparConfigurationService {
    constructor(private store: PortalSeparConfigurationStore) {}

    public getConfiguration(): PortalSeparConfiguration | null | undefined {
        return this.store.getState();
    }
}
