import { Injectable } from '@angular/core';
import { PortalNotificationsStore } from '../data/portal-notifications.store';
import { PortalNotification } from '../models/portal-notification.model';

@Injectable()
export class PortalNotificationsService {

    constructor(
        public notificationStore: PortalNotificationsStore
    ) { }

    public initialize(): void {

    }

    public addSuccess(message: string): void {
        this.add(new PortalNotification({
            message,
            classname: 'bg-success text-light',
        }));
    }

    public addDanger(message: string): void {
        this.add(new PortalNotification({
            message,
            classname: 'bg-danger text-light',
        }));
    }

    public add(notification: PortalNotification): void {
        const notifications = this.notificationStore.getState();
        notifications.push(notification);
        this.notificationStore.update(notifications);
    }

    public remove(notification: PortalNotification) {
        let notifications = this.notificationStore.getState();
        notifications = notifications.filter(n => n.id !== notification.id);
        this.notificationStore.update(notifications);
    }
}
