

export  class BeneficiaryViewModel {
    public firstname: string;
    public lastname: string;
    public socialSecurityNumber: string;
    public bankAccount: string;
    public birthDate: string;
    public phone: string;
    public mobilePhone: string;
    public languageCode: string;

    constructor(init?: Partial<BeneficiaryViewModel>) {
        Object.assign(this, init);
    }
}
