export class AuthenticationConfiguration {
    public overviewTitle: string;
    public overviewDescription: string;
    public authenticationCSAMTitle: string;
    public authenticationCSAMDescription: string;
    public authenticationCSAMIcon: string;
    public authenticationCSAMButtonText: string;
    public authenticationLoginTitle: string;
    public authenticationLoginDescription: string;
    public authenticationLoginIcon: string;
    public authenticationLoginButtonText: string;
    public authenticateWithLoginTitle: string;
    public authenticateWithLoginEmailLabel: string;
    public authenticateWithLoginEmailPlaceholder: string;
    public authenticateWithLoginPasswordLabel: string;
    public authenticateWithLoginPasswordPlaceholder: string;
    public authenticateWithLoginForgotPasswordButton: string;
    public authenticateWithLoginNewAccountPageUrl: string;
    public authenticateWithLoginButton: string;
    public authenticateWithLoginOpenCSAMButton: string;
    public authenticateWithLoginNewAccountTitle: string;
    public authenticateWithLoginNewAccountDescription: string;
    public authenticateWithLoginNewAccountButton: string;
    public resetPasswordTitle: string;
    public resetPasswordButton: string;
    public resetPasswordSuccessMessage: string;
    public resetPasswordReturnToLoginButton: string;
    public resetPasswordNewPasswordTitle: string;
    public resetPasswordNewPasswordLabel: string;
    public resetPasswordNewPasswordPlaceholder: string;
    public resetPasswordConfirmPasswordLabel: string;
    public resetPasswordConfirmPasswordPlaceholder: string;
    public backofficeAuthenticationTitle: string;
    public backofficeAuthenticationSocialSecurityNumberLabel: string;
    public backofficeAuthenticationSocialSecurityNumberPlaceholder: string;
    public backofficeAuthenticationAccountLabel: string;
    public backofficeAuthenticationAccountPlaceholder: string;
    public backofficeAuthenticationPasswordLabel: string;
    public backofficeAuthenticationPasswordPlaceholder: string;
    public notFoundTransferDescription: string;
    public notFoundTransferButton: string;

    constructor(init?: Partial<AuthenticationConfiguration>) {
        Object.assign(this, init);
    }
}
