<div class="rounded overflow-hidden mb-4">
    <div *ngFor="let child of getChildrenByStatus(); let first = first">
        <div class="px-4 py-2 d-flex align-items-center cursor-pointer" style="margin-bottom: 1px;" (click)="selectChild(child)"
             [ngClass]="[((isSelected(child) && child.isActive()) ? 'bg-blue-10 text-secondary border-bottom border-bottom-blue-40'
                                                                : (!isSelected(child) && child.isActive() ? 'bg-white' : '')),
                    ((isSelected(child) && child.isSuspended()) ? 'bg-yellow border-bottom border-bottom-ecru-dark'
                                                                : (!isSelected(child) && child.isSuspended() ? 'bg-yellow-25' : '')),
                    ((isSelected(child) && child.isClosed()) ? 'bg-grey border-bottom border-bottom-ecru-dark'
                                                            : (!isSelected(child) && child.isClosed() ? 'bg-grey-25' : '')),
                    ((isSelected(child) && child.isActive() && !first) ? ' border-top border-top-blue-40' : ''),
                    ((isSelected(child) && child.isSuspended() && !first) ? ' border-top border-top-ecru-dark' : ''),
                    ((isSelected(child) && child.isClosed() && !first) ? ' border-top border-top-ecru-dark' : '')]">
            <div class="pr-4" *ngIf="iconPath">
                <img src="{{iconPath}}" />
            </div>
            <div class="flex-grow-1">
                <div class="font-weight-semi-bold">
                    {{child.firstname}} {{child.lastname}}
                </div>
                <div>
                    {{child.socialSecurityNumber | socialSecurityNumberFormat}}
                </div>
            </div>
            <div [ngClass]="{'text-green': child.isActive()}" *ngIf="(child.getAge() || child.getAge() == 0) && !child.isDeceased()">
                {{child.getAge()}} {{yearLabel(child.getAge())}}
            </div>
            <app-icon-chevron [color]="iconColorByChildBasicAllowanceStatus(child.childBasicAllowance?.status, isSelected(child))" [direction]="iconDirectionRight"></app-icon-chevron>
        </div>
    </div>
    <div *ngIf="!file.children || !file.children.length">
        {{ 'Infino.Portal.File.ThereAreNoChildren' | translate }}
    </div>
</div>
