<div class="h-100 d-flex justify-content-center align-items-center">
    <div class="col-md-3 py-4 py-lg-0 d-flex flex-column align-items-stretch">
        <p class="h4">{{ model.resetPasswordNewPasswordTitle }}</p>

        <form name="login" (ngSubmit)="resetPassword()">
            <app-text-input
                [id]="'email'"
                [name]="'email'"
                [placeholder]="model.authenticateWithLoginEmailPlaceholder"
                [label]="model.authenticateWithLoginEmailLabel"
                [(ngModel)]="formModel.email"
                [validationErrors]="validationErrors['email']"
                (validate)="validateItem('email')"
                (resetValidate)="resetItemValidation('email')">
            </app-text-input>
            <app-password-input
                [id]="'password'"
                [name]="'password'"
                [placeholder]="model.resetPasswordNewPasswordPlaceholder"
                [label]="model.resetPasswordNewPasswordLabel"
                [(ngModel)]="formModel.password"
                [validationErrors]="validationErrors['password']"
                (validate)="validateItem('password')"
                (resetValidate)="resetItemValidation('password')">
            </app-password-input>
            <app-password-input
                [id]="'confirmPassword'"
                [name]="'confirmPassword'"
                [placeholder]="model.resetPasswordConfirmPasswordPlaceholder"
                [label]="model.resetPasswordConfirmPasswordLabel"
                [(ngModel)]="formModel.confirmPassword"
                [validationErrors]="validationErrors['confirmPassword']"
                (validate)="validateItem('confirmPassword')"
                (resetValidate)="resetItemValidation('confirmPassword')">
            </app-password-input>
            <div *ngIf="this.errors && this.errors.length > 0">
                <div class="alert alert-danger" *ngFor="let error of errors" [innerHTML]="this.errors"></div>
            </div>
            <button
                type="submit"
                class="btn btn-primary my-3 text-white form-control"
                (onClick)="resetPassword()"
                [disabled]="loading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                {{ model.resetPasswordSubmitLabel }}
            </button>
        </form>
    </div>
</div>
