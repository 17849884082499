export enum ValidationError {
    Required = 'Required',
    AlphaNumeric = 'AlphaNumeric',
    Alphabet = 'Alphabet',
    MaxLength = 'MaxLength',
    PasswordMinLength = 'PasswordMinLength',
    MinLength = 'MinLength',
    MinValue = 'MinValue',
    PostalCode = 'Postalcode',
    Email = 'Email',
    Telephone = 'Telephone',
    NationalInsuranceNumber = 'NationalInsuranceNumber',
    DateFormat = 'DateFormat',
    Iban = 'Iban',
    MinTextDate = 'MinTextDate',
    MaxTextDate = 'MaxTextDate',
    NotChecked = 'NotChecked',
    ComplexPassword = 'ComplexPassword',
}
