import { PortalChildStatusLinksService } from './services/portal-childstatuslinks.service';
import { PortalChildStatusLinksStore } from './data/portal-childstatuslinks.store';
import { PortalChildStatusLinksMapper } from './mappers/portal-childstatuslinks.mapper';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SingletonModule } from '../../shared/modules/singleton.module';
import { FlowConfigurationStore } from './data/flow-configuration.store';
import { FlowConfigurationMapper } from './mappers/flow-configuration.mapper';
import { FlowConfigurationService } from './services/flow-configuration.service';
import { HttpClientModule } from '@angular/common/http';
import { PortalConfigurationService } from './services/portal-configuration.service';
import { AuthenticationConfigurationStore } from './data/authentication-configuration.store';
import { AuthenticationConfigurationMapper } from './mappers/authentication-configuration.mapper';
import { PortalConfigurationStore } from './data/portal-configuration.store';
import { PortalConfigurationMapper } from './mappers/portal-configuration.mapper';
import { PortalSeparConfigurationStore } from './data/portal-separ-configuration.store';
import { PortalSeparConfigurationMapper } from './mappers/portal-separ-configuration.mapper';
import { PortalSeparConfigurationService } from './services/portal-separ-configuration.service';
import { RegionMapper } from '../user/mappers/region.mapper';
import { PortalAlertConfigurationStore } from './data/portal-alert-configuration.store';
import { PortalAlertConfigurationMapper } from './mappers/portal-alert-configuration.mapper';

@NgModule({
    imports: [
        HttpClientModule,
    ],
    providers: [
        FlowConfigurationStore,
        FlowConfigurationService,
        FlowConfigurationMapper,
        AuthenticationConfigurationStore,
        AuthenticationConfigurationMapper,
        PortalChildStatusLinksMapper,
        PortalConfigurationStore,
        PortalConfigurationMapper,
        PortalConfigurationService,
        PortalChildStatusLinksStore,
        PortalChildStatusLinksService,
        PortalSeparConfigurationStore,
        PortalSeparConfigurationMapper,
        PortalSeparConfigurationService,
        RegionMapper,
        PortalAlertConfigurationStore,
        PortalAlertConfigurationMapper,
    ]
})
export class FlowConfigurationModule extends SingletonModule {
    constructor(@Optional() @SkipSelf() module: FlowConfigurationModule) {
        super(module);
    }
}
