import { EmailInputComponent } from './../../../../../shared/components/inputs/email-input/email-input.component';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PortalResetPasswordModel } from './portal-reset-password.model';
import { PortalResetPasswordFormModel } from './portal-reset-password-form.model';
import { PortalResetPasswordMapper } from './portal-reset-password.mapper';
import { ValidationHelper } from 'src/app/shared/infrastructure/validation/validation.helper';
import { AuthenticationConfigurationStore } from 'src/app/core/configuration/data/authentication-configuration.store';
import { AuthenticationConfiguration } from 'src/app/core/configuration/models/authentication-configuration.model';
import { TranslateService } from '@ngx-translate/core';
import { ResetPasswordService } from '../../services/reset-password.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'app-portal-authentication',
  templateUrl: './portal-reset-password.component.html',
})
export class PortalResetPasswordComponent implements OnInit {

  public validationErrors = {};

  public model: PortalResetPasswordModel;
  public formModel: PortalResetPasswordFormModel = new PortalResetPasswordFormModel();

  public initial = false;
  public token: string;

  public submitted = true;
  public loading = false;
  public errors: string[];

  constructor(
    private fb: FormBuilder,
    private resetPasswordService: ResetPasswordService,
    private authenticationConfigurationStore: AuthenticationConfigurationStore,
    private mapper: PortalResetPasswordMapper,
    private route: ActivatedRoute,
    private router: Router,
    private localize: LocalizeRouterService,
    private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.authenticationConfigurationStore.state$.subscribe((configuration: AuthenticationConfiguration) => {
      this.model = this.mapper.map(configuration);
    });

    this.route.queryParams.subscribe(params => {
      if (params.token) {
        this.token = params.token;
      }
      if (params.email) {
        this.formModel.email = params.email;
      }
      if (params.initial === '1') {
        this.initial = true;
      }
    });
  }

  resetPassword() {
    this.loading = true;
    this.errors = [];
    const validationResult = ValidationHelper.validate(this.formModel.getValidators());
    if (validationResult.isValid) {
      if (this.formModel.confirmPassword !== this.formModel.password) {
        this.errors.push(this.translateService.instant('Infino.ClientPortal.Validation.NotEqual'));
        this.loading = false;
      }
      else {
        this.resetPasswordService.resetPassword(this.formModel.email, this.formModel.password, this.token, this.initial).subscribe(
          (response: Boolean) => {
            this.loading = false;
            if (response) {
              const translatedPath: any = this.localize.translateRoute('/portal/auth/foreign');
              this.router.navigate([translatedPath]);
            } else {
              this.errors.push(this.translateService.instant('Infino.Portal.ResetPassword.Error'));
            }
          },
          (error) => {
            this.errors.push(this.translateService.instant('Infino.Portal.ResetPassword.Error'));
            this.loading = false;
          }
        );
      }
    } else {
      this.loading = false;
    }
  }

  public validateItem(fieldName: string) {
    const validationResult = ValidationHelper.validateField(fieldName, this.formModel.getValidators());
    this.validationErrors = validationResult.extendErrorObject(this.validationErrors);
  }

  public resetItemValidation(fieldName: string) {
    this.validationErrors = ValidationHelper.removeFromErrorObject(fieldName, this.validationErrors);
  }
}
