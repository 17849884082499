import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullScreenHandlerService } from './full-screen-handler.service';

@NgModule({
    declarations: [],
    imports: [ CommonModule ],
    exports: [],
    providers: [FullScreenHandlerService],
})
export class PresentationModule {}
