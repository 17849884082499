import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BootModule } from './core/boot/boot.module';
import { FlowConfigurationModule } from './core/configuration/flow-configuration.module';
import { PresentationModule } from './core/presentation/presentation.module';
import { UserService } from './core/user/services/user.service';
import { UserModule } from './core/user/user.module';
import { OnboardingStoreModule } from './modules/onboarding/data/onboarding-store.module';
import { TransferStoreModule } from './modules/transfer/data/transfer-store.module';
import { DigitalSignatureModule } from './shared/components/modals/digital-signature/digital-signature.module';
import { SimpleInfoModalModule } from './shared/components/modals/simple-info/simple-info-modal.module';
import { ModalModule } from './shared/infrastructure/modals/modal.module';
import { CookiesModule } from './shared/components/cookies/cookies.module';
import { KenticoResourceStringLoader } from './shared/loaders/kenticoResourceString.loader';
import { OnboardingFlowDirectAccessGuard } from './shared/guards/onboarding-flow-direct-access.guard';
import { TransferFlowDirectAccessGuard } from './shared/guards/transfer-flow-direct-access.guard';
import { PortalModule } from './modules/portal/portal.module';
import { ChildBenefitsFilesDataModule } from './core/files/child-benefits-files-data.module';
import { SeparFilesDataModule } from './core/separ/separ-files-data.module';
import { NotificationsModule } from './core/notifications/notifications.module';
import { ApplicationInsightsErrorHandlerService } from './core/logging/handlers/application-insights-error-handler-service';
import { LoggingService } from './core/logging/services/logging.service';


@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        // ServiceWorker is disabled in attempt to solve double submit issue.
        // If you want to reenable it, also remove the rule 'Disable ServiceWorker' in web.config !!!
        // ServiceWorkerModule.register('ngsw-worker.js', { enabled: false }),
        HttpClientModule,
        BootModule,
        UserModule,
        NotificationsModule,
        FlowConfigurationModule,
        ChildBenefitsFilesDataModule,
        SeparFilesDataModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: KenticoResourceStringLoader,
                deps: [HttpClient, UserService]
            },
            // defaultLanguage: 'nl-BE',
            // missingTranslationHandler: { provide: MissingTranslationHandler, useClass: AppMissingTranslationHandler}
        }),
        AppRoutingModule,
        PresentationModule,
        ModalModule,
        NgbModule,
        CookiesModule,

        // Defined here as it is a modal that opens at app component level
        DigitalSignatureModule,
        SimpleInfoModalModule,

        // Stores
        OnboardingStoreModule,
        TransferStoreModule,
        BrowserAnimationsModule,

        // Portal
        PortalModule,
    ],
    providers: [
      NgbActiveModal,
      KenticoResourceStringLoader,
      OnboardingFlowDirectAccessGuard,
      TransferFlowDirectAccessGuard,
      LoggingService,
      { provide: ErrorHandler, useClass: ApplicationInsightsErrorHandlerService}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
