<div class="form-group">

    <label for="{{id}}">
        {{label}}
        <span *ngIf="!required" class="text-danger">{{'Infino.ClientPortal.Validation.optional' | translate}}</span>
    </label>

    <input [id]=id type="file" #file class="sr-only" (change)="onFilesAdded()" multiple [accept]="allowedExtensions.concat(',')" />

    <div class="d-flex justify-content-center align-items-center form-component-filedrop p-4 cursor-pointer bg-white border-brown-25 border-dashed" (click)="addFiles()" (drop)="dropHandler($event)" (dragenter)="dragEnterHandler($event)" (dragover)="dragOverHandler($event)">
        <img src="assets/svg/icon-paperclip.svg" class="mr-2" alt="" />
        <span class="text-primary">
            {{ labelAddFile || ('Infino.ClientPortal.FileUpload.AddFile' | translate) }}
        </span>&nbsp;
        <span class="text-brown-40">
            {{ labelDragFile || ('Infino.ClientPortal.FileUpload.DragFile' | translate) }}
        </span>
    </div>
    <small class="mt-1" *ngIf="!allowedFormatsLabel">{{'Infino.ClientPortal.FileUpload.AllowedFormats' | translate}} jpg, png, gif, heic, pdf, docx</small>
    <small class="mt-1" *ngIf="allowedFormatsLabel">{{ allowedFormatsLabel }}</small>
    <div *ngIf="showValidationErrors">
        <div class="alert alert-danger" *ngFor="let error of validationErrors" [innerHTML]="'Infino.ClientPortal.Validation.' + error | translate">
        </div>
    </div>
    <ng-container *ngIf="files && files.length > 0">
        <div *ngFor="let file of files, let i = index" [ngClass]="borderStyle(i)" class="d-flex justify-content-between border-left border-right border-bottom border-brown-25-alpha py-1 px-2 ">
            <div>{{file.name}}</div>
            <img src="assets/svg/icon-x-gray.svg" class="cursor-pointer" (click)="deleteFile(file)"/>
        </div>
    </ng-container>
    <small class="form-text">{{subText}}</small>
</div>
