import { RegionEnum } from './../../payments/models/region-enum';
import { SeparSchoolYearDetails } from './separ-school-year-details.model';

export class SeparFile {
    public region: RegionEnum;
    public fileId: string;
    public schoolYearDetails: SeparSchoolYearDetails[];

    constructor(init?: Partial<SeparFile>) {
        Object.assign(this, init);
    }
}
