import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { LogHelper } from '../../../shared/helpers/log.helper';
import { RegionEnum } from '../../payments/models/region-enum';

@Injectable()
export class RegionMapper {
    public map(regionParam: string): RegionEnum {
        switch (regionParam.toLocaleLowerCase()) {
            case 'flanders':
            case 'vlaanderen':
            case 'flandre':
                return RegionEnum.Flanders;
            case 'wallonia':
            case 'wallonie':
                return RegionEnum.Wallonia;
            case 'brussels':
            case 'brussel':
            case 'bruxelles':
                return RegionEnum.Brussels;

            default:
                if (environment.isLocalHost) {
                    LogHelper.log('Could not find region query params, defaulting to Flanders in development', regionParam);
                    return RegionEnum.Flanders;
                }
                throw new Error(`Provided region not known: ${regionParam}`);
        }
    }
}
