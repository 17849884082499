import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { createUUID } from 'src/app/shared/helpers/uuid.helper';

import { Store } from '../../../shared/infrastructure/state/store';
import { TransferDataModel } from '../models/transfer-data.model';
import { TransferFormModel } from '../models/transfer-form.model';

@Injectable()
export class TransferStore extends Store<TransferDataModel> {

    public calculationUpdatePending = new BehaviorSubject<boolean>(false);

    protected getInitialState(): TransferDataModel {
        return new TransferDataModel(
            new TransferFormModel(),
            createUUID(),
        );
    }

    public getTransferData(): TransferDataModel {
        return this.getState();
    }

    public getTransferForm(): TransferFormModel {
        return this.getTransferData().transferForm;
    }

    public setTransferForm(form: TransferFormModel): void {
        const data = this.getTransferData();
        data.transferForm = form;
        this.update(data);
    }

    public reset(): void {
        this.update(this.getInitialState());
    }

    protected getStoreName(): string {
        return 'transfer-state';
    }
}
