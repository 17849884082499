<div class="h-100 d-flex flex-column justify-content-center py-4">
    <div class="container">
        <p class="h4" [innerHtml]="model.overviewTitle"></p>
        <p class="text-medium" [innerHtml]="model.overviewDescription"></p>
    </div>
    <div class="container">
        <div class="row d-flex flex-wrap">
            <div class="mb-5 col-md-6">
                <app-card [title]="model.authenticationCSAMTitle" [buttonText]="model.authenticationCSAMButtonText"
                    (onAction)="loginItsMe()" [imageLink]="model.authenticationCSAMIcon">
                    <div [innerHtml]="model.authenticationCSAMDescription"></div>
                    <div *ngIf="fileNotFound$ | async" class="mt-3">
                        <p class="text-danger font-weight-semi-bold">{{ model.notFoundTransferDescription }}</p>
                        <a [routerLink]="'/transfer' | localize" class="btn btn-primary">{{ model.notFoundTransferButton }}</a>
                    </div>
                </app-card>
            </div>
            <div class="mb-5 col-md-6">
                <app-card [title]="model.authenticationLoginTitle" [buttonText]="model.authenticationLoginButtonText"
                    (onAction)="login()" [imageLink]="model.authenticationLoginIcon">
                    <div [innerHtml]="model.authenticationLoginDescription"></div>
                </app-card>
            </div>
        </div>
    </div>
</div>