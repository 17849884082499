export class QuickLinksViewModel {
    translations: {
        getToWorkYourself: string,
        generateCertificate: string,
        aSpecificQuestion: string,
        askQuestionAboutYourFile: string,
        checkoutFAQ: string,
    };
    faqLink: string;
    certificateLink: string | any[];
    fileManagers: {
        firstname: string,
        lastname: string,
        phone: string,
        questionLink: string | string[],
        kids: string[],
        id: string,
        show: boolean
    }[];

    constructor(init: Partial<QuickLinksViewModel>) {
        Object.assign(this, init);
    }

    multiple(): boolean {
        return this.fileManagers?.length > 1;
    }
}
