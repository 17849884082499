<app-file-tabs></app-file-tabs>
<div class="d-flex h-100" *ngIf="model$ | async as model">
    <div class="flex-grow-1">
        <div *ngIf="model.isLoaded">
            <div class="bg-yellow slope-bottom" *ngIf="model.isFileSelected()">
                <div class="container-lg p-4">
                    <h3 class="font-weight-semi-bold mb-3">{{ model.fileLabel }}: {{ model.selectedFile.fileId }}</h3>
                    <div class="d-flex align-items-center justify-content-between pt-3 pb-3">
                        <div class="text-overline text-uppercase">
                            {{model.beneficiariesTitle}}
                        </div>

                        <button class="btn btn-outline-dark" (click)="openChangeDataModal()">{{ model.changeDataLabel }}</button>
                    </div>
                    <app-beneficiary-overview [file]="model.selectedFile" [primaryEmail]="model.primaryEmail"></app-beneficiary-overview>
                    <div class="d-flex align-items-center justify-content-between pt-3 pb-3" *ngIf="model.selectedFile.hasMediator()">
                        <div class="text-overline text-uppercase">
                            {{model.mediatorTitle}}
                        </div>
                    </div>
                    <app-mediator-overview [file]="model.selectedFile"></app-mediator-overview>
                    <div class="d-flex align-items-center justify-content-between pt-3 pb-3" *ngIf="model.selectedFile.hasAdministrator()">
                        <div class="text-overline text-uppercase">
                            {{model.administratorTitle}}
                        </div>
                    </div>
                    <app-administrator-overview [file]="model.selectedFile"></app-administrator-overview>
                </div>
            </div>
            <div class="container-lg p-4">
                <div class="d-flex justify-content-between pt-2 pb-3 align-items-center">
                    <div class="text-overline text-uppercase">
                        {{model.childrenActiveTitle}}
                    </div>

                    <a [routerLink]="'/onboarding' | localize" class="btn btn-primary text-medium">{{ model.childOnTheWay }}</a>
                </div>
                <app-children-overview
                    [file]="model.selectedFile"
                    [selectedChild]="model.selectedChild"
                    [yearLabel]="model.yearLabel"
                    [statuses]="[childBasicAllowanceStatusEnum.Unknown, childBasicAllowanceStatusEnum.Active]"
                    (childSelected)="model.selectChild($event)">
                </app-children-overview>

                <div *ngIf="model.hasSelectedFileChildrenWithBasicAllowanceStatus(childBasicAllowanceStatusEnum.Suspended)">
                    <div class="d-flex justify-content-between pt-2 pb-3 align-items-center">
                        <div class="text-overline text-uppercase">
                            {{model.childrenSuspendedTitle}}
                        </div>
                    </div>
                    <app-children-overview
                        [file]="model.selectedFile"
                        [selectedChild]="model.selectedChild"
                        [yearLabel]="model.yearLabel"
                        [statuses]="[childBasicAllowanceStatusEnum.Suspended]"
                        (childSelected)="model.selectChild($event)"
                        iconPath="/assets/svg/icon-alert-triangle-red.svg" >
                    </app-children-overview>
                </div>

                <div *ngIf="model.hasSelectedFileChildrenWithBasicAllowanceStatus(childBasicAllowanceStatusEnum.Closed)">
                    <div class="d-flex justify-content-between pt-2 pb-3 align-items-center">
                        <div class="text-overline text-uppercase">
                            {{model.childrenClosedTitle}}
                        </div>
                    </div>
                    <app-children-overview
                        [file]="model.selectedFile"
                        [selectedChild]="model.selectedChild"
                        [yearLabel]="model.yearLabel"
                        [statuses]="[childBasicAllowanceStatusEnum.Closed]"
                        (childSelected)="model.selectChild($event)">
                    </app-children-overview>
                </div>
            </div>
        </div>

        <div class="loading" *ngIf="!model.isLoaded">
            <div class="bg-yellow slope-bottom">
                <div class="container-lg p-4">
                    <div class="d-flex justify-content-between pt-2 pb-4 my-1">
                        <div class="text-overline text-uppercase">
                            {{model.beneficiariesTitle}}
                        </div>
                    </div>
                    <app-beneficiary-overview></app-beneficiary-overview>
                </div>
            </div>
        </div>
    </div>
    <app-sidepanel [open]="model.selectedChild != null">
        <app-child-detail
            [child]="model.selectedChild"
            [labels]="model.childDetailLabels"
            [canShowPrevious]="model.hasPreviousChild"
            [canShowNext]="model.hasNextChild"
            (onClose)="model.hideDetail()"
            (onNext)="model.nextChildDetails()"
            (onPrevious)="model.previousChildDetails()"></app-child-detail>
    </app-sidepanel>
</div>
