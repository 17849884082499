import { ValidationHelper } from './../../../../shared/infrastructure/validation/validation.helper';
import { PortalCertificateModelMapper } from '../mappers/portal-certificate-model.mapper';
import { PortalCertificateModel } from './../models/portal-certificate.model';
import { ChildBenefitsStateStore } from './../../../../core/files/data/child-benefits-state.store';
import { CertificateService } from '../services/certificate.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { isMobileUserAgent } from '../../../../shared/helpers/is-mobile.helper';
import { datalayerPush } from 'src/app/shared/helpers/datalayer.helper';
import { PortalNotificationsService } from 'src/app/core/notifications/services/portal-notification.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-portal-certificate',
    templateUrl: './portal-certificate.component.html'
})
export class PortalCertificateComponent implements OnInit {
    public model: PortalCertificateModel;
    public showAmount: boolean;
    public validationErrors: any = {};
    public get fileId(): string { return this.store.getState().selectedId; }

    constructor(
        private service: CertificateService,
        private store: ChildBenefitsStateStore,
        private mapper: PortalCertificateModelMapper,
        private domSanitizer: DomSanitizer,
        private notificationService: PortalNotificationsService,
        private translate: TranslateService,
    ) { }

    ngOnInit(): void {
        this.model = this.mapper.map();
        this.showAmount = true;
    }

    public submit() {
        this.model.noData = false;
        this.model.certificate = undefined;
        this.model.certificateUrl = undefined;
        this.model.certificateFilename = undefined;

        const validationResult = ValidationHelper.validate(this.model.getValidators());
        if (!validationResult.isValid) {
            this.validationErrors = validationResult.getErrorObject();
            return;
        }

        datalayerPush({ event: 'generate_certificate', type: this.model.form.sendByMail ? 'pdf_byemail' : 'pdf_only' });

        this.model.loading = true;

        this.service.getCertificate(new Date(this.model.form.dateFrom), new Date(this.model.form.dateUntil), this.fileId, this.showAmount).subscribe(pdf => {
            this.model.certificate = pdf;

            // if mail, send the mail, and open the pdf
            // only end loading state when mail is sent
            if (this.model.form.sendByMail && this.model.form.email) {

                pdf.getBlob(blob => {
                    this.service.mailCertificate(this.model.form.email, blob).subscribe(() => {
                        this.model.loading = false;
                        this.model.success = true;
                    }, (error) => {
                        this.model.loading = false;
                    });
                });
            } else {
                this.model.loading = false;
            }

            // otherwise, just show the pdf
            this.openCertificate();
            this.prepareCertificateUrl();
        }, (error) => {
            this.model.loading = false;

            if (error instanceof Error && error.message === 'nodata') {
                this.model.noData = true;
            } else {
                this.notificationService.addDanger(this.translate.instant('Infino.Portal.Certificate.Pdf.SomethingWentWrong'));
            }
        });
    }

    public openCertificate() {
        if (isMobileUserAgent()) {
            this.downloadCertificate();
        } else {
            this.model.certificate.open();
        }
        this.notificationService.addSuccess(this.translate.instant('Infino.Portal.Certificate.DownloadStartedNotification'));
    }

    public prepareCertificateUrl() {
        this.model.certificate.getBlob(blob => {
            const filename = `infino_certificate_${this.model.form.dateFrom || ''}-${this.model.form.dateUntil || ''}.pdf`;
            const url = window.URL.createObjectURL(blob);
            const safeUrl = this.domSanitizer.bypassSecurityTrustUrl(url);

            this.model.certificateUrl = safeUrl;
            this.model.certificateFilename = filename;
        });
    }

    public validateItem(fieldName: string) {
        const validationResult = ValidationHelper.validateField(fieldName, this.model.getValidators());
        this.validationErrors = validationResult.extendErrorObject(this.validationErrors);
    }

    public resetItemValidation(fieldName: string) {
        this.validationErrors = ValidationHelper.removeFromErrorObject(fieldName, this.validationErrors);
    }

    public resetForm() {
        this.model = this.mapper.map();
        this.validationErrors = {};
    }

    public downloadCertificate() {
        this.model.certificate.download();
    }

    public downloadCertificateInNewTab() {
        const filename = `infino_certificate_${this.model.form.dateFrom || ''}-${this.model.form.dateUntil || ''}.pdf`;
        const tabWindow = window.open('', '_blank', '');
        const a = tabWindow.document.createElement('a');
        a.id = 'aaa';
        a.textContent = 'Download should start automatically. Click here to download ' + filename + ' if nothing happens';
        const scrpt = tabWindow.document.createElement('script');
        scrpt.innerHTML = 'document.getElementById("aaa").click()';
        tabWindow.document.body.appendChild(a);
        tabWindow.document.body.style.cursor = 'wait';
        this.model.certificate.getBlob(blob => {
            tabWindow.document.body.style.cursor = 'auto';
            const url = a.href = window.URL.createObjectURL(blob);
            a.download = filename;
            // a.click();
            tabWindow.document.body.appendChild(scrpt);

            setTimeout(() => {
                tabWindow.close();
            }, 200);

            setTimeout(function revokeUrl() {
                window.URL.revokeObjectURL(url);
            }, 3600000);
        });
    }
}
