import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as dayjs from 'dayjs';
import { HeaderColorMode } from '../../../components/detail-pane/detail-pane-header.component';
import { DocumentDetailLabelsViewModel } from '../../viewmodels/portal-documents-document-detail-labels.viewmodel';
import { DocumentViewModel } from '../../viewmodels/portal-documents-document.viewmodel';
import { IconColorEnum } from 'src/app/shared/components/icons/enums/icon-color.enum';

@Component({
  selector: 'app-portal-document-detail',
  templateUrl: './portal-document-detail.component.html',
})
export class PortalDocumentDetailComponent implements OnInit {

  @Input() document: DocumentViewModel;
  @Input() model: DocumentDetailLabelsViewModel;
  @Output() previous = new EventEmitter();
  @Output() next = new EventEmitter();
  @Output() close = new EventEmitter();
  @Input() hasNext = false;
  @Input() hasPrev = false;

  public detailHeaderColorMode = HeaderColorMode.Light;

  constructor() { }

  ngOnInit(): void {
  }

  getDate(date: string) {
    return dayjs(date);
  }
}
