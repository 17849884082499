import { Validators } from '../../../../../shared/infrastructure/validation/validators';
import { IValidationField } from '../../../../../shared/infrastructure/validation/validation-field.interface';

export class PortalBackofficeAuthenticationFormModel {
    public email: string;
    public password: string;
    public socialSecurityNumber: string;

    public getValidators(): IValidationField[] {
        const date = new Date();
        const maxdate = new Date();
        maxdate.setMonth(date.getMonth() + 9);

        return [
            {
                name: 'socialSecurityNumber',
                value: this.socialSecurityNumber,
                validators: [
                    Validators.required(),
                ]
            },
            {
                name: 'email',
                value: this.email,
                validators: [
                    Validators.required(),
                ]
            },
            {
                name: 'password',
                value: this.password,
                validators: [
                    Validators.required(),
                ]
            }
        ];
    }
}
