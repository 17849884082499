import { LocalizedDateModule } from './../../../shared/pipes/localized-date/localized-date.module';
import { SmoothHeightModule } from './../../../shared/components/smooth-height/smooth-height.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { InputsModule } from 'src/app/shared/components/inputs/inputs.module';
import { PortalHelpMapper } from './mappers/portal-help.mapper';
import { PortalHelpComponent } from './pages/portal-help.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';
import { PortalFilesComponent } from './pages/portal-files.component';
import { PortalFilesMapper } from './mappers/portal-files.mapper';
import { BeneficiaryOverviewComponent } from './components/beneficiary-overview/beneficiary-overview.component';
import {ChildrenOverviewComponent} from './components/children-overview/children-overview.component';
import { ChildDetailComponent } from './components/child-detail/child-detail.component';
import { IconsModule } from 'src/app/shared/components/icons/icons.module';
import { DetailPaneModule } from '../components/detail-pane/detail-pane.module';
import { SidepanelModule } from 'src/app/shared/components/sidepanel/sidepanel.module';
import { FileTabsComponent } from './components/file-tabs/file-tabs.component';
import { UserModule } from '../user/user.module';
import { MediatorOverviewComponent } from './components/mediator-overview/mediator-overview.component';
import { AdministratorOverviewComponent } from './components/administrator-overview/administrator-overview.component';
import { SocialSecurityNumberModule } from 'src/app/shared/pipes/social-security-number/social-security-number.module';

@NgModule({
  declarations: [
    PortalFilesComponent,
    BeneficiaryOverviewComponent,
    ChildrenOverviewComponent,
    ChildDetailComponent,
    FileTabsComponent,
    PortalHelpComponent,
    MediatorOverviewComponent,
    AdministratorOverviewComponent,
  ],
  imports: [
    CommonModule,
    LocalizeRouterModule,
    TranslateModule,
    IconsModule,
    DetailPaneModule,
    SidepanelModule,
    InputsModule,
    FormsModule,
    RouterModule,
    SmoothHeightModule,
    LocalizedDateModule,
    UserModule,
    SocialSecurityNumberModule,
  ],
  exports: [
    FileTabsComponent,
  ],
  providers: [
    PortalFilesMapper,
    PortalHelpMapper
  ]
})
export class ChildBenefitsFilesModule { }
