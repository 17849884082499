import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { of } from 'rxjs';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileUploadComponent),
            multi: true
        }
    ]
})
export class FileUploadComponent implements ControlValueAccessor {

    @Input() public label: string;
    @Input() public id: string;
    @Input() public subText: string;
    @Input() public required = true;
    @Input() public filesTooLargeValidationMessage = '';
    @Input() public extensionNotAllowedValidationMessage = '';
    @Input() public allowedExtensions = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/heic', 'application/pdf', '.docx'];
    @Input() public allowedFormatsLabel: string;
    @Input() public labelAddFile: string;
    @Input() public labelDragFile: string;

    public showValidationErrors = true;
    public validationErrors = [];

    @Output() fileAdded = new EventEmitter();
    @ViewChild('file') file;


    public files: File[] = new Array();

    private maxFilesSize = 15000000;

    constructor(private sanitizer: DomSanitizer) { }

    public addFiles() {
        this.file.nativeElement.click();
    }

    public onFilesAdded() {
        const files: { [key: string]: File } = this.file.nativeElement.files;
        for (const key in files) {
            if (!isNaN(parseInt(key, 10))) {
                this.addFile(files[key]);
            }
        }
        this.fileAdded.emit(this.files);
    }

    public addError(errorType: string) {
        this.showValidationErrors = true;
        this.validationErrors.push(errorType);

    }

    public resetErrors() {
        this.showValidationErrors = false;
        this.validationErrors = [];
    }

    public serveFile(file: File) {
        const urlCreator = window.URL;
        return this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(file));
    }

    public dropHandler(event) {
        // Prevent default behavior (Prevent file from being opened)
        event.preventDefault();
        if (event.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (const item of event.dataTransfer.items) {
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    this.addFile(file);
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            for (const item of event.dataTransfer.files) {
                this.files.push(item);
            }
        }

        // Pass event to removeDragData for cleanup
        this.removeDragData(event);
    }

    public addFile(file: File) {
        let totalfilesSize = 0;
        if (this.files.length > 0) {
            totalfilesSize = this.files.map(f => f.size).reduce((sizeA, sizeB) => sizeA + sizeB);
        }

        console.log({ file });
        console.log(this.allowedExtensions);

        if (this.allowedExtensions.some(ext => file.type === ext
            || file.name.split('.').pop() === ext.split('.').pop()
            || file.name.split('.').pop() === ext.split('/').pop())
        ) {
            if ((totalfilesSize + file.size) < this.maxFilesSize) {
                this.files.push(file);
                this.resetErrors();
            }
            else {
                alert(this.filesTooLargeValidationMessage);
            }
        } else {
            alert(this.extensionNotAllowedValidationMessage);
        }
    }

    public dragEnterHandler(event) {
        // Prevent default behavior (Prevent file from being opened)

        event.preventDefault();
    }

    public dragOverHandler(event) {
        // Prevent default behavior (Prevent file from being opened)
        event.preventDefault();
    }

    public writeValue(value: any): void {
        if (value !== undefined) {
            this.files = value;
            this.notify();
        }
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any): void { }

    public deleteFile(file: File) {
        this.files = this.files.filter(f => f !== file);
        this.notify();
    }

    borderStyle(i: number) {
        return [i + 1 === this.files.length ? 'rounded-bottom' : '', i === 0 ? 'border-top rounded-top' : ''];
    }

    private removeDragData(event) {
        if (event.dataTransfer.items) {
            // Use DataTransferItemList interface to remove the drag data
            event.dataTransfer.items.clear();
        } else {
            // Use DataTransfer interface to remove the drag data
            event.dataTransfer.clearData();
        }
    }

    public propagateChange = (_: any) => { };

    private notify(): void {
        const val = this.files;

        if (val) {
            this.propagateChange(val);
            this.fileAdded.emit(this.files);
        }
    }
}
