<div class="icon h-100  d-flex align-items-center justify-content-center" style="width:20px;" [ngClass]="classes">
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
            <path
                d="M0.999878 4.00065V14.6673L5.66654 12.0007L10.9999 14.6673L15.6665 12.0007V1.33398L10.9999 4.00065L5.66654 1.33398L0.999878 4.00065Z"
                stroke="#E2D3C6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.66663 1.33398V12.0007" stroke="#E2D3C6" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            <path d="M10.9999 4V14.6667" stroke="#E2D3C6" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" transform="translate(0.333252)" />
            </clipPath>
        </defs>
    </svg>
</div>