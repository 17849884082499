import { TranslateService } from '@ngx-translate/core';
import { PortalCertificateModel } from '../models/portal-certificate.model';
import { Injectable } from '@angular/core';

@Injectable()
export class PortalCertificateModelMapper {
    translateKey = 'Infino.Portal.Certificate';

    constructor(private translate: TranslateService) { }

    public map(): PortalCertificateModel {
        return new PortalCertificateModel({
            translations: {
                generateAndDownload: this.translate.instant(`${this.translateKey}.GenerateAndDownload`),
                generateAndDownloadSubtitle: this.translate.instant(`${this.translateKey}.GenerateAndDownloadSubtitle`),
                generateCertificate: this.translate.instant(`${this.translateKey}.GenerateCertificate`),
                generateCertificateSubtitle: this.translate.instant(`${this.translateKey}.GenerateCertificateSubtitle`),
                pickEndDate: this.translate.instant(`${this.translateKey}.PickEndDate`),
                pickStartDate: this.translate.instant(`${this.translateKey}.PickStartDate`),
                iWantToMailIt: this.translate.instant(`${this.translateKey}.IWantToMailIt`),
                enterAnEmail: this.translate.instant(`${this.translateKey}.EnterAnEmail`),
                email: this.translate.instant(`${this.translateKey}.Email`),
                certificateReadyClickIfNothingHappens: this.translate.instant(`${this.translateKey}.CertificateReadyClickIfNothingHappens`),
                noData: this.translate.instant(`${this.translateKey}.NoData`),
                certificateWithPayments: this.translate.instant(`${this.translateKey}.WithPayments`),
                certificateWithoutPayments: this.translate.instant(`${this.translateKey}.WithoutPayments`),
            },
            form: {
                dateFrom: undefined,
                dateUntil: undefined,
                email: '',
                sendByMail: false
            }
        });
    }
}
