import { PaymentsService } from 'src/app/core/payments/services/payments.service';
import { ChildBenefitsPaymentDetail } from './../../../../../core/payments/models/child-benefits-payment-detail';
import { ChildBenefitsStateStore } from './../../../../../core/files/data/child-benefits-state.store';
import { TranslateService } from '@ngx-translate/core';
import { rotateUpDown } from './../../../../../shared/infrastructure/animations/animation.constant';
import { ChildBenefitsPayment } from './../../../../../core/payments/models/child-benefits-payment';
import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { IconColorEnum } from '../../../../../shared/components/icons/enums/icon-color.enum';
import { IconDirectionEnum } from '../../../../../shared/components/icons/enums/icon-direction.enum';
import { HeaderColorMode } from '../../../components/detail-pane/detail-pane-header.component';
import * as dayjs from 'dayjs';
import { pluralizedTranslation } from 'src/app/shared/helpers/translations';
import { datalayerPush } from 'src/app/shared/helpers/datalayer.helper';
import { BeneficiaryTypeEnum } from 'src/app/core/payments/models/beneficiary-type-enum';
import { SeparStateStore } from 'src/app/core/separ/data/separ-state.store';

@Component({
  selector: 'app-portal-payment-detail',
  templateUrl: './portal-payment-detail.component.html',
  animations: [rotateUpDown]
})
export class PortalPaymentDetailComponent implements OnInit, OnChanges {
  @Input() payment: ChildBenefitsPayment;
  @Output() previous = new EventEmitter();
  @Output() next = new EventEmitter();
  @Output() close = new EventEmitter();
  @Input() hasNext = false;
  @Input() hasPrev = false;

  public isRegularisation: boolean;
  public translations: {
    questionAboutThisPayment: string,
    thenContactYourFileManager: string,
    region: string,
    accountNumber: string,
    paymentDate: string,
    years: (amount: number) => string,
    viewFile: string,
    viewSepar: string,
    total: string,
    foreignFundsTitle: string,
    foreignFundsOrganisation: string,
    foreignFundsCountry: string,
    foreignFundsReference: string,
    foreignFundsStartDate: string,
    foreignFundsEndDate: string,
  };

  public beneficiaryType = BeneficiaryTypeEnum;
  public iconColorBlue = IconColorEnum.Blue40;
  public iconDirectionLeft = IconDirectionEnum.Left;
  public iconDirectionRight = IconDirectionEnum.Right;
  public detailHeaderColorMode = HeaderColorMode.Light;
  public iconUp = IconDirectionEnum.Up;
  public iconDown = IconDirectionEnum.Down;

  private open: { [key: string]: boolean } = {};

  constructor(
    private translate: TranslateService,
    private childBenefitsStore: ChildBenefitsStateStore,
    private separStore: SeparStateStore,
    private paymentsService: PaymentsService) { }

  ngOnInit(): void {
    this.isRegularisation = this.paymentsService.isRegularisationPayment(this.payment);

    this.translations = {
      questionAboutThisPayment: this.translate.instant('Infino.Portal.Payment.QuestionAboutThisPayment'),
      thenContactYourFileManager: this.translate.instant('Infino.Portal.Payment.ThenContactYourFileManager'),
      region: this.translate.instant('Infino.Portal.Payment.Region'),
      accountNumber: this.translate.instant('Infino.Portal.Payment.AccountNumber'),
      paymentDate: this.translate.instant('Infino.Portal.Payment.PaymentDate'),
      years: pluralizedTranslation(this.translate, 'Infino.Portal.Years'),
      viewFile: this.translate.instant('Infino.Portal.Payment.ViewFile'),
      viewSepar: this.translate.instant('Infino.Portal.Payment.ViewSepar'),
      total: this.translate.instant('Infino.Portal.Payment.Total'),
      foreignFundsTitle: this.translate.instant('Infino.Portal.Payment.ForeignFunds.Title'),
      foreignFundsOrganisation: this.translate.instant('Infino.Portal.Payment.ForeignFunds.Organisation'),
      foreignFundsCountry: this.translate.instant('Infino.Portal.Payment.ForeignFunds.Country'),
      foreignFundsReference: this.translate.instant('Infino.Portal.Payment.ForeignFunds.Reference'),
      foreignFundsStartDate: this.translate.instant('Infino.Portal.Payment.ForeignFunds.StartDate'),
      foreignFundsEndDate: this.translate.instant('Infino.Portal.Payment.ForeignFunds.EndDate'),
    };
  }

  isOpen(item: any): boolean {
    return !!this.open[this.key(item)];
  }

  isSepar(): boolean {
    return this.payment?.paymentType === 'SEPAR_ALLOWANCE';
  }

  toggle(item: any) {
    this.open[this.key(item)] = !this.open[this.key(item)];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['payment'].currentValue !== changes['payment'].previousValue) {
      this.isRegularisation = this.paymentsService.isRegularisationPayment(this.payment);
      this.open = {};
      datalayerPush({ event: 'view_detail', type: 'payment' });
    }
  }

  key(item: any): string {
    return JSON.stringify(item);
  }

  withAmount<T extends { amount: number }>(items: T[]): T[] {
    return items.filter(item => item.amount !== 0);
  }

  paymentTranslation(type: string): string {
    return this.translate.instant('Infino.Portal.Payment.Type.' + type);
  }

  hasPaymentWarningMessage(type: string): boolean {
    const translatedDocumentType: string = this.translate.instant(`Infino.Portal.Payment.WarningMessage.${type}`);
    return translatedDocumentType !== `Infino.Portal.Payment.WarningMessage.${type}`;
  }

  allowanceTranslation(type: string): string {
    return this.translate.instant('Infino.Portal.Allowance.Type.' + type);
  }

  age(date: string | Date): number {
    return dayjs().diff(dayjs(date), 'year');
  }

  getDate(year: number, month: number): Date {
    return new Date(year, month - 1);
  }

  setActiveFile(fileId: string) {
    this.childBenefitsStore.select(fileId);
  }

  setActiveYear(fileId: string, paymentDate: string) {
    const paymentDateParts = paymentDate.split('-');
    const paymentDateRounded: Date = new Date(parseInt(paymentDateParts[0], 10), parseInt(paymentDateParts[1], 10) - 1, 1);  // -1 because months are from 0 to 11
    const startOfSchoolYear: Date = new Date(parseInt(paymentDateParts[0], 10), 7 - 1, 1);  // schoolYear period July -> July

    let schoolYear: string;
    if (paymentDateRounded < startOfSchoolYear) {
      schoolYear = (parseInt(paymentDateParts[0], 10) - 1) + '-' + parseInt(paymentDateParts[0], 10);
    }
    else {
      schoolYear = parseInt(paymentDateParts[0], 10) + '-' + (parseInt(paymentDateParts[0], 10) + 1);
    }

    this.separStore.select(fileId, schoolYear);
  }

  sortByAge(a: ChildBenefitsPaymentDetail, b: ChildBenefitsPaymentDetail): number {
    return new Date(a.childBirthDate || 0).getTime() - new Date(b.childBirthDate || 0).getTime();
  }

  getPaymentMethod(payment = this.payment): string {
    if (payment.beneficiaryType !== this.beneficiaryType.Unknown) {
      return this.translate.instant('Infino.Portal.Payment.BeneficiaryType.' + payment.beneficiaryType) + ': ' + payment.accountNumber;
    }
    return payment.accountNumber;
  }

  getPaymentAmount(): number {
    return this.paymentsService.getPaymentTotalAmount(this.payment);
  }
}
