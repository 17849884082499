import { Injectable } from '@angular/core';
import { Store } from '../../../shared/infrastructure/state/store';
import { PortalNotification } from './../models/portal-notification.model';

@Injectable()
export class PortalNotificationsStore extends Store<PortalNotification[]> {
    protected getInitialState(): PortalNotification[] {
        return [];
    }

    protected getStoreName(): string {
        return 'notifications-store';
    }
}
