import { IValidator } from '../validator.interface';
import { ValidationError } from '../validation-error.enum';

/**
 * Validates if the input is not empty and checks if the string is a valid national insurance number (=rijksregisternummer)
 */
export class NationalInsuranceNumberValidator implements IValidator {

    private numberValid: RegExp = /^([0-9]{2})\.(0[1-9]|1[0-2])\.(0[1-9]|[12]\d|3[01])\-([0-9]{3})\.([0-9]{2})$/;

    public validate(value: string): string {
        if (value === undefined || value === null || value.length === 0 || typeof value === 'object') {
            return undefined;
        }

        const matches = value.match(this.numberValid);

        if (matches && matches.length) {
            const currentYear = new Date().getFullYear() - 2000;
            const year = matches[1];
            const month = matches[2];
            const day = matches[3];
            const serial = matches[4];
            const controllNumberA = +matches[5];

            let identificationNumber = `${year}${month}${day}${serial}`;
            if (+year <= currentYear) {
                // For persons born after 2000 the number 2 has to be added in front
                identificationNumber = `2${identificationNumber}`;
            }

            const controllNumberB = 97 - (+identificationNumber % 97);
            if (controllNumberA === controllNumberB) {
                return undefined;
            }
        }

        return ValidationError.NationalInsuranceNumber;
    }
}
