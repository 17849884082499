import { Injectable } from '@angular/core';
import { FlowConfiguration } from '../models/flow-configuration.model';
import { config } from 'rxjs';


@Injectable()
export class FlowConfigurationMapper {

    public map(data: any): FlowConfiguration {
        return new FlowConfiguration({
            code: data.Code,
            redirectURL: data.RedirectUrl,
            pageTitle: data.PageTitle,
            identityCardImageUrl: data.IdentityCardImageUrl,
            websiteUrl: this.getWebsiteUrl(data.WebsiteUrl),
            privacyPolicyUrl: data.PrivacyPolicyUrl,
            brusselsLegalUrl: data.BrusselsLegalUrl
        });
    }

    private getWebsiteUrl(siteUrl: string): string {
        const referrer = document.referrer;
        if (referrer.includes(siteUrl)) {
            return referrer;
        }
        return siteUrl;
    }
}
