import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { startWith, map, filter } from 'rxjs/operators';
import { ChildBenefitsStateStore } from 'src/app/core/files/data/child-benefits-state.store';
import { RegionEnum } from 'src/app/core/payments/models/region-enum';
import { PortalBurgerProfielService } from './portal-burgerprofiel.service';
import { Injectable } from '@angular/core';
import { ChildBenefitsFile } from 'src/app/core/files/models/child-benefits-file.model';
import { combineLatest } from 'rxjs';
import { PortalAuthenticationService } from './portal-authentication.service';
import { PortalConfigurationStore } from 'src/app/core/configuration/data/portal-configuration.store';
import { UserStore } from 'src/app/core/user/data/user.store';
import { TokenIssuer } from '../../services/token-storage/token-storage.service';

@Injectable({
    providedIn: 'root',
})
export class PortalBurgerProfielVisibilityService {
    constructor(
        private store: ChildBenefitsStateStore,
        private burgerprofiel: PortalBurgerProfielService,
        private auth: PortalAuthenticationService,
        private portalConfigurationStore: PortalConfigurationStore,
        private userStore: UserStore,
        private router: Router
    ) {}

    public initialize() {
        combineLatest([
            this.store.state$.pipe(
                map((v) => v.files),
                startWith(undefined)
            ),
            this.auth.authState.pipe(startWith(undefined)),
            this.portalConfigurationStore.state$.pipe(startWith(undefined)),
            this.userStore.state$.pipe(startWith(undefined)),
            this.router.events.pipe(
                filter((evt: RouterEvent) => evt instanceof NavigationEnd),
                map((evt) => evt.url),
                startWith(this.router.routerState.snapshot.url)
            ),
        ]).subscribe((state) => {
            // disable until burgeprofiel prod url has been delivered
            // if (environment.production) {
            //     return false;
            // }

            if (state[4].indexOf('/backoffice') > -1) {
                return false;
            }

            const show = this.requireVisibily({
                acm: this.auth.tokenIssuer() === TokenIssuer.ACM,
                csam: this.auth.tokenIssuer() === TokenIssuer.CSAM,
                files: state[0],
                authenticated: this.auth.authenticated(),
                region: state[2]?.region || state[3]?.region,
            });

            if (show) {
                this.burgerprofiel.show();
            } else {
                this.burgerprofiel.hide();
            }
        });
    }

    private requireVisibily(state: {
        authenticated?: boolean;
        csam?: boolean;
        acm?: boolean;
        region?: RegionEnum;
        files?: ChildBenefitsFile[];
    }): boolean {
        if (!state.authenticated) {
            if (state.region === RegionEnum.Flanders) {
                return true;
            }
        }

        if (state.authenticated) {
            if (state.acm || state.csam) {
                if (
                    state.files?.some(
                        (file) => file.region === RegionEnum.Flanders
                    )
                ) {
                    return true;
                }
            }
        }

        return false;
    }
}
