import { FileViewModel } from './portal-files-file.viewmodel';

export class PortalHelpViewModel {
    public isLoaded: boolean;
    public files: FileViewModel[];
    public selectedFile: FileViewModel;
    public translations: {
        needHelp: string,
        whatYouCanDo: string,
        yourFileManager: string,
        yourOffice: string,
        generateCertificate: string,
        checkoutFAQ: string,
        askAQuestionAboutFile: string,
        directions: string,
        sendMail: string,
        sendAMessageTitle: string,
        sendAMessageDescription: string,
        message: string,
        messagePlaceholder: string,
        messageType: string,
        messageTypePlaceholder: string,
        attachment: string,
        attachmentPlaceholderAddFile: string,
        attachmentPlaceholderOrDragFile: string,
        allowedFormats: string,
        send: string,
        sendDescription: string,
        thankYouMessage: string,
        sendAnotherMessage: string,
        email: string,
        emailPlaceholder: string,
    };
    public links: {
        certificate: string | string[],
        faq: string,
    };
    public fileManager: {
        name: string,
        phone: string,
        email: string,
        officeAddress: string,
        officePostalCode: string,
        officeCity: string,
    };

    public email: string | undefined;
    public contactFormTypes: { label: string, value: string, key: string }[];

    constructor(init?: Partial<PortalHelpViewModel>) {
        Object.assign(this, init);

        if (this.files && this.files.length > 0) {
            this.selectedFile = this.files[0];
        }
    }

    public selectFileId(fileId: string): void {
        const file = this.files.find(f => f.fileId === fileId);
        this.selectFile(file);
    }

    public selectFile(file: FileViewModel): void {
        this.selectedFile = file;
    }
}
