import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { flatMap, tap, map } from 'rxjs/operators';
import { registerLocaleData } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

import { UrlHelper } from '../../shared/helpers/url-helper';
import { KenticoResourceStringLoader } from '../../shared/loaders/kenticoResourceString.loader';
import { FlowConfigurationStore } from '../configuration/data/flow-configuration.store';
import { FlowConfiguration } from '../configuration/models/flow-configuration.model';
import { FlowConfigurationService } from '../configuration/services/flow-configuration.service';
import { UserService } from '../user/services/user.service';
import { datalayerPush } from 'src/app/shared/helpers/datalayer.helper';

import localeNlBe from '@angular/common/locales/nl-BE';
import localeFrBe from '@angular/common/locales/fr-BE';
import localeEnGb from '@angular/common/locales/en-GB';

registerLocaleData(localeNlBe, 'nl-BE');
registerLocaleData(localeFrBe, 'fr-BE');
registerLocaleData(localeEnGb, 'en-GB');

@Injectable()
export class BootService {

    private languages = ['nl-BE', 'fr-BE', 'en-GB'];

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private translateService: TranslateService,
        private userService: UserService,
        private flowConfigurationService: FlowConfigurationService,
        private flowConfigurationStore: FlowConfigurationStore,
        private titleService: Title,
        private translateLoader: KenticoResourceStringLoader,
    ) { }

    public initialize(): Observable<void> {
        const region = this.GetRegionInEnglish();
        const partner = UrlHelper.getUrlParameter('partner') ?? this.userService.getPartner();
        datalayerPush({
            event: 'regionSelection',
            region: region
        });

        this.translateService.setDefaultLang('nl-BE');
        this.document.documentElement.lang = this.translateService.currentLang?.substring(0, 2);
        this.userService.initializeUser(region, partner);
        this.flowConfigurationService.initialize();

        this.flowConfigurationStore.state$.subscribe((value: FlowConfiguration) => {
            this.titleService.setTitle(this.flowConfigurationStore.getState().pageTitle);
        });

        this.translateService.onLangChange.subscribe(() => {
            this.document.documentElement.lang = this.translateService.currentLang?.substring(0, 2);
        });
        this.fetchOtherTranslations();

        return this.fetchInitialTranslations();
    }

    private GetRegionInEnglish() {
        const region = UrlHelper.getUrlParameter('region') ?? this.userService.getRegion();
        const regionLowerCase = region.toLocaleLowerCase();

        if (regionLowerCase === "brussels" || regionLowerCase === "flanders" || regionLowerCase === "wallonia") {
            return region;
        }

        switch (regionLowerCase) {
            case "wallonië":
            case "wallonie":
                return "Wallonia";
            case "brussel":
                return "Brussels";
            case "vlaanderen":
            default:
                return "Flanders";
        }
    }

    private fetchInitialTranslations(): Observable<void> {
        const translations = this.translateLoader.loadTranslation(this.translateService.currentLang, environment.resourceStringKey);
        const portalTranslations = this.translateLoader.loadTranslation(this.translateService.currentLang, environment.portalResourceStringKey);

        return translations.pipe(
            map(t1 => portalTranslations.pipe(map(t2 => ({ ...(t1 || {}), ...(t2 || {}) })))),
            flatMap(t => t),
            tap(response => {
                this.translateService.setTranslation(this.translateService.currentLang, response);
                this.translateService.use(this.translateService.currentLang);
            }),
        );
    }

    private fetchOtherTranslations() {
        const languages = this.languages.filter(lang => lang !== this.translateService.currentLang);

        for (const lang of languages) {
            const translations = this.translateLoader.loadTranslation(lang, environment.resourceStringKey);
            const portalTranslations = this.translateLoader.loadTranslation(lang, environment.portalResourceStringKey);

            const subscription = translations.pipe(
                map(t1 => portalTranslations.pipe(map(t2 => ({ ...(t1 || {}), ...(t2 || {}) })))),
                flatMap(t => t),
            ).subscribe(response => {
                this.translateService.setTranslation(lang, response);
                subscription.unsubscribe();
            });
        }
    }
}
