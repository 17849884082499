import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalDocumentsComponent } from './pages/portal-documents.component';
import { LocalizedDateModule } from './../../../shared/pipes/localized-date/localized-date.module';
import { SmoothHeightModule } from './../../../shared/components/smooth-height/smooth-height.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { InputsModule } from '../../../shared/components/inputs/inputs.module';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';
import { IconsModule } from '../../..//shared/components/icons/icons.module';
import { DetailPaneModule } from '../components/detail-pane/detail-pane.module';
import { SidepanelModule } from '../../../shared/components/sidepanel/sidepanel.module';
import { UserModule } from '../user/user.module';
import { PortalDocumentsMapper } from './mappers/portal-documents.mapper';
import { DocumentService } from './services/document.service';
import { PortalDocumentsListComponent } from './components/portal-documents-list/portal-documents-list.component';
import { PortalDocumentComponent } from './components/portal-document/portal-document.component';
import { PortalDocumentDetailComponent } from './components/portal-document-detail/portal-document-detail.component';

@NgModule({
  declarations: [PortalDocumentsComponent, PortalDocumentsListComponent, PortalDocumentComponent, PortalDocumentDetailComponent],
  imports: [
    CommonModule,
    LocalizeRouterModule,
    TranslateModule,
    IconsModule,
    DetailPaneModule,
    SidepanelModule,
    InputsModule,
    FormsModule,
    RouterModule,
    SmoothHeightModule,
    LocalizedDateModule,
    UserModule,
  ],
  providers: [
    PortalDocumentsMapper,
    DocumentService
  ]
})
export class PortalDocumentsModule { }
