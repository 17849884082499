import { Component, Input, OnInit } from '@angular/core';
import { IconColorEnum } from '../enums/icon-color.enum';

@Component({
  selector: 'app-icon-warning-round',
  templateUrl: './warning-round.component.html',
})
export class IconWarningRoundComponent {
  @Input() color: IconColorEnum = IconColorEnum.Yellow100;

  public get classes(): string {
    return `${this.color}`;
  }
}
