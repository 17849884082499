import { DatepickerInputComponent } from './datepicker-input/datepicker-input.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { TextareaInputComponent } from './textarea/textarea-input.component';
import { IconsModule } from 'src/app/shared/components/icons/icons.module';
import { AttachmentComponent } from './attachment/attachment.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DateInputComponent } from './date-input/date-input.component';
import { DrawingCanvasComponent } from './drawing-canvas/drawing-canvas.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { NationalIdInputComponent } from './national-id-input/national-id-input.component';
import { PhoneNumberInputComponent } from './phone-number-input/phone-number-input.component';
import { PostalCodeInputComponent } from './postal-code-input/postal-code-input.component';
import { IbanInputComponent } from './iban-input/iban-input.component';
import { EmailInputComponent } from './email-input/email-input.component';
import { ConfigurableTextInputComponent } from './configurable-text-input/configurable-text-input.component';
import { ConfigurableTextInputDirective } from './configurable-text-input/configurable-text-input.directive';
import { TextInputComponent } from './text-input/text-input.component';
import { RadioInputComponent } from './radio-input/radio-input.component';
import { PrivacyPolicyCheckboxComponent } from './privacy-policy-checkbox/privacy-policy-checkbox.component';
import { BrusselsLegalCheckboxComponent } from './brussels-legal-checkbox/brussels-legal-checkbox.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        IconsModule,
        NgbDatepickerModule
    ],
    declarations: [
        TextInputComponent,
        ConfigurableTextInputComponent,
        CheckboxComponent,
        PrivacyPolicyCheckboxComponent,
        BrusselsLegalCheckboxComponent,
        FileUploadComponent,
        DrawingCanvasComponent,
        DateInputComponent,
        PostalCodeInputComponent,
        PhoneNumberInputComponent,
        NationalIdInputComponent,
        EmailInputComponent,
        IbanInputComponent,
        RadioInputComponent,
        PasswordInputComponent,
        DropdownComponent,
        AttachmentComponent,
        TextareaInputComponent,
        NumberInputComponent,
        ConfigurableTextInputDirective,
        DatepickerInputComponent,
    ],
    exports: [
        TextInputComponent,
        ConfigurableTextInputComponent,
        CheckboxComponent,
        PrivacyPolicyCheckboxComponent,
        BrusselsLegalCheckboxComponent,
        FileUploadComponent,
        DrawingCanvasComponent,
        DateInputComponent,
        PostalCodeInputComponent,
        PhoneNumberInputComponent,
        NationalIdInputComponent,
        EmailInputComponent,
        IbanInputComponent,
        RadioInputComponent,
        PasswordInputComponent,
        DropdownComponent,
        AttachmentComponent,
        TextareaInputComponent,
        NumberInputComponent,
        DatepickerInputComponent,
    ]
})
export class InputsModule { }
