import { QuickLinkFileManagerComponent } from './quick-link-filemanager.component';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { RouterModule } from '@angular/router';
import { QuickLinksMapper } from './quick-links.mapper';
import { QuickLinksComponent } from './quick-links.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '../../../../shared/components/icons/icons.module';

@NgModule({
  declarations: [
    QuickLinksComponent,
    QuickLinkFileManagerComponent
  ],
  imports: [
    CommonModule,
    IconsModule,
    RouterModule,
    LocalizeRouterModule
  ],
  providers: [
    QuickLinksMapper
  ],
  exports: [
    QuickLinksComponent
  ]
})
export class QuickLinksModule { }
