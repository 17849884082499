<div *ngIf="isLoaded()">
    <app-detail-pane-header
        [colorMode]="getDetailHeaderColorMode()"
        [iconColorMode]="getIconColorMode()"
        [textColorMode]="getTextColorMode()"
        [title]="child.getFullName()"
        [subtitle]="getSubtitle()"
        [iconPath]="getIconPath()"
        [canShowPrevious]="canShowPrevious"
        [canShowNext]="canShowNext"
        (onPrevious)="previous()"
        (onNext)="next()"
        (onClose)="onClose.emit()"></app-detail-pane-header>
    <div class="m-3 row">
        <div class="col-lg mb-3">
            <div class="line-height-normal text-overline text-muted">{{labels.socialSecurityNumberLabel}}</div>
            <small class="line-height-normal">{{ child.socialSecurityNumber }}</small>
        </div>
        <div class="col-lg mb-3">
            <div class="line-height-normal text-overline text-muted">{{labels.birthDateNumberLabel}}</div>
            <small class="line-height-normal">{{ child.getDateOfBirthParsed() }}</small>
        </div>
    </div>
    <div class="m-3 row">
        <div class="col-lg mb-3" *ngIf="child.points">
            <div class="line-height-normal text-overline text-muted">{{child.medicalLabel}}</div>
            <small class="line-height-normal">{{ 'Infino.Portal.File.ChildPoints.' + child.points | translate }}
            </small>
        </div>
        <div class="col-lg mb-3" *ngIf="child.status">
            <div class="line-height-normal text-overline text-muted">{{ child.capacityLabel }}</div>
            <ng-template #childStatus>
                <small class="line-height-normal">{{ 'Infino.Portal.File.ChildStatus.' + child.status | translate }}</small>
            </ng-template>
            <a [href]="child.statusLink" target="_blank" rel="noreferrer noopener" *ngIf="child.statusLink else childStatus">
                <ng-container *ngTemplateOutlet="childStatus"></ng-container>
            </a>
        </div>
    </div>
    <div class="m-3 row" *ngIf="child.childBasicAllowance?.status === childBasicAllowanceStatusEnum.Suspended">
        <div class="col-lg mb-3">
            <div class="line-height-normal text-overline text-muted">{{labels.childBasicAllowanceSuspendedDateLabel}}</div>
            <small class="line-height-normal">{{ child.getChildBasicAllowanceStartDateParsed() }}</small>
        </div>
        <div class="col-lg mb-3" *ngIf="child.childBasicAllowance.reason !== null">
            <div class="line-height-normal text-overline text-muted">{{labels.childBasicAllowanceSuspendedReasonLabel}}</div>
            <small class="line-height-normal">{{ ( child.childBasicAllowance.reason !== null ? 'Infino.Portal.File.ChildBasicAllowanceReason.' + child.childBasicAllowance.reason : "-" ) | translate }}</small>
        </div>
    </div>
    <div class="m-3 row" *ngIf="child.childBasicAllowance?.status === childBasicAllowanceStatusEnum.Closed">
        <div class="col-lg mb-3">
            <div class="line-height-normal text-overline text-muted">{{labels.childBasicAllowanceClosedDateLabel}}</div>
            <small class="line-height-normal">{{ child.getChildBasicAllowanceStartDateParsed() }}</small>
        </div>
        <div class="col-lg mb-3" *ngIf="child.childBasicAllowance.reason !== null">
            <div class="line-height-normal text-overline text-muted">{{labels.childBasicAllowanceClosedReasonLabel}}</div>
            <small class="line-height-normal">{{ ( child.childBasicAllowance.reason !== null ? 'Infino.Portal.File.ChildBasicAllowanceReason.' + child.childBasicAllowance.reason : "-" ) | translate }}</small>
        </div>
    </div>
    <div class="border rounded ml-3 mr-2 my-2 text-secondary border-blue" *ngIf="child.allowances">
        <!-- Header -->
        <div class="bg-blue-10 px-4 py-3 rounded-top d-flex justify-content-between align-items-center cursor-pointer">
            <div class="line-height-normal">
                {{ child.childBenefitsTypeLabel }}
            </div>
        </div>

        <div class="row px-4 text-dark align-items-center mt-2 mb-2" *ngFor="let allowance of child.allowances">
            <div class="col-lg d-flex flex-column">
                <div class="line-height-normal mt-1 text-capitalize">
                    {{ labels.allowanceType(allowance.name) }}
                </div>
            </div>
        </div>

    </div>

</div>
