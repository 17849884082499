import { PortalUserStore } from 'src/app/core/portal-user/data/portal-user.store';
import { FileViewModel } from './../viewmodels/portal-files-file.viewmodel';
import { ChildBenefitsStateStore } from './../../../../core/files/data/child-benefits-state.store';
import { TranslateService } from '@ngx-translate/core';
import { take, map, flatMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Validators } from './../../../../shared/infrastructure/validation/validators';
import { IValidationField } from './../../../../shared/infrastructure/validation/validation-field.interface';
import { ValidationHelper } from './../../../../shared/infrastructure/validation/validation.helper';
import { IconColorEnum } from 'src/app/shared/components/icons/enums/icon-color.enum';
import { IconDirectionEnum } from './../../../../shared/components/icons/enums/icon-direction.enum';
import { PortalHelpViewModel } from './../viewmodels/portal-help.viewmodel';
import { Component, OnInit } from '@angular/core';
import { PortalHelpMapper } from '../mappers/portal-help.mapper';
import { Observable, combineLatest } from 'rxjs';
import { datalayerPush } from 'src/app/shared/helpers/datalayer.helper';

@Component({
  selector: 'app-portal-help',
  templateUrl: './portal-help.component.html',
})
export class PortalHelpComponent implements OnInit {
  public model$: Observable<PortalHelpViewModel>;
  chevronDirection = IconDirectionEnum.Right;
  chevronColor = IconColorEnum.EcruDark;

  public messageSent = false;
  public validationErrors = {};
  public formModel: {
    type?: string,
    message?: string,
    files: File[],
    email?: string,
    submitted: boolean,
  };

  public allowedExtensions = [
    'image/jpg', 'image/jpeg', 'image/png', 'application/pdf', '.doc', '.docx', '.xls', '.xlsx', '.odt', '.ods', '.pdf',
    'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel', 'text/plain', 'application/vnd.oasis.opendocument.text'
  ];

  constructor(
    private http: HttpClient,
    private store: ChildBenefitsStateStore,
    private userStore: PortalUserStore,
    private translateService: TranslateService,
    private mapper: PortalHelpMapper
  ) { }

  ngOnInit(): void {
    this.model$ = combineLatest([this.store.state$, this.userStore.state$]).pipe(
      map(([state, info]) => this.mapper.map(state, info)),
      tap(() => this.messageSent = false)
    );
    this.resetForm();
  }

  public onSubmit() {
    if (this.formModel.submitted) {
      return;
    }

    this.formModel.submitted = true;

    this.model$
      .pipe(take(1), flatMap((model) => {
        if (model.email && !this.formModel.email) {
          this.formModel.email = model.email;
        }

        const validationResult = ValidationHelper.validate(this.getValidators());
        this.messageSent = false;
        if (!validationResult.isValid) {
          this.validationErrors = validationResult.getErrorObject();
          throw new Error('validationerrors');
        }

        try {
          const topic = this.mapper.mapEmpty().contactFormTypes.find(type => type.value === this.formModel.type)?.key;
          datalayerPush({ event: 'send_message', topic });
        } catch { }

        const formData = new FormData();
        formData.append('subjectText', this.formModel.type);
        formData.append('question', this.formModel.message);
        if (this.formModel.email || model.email) {
          formData.append('email', this.formModel.email || model.email);
        }
        formData.append('fileNumber', model.selectedFile.fileId);
        formData.append('region', model.selectedFile.region.toString());

        this.formModel.files.forEach(file => formData.append('file', file, file.name));
        const headers = new HttpHeaders().set('language', this.translateService.currentLang);

        return this.http.post(environment.apiUrl + '/Mail/SendPortalContactMail', formData, { headers });
      })).subscribe(() => {
        this.resetForm();
        this.messageSent = true;
      }, (error) => {
        this.formModel.submitted = false;
      },
        () => this.formModel.submitted = false);
  }

  public validateItem = (fieldName: string) => {
    const validationResult = ValidationHelper.validateField(fieldName, this.getValidators());
    this.validationErrors = validationResult.extendErrorObject(this.validationErrors);
  }

  public resetItemValidation = (fieldName: string) => {
    this.validationErrors = ValidationHelper.removeFromErrorObject(fieldName, this.validationErrors);
  }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  public getValidators(): IValidationField[] {
    return [
      {
        name: 'type',
        value: this.formModel.type,
        validators: [
          Validators.required(),
        ]
      },
      {
        name: 'message',
        value: this.formModel.message,
        validators: [
          Validators.required(),
        ]
      },
      {
        name: 'email',
        value: this.formModel.email,
        validators: [
          Validators.required(),
          Validators.email()
        ]
      }
    ];
  }

  public resetForm() {
    this.formModel = { files: [], type: '', message: '', submitted: false };
    this.messageSent = false;
  }

  public routeLink(file: FileViewModel) {
    const { street, city, zipcode, country } = file.fileManager.office;
    const destination = [street, zipcode, city, country].filter(v => !!v).join(', ');
    return `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(destination)}`;
  }
}
