import { IValidator } from '../validator.interface';
import { ValidationError } from '../validation-error.enum';

/**
 * Validates if the input is not empty and checks if the string exists of exactly 4 digits
 */
export class PostalcodeValidator implements IValidator {

    private postalcodeValid: RegExp = /^[1-9]{1}[0-9]{3}$/;

    public validate(value: string): string {
        if (value === undefined || value === null || value.length === 0 || typeof value === 'object') {
            return undefined;
        }

        const matches = value.match(this.postalcodeValid);

        if (matches && matches.length) {
            return undefined;
        }

        return ValidationError.PostalCode;
    }
}
