import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IconColorEnum } from 'src/app/shared/components/icons/enums/icon-color.enum';
import { IconDirectionEnum } from 'src/app/shared/components/icons/enums/icon-direction.enum';

@Component({
  selector: 'app-detail-pane-header',
  templateUrl: './detail-pane-header.component.html',
})
export class DetailPaineHeaderComponent implements OnInit {

  @Input() public title: string;
  @Input() public subtitle: string;
  @Input() public colorMode: HeaderColorMode;
  @Input() public iconColorMode: IconColorEnum = IconColorEnum.Blue40;
  @Input() public textColorMode = 'text-white';
  @Input() public iconPath: string;

  @Input() public canShowPrevious = true;
  @Input() public canShowNext = true;

  @Output() public onNext = new EventEmitter();
  @Output() public onPrevious = new EventEmitter();
  @Output() public onClose = new EventEmitter();

  public iconDirectionLeft = IconDirectionEnum.Left;
  public iconDirectionRight = IconDirectionEnum.Right;

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

  public next(): void {
    this.onNext.emit();
  }

  public previous(): void {
    this.onPrevious.emit();
  }

  public close(): void {
    this.onClose.emit();
  }

  public getIconColorEnumForClose(): string {
    const position = this.iconColorMode?.toString().indexOf('-');
    return 'fill' + this.iconColorMode?.toString().substring(position);
  }

  public headerClasses(): string[] {
    return [this.colorMode, this.textColorMode];
  }
}

export enum HeaderColorMode {
  Dark = 'bg-blue-dark',
  Light = 'bg-blue-light',
  Yellow = 'bg-yellow',
  Grey = 'bg-grey'
}
