<div class="form-group">
    <label [for]="id" [ngClass]="labelClass">
        {{label}}
        <span *ngIf="!required" class="text-danger">{{'Infino.ClientPortal.Validation.optional' | translate}}</span>
    </label>

    <label for="file-input"
        class="d-flex justify-content-center align-items-center form-component-filedrop p-4 cursor-pointer bg-white border-brown-25-alhpa"
        (dragover)="$event.preventDefault()" (dragenter)="$event.preventDefault()" (drop)="onDrop($event)">
        <ng-content></ng-content>
    </label>
    <small class="form-text">{{subText}}</small>

    <input #input multiple="" type="file" autocomplete="off" tabindex="-1" name="file-input" id="file-input"
        style="display: none;" [disabled]="disabled" [required]="required"
        (change)="handleFileInput($event.target.files)" (blur)="startValidation()"
        [ngClass]="[validationErrors ? 'is-invalid': '' ]" />

    <div *ngIf="value && value.length" class="border rounded my-3">
        <div *ngFor="let file of value" class="d-flex">
            {{ file.name }}
            ({{ file.size }})
            <app-icon-close class="cursor-pointer ml-auto" (click)="removeFile(file)"></app-icon-close>
        </div>
    </div>
</div>

<div class="invalid-feedback">
    <span *ngFor="let error of validationErrors">
        {{'Infino.ClientPortal.Validation.' + error | translate}}
    </span>
</div>