import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageToggleComponent } from './language-toggle.component';
import { IconsModule } from '../../../../shared/components/icons/icons.module';
import { LanguagesMapper } from './languages.mapper';

@NgModule({
  declarations: [
    LanguageToggleComponent,
  ],
  imports: [
    CommonModule,
    IconsModule
  ],
  exports: [
    LanguageToggleComponent
  ],
  providers: [
    LanguagesMapper
  ]
})
export class LanguageToggleModule { }
