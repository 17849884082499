export class SeparChild {
    public dateOfBirth: string;
    public firstname: string;
    public lastname: string;
    public meetingAgeRequirements: boolean;
    public meetingNationalityRequirements: boolean;
    public meetingPedagogicRequirements: boolean;
    public socialSecurityNumber: string;

    constructor(init?: Partial<SeparChild>) {
        Object.assign(this, init);
    }
}
