import { Component, Input, OnInit } from '@angular/core';
import { IconColorEnum } from '../enums/icon-color.enum';

@Component({
  selector: 'app-icon-download',
  templateUrl: './download.component.html',
})
export class IconDownloadComponent implements OnInit {

  @Input() color: IconColorEnum = IconColorEnum.Blue40;

  constructor() { }

  ngOnInit(): void {
  }

  public get classes(): string {
    return `${this.color}`;
  }

}
