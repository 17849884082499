export class PortalBackofficeAuthenticationModel {
    public authenticateWithLoginEmailLabel: string;
    public authenticateWithLoginEmailPlaceholder: string;
    public authenticateWithLoginPasswordLabel: string;
    public authenticateWithLoginPasswordPlaceholder: string;
    public authenticateWithLoginButton: string;

    public backofficeAuthenticationTitle: string;
    public backofficeAuthenticationSocialSecurityNumberLabel: string;
    public backofficeAuthenticationSocialSecurityNumberPlaceholder: string;
    public backofficeAuthenticationAccountLabel: string;
    public backofficeAuthenticationAccountPlaceholder: string;
    public backofficeAuthenticationPasswordLabel: string;
    public backofficeAuthenticationPasswordPlaceholder: string;

    public forgotPassword: string;
    public forgotPasswordUrl: string;

    constructor(partial?: Partial<PortalBackofficeAuthenticationModel>) {
        Object.assign(this, partial);
    }
}
