import { Component, Input } from '@angular/core';
import { IconColorEnum } from '../enums/icon-color.enum';

@Component({
  selector: 'app-icon-document-bold',
  templateUrl: './document-bold.component.html',
})
export class IconDocumentBoldComponent {
  @Input() color: IconColorEnum = IconColorEnum.Brown40;

  public get classes(): string {
    return `${this.color}`;
  }
}
