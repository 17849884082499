import { Injectable } from '@angular/core';
import { Store } from '../../../shared/infrastructure/state/store';
import { AuthenticationConfiguration } from '../models/authentication-configuration.model';

@Injectable()
export class AuthenticationConfigurationStore extends Store<AuthenticationConfiguration> {
    protected getInitialState(): AuthenticationConfiguration {
        return new AuthenticationConfiguration();
    }

    protected getStoreName(): string {
        return 'authentication-configuration-store';
    }
}
