import { RegionEnum } from './../../../../core/payments/models/region-enum';
import { BeneficiaryViewModel } from './portal-files-beneficiary.viewmodel';
import { ChildViewModel } from './portal-files-child.viewmodel';


export class FileViewModel {
    public region: RegionEnum;
    public fileId: string;

    public city: string;
    public country: string;
    public street: string;
    public zipcode: string;

    public primaryBeneficiary: BeneficiaryViewModel;
    public secondaryBeneficiary: BeneficiaryViewModel;

    public children: ChildViewModel[];

    public fileManager: {
        firstname: string;
        lastname: string;
        email: string,
        phone: string,
        office: {
            info: string,
            street: string,
            city: string,
            zipcode: string,
            country: string,
        }
    };

    public mediator: {
        firstName: string;
        lastName: string;
        account: string;
        street: string;
        city: string;
        zipCode: string;
        country: string;
    };

    public administrator: {
        firstName: string;
        lastName: string;
        account: string;
        street: string;
        city: string;
        zipCode: string;
        country: string;
    };

    constructor(init?: Partial<FileViewModel>) {
        Object.assign(this, init);
    }

    public hasSecondaryBeneficiary(): boolean {
        return this.secondaryBeneficiary != null;
    }

    public hasMediator(): boolean {
        return this.mediator != null;
    }

    public hasAdministrator(): boolean {
        return this.administrator != null;
    }
}
