import { Component, HostBinding, TemplateRef, ViewChild, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoggingService } from './core/logging/services/logging.service';
import { BootService } from './core/boot/boot.service';
import { FlowConfigurationStore } from './core/configuration/data/flow-configuration.store';
import { FlowConfiguration } from './core/configuration/models/flow-configuration.model';
import { timeout, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    @HostBinding('class') classes = 'd-flex flex-column full-height';

    public helpString = 'Infino.ClientPortal.Navigation.Help';
    public websiteUrl = '';
    public done = false;

    constructor(
        private loggingService: LoggingService,
        private translateService: TranslateService,
        private flowConfigurationStore: FlowConfigurationStore,
        private bootService: BootService,
    ) { }

    public ngOnInit(): void {
        this.bootService.initialize().subscribe(() => this.done = true);
        this.helpString = this.translateService.instant(this.helpString);
    }
}
