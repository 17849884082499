import { Validators } from '../../../../../shared/infrastructure/validation/validators';
import { IValidationField } from '../../../../../shared/infrastructure/validation/validation-field.interface';

export class PortalForeignAuthenticationModel {
    public authenticateWithLoginTitle: string;
    public authenticateWithLoginEmailLabel: string;
    public authenticateWithLoginEmailPlaceholder: string;
    public authenticateWithLoginPasswordLabel: string;
    public authenticateWithLoginPasswordPlaceholder: string;
    public authenticateWithLoginForgotPasswordButton: string;
    public authenticateWithLoginNewAccountPageUrl: string;
    public authenticateWithLoginButton: string;
    public authenticateWithLoginOpenCSAMButton: string;

    constructor(partial?: Partial<PortalForeignAuthenticationModel>) {
        Object.assign(this, partial);
    }
}
