import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizeParser, LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { IconColorEnum } from '../../../../shared/components/icons/enums/icon-color.enum';
import { IconDirectionEnum } from '../../../../shared/components/icons/enums/icon-direction.enum';
import { LanguageModel } from './language.model';
import { LanguagesMapper } from './languages.mapper';


@Component({
  selector: 'app-language-toggle',
  templateUrl: './language-toggle.component.html',
  /* tslint:disable-next-line */
  host: {
    '(document:click)': 'close($event)',
  }
})
export class LanguageToggleComponent implements OnInit {

  public iconColorBlue = IconColorEnum.Blue100;
  public iconDirectionDown = IconDirectionEnum.Down;
  public iconDirectionUp = IconDirectionEnum.Up;

  public isOpen = false;

  public languages: LanguageModel[] = [];
  public selectedLanguage: LanguageModel;

  constructor(
    public translateService: TranslateService,
    public mapper: LanguagesMapper,
    public activeRoute: ActivatedRoute,
    public router: Router,
    private localize: LocalizeRouterService,
    public parser: LocalizeParser,
  ) {

  }

  ngOnInit(): void {
    this.initiateModels(this.translateService.currentLang);

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.initiateModels(event.lang);
    });
  }

  private initiateModels(currentLanguageCode: string) {
    this.languages = this.mapper.map();

    this.selectedLanguage = this.languages.filter(lang => lang.languageCode === currentLanguageCode)[0];
    this.languages = this.languages.filter(lang => lang.languageCode !== currentLanguageCode);
  }

  public toggleOpen(event: any) {
    this.isOpen = !this.isOpen;
    event.stopPropagation();
  }

  public close() {
    this.isOpen = false;
  }

  public selectLanguage(language: LanguageModel) {
    this.localize.changeLanguage(language.languageCode);
  }
}
