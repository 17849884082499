import { PortalConfigurationStore } from './../../../../core/configuration/data/portal-configuration.store';
import { TranslateService } from '@ngx-translate/core';
import { CertificateModel } from './../models/certificate-model';
import { ChildBenefitsCertificate } from './../../../../core/certificate/models/child-benefits-certificate.model';
import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { RegionEnum } from 'src/app/core/payments/models/region-enum';
import { PortalConfiguration } from 'src/app/core/configuration/models/portal-configuration.model';

@Injectable()
export class CertificateModelMapper {
    translateKey = 'Infino.Portal.Certificate.Pdf';

    constructor(private translate: TranslateService, private configStore: PortalConfigurationStore) { }

    map(data: ChildBenefitsCertificate, from: Date, to: Date): CertificateModel {
        const model = new CertificateModel();

        model.region = data.region;
        model.fileId = data.fileId;
        model.translations = this.mapTranslations(data, from, to);
        model.recipient = {
            firstname: data.beneficiaries[0].firstName,
            lastname: data.beneficiaries[0].lastName,
            street: data.beneficiaries[0].street,
            city: data.beneficiaries[0].city,
            postalCode: data.beneficiaries[0].zipCode,
            country: data.beneficiaries[0].country
        };
        model.payments = data.payments
            .sort((a, b) => {
                if (b.month === a.month) {
                    // children from old to young
                    return b.childBirthDate < a.childBirthDate ? 1 : -1;
                }

                // months from new to old
                return b.month > a.month ? 1 : -1;
            })
            .map(payment => ({
                childName: [payment.childFirstName, payment.childLastName].join(' '),
                month: payment.month,
                amount: payment.amount
            }));

        const config = this.configStore.getState();
        this.mapRegionSpecificContent(config, model);

        return model;
    }

    private mapRegionSpecificContent(config: PortalConfiguration, model: CertificateModel): void {
        switch (model.region) {
            case RegionEnum.Flanders:
                model.headerAddress = config.certificateFlandersHeaderAddress;
                model.footer = config.certificateFlandersFooter;
                model.signature = {
                    signer: config.certificateFlandersSigner,
                    signerPosition: config.certificateFlandersSignerPosition,
                    signatureUrl: config.certificateFlandersSignatureUrl,
                    signatureSize: config.certificateFlandersSignatureSize,
                };
                break;
            case RegionEnum.Wallonia:
                model.headerAddress = config.certificateWalloniaHeaderAddress;
                model.footer = config.certificateWalloniaFooter;
                model.signature = {
                    signer: config.certificateWalloniaSigner,
                    signerPosition: config.certificateWalloniaSignerPosition,
                    signatureUrl: config.certificateWalloniaSignatureUrl,
                    signatureSize: config.certificateWalloniaSignatureSize,
                };
                break;
            case RegionEnum.Brussels:
                model.headerAddress = config.certificateBrusselsHeaderAddress;
                model.footer = config.certificateBrusselsFooter;
                model.signature = {
                    signer: config.certificateBrusselsSigner,
                    signerPosition: config.certificateBrusselsSignerPosition,
                    signatureUrl: config.certificateBrusselsSignatureUrl,
                    signatureSize: config.certificateBrusselsSignatureSize,
                };
                break;
            default:
                model.headerAddress = config.certificateHeaderAddress;
                model.footer = config.certificateFooter;
                model.signature = {
                    signer: config.certificateSigner,
                    signerPosition: config.certificateSignerPosition,
                    signatureUrl: config.certificateSignatureUrl,
                    signatureSize: config.certificateSignatureSize,
                };
        }
    }

    private mapTranslations(data: ChildBenefitsCertificate, from: Date, to: Date): CertificateModel['translations'] {
        return {
            headerCity: this.translate.instant(`${this.translateKey}.HeaderCity${data.region}`),
            filenumber: this.translate.instant(`${this.translateKey}.Filenumber`),
            confirmationOfChildBenefitPayments: this.translate.instant(`${this.translateKey}.ConfirmationOfChildBenefitPayments`),
            infinoPaidChildBenefitsTo: this.translate.instant(`${this.translateKey}.InfinoPaidChildBenefitsTo`, {
                name: `${data.beneficiaries[0].firstName} ${data.beneficiaries[0].lastName}`
            }),
            wePaidFromTill: this.translate.instant(`${this.translateKey}.WePaidFromTill`, {
                from: dayjs(from).format('DD/MM/YYYY'),
                until: dayjs(to).format('DD/MM/YYYY'),
            }),
            nameAndFirstname: this.translate.instant(`${this.translateKey}.NameAndFirstname`),
            month: this.translate.instant(`${this.translateKey}.Month`),
            amount: this.translate.instant(`${this.translateKey}.Amount`),
            kindRegards: this.translate.instant(`${this.translateKey}.KindRegards`),
        };
    }
}
