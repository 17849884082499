import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxComponent } from '../checkbox/checkbox.component';

@Component({
  selector: 'app-brussels-legal-checkbox',
  templateUrl: './brussels-legal-checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BrusselsLegalCheckboxComponent),
      multi: true
    }
  ]
})
export class BrusselsLegalCheckboxComponent extends CheckboxComponent implements ControlValueAccessor {

  @Input() public labelClass = '';
  @Input() public showValidationErrors = true;

  @Input() public linkLabel = '';
  @Input() public linkUrl = '';

  @Output() public updated: EventEmitter<boolean> = new EventEmitter<boolean>();

  public valueChanged($event: boolean) {
    this.value = $event;
    this.propagateChange($event);

    this.updated.emit($event);
  }

}
