import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Store } from '../../../shared/infrastructure/state/store';
import { createUUID } from '../../../shared/helpers/uuid.helper';
import { OnboardingDataModel } from '../models/onboarding-data.model';
import { OnboardingFormModel } from '../models/onboarding-form.model';

@Injectable()
export class OnboardingStore extends Store<OnboardingDataModel> {

    public calculationUpdatePending = new BehaviorSubject<boolean>(false);

    protected getInitialState(): OnboardingDataModel {
        return new OnboardingDataModel(
            new OnboardingFormModel(),
            createUUID(),
        );
    }

    public getOnboardingData(): OnboardingDataModel {
        return this.getState();
    }

    public getOnboardingForm(): OnboardingFormModel {
        return this.getOnboardingData().onboardingForm;
    }

    public setOnboardingForm(form: OnboardingFormModel): void {
        const data = this.getOnboardingData();
        data.onboardingForm = form;
        this.update(data);
    }

    public reset(): void {
        this.update(this.getInitialState());
    }

    protected getStoreName(): string {
        return 'onboarding-state';
    }
}
