export class FlowConfiguration {
    public code: string;
    public redirectURL: string;
    public identityCardImageUrl: string;
    public pageTitle: string;
    public websiteUrl: string;
    public privacyPolicyUrl: string;
    public brusselsLegalUrl: string;

    constructor(init?: Partial<FlowConfiguration>) {
        Object.assign(this, init);
    }
}
