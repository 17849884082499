import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TwoWayBindingInputComponent } from '../two-way-binding-input.component';

@Component({
    selector: 'app-radio-input',
    templateUrl: './radio-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioInputComponent),
            multi: true
        }
    ]
})
export class RadioInputComponent extends TwoWayBindingInputComponent<boolean> implements ControlValueAccessor {

    @Input() public labelClass = '';
    @Input() public name = '';
    @Input() public radioValue = '';


    public valueChanged($event: any) {
        this.value = $event;
        this.propagateChange(this.value);
    }
}
