import { IValidator } from '../validator.interface';
import { ValidationError } from '../validation-error.enum';

/**
 * Validates if the input is not empty and checks if the date format is dd/mm/jjj
 */
export class DateFormatValidator implements IValidator {

    private dateformatValid: RegExp = /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/(19\d{2}|20\d{2})$/;

    public validate(value: string): string {
        if (value === undefined || value === null || value.length === 0 || typeof value === 'object') {
            return undefined;
        }

        const matches = value.match(this.dateformatValid);

        if (matches && matches.length) {
            return undefined;
        }

        return ValidationError.DateFormat;
    }
}
