import { BehaviorSubject, Observable } from 'rxjs';
import { LogHelper } from '../../helpers/log.helper';
import { CloneHelper } from '../../helpers/clone.helper';

export abstract class Store<TModelType> {
    protected state: TModelType;

    /**
     * Gets observable state of the store
     */
    public state$: Observable<TModelType>;

    protected dataSubject: BehaviorSubject<TModelType>;

    constructor() {
        this.state = this.getInitialState();
        this.dataSubject = new BehaviorSubject(this.getState());
        this.state$ = this.dataSubject.asObservable();
    }

    /**
     * Update the store
     * @param model state to be set
     */
    public update(model: TModelType): void {
        this.state = this.clone(model);
        this.dataSubject.next(this.getState());
        LogHelper.log(this.getStoreName(), this.state);
    }

    /**
     * Get the state of the store
     */
    public getState(): TModelType {
        return this.clone(this.state);
    }

    /**
     * Resets the state to its initial value
     */
    public resetState(): void {
        this.update(this.getInitialState());
    }

    /**
     * Set the initial state of the store
     */
    protected abstract getInitialState(): TModelType;

    /**
     * Returns store name for logging purposes, added because classnames are uglyfied in production
     */
    protected abstract getStoreName(): string;

    protected clone(model: TModelType): TModelType {
        return CloneHelper.clone(model);
    }
}
