import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ChildBenefitsPayment } from './../../../../../core/payments/models/child-benefits-payment';
import { IconColorEnum } from 'src/app/shared/components/icons/enums/icon-color.enum';
import { IconDirectionEnum } from 'src/app/shared/components/icons/enums/icon-direction.enum';
import { PaymentMethod } from 'src/app/core/payments/models/payment-method-enum';
import { PaymentsService } from 'src/app/core/payments/services/payments.service';
import { TranslateService } from '@ngx-translate/core';
import { BeneficiaryTypeEnum } from 'src/app/core/payments/models/beneficiary-type-enum';

@Component({
    selector: 'app-portal-payment',
    templateUrl: './portal-payment.component.html',
})
export class PortalPaymentComponent implements OnInit {
    @Input() payment: ChildBenefitsPayment;
    @Input() active: ChildBenefitsPayment;
    @Output() selected: EventEmitter<any> = new EventEmitter();

    public beneficiaryType = BeneficiaryTypeEnum;
    public iconColorBrown = IconColorEnum.Brown40;
    public iconColorBlue = IconColorEnum.Blue100;
    public iconDirectionRight = IconDirectionEnum.Right;
    public hovering: boolean;

    constructor(private translate: TranslateService, private paymentsService: PaymentsService) { }

    ngOnInit(): void {
    }

    isActive(payment: ChildBenefitsPayment = this.payment): boolean {
        if (payment.isRegularisation && payment !== this.active) {
            return !!payment.payments?.some(v => v === this.active);
        }

        return payment === this.active;
    }

    getChevronDirection() {
        if (this.payment.isRegularisation) {
            return this.isActive() ? IconDirectionEnum.Up : IconDirectionEnum.Down;
        }

        return IconDirectionEnum.Right;
    }

    getPaymentType(payment = this.payment): string {
        return this.translate.instant('Infino.Portal.Payment.Type.' + payment.paymentType);
    }

    getPaymentMethod(payment = this.payment): string {
        if (payment.paymentMethod === PaymentMethod.CircularCheque) {
            return this.translate.instant('Infino.Portal.Payment.Method.CircularCheque');
        }
        else if (payment.beneficiaryType !== this.beneficiaryType.Unknown) {
            return this.translate.instant('Infino.Portal.Payment.BeneficiaryType.' + payment.beneficiaryType) + ': ' + payment.accountNumber;
        }
        return payment.accountNumber;
    }

    getPaymentAmount(payment: ChildBenefitsPayment = this.payment): number {
        return this.paymentsService.getPaymentTotalAmount(payment);
    }

    getDate(year: number, month: number): Date {
        return new Date(year, month - 1);
    }

    hover(active: boolean) {
        this.hovering = active;
    }
}
