// Variables are replaced in Azure DevOps Release Pipeline
export const environment = {
    production: '#{PortalPWA.IsProduction}#',
    isLocalHost: false,
    apiUrl: '#{PortalPWA.WebsiteUrl}#',
    portalApiUrl: '#{PortalPWA.PortalApiUrl}#',
    portalCookieDomain: '#{PortalPWA.PortalCookieDomain}#',
    resourceStringKey: '#{PortalPWA.ResourceStringKey}#',
    portalResourceStringKey: '#{PortalPWA.PortalResourceStringKey}#',
    headerWidget: '#{PortalPWA.HeaderWidgetUrl}#',
    appInsights: {
        connectionString: '#{PortalPWA.ApplicationInsightsConnectionString}#'
    }
};
