import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { QuickLinksModule } from './../components/quick-links/quick-links.module';
import { PortalOverviewComponent } from './pages/overview/portal-overview.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentsModule } from '../payments/payments.module';
import { SidepanelModule } from 'src/app/shared/components/sidepanel/sidepanel.module';
import { OverviewPageMapper } from './pages/overview/overview-page.mapper';
import { PaymentsDataModule } from 'src/app/core/payments/payments-data.module';
import { PaymentsPaginationService } from 'src/app/core/payments/services/payments-pagination.service';
import { IconsModule } from 'src/app/shared/components/icons/icons.module';


@NgModule({
  declarations: [PortalOverviewComponent],
  imports: [
    CommonModule,
    QuickLinksModule,
    PaymentsModule,
    PaymentsDataModule,
    SidepanelModule,
    IconsModule,
    RouterModule,
    TranslateModule
  ],
  exports: [
    PortalOverviewComponent,
  ],
  providers: [
    OverviewPageMapper,
    PaymentsPaginationService
  ]
})
export class OverviewModule {}
