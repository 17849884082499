import { RegionEnum } from '../../payments/models/region-enum';

export class PortalConfiguration {
    public pageName: string;
    public faqPageUrl: string;
    public certificateHeaderAddress: string;
    public certificateFooter: string;
    public certificateSignatureUrl: string;
    public certificateSigner: string;
    public certificateSignerPosition: string;
    public certificateSignatureSize: [number, number];
    public certificateFlandersHeaderAddress: string;
    public certificateFlandersFooter: string;
    public certificateFlandersSignatureUrl: string;
    public certificateFlandersSigner: string;
    public certificateFlandersSignerPosition: string;
    public certificateFlandersSignatureSize: [number, number];
    public certificateWalloniaHeaderAddress: string;
    public certificateWalloniaFooter: string;
    public certificateWalloniaSignatureUrl: string;
    public certificateWalloniaSigner: string;
    public certificateWalloniaSignerPosition: string;
    public certificateWalloniaSignatureSize: [number, number];
    public certificateBrusselsHeaderAddress: string;
    public certificateBrusselsFooter: string;
    public certificateBrusselsSignatureUrl: string;
    public certificateBrusselsSigner: string;
    public certificateBrusselsSignerPosition: string;
    public certificateBrusselsSignatureSize: [number, number];
    public region?: RegionEnum;

    constructor(init?: Partial<PortalConfiguration>) {
        Object.assign(this, init);
    }
}
