import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpleNavigationComponent } from './simple-navigation.component';

@NgModule({
  declarations: [SimpleNavigationComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule
  ],
  exports: [SimpleNavigationComponent]
})
export class NavigationModule { }
