import { filter, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '../../../shared/infrastructure/state/store';
import { SeparState } from '../models/separ-state.model';

@Injectable()
export class SeparStateStore extends Store<SeparState> {
    public readonly loaded$: Observable<SeparState> = this.state$.pipe(filter(state => state.loading === false), take(1));

    protected getInitialState(): SeparState {
        return {
            loading: true,
            files: undefined,
            selectedId: undefined,
            selectedSchoolYear: undefined,
        };
    }

    protected getStoreName(): string {
        return 'separ-state-store';
    }

    public select(fileId: string, schoolYear: string) {
        this.update({
            ...this.getState(),
            selectedId: fileId,
            selectedSchoolYear: schoolYear,
        });
    }
}
