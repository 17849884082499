<div class="icon h-100 w-100 d-flex align-items-center justify-content-center" [ngClass]="classes">
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.7998 3H3.7998V10H10.7998V3Z" stroke="#B3ADAD" stroke-width="2" stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M21.7998 3H14.7998V10H21.7998V3Z" stroke="#B3ADAD" stroke-width="2" stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M21.7998 14H14.7998V21H21.7998V14Z" stroke="#B3ADAD" stroke-width="2" stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M10.7998 14H3.7998V21H10.7998V14Z" stroke="#B3ADAD" stroke-width="2" stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</div>