import { IconsModule } from './../../../shared/components/icons/icons.module';
import { ModifyUserDataModalMapper } from './components/modify-user-data/modify-user-data-modal.mapper';
import { ModifyUserDataModalService } from './components/modify-user-data/modify-user-data-modal.service';
import { ModifyUserDataModalComponent } from './components/modify-user-data/modify-user-data-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from './../../../shared/components/inputs/inputs.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataEnrichmentComponent } from './components/data-enrichment/data-enrichment.component';

@NgModule({
    declarations: [DataEnrichmentComponent, ModifyUserDataModalComponent],
    imports: [
        CommonModule,
        TranslateModule,
        InputsModule,
        FormsModule,
        ReactiveFormsModule,
        IconsModule,
    ],
    exports: [DataEnrichmentComponent, ModifyUserDataModalComponent],
    providers: [ModifyUserDataModalMapper, ModifyUserDataModalService],
})
export class UserModule {}
