import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { Store } from '../../../shared/infrastructure/state/store';

@Injectable()
export class UserStore extends Store<User> {
    protected getInitialState(): User {
        return new User();
    }

    protected getStoreName(): string {
        return 'user-store';
    }
}
