import { Injectable } from '@angular/core';
import { PortalSeparConfiguration } from '../models/portal-separ-configuration.model';

@Injectable()
export class PortalSeparConfigurationMapper {
    constructor() {}

    public map(data: any): PortalSeparConfiguration {
        return new PortalSeparConfiguration({
            mainDescription: data.MainDescription,
            familyIncomeCompletedInfo: data.FamilyIncomeCompletedInfo,
            familyIncomeCompletedError: data.FamilyIncomeCompletedError,
            incomeLimitInfo: data.IncomeLimitInfo,
            incomeLimitErrorUpperTreshold: data.IncomeLimitError_UpperTreshold,
            incomeLimitErrorUnknown: data.IncomeLimitError_Unknown,
            cadastralIncomeInfo: data.CadastralIncomeInfo,
            cadastralIncomeErrorUpperTreshold: data.CadastralIncomeError_UpperTreshold,
            cadastralIncomeErrorUnknown: data.CadastralIncomeError_Unknown,
            separCalculationBlockedInfo: data.SeparCalculationBlockedInfo,
            separCalculationBlockedError: data.SeparCalculationBlockedError,
            childNationalityError: data.ChildNationalityError,
            childAgeError: data.ChildAgeError,
            childPedagogicError: data.ChildPedagogicError,
        });
    }
}
