import { Component, EventEmitter, forwardRef, Output, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TwoWayBindingInputComponent } from '../two-way-binding-input.component';

@Component({
    selector: 'app-text-input',
    templateUrl: './text-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextInputComponent),
            multi: true
        }
    ]
})
export class TextInputComponent extends TwoWayBindingInputComponent<string>  {

    @Input() labelClass = '';

    // keep space for feedback area open
    @Input() feedbackSpacing = false;

    public valueChanged($event: any) {
        this.value = $event;
        this.propagateChange($event);
    }
}
