import { Injectable } from '@angular/core';
import { FlowConfiguration } from '../models/flow-configuration.model';
import { Store } from '../../../shared/infrastructure/state/store';

@Injectable()
export class FlowConfigurationStore extends Store<FlowConfiguration> {
    protected getInitialState(): FlowConfiguration {
        return new FlowConfiguration();
    }

    protected getStoreName(): string {
        return 'flow-configuration-store';
    }
}
