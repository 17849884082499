import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class FullScreenHandlerService {

    private renderer: Renderer2;

    constructor(rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    // used to prevent scrolling behind modals on iOS
    public blockBody(): void {
        this.renderer.setStyle(document.body, 'position', 'fixed');
        this.renderer.setStyle(document.body, 'top', '0px');
        this.renderer.setStyle(document.body, 'left', '0px');
        this.renderer.setStyle(document.body, 'right', '0px');
    }

    public unblockBody(): void {
        this.renderer.setStyle(document.body, 'position', '');
        this.renderer.setStyle(document.body, 'top', '');
        this.renderer.setStyle(document.body, 'left', '');
        this.renderer.setStyle(document.body, 'right', '');
    }
}
