import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { CertificateModelMapper } from './../mappers/certificate-model.mapper';
import { CertificateApiService } from './../../../../core/certificate/certificate-api.service';
import { Injectable } from '@angular/core';
import {
    TDocumentDefinitions,
} from 'pdfmake/interfaces';

import * as pdfMake from 'pdfmake/build/pdfmake';
import { CertificatePdfModelMapper } from '../mappers/certificate-pdf-model.mapper';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CertificateService {
    constructor(
        private api: CertificateApiService,
        private certificateMapper: CertificateModelMapper,
        private pdfMapper: CertificatePdfModelMapper,
        private http: HttpClient,
        private translate: TranslateService
    ) { }

    public getCertificate(from: Date, to: Date, fileId: string, showAmount = true): Observable<pdfMake.TCreatedPdf> {
        return this.api.getAll(from, to).pipe(
            map(result => {
                if (result.length === 0) {
                    throw new Error('nodata');
                }

                const data = result.find(x => x.fileId === fileId);
                const dataModel = this.certificateMapper.map(data, from, to);
                dataModel.showAmount = showAmount;
                const definition = this.pdfMapper.map(dataModel);
                return this.build(definition);
            })
        );
    }

    public mailCertificate(email: string, certificateBlob: Blob): Observable<object> {
        const certificate = new File([certificateBlob], 'certificate.pdf', { type: 'application/pdf' });
        const formData = new FormData();
        formData.append('email', email);
        formData.append('certificate', certificate);
        const headers = new HttpHeaders().set('language', this.translate.currentLang);
        return this.http.post(`${environment.apiUrl}/Mail/SendCertificateMail`, formData, { headers });
    }

    private build(definition: TDocumentDefinitions): pdfMake.TCreatedPdf {
        const fonts = this.pdfMapper.mapFonts();
        const layouts = this.pdfMapper.mapTableLayouts();
        return pdfMake.createPdf(definition, layouts, fonts);
    }
}
