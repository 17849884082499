import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ValidationHelper } from '../../../../../shared/infrastructure/validation/validation.helper';
import { PortalForgotPasswordModel } from './portal-forgot-password.model';
import { PortalForgotPasswordMapper } from './portal-forgot-password.mapper';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AuthenticationConfiguration } from 'src/app/core/configuration/models/authentication-configuration.model';
import { AuthenticationConfigurationStore } from 'src/app/core/configuration/data/authentication-configuration.store';
import { ResetPasswordService } from '../../services/reset-password.service';
import { PortalForgotPasswordFormModel } from './portal-forgot-password-form.model';

@Component({
  selector: 'app-portal-authentication',
  templateUrl: './portal-forgot-password.component.html',
})
export class PortalForgotPasswordComponent implements OnInit {
  public validationErrors = {};

  public model: PortalForgotPasswordModel;
  public formModel: PortalForgotPasswordFormModel = new PortalForgotPasswordFormModel();

  public successMessage: string;
  public isSuccess: boolean;
  public submitted = false;
  public loading = false;
  public errors: string[];

  constructor(
    private fb: FormBuilder,
    private localize: LocalizeRouterService,
    private authenticationConfigurationStore: AuthenticationConfigurationStore,
    private mapper: PortalForgotPasswordMapper,
    private resetPasswordService: ResetPasswordService) {
  }

  ngOnInit(): void {
    this.authenticationConfigurationStore.state$.subscribe((configuration: AuthenticationConfiguration) => {
      this.model = this.mapper.map(configuration);
    });
  }

  requestResetPassword() {

    this.submitted = true;
    this.loading = true;
    this.errors = [];

    const validationResult = ValidationHelper.validate(this.formModel.getValidators());
    if (validationResult.isValid) {
      this.resetPasswordService.requestResetPassword(this.formModel.email).subscribe(
        (response: Response) => {
          this.isSuccess = true;
        }
      );
    }
  }

  public getAuthenticationOverviewUrl(): string {
    const link: string = this.localize.translateRoute('/portal/auth/foreign') as string;

    return link;
  }

  public validateItem(fieldName: string) {
    const validationResult = ValidationHelper.validateField(fieldName, this.formModel.getValidators());
    this.validationErrors = validationResult.extendErrorObject(this.validationErrors);
  }

  public resetItemValidation(fieldName: string) {
    this.validationErrors = ValidationHelper.removeFromErrorObject(fieldName, this.validationErrors);
  }
}
