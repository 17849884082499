<app-separ-file-tabs></app-separ-file-tabs>
<div class="d-flex h-100" *ngIf="model$ | async as model">
    <div class="flex-grow-1" *ngIf="model.isFileSelected()">
        <div class="bg-yellow slope-bottom text-black">
            <div class="container-lg p-4">
                <h3 class="font-weight-semi-bold mb-3">{{ model.translations.title }}</h3>
                <div class="text-large" [innerHtml]="model.configuration.mainDescription">
                </div>
                <div class="d-flex align-items-center justify-content-between pt-3 mb-3 border-bottom border-yellow-dark">
                    <div class="text-overline text-uppercase">
                        {{ model.translations.schoolYearSelectionLabel }}
                    </div>            
                    <div class="separ-school-year-select-wrapper">
                        <app-dropdown class="separ-school-year-select"
                            [id]="'schoolYear'"
                            [label]=""
                            [options]="model.selectedFile.schoolYearDropdownValues"
                            [(ngModel)]="model.selectedFile.selectedSchoolYear"
                            [validationErrors]="[]"
                            (validate)="model.selectedFile.validateSchoolYearSelectedChild()"
                            [selectClass]="'btn btn-outline-dark mb-2'"
                            [isFormControl]="false"
                        ></app-dropdown>
                    </div>
                </div>
                <separ-school-year-detail 
                    [model]="model.selectedFile?.selectedSchoolYear"
                    [labels]="model.translations.schoolYearDetailTranslations"
                    [configuration]="model.configuration"
                    ></separ-school-year-detail>
            </div>
        </div>
        <div class="container-lg p-4">
            <div class="d-flex justify-content-between pt-2 pb-3 align-items-center">
                <div class="text-overline text-uppercase">
                    {{model.translations.childrenTitle}}
                </div>
            </div> 
            <app-separ-children-overview
                [model]="model.selectedFile?.selectedSchoolYear" 
                [selectedChild]="model.selectedFile?.selectedChild"
                [yearLabel]="model.translations.schoolYearDetailTranslations.yearLabel"
                (childSelected)="model.selectedFile?.selectChild($event)">
            </app-separ-children-overview>
        </div>
    </div>
    <app-sidepanel [open]="model.selectedFile?.selectedChild != null">
         <app-separ-child-detail 
            [fileId]="model.selectedFile?.fileId"
            [child]="model.selectedFile?.selectedChild"
            [configuration]="model.configuration"
            [labels]="model.translations.childTranslations"
            [canShowPrevious]="model.selectedFile?.hasPreviousChild"
            [canShowNext]="model.selectedFile?.hasNextChild"
            (onClose)="model.selectedFile?.unselectChild()" 
            (onNext)="model.selectedFile?.nextChildDetails()"
            (onPrevious)="model.selectedFile?.previousChildDetails()">
        </app-separ-child-detail>
    </app-sidepanel>
</div>