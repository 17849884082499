import { Component, Input } from '@angular/core';
import { IconColorEnum } from '../enums/icon-color.enum';

@Component({
  selector: 'app-icon-file-text',
  templateUrl: './file-text.component.html',
})
export class IconFileTextComponent {
  @Input() color: IconColorEnum;

  public get classes(): string {
    return `${this.color}`;
  }
}
