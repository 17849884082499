<div class="d-flex h-100" *ngIf="model">
    <div class="flex-grow-1">
        <div *ngIf="model.isLoaded">
            <div class="bg-yellow slope-bottom">
                <div class="container-lg p-4">
                    <h3 class="mt-2 mb-4">{{ model.myDocumentsTitle }}</h3>
                </div>
            </div>
            <div #searchform class="container">
                <div class="d-flex flex-column">
                    <div class="text-overline my-2 text-uppercase">
                        {{ model.documentsLabel }}
                    </div>
                    <div class="px-3 py-2 mb-4 rounded border">
                        <h6 class="mb-3"> {{ model.searchTitle }} </h6>
                        <form class="" (ngSubmit)="submit()">
                            <div class="d-flex flex-column flex-md-row justify-stretch">
                                <div class="form-group d-flex flex-column mb-2 mr-4 w-100">
                                    <app-dropdown [label]="model.filesLabel"
                                        [placeholder]="model.filesPlaceholderLabel"
                                        [options]="model.files" name="file" id="file" [disabled]="model.loading"
                                        [(ngModel)]="model.form.fileId" [validationErrors]="validationErrors['documentFile']"
                                        (validate)="validateItem('documentFile')" (resetValidate)="resetItemValidation('documentFile')">
                                    </app-dropdown>
                                </div>
                                <div class="form-group d-flex flex-column mb-2 w-100">
                                    <app-dropdown [label]="model.statusLabel"
                                        [placeholder]="model.statusPlaceholderLabel"
                                        [options]="model.statuses" name="status" id="status" [disabled]="model.loading"
                                        [(ngModel)]="model.form.statusId" [validationErrors]="validationErrors['documentStatus']"
                                        (validate)="validateItem('documentStatus')" (resetValidate)="resetItemValidation('documentStatus')">
                                        </app-dropdown>
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-md-row justify-stretch">
                                <div class="form-group d-flex flex-column mb-2 mr-4 w-100">
                                    <label class="line-height-normal">
                                        {{ model.fromLabel }}
                                    </label>
                                    <input type="date" class="form-control form-control-sm rounded"
                                        [(ngModel)]="model.form.from" name="documentFrom"
                                        [class.text-brown-40]="!model.form.from" [disabled]="model.loading"
                                        [ngClass]="[validationErrors['documentFrom'] ? 'is-invalid': '' ]"
                                        (ngModelChange)="validateItem('documentFrom')" [disabled]="model.formIsLoading" />
                                    <div class="invalid-feedback">
                                        <span *ngFor="let error of validationErrors['documentFrom']">
                                            {{'Infino.ClientPortal.Validation.' + error | translate }}
                                        </span>
                                    </div>
                                </div>
                                <div class="form-group d-flex flex-column mb-2 w-100">
                                    <label class="line-height-normal">
                                        {{ model.toLabel }}
                                    </label>
                                    <input type="date" class="form-control form-control-sm rounded"
                                        [(ngModel)]="model.form.to" name="documentTo"
                                        [class.text-brown-40]="!model.form.to" [disabled]="model.loading"
                                        [ngClass]="[validationErrors['documentTo'] ? 'is-invalid': '' ]"
                                        (ngModelChange)="validateItem('documentTo')" [disabled]="model.formIsLoading" />
                                    <div class="invalid-feedback">
                                        <span *ngFor="let error of validationErrors['documentTo']">
                                            {{'Infino.ClientPortal.Validation.' + error | translate }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 d-flex flex-row flex-row-reverse">
                                <button type="submit" class="btn btn-primary" [disabled]="model.loading">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                        *ngIf="model.loading"></span>
                                    {{ model.searchButton }}
                                </button>
                                <button type="button" class="btn text-primary mr-2" (click)="resetForm()" [disabled]="model.loading">
                                    {{ model.clearButton }}
                                </button>
                            </div>
            
                            <!-- <p class="text-danger" *ngIf="model.noData">{{ model.translations.noData }}</p> -->
                        </form>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="rounded overflow-hidden">
                    <app-portal-documents-list [documents]="model.filteredDocuments" (selected)="onSelect($event)" (download)="onDownload($event)" [active]="model.selectedDocument" [darkSeparator]="true">
                    </app-portal-documents-list>
                </div>
            </div>
        </div>
    </div>    

    <app-sidepanel [open]="!!model.selectedDocument">
        <app-portal-document-detail [document]="model.selectedDocument" [model]="model.documentDetailLabels" (previous)="onPrevious()" (next)="onNext()" (close)="model.hideDetail()" [hasNext]="model.hasNextDocument" [hasPrev]="model.hasPreviousDocument"
            *ngIf="model.selectedDocument"></app-portal-document-detail>
    </app-sidepanel>
</div>
