<div #wrapper class="position-relative d-flex h-100">
    <button class="position-absolute btn mr-2 mb-2" (click)="clearDrawing()" *ngIf="hasStartedDrawing" style="bottom: 0; right: 0;">
        <img src="assets/svg/retry.svg">
    </button>

    <div *ngIf="enableSkip" class="position-absolute ml-3 mb-3" style="bottom: 0; left: 0; margin-right: 50px">
        <app-checkbox [(ngModel)]="isSkipped" [label]="skipText" [id]="'skipSignature'" (updated)="updatedSkip($event)" [labelClass]="'line-height-normal small pt-1'"></app-checkbox>
    </div>

    <span class="avoid-clicks position-absolute d-block text-center w-100 text-muted small p-4" *ngIf="!hasStartedDrawing" style="top: 35%;">
        {{ placeholder }}
    </span>

    <div class="border-dashed border-gray border-3 rounded">
        <canvas class="d-block w-100 h-100" #canvas></canvas>
    </div>
</div>
