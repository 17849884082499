import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PortalAuthenticationService } from '../../services/portal-authentication.service';
import { PortalApiService } from '../../services/portal-api.service';
import { Observable } from 'rxjs';
import { shareReplay, take, filter, map } from 'rxjs/operators';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AuthenticationConfigurationStore } from 'src/app/core/configuration/data/authentication-configuration.store';
import { AuthenticationConfiguration } from 'src/app/core/configuration/models/authentication-configuration.model';
import { PortalAuthenticationMapper } from './portal-authentication.mapper';
import { PortalAuthenticationModel } from './portal-authentication.model';

@Component({
  selector: 'app-portal-authentication',
  templateUrl: './portal-authentication.component.html',
})
export class PortalAuthenticationComponent implements OnInit {
  itsMeUrl: Observable<string>;

  public model: PortalAuthenticationModel;
  public fileNotFound$: Observable<boolean>;

  constructor(
    private apiService: PortalApiService,
    private router: Router,
    private localize: LocalizeRouterService,
    private authenticationConfigurationStore: AuthenticationConfigurationStore,
    private mapper: PortalAuthenticationMapper,
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.itsMeUrl = this.apiService.csamUrl().pipe(shareReplay(1));
    this.itsMeUrl.pipe(take(1)).subscribe();

    this.authenticationConfigurationStore.state$.subscribe((configuration: AuthenticationConfiguration) => {
      this.model = this.mapper.map(configuration);
    });

    this.fileNotFound$ = this.activeRoute.queryParams.pipe(
      filter(params => params['error'] === 'NoFileFound'),
      map(() => true));
  }

  login() {
    const translatedPath: any = this.localize.translateRoute('/portal/auth/foreign');

    this.router.navigate([translatedPath]);
  }

  public getForeignUrl(): string {
    return this.localize.translateRoute('/portal/auth/foreign') as string;
  }

  loginItsMe(): void {
    this.itsMeUrl.subscribe(url => window.location.href = url);
  }
}
