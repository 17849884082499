import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SeparFileStore } from '../data/separ-file.store';
import { SeparFileMapper } from '../mappers/separ-file.mapper';
import { SeparFile } from '../models/separ-file.model';

@Injectable({
    providedIn: 'root'
})
export class SeparApiService {

    constructor(
        private http: HttpClient,
        private separFileMapper: SeparFileMapper,
        private separFileStore: SeparFileStore,
    ) {
    }

    public get apiUrl() {
        return environment.portalApiUrl;
    }

    public initialize() {
        const subscription = this.getFiles()
            .subscribe((files: SeparFile[]) => {
                this.separFileStore.update(files);
                subscription.unsubscribe();
            });
    }

    public getFiles(): Observable<SeparFile[]> {
        return this.http.get(`${this.apiUrl}/Separ/All`)
            .pipe(
                map((data: Array<any>) => {
                    return data.map(file => this.separFileMapper.map(file));
                }),
                catchError((error) => {
                    throw error;
                })
            );
    }
}
