import { fadeInOut } from './../../infrastructure/animations/animation.constant';
import { PortalNotificationsService } from 'src/app/core/notifications/services/portal-notification.service';
import { Component, Input, OnInit } from '@angular/core';
import { PortalNotificationsStore } from 'src/app/core/notifications/data/portal-notifications.store';
import { PortalNotification } from 'src/app/core/notifications/models/portal-notification.model';

@Component({
  selector: 'app-portal-notifications',
  templateUrl: './portal-notifications.component.html',
  animations: [fadeInOut],
})
export class PortalNotificationsComponent implements OnInit {

  constructor(private notificationsStore: PortalNotificationsStore, public service: PortalNotificationsService) { }

  public notifications: PortalNotification[];

  ngOnInit(): void {
    this.notificationsStore.state$.subscribe((notifications: PortalNotification[]) => {
      this.notifications = notifications;
    });
  }

  trackNotification(index: number, notification: PortalNotification): string {
    return notification?.id;
  }
}
