import { RegionMapper } from './../../user/mappers/region.mapper';
import { Injectable } from '@angular/core';
import { PortalConfiguration } from '../models/portal-configuration.model';

@Injectable()
export class PortalConfigurationMapper {
    constructor(private regionMapper: RegionMapper) { }

    public map(data: any): PortalConfiguration {
        return new PortalConfiguration({
            pageName: data.PageTitle,
            faqPageUrl: data.FAQPageUrl,
            certificateHeaderAddress: data.CertificateHeaderAddress,
            certificateFooter: data.CertificateFooter,
            certificateSigner: data.CertificateSigner,
            certificateSignerPosition: data.CertificateSignerPosition,
            certificateSignatureUrl: data.CertificateSignatureUrl,
            certificateSignatureSize: data.CertificateSignatureSize,
            certificateFlandersHeaderAddress: data.CertificateFlandersHeaderAddress,
            certificateFlandersFooter: data.CertificateFlandersFooter,
            certificateFlandersSigner: data.CertificateFlandersSigner,
            certificateFlandersSignerPosition: data.CertificateFlandersSignerPosition,
            certificateFlandersSignatureUrl: data.CertificateFlandersSignatureUrl,
            certificateFlandersSignatureSize: data.CertificateFlandersSignatureSize,
            certificateWalloniaHeaderAddress: data.CertificateWalloniaHeaderAddress,
            certificateWalloniaFooter: data.CertificateWalloniaFooter,
            certificateWalloniaSigner: data.CertificateWalloniaSigner,
            certificateWalloniaSignerPosition: data.CertificateWalloniaSignerPosition,
            certificateWalloniaSignatureUrl: data.CertificateWalloniaSignatureUrl,
            certificateWalloniaSignatureSize: data.CertificateWalloniaSignatureSize,
            certificateBrusselsHeaderAddress: data.CertificateBrusselsHeaderAddress,
            certificateBrusselsFooter: data.CertificateBrusselsFooter,
            certificateBrusselsSigner: data.CertificateBrusselsSigner,
            certificateBrusselsSignerPosition: data.CertificateBrusselsSignerPosition,
            certificateBrusselsSignatureUrl: data.CertificateBrusselsSignatureUrl,
            certificateBrusselsSignatureSize: data.CertificateBrusselsSignatureSize,
            region: data.ActiveRegion
                ? this.regionMapper.map((data.ActiveRegion as string).toLowerCase())
                : undefined,
        });
    }
}
