import { CookieCategory } from './CookieCategory';

export class CookieBannerConfiguration {

    public title = '';
    public acceptButtonText = '';
    public otherSettingsButtonText = '';
    public additionalInformation = '';
    public cookieAndPrivacyInformation = '';
    public acceptOtherSettingsButtonText: string;
    public acceptCategoryButtonText: string;
    public declineCategoryButtonText: string;

    public cookieCategories: CookieCategory[] = [];
}
