import { ChildBenefitsStateService } from './services/child-benefits-state.service';
import { ChildBenefitsStateStore } from './data/child-benefits-state.store';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SingletonModule } from '../../shared/modules/singleton.module';
import { ChildBenefitsApiService } from './services/child-benefits-files-api.service';
import { ChildBenefitsFileMapper } from './mappers/child-benefits-file.mapper';
import { ChildBenefitsBeneficiaryMapper } from './mappers/child-benefits-beneficiary.mapper';
import { ChildBenefitsChildMapper } from './mappers/child-benefits-child.mapper';
import { ChildBenefitsFileStore } from './data/child-benefits-file.store';

@NgModule({
    providers: [
        ChildBenefitsApiService,
        ChildBenefitsFileMapper,
        ChildBenefitsBeneficiaryMapper,
        ChildBenefitsChildMapper,
        ChildBenefitsFileStore,
        ChildBenefitsStateStore,
        ChildBenefitsStateService
    ]
})
export class ChildBenefitsFilesDataModule extends SingletonModule {
    constructor(@Optional() @SkipSelf() module: ChildBenefitsFilesDataModule) {
        super(module);
    }
}
