import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CookieBannerConfiguration } from '../models/CookieBannerConfiguration';
import { CookieCategory } from '../models/CookieCategory';

@Component({
    selector: 'app-cookie-category-manage',
    templateUrl: './cookie-category-manage.component.html',
})
export class CookieCategoryManageComponent  {
    @Input() public cookieCategory: CookieCategory;
    @Input() public cookieBannerConfiguration: CookieBannerConfiguration;
    @Output() public consentChanged = new EventEmitter<boolean>();

    public getButtonClass(negate: boolean): string {
      if (negate) {
        return 'btn btn-sm mr-2' + (!this.cookieCategory.hasConcent ? ' btn-primary' : ' btn-outline-secondary');
      }
      return 'btn btn-sm mr-2' + (this.cookieCategory.hasConcent ? ' btn-primary' : ' btn-outline-secondary');
    }

    public changeConsent(accepted: boolean): void {
      this.consentChanged.emit(accepted);
    }
}
