import { AuthenticationRefresherComponent } from './../components/authentication-refresher/authentication-refresher.component';
import { ModalService } from './../../../../shared/infrastructure/modals/modal.service';
import { Injectable, OnInit } from '@angular/core';
import { PortalAuthenticationService } from './portal-authentication.service';
import { AuthenticationState } from './portal-authentication.model';

@Injectable()
export class PortalAuthenticationRefresherService {
    private timeoutHandle;

    constructor(private auth: PortalAuthenticationService, private modalService: ModalService) { }

    initialize() {
        clearTimeout(this.timeoutHandle);

        // prevent showing modal if timeout ends after logout
        this.auth.authState.subscribe(state => {
            if (state === AuthenticationState.LoggedOut) {
                clearTimeout(this.timeoutHandle);
            }
        });

        // show authentication refresh modal when token is only valid for 10 more seconds
        const token = this.auth.token();
        const timeout = new Date(token.exp * 1000).getTime() - new Date().getTime() - 10 * 1000;
        this.timeoutHandle = setTimeout(() => this.showModal(), timeout);
    }

    showModal() {
        this.modalService.openModal(AuthenticationRefresherComponent);
    }
}
