import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconColorEnum } from 'src/app/shared/components/icons/enums/icon-color.enum';
import { IconDirectionEnum } from 'src/app/shared/components/icons/enums/icon-direction.enum';
import { SeparChildViewModel } from '../../viewmodels/separ-child.viewmodel';
import { SeparSchoolYearDetailsViewModel } from '../../viewmodels/separ-school-year-details.viewmodel';

@Component({
    selector: 'app-separ-children-overview',
    templateUrl: './separ-children-overview.component.html',
})
export class SeparChildrenOverviewComponent {
    @Input() public model: SeparSchoolYearDetailsViewModel;
    @Input() public selectedChild: SeparChildViewModel;
    @Input() public yearLabel: (amount: number) => string;

    @Output() public childSelected = new EventEmitter<SeparChildViewModel>();

    public iconColorBrown = IconColorEnum.Brown40;
    public iconColorBlue = IconColorEnum.Blue100;
    public iconColorBlack = IconColorEnum.Black;
    public iconDirectionRight = IconDirectionEnum.Right;

    public get isLoaded(): boolean {
        return this.model != null;
    }

    public isActive(child: SeparChildViewModel): boolean {
        return child === this.selectedChild && this.selectedChild !== undefined;
    }

    public selectChild(child: SeparChildViewModel) {
        this.selectedChild = child;
        this.childSelected.emit(child);
    }
}
