import { RegionEnum } from './../../payments/models/region-enum';
import { UpdateContactDetailsRequest } from './../models/update-contact-details.request';
import { UpdateContactBankAccountNumberRequest } from './../models/update-contact-bankaccountnumber.request';
import { UpdateContactResponse } from '../models/update-contact.response';
import { retry, shareReplay, take } from 'rxjs/operators';
import { PortalUserStore } from './../data/portal-user.store';
import { EnrichModel } from './../models/enrich.model';
import { Observable } from 'rxjs';
import { UserInfoModel } from './../models/user-info.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class PortalUserService {
  constructor(private http: HttpClient, private store: PortalUserStore) { }

  getUserInfo(): Observable<UserInfoModel> {
    return this.http.get<UserInfoModel>(environment.portalApiUrl + '/user/info');
  }

  enrichUserData(data: EnrichModel) {
    return this.http.post(environment.portalApiUrl + '/user/enrich', data);
  }

  updateUserInfo({ email, phone, regions }: { email: string, phone: string, regions: RegionEnum[] }) {
    const payload: UpdateContactDetailsRequest = {
      Regions: regions,
      Email: email,
      Phone: phone,
    };

    return this.http.post<UpdateContactResponse>(environment.portalApiUrl + '/user/updatecontactdetails', payload);
  }

  updateUserBankAccountNumber({ region, fileId, bankAccountNumber, bankAccountType, bankAccountHasConsent, personFullName }: {
    region: RegionEnum,
    fileId: string,
    bankAccountNumber: string,
    bankAccountType: string,
    bankAccountHasConsent: boolean,
    personFullName: string
  }) {
    const payload: UpdateContactBankAccountNumberRequest = {
      Region: region,
      FileId: fileId,
      BankAccountNumber: bankAccountNumber,
      BankAccountType: bankAccountType,
      BankAccountHasConsent: bankAccountHasConsent,
      PersonFullName: personFullName
    };

    return this.http.post<UpdateContactResponse>(environment.portalApiUrl + '/user/updatecontactbankaccountnumber', payload);
  }

  initialize(): Observable<UserInfoModel> {
    this.store.resetState();
    const stream = this.getUserInfo().pipe(retry(3), shareReplay(1));
    stream.subscribe(info => this.store.update(info));
    return stream;
  }

  refetch() {
    this.getUserInfo()
      .pipe(retry(3), take(1))
      .subscribe((info) => this.store.update(info));
  }
}
