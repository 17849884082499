export class UrlHelper {

    public static getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        const results = regex.exec(location.search);
        return results == null ? undefined : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

}
