import { Injectable } from '@angular/core';
import { Store } from '../../../shared/infrastructure/state/store';
import { PortalSeparConfiguration } from '../models/portal-separ-configuration.model';

@Injectable()
export class PortalSeparConfigurationStore extends Store<PortalSeparConfiguration> {
    protected getInitialState(): PortalSeparConfiguration {
        return new PortalSeparConfiguration();
    }

    protected getStoreName(): string {
        return 'portal-separ-configuration-store';
    }
}
