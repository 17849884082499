import { CertificateModel } from './../models/certificate-model';
import { Injectable } from '@angular/core';
import { Content, ContentTable, CustomTableLayout, DynamicBackground, TableLayout, TDocumentDefinitions } from 'pdfmake/interfaces';
import * as dayjs from 'dayjs';

@Injectable()
export class CertificatePdfModelMapper {
    public map(data: CertificateModel): TDocumentDefinitions {
        const definition: TDocumentDefinitions = {
            pageSize: 'A4',
            pageMargins: [56, 100, 56, 99],
            background: this.mapBackground(),
            content: this.mapContent(data),
            images: this.mapImages(data),
            footer: this.mapFooter(data),
            defaultStyle: {
                font: 'domus',
                fontSize: 10
            },
            styles: {
                tableHeader: {
                    fontSize: 6,
                    characterSpacing: 1.5
                }
            }
        };

        return definition;
    }

    public mapFonts(): { [key: string]: { [key: string]: string } } {
        return {
            domus: {
                normal: `${window.location.origin}/assets/certificate/fonts/domus-regular.ttf` // 400
            }
        };
    }

    private mapContent(data: CertificateModel): Content {
        return [
            this.mapAddress(data),
            this.mapIntroduction(data),
            this.mapPaymentsTable(data),
            this.mapSignature(data),
        ];
    }

    private mapAddress(data: CertificateModel): Content {
        const { recipient } = data;
        return [
            {
                margin: [320 - 56, 0, 0, 56],
                stack: [
                    {
                        text: data.headerAddress,
                        margin: [0, 0, 0, 24]
                    },
                    {
                        stack: [
                            // second column consists of paragraphs
                            `${recipient.firstname} ${recipient.lastname}`,
                            recipient.street,
                            `${recipient.postalCode} ${recipient.city}`,
                            recipient.country?.toUpperCase()
                        ],
                    }
                ]
            }
        ];
    }

    private mapIntroduction(data: CertificateModel): Content {
        return [
            {
                margin: [0, 0, 0, 36],
                stack: [
                    `${data.translations.headerCity}, ${dayjs().format('DD/MM/YYYY')}`,
                    `${data.translations.filenumber}: ${data.fileId}`
                ]
            },
            {
                margin: [0, 0, 0, 43],
                stack: [
                    data.translations.confirmationOfChildBenefitPayments,
                    data.translations.infinoPaidChildBenefitsTo,
                    data.translations.wePaidFromTill,
                ]
            }
        ];
    }


    private mapPaymentsTable(data: CertificateModel): ContentTable {
        if (data.showAmount) {
            return {
                margin: [0, 0, 0, 56],
                layout: 'customTableLayout',
                table: {
                    headerRows: 1,
                    widths: ['*', 100, 100],
                    body: [
                        [data.translations.nameAndFirstname, data.translations.month, `${data.translations.amount} (EURO)`].map(v => ({
                            text: v.toUpperCase(),
                            color: '#B3ADAD',
                            fontSize: 6,
                        })),
                        ...data.payments.map(({ childName, month, amount }) => [childName, month, amount.toString().replace('.', ',')])
                    ]
                }
            };
        }
        else {
            return {
                margin: [0, 0, 0, 56],
                layout: 'customTableLayout',
                table: {
                    headerRows: 1,
                    widths: ['*', 100],
                    body: [
                        [data.translations.nameAndFirstname, data.translations.month].map(v => ({
                            text: v.toUpperCase(),
                            color: '#B3ADAD',
                            fontSize: 6,
                        })),
                        ...data.payments.map(({ childName, month }) => [childName, month])
                    ]
                }
            };
        }

    }

    private mapBackground(): DynamicBackground | Content {
        return [
            {
                image: 'headerBlob',
                width: 595.28,
                absolutePosition: { x: 0, y: 0 }
            },
            {
                image: 'footerBlob',
                width: 595.28,
                absolutePosition: { x: 0, y: 700 }
            }
        ];
    }

    private mapImages(data: CertificateModel): { [key: string]: string; } {
        return {
            headerBlob: `${window.location.origin}/assets/certificate/images/top.png`,
            footerBlob: `${window.location.origin}/assets/certificate/images/bottom.png`,
            signature: data.signature.signatureUrl,
        };
    }

    private mapSignature(data: CertificateModel): Content {
        return {
            unbreakable: true,
            stack: [
                { text: data.translations.kindRegards, margin: [0, 0, 0, 16] },
                {
                    image: 'signature',
                    margin: [0, 0, 0, 16],
                    height: 64,
                    width: data.signature.signatureSize[0] / data.signature.signatureSize[1] * 64,
                },
                { text: data.signature.signer },
                { text: data.signature.signerPosition },
            ]
        };
    }

    private mapFooter(data: CertificateModel): Content {
        return {
            margin: [0, 50, 0, 0],
            fontSize: 7,
            text: data.footer || '',
            alignment: 'center',
            color: '#215873'
        };
    }

    public mapTableLayouts(): { [name: string]: CustomTableLayout } {
        const tableLayout: TableLayout = {
            hLineWidth: function (i, node) {
                if (i === 0 /*|| i === node.table.body.length*/) {
                    return 0;
                }
                return (i === node.table.headerRows) ? 1 : 1;
            },
            vLineWidth: function (i) {
                return 0;
            },
            hLineColor: function (i) {
                return i === 1 ? '#B3ADAD' : '#D0CBCC';
            },
            paddingTop: function (i, node) {
                return i === node.table.headerRows ? 8 : 8;
            },
            paddingBottom: function (i, node) {
                return (i === node.table.headerRows) ? 8 : 10;
            },
            paddingLeft: function (i, node) {
                return (i === node.table.headerRows) ? 4 : 4;
            },
            paddingRight: function (i, node) {
                return (i === node.table.headerRows) ? 0 : 0;
            },
        };

        return { 'customTableLayout': tableLayout };
    }
}
