import { Observable } from 'rxjs';
import { PortalAuthenticationService } from './portal-authentication.service';
import { UrlTree, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PortalLogoutGuard implements CanActivate {
    constructor(
        private auth: PortalAuthenticationService,
    ) { }

    canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.auth.logoutAndRedirect();
    }
}
