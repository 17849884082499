export class DateFormatHelper {

    static transform(value: string, deletion: boolean): string {
        const numbersRegex: RegExp = /[0-9]/mg;
        const matches = value.match(numbersRegex);
        let result = '';

        if (matches && matches.length) {
            matches.forEach((val, i) => {
                if (i <= 7) {
                    result = result + val;
                }

                if ((i === 1 && (matches.length > 2 || !deletion)) ||
                    (i === 3 && (matches.length > 4 || !deletion))) {
                    result = result + '/';
                }
            });
        }

        return result;
    }
}
