import { Component, Input } from '@angular/core';
import { IconColorEnum } from '../enums/icon-color.enum';

@Component({
  selector: 'app-icon-academy',
  templateUrl: './academy.component.html',
})
export class IconAcademyComponent {
  @Input() color: IconColorEnum = IconColorEnum.Brown40;

  public get classes(): string {
    return `${this.color}`;
  }
}
