import { Injectable } from '@angular/core';
import { PortalAlertConfigurationModel } from '../models/portal-alert-configuration.model';
import { Store } from '../../../shared/infrastructure/state/store';

@Injectable()
export class PortalAlertConfigurationStore extends Store<PortalAlertConfigurationModel> {
    protected getInitialState(): PortalAlertConfigurationModel {
        return new PortalAlertConfigurationModel();
    }

    protected getStoreName(): string {
        return 'portal-alert-configuration-store';
    }
}
