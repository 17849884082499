import { NgModule, Optional, SkipSelf } from '@angular/core';

import { SingletonModule } from '../../shared/modules/singleton.module';
import { FlowConfigurationModule } from '../configuration/flow-configuration.module';
import { BootService } from './boot.service';

@NgModule({
    imports: [
        FlowConfigurationModule,
    ],
    providers: [
        BootService,
    ]
})
export class BootModule extends SingletonModule {
    constructor(@Optional() @SkipSelf() module: BootModule) {
        super(module);
    }
}
