import { ChildBenefitsPayment } from './../../../../../core/payments/models/child-benefits-payment';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-portal-payments-list',
  templateUrl: './portal-payments-list.component.html'
})
export class PortalPaymentsListComponent implements OnInit {
  @Input() payments: ChildBenefitsPayment[];
  @Input() active: ChildBenefitsPayment;
  @Output() selected: EventEmitter<ChildBenefitsPayment> = new EventEmitter();
  @Input() darkSeparator = false;

  constructor() { }

  ngOnInit(): void {
  }

  borderTopActive(payment: ChildBenefitsPayment, first: boolean, i: number): boolean {
    // first item doesn't have a blue border on top when active
    if (!first && payment === this.active) {
      return true;
    }

    // if the previous item is selected, the border between the two (this item's top border) has to be blue
    return i > 0 && this.payments[i - 1] === this.active && !this.payments[i - 1].isRegularisation;
  }
}
