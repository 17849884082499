import { IValidator } from '../validator.interface';
import { ValidationError } from '../validation-error.enum';

/**
 * Validates if a property is different from undefined or null
 */
export class CheckedValidator implements IValidator {

    public validate(value: any): string {
        if (!value) {
            return ValidationError.NotChecked;
        }

        return undefined;
    }
}
