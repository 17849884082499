import { UpdateContactResponse } from './../../../../../core/portal-user/models/update-contact.response';
import { RegionEnum } from 'src/app/core/payments/models/region-enum';
import { Validators } from './../../../../../shared/infrastructure/validation/validators';
import { IValidationField } from './../../../../../shared/infrastructure/validation/validation-field.interface';

export class ModifyUserDataModalModel {
    form: {
        loading: boolean;
        success: boolean;

        email: string;
        emailEnabled: boolean;
        phone: string;
        phoneEnabled: boolean;
        bankAccountEnabled: boolean;
        bankAccountNumberMother: string;
        bankAccountMotherCheck: boolean;
        bankAccountMotherHasConsent: boolean;
        bankAccountNumberBoth: string;
        bankAccountBothCheck: boolean;
        bankAccountBothHasConsent: boolean;

        result?: UpdateContactResponse
    };
    personFullName: string;

    activeRegion: RegionEnum;
    regions: RegionEnum[];
    activeFileId: string;

    translations: {
        title: string;
        introduction: string;

        emailChangeLabel: string;
        emailPlaceholder: string;

        phoneChangeLabel: string;
        phonePlaceholder: string;

        bankAccountChangeLabel: string;
        bankAccountInputLabel: string;
        bankAccountMotherLabel: string;
        bankAccountBothLabel: string;
        bankAccountConsentLabel: string;
        bankAccountPlaceholder: string;

        submit: string;
        submitting: string;

        submittedExplanation: string;
        close: string;

        somethingWentWrong: string;
    };

    constructor(init?: Partial<ModifyUserDataModalModel>) {
        Object.assign(this, init);
    }

    public getValidators(): IValidationField[] {
        const validators: IValidationField[] = [];

        if (this.form.emailEnabled) {
            validators.push({
                name: 'email',
                value: this.form.email,
                validators: [Validators.required(), Validators.email()],
            });
        }

        if (this.form.phoneEnabled) {
            validators.push({
                name: 'phone',
                value: this.form.phone,
                validators: [Validators.required(), Validators.telephone()],
            });
        }

        if (this.form.bankAccountEnabled) {
            validators.push({
                name: 'bankAccountNumberMother',
                value: this.form.bankAccountNumberMother,
                validators: [
                    Validators.required(),
                    Validators.Iban(),
                ],
                skipValidation: !this.form.bankAccountMotherCheck
            });
            validators.push({
                name: 'bankAccountMotherHasConsent',
                value: this.form.bankAccountMotherHasConsent,
                validators: [
                    Validators.checked(),
                ],
                skipValidation: !this.form.bankAccountMotherCheck
            });

            validators.push({
                name: 'bankAccountNumberBoth',
                value: this.form.bankAccountNumberBoth,
                validators: [
                    Validators.required(),
                    Validators.Iban(),
                ],
                skipValidation: !this.form.bankAccountBothCheck
            });
            validators.push({
                name: 'bankAccountBothHasConsent',
                value: this.form.bankAccountBothHasConsent,
                validators: [
                    Validators.checked(),
                ],
                skipValidation: !this.form.bankAccountBothCheck
            });
        }

        return validators;
    }
}
