<div class="d-flex h-100">
    <div class="flex-grow-1">
        <app-portal-future-payments (selected)="onSelect($event)" [active]="selected" [slope]="true"></app-portal-future-payments>
        <div class="container mt-5">
            <div class=" mb-3">

                <!-- Header -->

                <div class="d-flex justify-content-between align-items-center">
                    <div class="text-overline text-uppercase">{{ translations.history }}</div>
                    <button (click)="toggleSearch()" class="btn btn-outline-dark d-flex align-items-center text-medium font-weight-semi-bold z-index-10 position-relative"
                        [ngClass]="showSearch ? 'rounded-top bg-ecru border-bottom-transparent' : ''">
                        {{ translations.search }}
                        <app-icon-chevron class="ml-2" [direction]="showSearch ? chevronUp : chevronDown"
                            [color]="chevronColor"></app-icon-chevron>
                    </button>
                </div>

                <!-- Search -->

                <app-smooth-height [trigger]="showSearch" [ngStyle]="{ 'margin-top': '-1px' }">
                    <form *ngIf="showSearch" class="border rounded-left rounded-bottom px-3 py-2" (submit)="search()">
                        <div class="line-height-normal font-weight-semi-bold mt-1 mb-3">{{ translations.searchPayment }}</div>
                        <div class="d-flex flex-column flex-md-row justify-content-stretch mb-3">
                            <div class="bg-ecru-dark-25 pl-2 pt-2 pr-2 pr-md-0 mb-2 mb-md-0 d-flex flex-column flex-md-row mr-2 w-100">
                                <div class="form-group d-flex flex-column mb-2 mr-2 w-100">
                                    <label for="fromamount" class="line-height-normal">{{ translations.amountFrom }}</label>
                                    <div class="position-relative">
                                        <input type="number" step="0.01" class="form-control form-control-sm appearance-none" style="padding-right: 30px;" [(ngModel)]="searchModel.amountFrom"
                                            name="amountFrom" />
                                        <span class="text-brown-40 position-absolute avoid-clicks fixed-top d-flex h-100 justify-content-end align-items-center mr-3">€</span>
                                    </div>
                                </div>
                                <div class="form-group d-flex flex-column mb-2 mr-2 w-100">
                                    <label for="fromamount" class="line-height-normal">
                                        {{ translations.amountUntil }}
                                    </label>
                                    <div class="position-relative">
                                        <input type="number" step="0.01" class="form-control form-control-sm rounded appearance-none" style="padding-right: 30px;" [(ngModel)]="searchModel.amountUntil"
                                        name="amountUntil" />
                                        <span class="text-brown-40 position-absolute avoid-clicks fixed-top d-flex h-100 justify-content-end align-items-center mr-3">€</span>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-ecru-dark-25 pl-2 pt-2 pr-2 pr-md-0 d-flex flex-column flex-md-row w-100">
                                <div class="form-group d-flex flex-column mb-2 mr-2 w-100">
                                    <label for="fromamount" class="line-height-normal">{{ translations.dateFrom }}</label>
                                    <input type="date" class="form-control form-control-sm rounded" [(ngModel)]="searchModel.dateFrom"
                                        name="dateFrom" [class.text-brown-40]="!searchModel.dateFrom" />
                                </div>
                                <div class="form-group d-flex flex-column mb-2 mr-2 w-100">
                                    <label for="fromamount" class="line-height-normal">{{ translations.dateUntil }}</label>
                                    <input type="date" class="form-control form-control-sm rounded" [(ngModel)]="searchModel.dateUntil"
                                        name="dateUntil" [class.text-brown-40]="!searchModel.dateUntil" />
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center mb-2">
                            <ng-container *ngIf="(payments.regions$ | async)?.length > 1 && (payments.regions$ | async) as regions">
                                <app-checkbox [label]="translations.regions[region]" [id]="'region-' + region"
                                    [name]="'region-' + region" *ngFor="let region of regions"
                                    [(ngModel)]="searchModel.regions[region]" class="mx-3 form-group-no-margin">
                                </app-checkbox>
                            </ng-container>
                            <button type="button" class="btn btn-outline-dark ml-auto text-medium" (click)="clearSearch()">
                                {{ translations.clear }}
                            </button>
                            <button type="submit" class="btn btn-primary ml-2 text-medium">
                                {{ translations.lookup }}
                            </button>
                        </div>
                    </form>
                </app-smooth-height>
            </div>

            <ng-template #loading>
                <div class="d-flex justify-content-center align-items-center my-5 py-5">
                    <div class="spinner-grow text-primary mr-4" role="status">
                        <span class="sr-only">{{ translations.loadingPayments }}</span>
                    </div>
                    <div>{{ translations.loadingPayments }}</div>
                </div>
            </ng-template>

            <div *ngIf="payments.payments$ | async as page; else loading">
                <!-- Payments -->
                <div class="mb-3">
                    <div class="rounded overflow-hidden">
                        <app-portal-payments-list [payments]="page" (selected)="onSelect($event)" [active]="selected" [darkSeparator]="true">
                        </app-portal-payments-list>
                    </div>

                    <div *ngIf="page.length === 0">{{ translations.noPaymentsFound }}</div>
                </div>

                <!-- Pagination -->
                <div class="d-flex flex-column flex-md-row align-items-center justify-content-md-between" *ngIf="page?.length !== 0">
                    <div class="text-muted text-medium ml-3">{{ translations.result }} {{ resultsCountBracket(page?.length) | async }}</div>
                    <nav aria-label="payment page navigation" *ngIf="trimmedPagination(payments.currentPage$ | async, payments.pageNumbers$ | async) as pageNumbers">
                        <ul class="pagination list-unstyled d-flex align-items-center" *ngIf="pageNumbers.length">
                            <!-- Previous page -->
                            <li class="page-item" [class.disabled]="(payments.currentPage$ | async) === 1">
                                <button class="btn btn-link text-decoration-none btn-outline-none" (click)="payments.prevPage()">
                                    <app-icon-chevron [direction]="chevronLeft" [color]="chevronColorBrown"></app-icon-chevron>
                                </button>
                            </li>

                            <!-- Numbered pagination -->
                            <li class="page-item" [class.active]="pageNumber == (payments.currentPage$ | async)"
                                *ngFor="let pageNumber of pageNumbers">
                                <button class="btn btn-link text-decoration-none btn-outline-none" [ngClass]="pageNumber == (payments.currentPage$ | async) ? 'text-dark' : 'text-brown-40'"
                                    [disabled]="!pageNumber" (click)="pageNumber && payments.setPageNumber(pageNumber)">
                                {{ pageNumber ? pageNumber : '...' }}
                            </button>
                            </li>

                            <!-- Next page -->
                            <li class="page-item"
                                [class.disabled]="(payments.currentPage$ | async) >= (payments.totalPages$ | async)">
                                <button class="btn btn-link text-decoration-none btn-outline-none" (click)="payments.nextPage()">
                                    <app-icon-chevron [direction]="chevronRight" [color]="chevronColorBrown"></app-icon-chevron>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

        </div>
    </div>

    <app-sidepanel [open]="showPanel">
        <app-portal-payment-detail [payment]="selected" (previous)="onPrevious()" (next)="onNext()" (close)="onClose()" [hasNext]="!!(hasNext() | async)" [hasPrev]="!!(hasPrevious() | async)"
            *ngIf="selected && !selected.isRegularisation"></app-portal-payment-detail>
    </app-sidepanel>
</div>
