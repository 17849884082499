import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChildBenefitsFile } from 'src/app/core/files/models/child-benefits-file.model';
import { ChildBenefitsState } from 'src/app/core/files/models/child-benefits-state.model';
import { Document } from '../models/document';
import { DocumentDetailLabelsViewModel } from '../viewmodels/portal-documents-document-detail-labels.viewmodel';
import { DocumentViewModel } from '../viewmodels/portal-documents-document.viewmodel';
import { PortalDocumentsViewModel } from './../viewmodels/portal-documents.viewmodel';

@Injectable()
export class PortalDocumentsMapper {
    private translationKey = 'Infino.Portal.Documents.';

    constructor(private translateService: TranslateService) {
    }

    public mapEmpty(): PortalDocumentsViewModel {
        const viewmodel = new PortalDocumentsViewModel({
            myDocumentsTitle: this.translate('MyDocuments.Label'),
            documentsLabel: this.translate('Documents.Label'),
            searchTitle: this.translate('Search.Label'),
            filesLabel: this.translate('Files.Label'),
            filesPlaceholderLabel: this.translate('Files.Placeholder.Label'),
            statusLabel: this.translate('Status.Label'),
            statusPlaceholderLabel: this.translate('Status.Placeholder.Label'),
            fromLabel: this.translate('From.Label'),
            toLabel: this.translate('To.Label'),
            clearButton: this.translate('Clear.Label'),
            searchButton: this.translate('SearchButton.Label'),

            documentDetailLabels: new DocumentDetailLabelsViewModel({
                filesLabel: this.translate('Details.Files'),
                statusLabel: this.translate('Details.Status'),
                dateSentLabel: this.translate('Details.DateSent'),
                periodFromLabel: this.translate('Details.Period.From'),
                periodToLabel: this.translate('Details.Period.To'),
            }),

            files: [
                {
                    label: this.translate('Files.Any.Label'),
                    value: 'any',
                    key: 'Request',
                }
            ],

            statuses: [
                {
                    label: this.translate('Status.Any.Label'),
                    value: 'any',
                    key: 'AnyStatus',
                },
                {
                    label: this.translate('Status.O.Label'),
                    value: 'O',
                    key: 'StatusO', // verstuurd en geen antwoord nodig
                },
                {
                    label: this.translate('Status.A.Label'),
                    value: 'A',
                    key: 'Request', // verstuurd en wachten op antwoord
                }
            ],

            form: {
                from: '',
                to: '',
                fileId: 'any',
                statusId: 'any'
            },

            documents: [],
            filteredDocuments: [],
            selectedDocument: null,
        });

        return viewmodel;
    }

    public map(state: ChildBenefitsState, documents: Document[]): PortalDocumentsViewModel {
        const viewmodel = this.mapEmpty();
        viewmodel.isLoaded = true;

        if (!state.loading) {
            // We must ADD to the existing array (which contains the 'any' option)
            state.files.forEach(file => {
                viewmodel.files.push({
                    label: `${this.translateService.instant(`Infino.Portal.Regions.${file.region}.Label`)} - ${this.children(file)}`,
                    value: file.fileId,
                    key: file.fileId,
                });
            });

            viewmodel.documents = documents.filter(document => {
                return state.files.find(f => f.fileId === document.fileId) !== undefined;
            }).map(document => {
                const file = state.files.find(f => f.fileId === document.fileId);
                return this.mapDocument(document, file);
            });

            viewmodel.applyFilters();
        }

        return viewmodel;
    }

    mapDocument(document: Document, file: ChildBenefitsFile): DocumentViewModel {
        const model = new DocumentViewModel({
            documentId: document.documentId,
            documentMimeType: document.mimeType,
            fileId: file?.fileId,
            dateSent: document.dateSent,
            periodFrom: document.periodStart,
            periodTo: document.periodEnd,
            documentType: document.docTypeDescription,
            statusId: document.documentStatus,
            status: this.translate(`Status.${document.documentStatus}.Label`),
            children: '',
            beneficiaries: ''
        });

        if (file !== undefined) {
            model.region = file.region;
            model.regionLabel = this.translateService.instant(`Infino.Portal.Regions.${file.region}.Label`);
            model.children = this.children(file);
            model.beneficiaries = this.beneficiaries(file);
        }

        return model;
    }

    private children(file: ChildBenefitsFile): string {
        return file?.children
            .sort((a, b) => new Date(a.dateOfBirth).getTime() - new Date(b.dateOfBirth).getTime())
            .map(child => child.firstname).join(', ');
    }

    private beneficiaries(file: ChildBenefitsFile): string {
        return file?.beneficiaries
            .map(beneficiary => `${beneficiary.firstname} ${beneficiary.lastname}`).join(', ');
    }

    private translate(key: string): string {
        return this.translateService.instant(this.translationKey + key);
    }
}
