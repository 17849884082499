import { PortalBurgerProfielService } from './portal-burgerprofiel.service';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AuthenticationState } from './portal-authentication.model';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PortalApiService } from './portal-api.service';
import { CsamLoginResponse, SignInResult } from './portal-api.service.models';
import { Token, TokenIssuer, TokenStorageService } from '../../services/token-storage/token-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PortalAuthenticationService implements OnDestroy {
  private authenticationState: Subject<AuthenticationState> = new Subject();
  public readonly authState: Observable<AuthenticationState> = this.authenticationState;

  public myId = Math.random();

  constructor(
    private api: PortalApiService,
    private tokenStorage: TokenStorageService,
    private localize: LocalizeRouterService,
    private router: Router,
    private burgerprofiel: PortalBurgerProfielService
  ) {
    this.handleSignin = this.handleSignin.bind(this);
    this.updateCookie();
    if (!this.authenticated()) {
      this.tokenStorage.clearTokens();
    }
  }

  login(email: string, password: string): Observable<CsamLoginResponse> {
    return this.api.login(email, password).pipe(tap(this.handleSignin));
  }

  loginBackoffice(email: string, password: string, socialSecurityNumber: string): Observable<CsamLoginResponse> {
    return this.api.loginBackoffice(email, password, socialSecurityNumber).pipe(tap(this.handleSignin));
  }

  loginCsam(code: string): Observable<CsamLoginResponse> {
    return this.api.csamLogin(code).pipe(tap(this.handleSignin));
  }

  loginAcm(code: string): Observable<CsamLoginResponse> {
    return this.api.acmLogin(code).pipe(tap(this.handleSignin));
  }

  logout(): void {
    const idToken = this.token().id_token;
    const issuer = this.tokenIssuer();
    this.tokenStorage.clearTokens();
    this.endCsamSession(idToken, issuer);
    this.authenticationState.next(AuthenticationState.LoggedOut);
  }

  logoutAndRedirect() {
    let url = this.localize.translateRoute('/portal/auth');

    if (this.userIsBackoffice()) {
      url = this.localize.translateRoute('/portal/auth/backoffice');
    }

    if (this.authenticated()) {
      this.logout();
    }

    return this.router.navigate([url]);
  }

  endCsamSession(idToken: string, issuer: TokenIssuer) {
    // console.debug('endCsamSession', idToken, issuer)
    if (idToken) {
      if (issuer === TokenIssuer.ACM) {
        // setTimeout(() => {
        this.burgerprofiel.logout();
        // }, 1000)
      }
      if (issuer === TokenIssuer.CSAM) {
        this.api.csamLogoutUrl().subscribe(url => {
          if (url) {
            window.location.href = `${url}?id_token_hint=${idToken}`;
          }
        });
      }
    }
  }

  handleSignin(response: CsamLoginResponse, initial = true): void {
    if (response.signInResult.succeeded) {
      this.tokenStorage.setToken(response.token);
      if (initial) {
        this.tokenStorage.setRefreshToken(response.refreshToken);
        this.authenticationState.next(AuthenticationState.LoggedIn);
      } else {
        this.authenticationState.next(AuthenticationState.Refreshed);
      }
      this.updateCookie();
    }
  }

  refreshToken(): Observable<CsamLoginResponse> {
    return this.api.refreshToken(this.tokenStorage.getRefreshToken()).pipe(tap((response) => this.handleSignin(response, false)));
  }

  authenticated(): boolean {
    const token = this.token();
    if (!token) {
      return false;
    }

    return token.exp > new Date().getTime() / 1000;
  }

  userIsBackoffice(): boolean {
    return this.hasRole('Backoffice');
  }

  hasRole(role: string): boolean {
    const tokenRole = this.token()?.role;

    if (Array.isArray(tokenRole)) {
      return tokenRole.includes(role);
    }

    return tokenRole === role;
  }

  tokenIssuer(): TokenIssuer {
    return this.tokenStorage.parseTokenIssuer(this.token());
  }

  token(): Token {
    return this.tokenStorage.getTokenParsed();
  }

  rawToken(): string {
    return this.tokenStorage.getToken();
  }

  getRefreshToken(): Token {
    return this.tokenStorage.getRefreshTokenParsed();
  }

  ngOnDestroy(): void {
    this.authenticationState.complete();
  }

  private updateCookie() {
    if (!this.authenticated()) {
      return;
    }
    const cookieName = 'PortalInfo';
    const cookieValue = btoa(this.rawToken());
    const cookieExpDate = new Date(this.token().exp * 1000);
    const domain = environment.portalCookieDomain;
    document.cookie = `${cookieName}=${cookieValue};expires=${cookieExpDate};domain=${domain};path=/;secure`;
  }
}
