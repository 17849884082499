import { take, shareReplay, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SeparApiService } from './separ-files-api.service';
import { SeparFile } from '../models/separ-file.model';
import { SeparStateStore } from '../data/separ-state.store';
import { RegionEnum } from '../../payments/models/region-enum';

@Injectable({
    providedIn: 'root'
})
export class SeparStateService {
    constructor(
        private apiService: SeparApiService,
        private store: SeparStateStore) { }

    public initialize(): Observable<SeparFile[]> {
        this.store.resetState();
        const files$ = this.apiService.getFiles()
            .pipe(retry(3), take(1), shareReplay(1));

        files$.subscribe(files => {
            this.store.update({
                loading: false,
                files,
            });

            this.store.select(files.length > 0 ? files.find(file => file.region === RegionEnum.Flanders)?.fileId : undefined, undefined);
        });

        return files$;
    }
}
