import { TranslateService } from '@ngx-translate/core';

export function pluralizedTranslation(translateService: TranslateService, label: string) {
    return function translate(amount: number): string {
        if (amount === 0) {
            return translateService.instant(label + '.None');
        }
        if (amount === 1) {
            return translateService.instant(label + '.Singular');
        }
        return translateService.instant(label + '.Plural');
    };
}
