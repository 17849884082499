export class PortalAuthenticationModel {
    public overviewTitle: string;
    public overviewDescription: string;
    public authenticationCSAMTitle: string;
    public authenticationCSAMDescription: string;
    public authenticationCSAMIcon: string;
    public authenticationCSAMButtonText: string;
    public authenticationLoginTitle: string;
    public authenticationLoginDescription: string;
    public authenticationLoginIcon: string;
    public authenticationLoginButtonText: string;
    public notFoundTransferDescription: string;
    public notFoundTransferButton: string;

    constructor(partial?: Partial<PortalAuthenticationModel>) {
        Object.assign(this, partial);
    }
}
