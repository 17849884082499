import { Component, Input } from '@angular/core';
import { IconColorEnum } from '../enums/icon-color.enum';

@Component({
  selector: 'app-icon-grid',
  templateUrl: './grid.component.html',
})
export class IconGridComponent {
  @Input() color: IconColorEnum;

  public get classes(): string {
    return `${this.color}`;
  }
}
