import { RegionEnum } from 'src/app/core/payments/models/region-enum';

export class DocumentViewModel {

    public region: RegionEnum;
    public regionLabel: string;
    public documentId: number;
    public documentMimeType: string;
    public dateSent: string;
    public periodFrom: string;
    public periodTo: string;
    public documentType: string;
    public statusId: string;
    public status: string;
    public children: string;
    public beneficiaries: string;
    public fileId: string;

    constructor(init?: Partial<DocumentViewModel>) {
        Object.assign(this, init);
    }
}
