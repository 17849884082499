import { SeparChildViewModel } from './separ-child.viewmodel';

export class SeparSchoolYearDetailsViewModel {
  public children: SeparChildViewModel[];
  public familyIncomeCompleted: boolean;
  public incomeLimit: string;
  public realEstateIncomeCheck: string;
  public schoolYear: string;
  public separCalculationBlocked: boolean;

  constructor(init?: Partial<SeparSchoolYearDetailsViewModel>) {
    Object.assign(this, init);
  }

  public incomeLimitValid(): boolean {
    return this.incomeLimit === 'BELOW_LOWER_THRESHOLD' || this.incomeLimit === 'BETWEEN_THRESHOLDS';
  }

  public realEstateValid(): boolean {
    return this.realEstateIncomeCheck === 'REAL_ESTATE_INCOME_BELOW_THRESHOLD' || this.realEstateIncomeCheck === 'NOT_APPLICABLE';
  }
}
