import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChildBenefitsStateStore } from './../../../../core/files/data/child-benefits-state.store';
import { IconColorEnum } from 'src/app/shared/components/icons/enums/icon-color.enum';
import { IconDirectionEnum } from 'src/app/shared/components/icons/enums/icon-direction.enum';
import { QuickLinksMapper } from './quick-links.mapper';
import { QuickLinksViewModel } from './quick-links.viewmodel';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
})
export class QuickLinksComponent implements OnInit {
  public model$: Observable<QuickLinksViewModel>;
  public chevronDirection = IconDirectionEnum.Right;
  public chevronColor = IconColorEnum.Blue40;

  constructor(private mapper: QuickLinksMapper, private store: ChildBenefitsStateStore) {}

  ngOnInit(): void {
    this.model$ = this.store.state$.pipe(map(state => this.mapper.map(state?.files)));
  }
}
