import { Component, Input } from '@angular/core';
import { IconColorEnum } from '../enums/icon-color.enum';

@Component({
  selector: 'app-icon-folder',
  templateUrl: './folder.component.html',
})
export class IconFolderComponent {
  @Input() color: IconColorEnum;

  public get classes(): string {
    return `${this.color}`;
  }
}
