<div *ngIf="isLoaded">
    <div class="rounded overflow-hidden" >
        <div *ngFor="let child of model.children; let first = first;">
            <div class="px-4 py-2 d-flex align-items-center cursor-pointer" style="margin-bottom: 1px;" (click)="selectChild(child)"
                [ngClass]="[((isActive(child) && !child.hasErrors) ? 'bg-blue-10 text-secondary border-bottom border-bottom-blue-40' 
                                                                   : ((isActive(child) && child.hasErrors) ? 'bg-yellow border-bottom border-bottom-ecru-dark' 
                                                                                                           : (child.hasErrors ? 'bg-yellow-25' : 'bg-white'))), 
                            ((isActive(child) && !child.hasErrors && !first) ? ' border-top border-top-blue-40' 
                                                                           : ((isActive(child) && child.hasErrors && !first) ? ' border-top border-top-ecru-dark' : ''))]">
                <div class="d-flex flex-grow-1">
                    <app-icon-alert-triangle class="mr-3" *ngIf="child.hasErrors"></app-icon-alert-triangle>
                    <div>
                        <div class="font-weight-semi-bold">
                            {{ child.firstname }} {{ child.lastname }}
                        </div>
                        <div>
                            {{ child.socialSecurityNumber | socialSecurityNumberFormat }}
                        </div>
                    </div>
                </div>
                <div [ngClass]="{'text-danger': child.hasErrors, 'text-green': !child.hasErrors}" *ngIf="child.getAge() || child.getAge() == 0">
                    {{ child.getAge() }} {{ yearLabel(child.getAge()) }}
                </div>
                <app-icon-chevron [direction]="iconDirectionRight"
                    [color]="isActive(child) && !child.hasErrors ? iconColorBlue 
                                                                 : (isActive(child) && child.hasErrors ? iconColorBlack : iconColorBrown)"></app-icon-chevron>
            </div>
        </div>
        <div *ngIf="!model.children || !model.children.length">
            {{ 'Infino.Portal.File.ThereAreNoChildren' | translate }}
        </div>
    </div>
</div>