export class PortalNotification {
    public readonly id: string;
    public message: string;
    public delay: number;
    public classname: string;

    constructor(init?: Partial<PortalNotification>) {
        Object.assign(this, init);

        this.id = Math.random().toString(36);
    }
}
