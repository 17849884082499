<div (click)="selected.emit(null)" class="bg-white d-flex align-items-center cursor-pointer pr-4"
    (mouseenter)="hover(true)" (mouseleave)="hover(false)"
    [class.bg-white]="!isActive()" [class.bg-blue-10]="isActive()" [class.text-secondary]="isActive()"
    [class.border-bottom]="payment.isRegularisation && isActive()"
    [class.border-bottom-blue-40]="payment.isRegularisation && isActive()"
    >
    <!-- Small Date -->
    <div class="p-2 d-flex flex-column align-items-center rounded">
        <div class="bg-ecru d-flex flex-column justify-content-center align-items-center"
            [ngClass]="isActive() ? 'bg-white' : 'bg-ecru'"
            style="width: 40px; height: 40px;">
            <span class="text-small line-height-normal">{{ payment.paymentDate | localizedDate: 'd'}}</span>
            <span class="text-small line-height-normal text-uppercase">{{ payment.paymentDate | localizedDate: 'MMM' }}</span>
        </div>
        <span class="text-small line-height-normal">{{ payment.paymentDate | localizedDate: 'y'}}</span>
    </div>

    <!-- Reason, Account number, Region -->
    <div class="d-flex flex-column py-md-0 py-2 flex-md-row flex-grow-1 align-items-flex-start align-items-md-center">
        <div class="order-1 order-md-0 d-flex flex-column justify-content-center ml-2 ml-md-3 mr-auto">
            <div class="d-flex flex-column flex-md-row line-height-normal border-bottom border-3 border-transparent">
                <span class="font-weight-semi-bold text-capitalize">
                    {{ getPaymentType() }}
                </span>
                <span *ngIf="!payment.isRegularisation" class="mb-1 mb-md-0">
                    <span class="d-none d-md-inline">&nbsp;-</span>
                    {{ getDate(payment.year, payment.month) | localizedDate: 'MMMM y' }}
                </span>
            </div>
            <small class="line-height-normal" [class.text-muted]="!isActive()" [class.opacity-60]="isActive()">
                {{ getPaymentMethod() }}
            </small>
        </div>
        <div class="ml-2 ml-md-0 mb-0 mb-md-0 order-0 order-md-1 text-overline" [class.text-muted]="!isActive()" [class.opacity-60]="isActive()">
            {{ "Infino.Portal.Regions." + payment.region + ".Label" | translate }}
        </div>
    </div>

    <!-- Amount -->
    <div class="d-flex justify-content-center flex-grow-1 mw-small text-larger text-transform-bold d-flex align-items-center"
        [class.text-danger]='getPaymentAmount() < 0' [class.text-green]='getPaymentAmount() >= 0'>
        {{ getPaymentAmount() }}
        <span class="text-overline text-transform-normal pl-1" [class.text-muted]="!isActive()"
            [class.opacity-60]="isActive()">EUR</span>
    </div>

    <!-- Action -->
    <app-icon-chevron [color]="(hovering || isActive()) ? iconColorBlue : iconColorBrown" [direction]="getChevronDirection()"></app-icon-chevron>
</div>
<app-smooth-height [trigger]="isActive()">
    <div *ngIf="isActive()" class="bg-white-60-alpha mx-2 pl-5 pr-4">
        <div *ngFor="let p of payment.payments; let last = last" class="ml-3 py-3 d-flex border-ecru-dark cursor-pointer" (click)="selected.emit(p)"
            [class.border-bottom]="!last">
            <div class="text-medium mr-auto">
                <b>{{ getPaymentType(p) }}</b> - {{ getDate(p.year, p.month) | localizedDate: 'MMMM y' }}
            </div>
            <div class="d-flex justify-content-center flex-grow-1 mw-small text-transform-bold d-flex align-items-center"
                [class.text-danger]='getPaymentAmount(p) < 0' [class.text-green]='getPaymentAmount(p) >= 0'>
                {{ getPaymentAmount(p) }}
                <span class="text-overline text-transform-normal pl-1 text-muted">EUR</span>
            </div>
            <app-icon-chevron [color]="isActive(p) ? iconColorBlue : iconColorBrown" [direction]="iconDirectionRight"></app-icon-chevron>
        </div>
    </div>
</app-smooth-height>
