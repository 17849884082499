<div (click)="selected.emit(null)" class="bg-white d-flex align-items-center cursor-pointer pr-4"
    (mouseenter)="hover(true)" (mouseleave)="hover(false)"
    [class.bg-white]="!isActive()" [class.bg-blue-10]="isActive()" [class.text-secondary]="isActive()"
    [class.border-bottom]="document && isActive()"
    [class.border-bottom-blue-40]="isActive()"
    >
    <!-- Small Date -->
    <div class="p-2 d-flex flex-column align-items-center rounded">
        <div class="bg-ecru d-flex flex-column justify-content-center align-items-center"
            [ngClass]="isActive() ? 'bg-white' : 'bg-ecru'"
            style="width: 40px; height: 40px;">
            <span class="text-small line-height-normal">{{ document.dateSent | localizedDate: 'd'}}</span>
            <span class="text-small line-height-normal text-uppercase">{{ document.dateSent | localizedDate: 'MMM' }}</span>
        </div>
        <span class="text-small line-height-normal">{{ document.dateSent | localizedDate: 'y'}}</span>
    </div>

    <!-- Document + children -->
    <div class="d-flex flex-column py-md-0 py-2 flex-md-row flex-grow-1 align-items-flex-start align-items-md-center">
        <div class="order-1 order-md-0 d-flex flex-column justify-content-center ml-2 ml-md-3 mr-auto">
            <div class="d-flex flex-column flex-md-row line-height-normal border-bottom border-3 border-transparent">
                {{ document.documentType }}
            </div>
            <!-- <small class="line-height-normal" [class.text-muted]="!isActive()" [class.opacity-60]="isActive()"> -->
            <small class="line-height-normal" [class.opacity-60]="isActive()">
                {{ document.beneficiaries }}
            </small>
        </div>
        <div class="ml-2 mr-5 ml-md-0 mb-0 mb-md-0 order-0 order-md-1 text-overline" [class.text-muted]="!isActive()" [class.opacity-60]="isActive()">
            {{ document.regionLabel }}
        </div>
    </div>

    <div class="d-flex document-actions">
        <div class="justify-content-center mw-small mr-2 text-larger text-transform-bold align-items-center">
            <button class="btn btn-text" 
                (click)="onClickDownload($event)" (mouseenter)="hoverDownload(true)" (mouseleave)="hoverDownload(false)">
                <app-icon-download [color]="hoveringDownload ? iconColorBlue : iconColorBrown"></app-icon-download>
            </button>
        </div>
    <!-- Action -->
    <app-icon-chevron [color]="(isActive() || (hovering && !hoveringDownload)) ? iconColorBlue : iconColorBrown" [direction]="chevronDirection"></app-icon-chevron>
</div>
