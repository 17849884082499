
<div class="custom-control custom-radio my-2">
    <input class="custom-control-input"
        type="radio"
        [id]="id"
        [ngModel]="value"
        [ngModelOptions]="{standalone: true}"
        [value]="radioValue"
        [name]="name"
        (ngModelChange)="valueChanged($event)"/>
    <label class="custom-control-label font-weight-normal" [for]="id" [ngClass]="labelClass">{{label}}</label>
</div>

<!-- <div className="custom-control custom-radio">
    <input type="radio" id={gender} name="gender" className="custom-control-input" />
    <label className="custom-control-label" htmlFor={gender}>{gender}</label>
</div> -->
