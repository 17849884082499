import { ChildBenefitsCertificate } from './models/child-benefits-certificate.model';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';

@Injectable()
export class CertificateApiService {
  constructor(private http: HttpClient) { }

  public getAll(fromDate: Date, toDate: Date) {
    return this.http.get<ChildBenefitsCertificate[]>(`${environment.portalApiUrl}/certificates/all`, {
      params: {
        fromDate: dayjs(fromDate).format('YYYY-MM-DD'),
        toDate: dayjs(toDate).format('YYYY-MM-DD'),
      }
    });
  }
}

