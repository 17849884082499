import { Injectable } from '@angular/core';
import { ChildBenefitsChild } from '../models/child-benefits-child.model';


@Injectable()
export class ChildBenefitsChildMapper {

    public map(data: any): ChildBenefitsChild {
        return new ChildBenefitsChild({
            firstname: data.firstname,
            lastname: data.lastname,
            socialSecurityNumber: data.socialSecurityNumber,
            dateOfBirth: data.dateOfBirth,
            allowances: data.allowances?.map(({ name, amount }) => ({ name, amount })),
            childPoints: data.childPoints,
            childStatus: data.childStatus,
            childBasicAllowance: data.childBasicAllowance,
        });
    }
}
