<div class="container" *ngIf="showAlerts"> 
    <div class="row my-0 my-md-4 pt-4 pb-2 d-flex justify-content-between align-items-center">
        <div class="col-sm-12" *ngFor="let alert of model.alerts">
            <div *ngIf="alert.show" class="alert alert-dismissable alert-danger">
                <button type="button" class="alert__close-button" (click)="hideAlert(alert)"> </button>
                <div class="alert__text alert__text--icon">
                        <img src="assets/svg/reddingsboei.svg" class="alert__main-icon" />
                        <p innerHtml="{{ alert.message }}"></p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="d-flex h-100">
    <div class="flex-grow-1">
        <div class="d-flex flex-column h-100">
            <div class="bg-yellow">
                <div class="container">
                    <div class="row my-0 my-md-4 pt-4 pb-2 d-flex justify-content-between align-items-center">
                        <div class="col-12 col-md-7 order-1 order-md-0 mt-4 mt-md-0">
                            <div class="h3 mb-3 mb-md-4 font-weight-semi-bold">
                                {{getGreeting()}}, {{model.firstname}}!
                            </div>
                            <div>
                                {{model.description}}
                            </div>
                        </div>
                        <div
                            class="d-flex order-0 order-md-1 col-12 col-md-5 justify-content-between justify-content-md-center">
                            <img src="/assets/svg/startbedrag-big.svg"/>
                            <button class="btn btn-outline-dark align-self-start d-flex align-items-center d-md-none" (click)="logout()">
                                {{ 'Infino.Portal.Logout' | translate }}
                                <img class="ml-2" src="assets/svg/icon-box-arrow-in-right-brown.svg"/>
                            </button>
                        </div>
                    </div>
                </div>
                <app-portal-future-payments (selected)="onSelect($event)" [active]="selected"></app-portal-future-payments>
            </div>
            <div class="slope-bottom"></div>
            <div class="container mt-5">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="text-overline text-uppercase">{{model.recentPaymentsLabel}}</div>
                    <a [routerLink]="getAllPaymentsLink()"
                        class="btn btn-outline-dark ml-auto d-flex justify-content-between align-items-center text-medium font-weight-semi-bold">
                        <span class="mr-2">{{model.allPaymentsButton}}</span>
                        <app-icon-chevron [color]="iconColorBrown" [direction]="iconDirectionRight"></app-icon-chevron>
                    </a>
                </div>
                <!-- Payments -->
                <div *ngIf="payments.payments$ | async as payments; else loading" class="my-3">
                    <div class="rounded overflow-hidden">
                        <app-portal-payments-list [payments]="payments" (selected)="onSelect($event)"
                            [active]="selected" [darkSeparator]="true"></app-portal-payments-list>
                    </div>

                    <div *ngIf="payments.length === 0">{{ model.noPaymentsFound }}</div>
                </div>

                <ng-template #loading>Loading...</ng-template>
            </div>
            <app-quick-links class="mt-auto"></app-quick-links>
        </div>
    </div>
    <app-sidepanel [open]="showPanel">
        <app-portal-payment-detail [payment]="selected" *ngIf="selected && !selected.isRegularisation" (close)="onClose()"></app-portal-payment-detail>
    </app-sidepanel>
</div>
