import { Component, Input, OnInit } from '@angular/core';
import { FileViewModel } from '../../viewmodels/portal-files-file.viewmodel';

@Component({
  selector: 'app-mediator-overview',
  templateUrl: './mediator-overview.component.html'
})
export class MediatorOverviewComponent {
  @Input() public file: FileViewModel;

  public get isLoaded(): boolean {
    return this.file != null;
  }
}
