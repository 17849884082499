<div *ngIf="show" [class.loading-white]="loading" [ngClass]="horizontal ? 'd-block d-md-flex align-items-md-center' : ''">
    <div class="mb-3 " *ngIf="!horizontal"><span class="line-height-normal font-weight-semi-bold">{{ fullname }}</span></div>
    <div class="text-medium mr-auto font-weight-semi-bold" *ngIf="horizontal">{{ nameHelpsKids() }}</div>
    <div class="text-medium" [ngClass]="horizontal ? 'd-block d-md-flex py-2 py-md-0' : 'py-2'">
        <div class="d-flex" [class.ml-3]="horizontal" *ngIf="phone">
            <app-icon-phone [color]="chevronColor" class="mr-2"></app-icon-phone>
            <a [href]="'tel:' + phone">
                <span>{{ phone }}</span>
            </a>
        </div>
        <div class="d-flex" [class.ml-3]="horizontal">
            <app-icon-email [color]="chevronColor" class="mr-2"></app-icon-email>
            <a [routerLink]="'/portal/help' | localize" (click)="setActiveFile()">
                <span class="line-height-normal">{{ translations.askQuestionAboutYourFile }}</span>
            </a>
        </div>
        <div class="d-flex" [class.ml-3]="horizontal">
            <app-icon-document [color]="chevronColor" class="mr-2"></app-icon-document>
            <a [routerLink]="'/portal/dossier' | localize" (click)="setActiveFile()">
                <span class="line-height-normal">{{ translations.checkoutFile }}</span>
            </a>
        </div>
    </div>
</div>