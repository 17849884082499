import { IValidator } from '../validator.interface';
import { ValidationError } from '../validation-error.enum';
import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Validates if the input is not empty and checks if the string is an email address
 */
export class EmailValidator implements IValidator {

    private emailValid: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    public validate(value: string): string {
        if (value === undefined || value === null || value.length === 0 || typeof value === 'object') {
            return undefined;
        }

        const matches = value.match(this.emailValid);

        if (matches && matches.length) {
            return undefined;
        }

        return ValidationError.Email;
    }
}

export const ReactiveEmailValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const result = new EmailValidator().validate(control.value);
    return result ? { email: result } : null;
};
