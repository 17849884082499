export class DocumentDetailLabelsViewModel {

    public filesLabel: string;
    public statusLabel: string;
    public dateSentLabel: string;
    public periodFromLabel: string;
    public periodToLabel: string;

    constructor(init?: Partial<DocumentDetailLabelsViewModel>) {
        Object.assign(this, init);
    }
}
