import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationConfiguration } from 'src/app/core/configuration/models/authentication-configuration.model';
import { PortalForgotPasswordModel } from './portal-forgot-password.model';

@Injectable()
export class PortalForgotPasswordMapper {

    constructor(
    ) { }

    public map(configuration: AuthenticationConfiguration): PortalForgotPasswordModel {
        return new PortalForgotPasswordModel({
            resetPasswordTitle: configuration.resetPasswordTitle,
            resetPasswordButton: configuration.resetPasswordButton,
            resetPasswordSuccessMessage: configuration.resetPasswordSuccessMessage,
            resetPasswordReturnToLoginButton: configuration.resetPasswordReturnToLoginButton,
            authenticateWithLoginEmailLabel: configuration.authenticateWithLoginEmailLabel,
            authenticateWithLoginEmailPlaceholder: configuration.authenticateWithLoginEmailPlaceholder,
            authenticateWithLoginNewAccountPageUrl: configuration.authenticateWithLoginNewAccountPageUrl,
        });
    }
}
