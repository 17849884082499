import { CookieBannerConfiguration } from './../models/CookieBannerConfiguration';
import { CookieCategory } from './../models/CookieCategory';

export class CookieBannerMapper {
  public static map(data: any): CookieBannerConfiguration {
    const configuration: CookieBannerConfiguration = new CookieBannerConfiguration();

    configuration.title = data.Title;
    configuration.acceptButtonText = data.AcceptButtonText;
    configuration.otherSettingsButtonText = data.OtherSettingsButtonText;
    configuration.additionalInformation = data.AdditionalInformation;
    configuration.cookieAndPrivacyInformation = data.CookieAndPrivacyInformation;
    configuration.acceptOtherSettingsButtonText = data.AcceptOtherSettingsButtonText;
    configuration.acceptCategoryButtonText = data.AcceptCategoryButtonText;
    configuration.declineCategoryButtonText = data.DeclineCategoryButtonText;

    configuration.cookieCategories = data.Categories
      .map((category: CookieCategory) => {
        return this.mapCookieCategory(category);
      });

    return configuration;
  }

  public static mapCookieCategory(data: any): CookieCategory {
    const category: CookieCategory = new CookieCategory();
    category.title = data.Title;
    category.description = data.Description;
    category.cookieName = data.CookieName;
    category.isRequired = data.IsRequired;
    // Only categories that are required have are 'consent' by default, others don't
    category.hasConcent = data.IsRequired;
    return category;
  }
}
