import { Component, Input } from '@angular/core';
import { CookieCategory } from '../models/CookieCategory';

@Component({
    selector: 'app-cookie-category',
    templateUrl: './cookie-category.component.html',
})
export class CookieCategoryComponent  {
  @Input() public cookieCategory: CookieCategory;
}
