import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailPaineHeaderComponent } from './detail-pane-header.component';
import { IconsModule } from '../../../../shared/components/icons/icons.module';

@NgModule({
  declarations: [
    DetailPaineHeaderComponent,
  ],
  imports: [
    CommonModule,
    IconsModule
  ],
  exports: [
    DetailPaineHeaderComponent
  ]
})
export class DetailPaneModule { }
