import { Injectable } from '@angular/core';
import Cookies from 'universal-cookie';

import { UserStore } from '../data/user.store';
import { RegionMapper } from '../mappers/region.mapper';
import { RegionEnum } from '../../payments/models/region-enum';

@Injectable()
export class UserService {
    constructor(
        private userStore: UserStore,
        private regionMapper: RegionMapper
    ) { }

    public initializeUser(regionCode: string, partner: string): void {
        const cookies = new Cookies();
        const user = this.userStore.getState();

        if (regionCode) {
            const region = this.regionMapper.map(regionCode);
            cookies.set('region', region, { path: '/', maxAge: 31556926, secure: true }); // MaxAge => Valid for 1 year
            user.region = region;
        }

        if (partner) {
            cookies.set('partner', partner, { path: '/', maxAge: 31556926, secure: true }); // MaxAge => Valid for 1 year
            user.partner = partner;
        }
        this.userStore.update(user);
    }

    public getRegion(): RegionEnum {
        return this.userStore.getState().region ?? new Cookies().get('region') ?? '';
    }

    public setRegion(region: RegionEnum) {
        const cookies = new Cookies();
        const user = this.userStore.getState();

        cookies.set('region', region, { path: '/', maxAge: 31556926, secure: true }); // MaxAge => Valid for 1 year
        user.region = region;

        this.userStore.update(user);
    }

    public getPartner(): string {
        return this.userStore.getState().partner ?? new Cookies().get('partner');
    }
}
