<div id="infinoHeader" class="py-0 py-md-1 px-3 bg-white z-index-100 position-relative" [ngClass]="{ 'shadow-sm': showShadow }">
    <nav class="navbar navbar-light py-2 px-4 d-md-flex">
        <div class="navbar-brand d-flex">
            <a href="{{websiteUrl}}" class="d-flex">
                <img src="assets/svg/logo_infino_no_baseline.svg" alt="{{ 'Infino.ClientPortal.InfinoLogo.Alt' | translate }}" height="20">
            </a>
        </div>

        <!-- central content / links -->
        <ng-content></ng-content>

        <!-- Right content/actions - defaults to help message -->
        <div #ref>
            <ng-content select="[actions]"></ng-content>
        </div>

        <span
            *ngIf="ref.children.length == 0"
            class="navbar-text"
            innerHtml="{{ 'Infino.ClientPortal.Navigation.Help' | translate }}"></span>
    </nav>
</div>
