import { ChildBenefitsStateStore } from './../../../../core/files/data/child-benefits-state.store';
import { TranslateService } from '@ngx-translate/core';
import { ChildBenefitsFile } from 'src/app/core/files/models/child-benefits-file.model';
import { map, take } from 'rxjs/operators';
import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { capitalize } from 'src/app/shared/helpers/string.helper';

@Component({
    selector: 'app-client-advisor-cta',
    templateUrl: './client-advisor-cta.component.html',
})
export class ClientAdvisorCtaComponent implements OnInit, OnChanges {
    @Input() public fileId: string;
    public label: string;

    private files$: Observable<ChildBenefitsFile[]>;

    constructor(private store: ChildBenefitsStateStore, private translate: TranslateService) { }

    ngOnInit(): void {
        this.updateFileManager(this.fileId);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.fileId.currentValue !== changes.fileId.previousValue) {
            this.updateFileManager(changes.fileId.currentValue);
        }
    }

    updateFileManager(fileId: string) {
        this.label = undefined;
        if (!this.files$) {
            this.files$ = this.store.state$.pipe(map(state => state.files));
        }
        this.files$.pipe(
            map(files => files.find(file => file.fileId === this.fileId)),
            map(file => file.fileManagerFirstName),
            take(1)
        ).subscribe(name => {
            this.label = this.translate.instant('Infino.Portal.Help.ContactName', { name: capitalize(name.toLowerCase()) });
        });
    }

    setActiveFile() {
        this.store.select(this.fileId);
    }
}
