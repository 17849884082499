<app-file-tabs></app-file-tabs>
<div class="d-flex h-100">
    <div class="flex-grow-1" *ngIf="model$ | async as model">
        <div class="bg-yellow slope-bottom-inverted mb-4" *ngIf="model.isLoaded">
            <div class="container-lg p-4">
                <div class="d-flex justify-content-center pt-2 mb-4 my-1">
                    <h5>
                        {{model.translations.needHelp}}
                    </h5>
                </div>

                <div class="d-flex justify-content-between flex-column flex-lg-row">
                    <div class="w-100 mr-4 mt-2">
                        <div class="text-overline mb-4 text-uppercase text-center">{{ model.translations.whatYouCanDo }}
                        </div>
                        <div class="bg-white p-3 text-medium">
                            <a [href]="model.links.certificate"
                                class="text-dark p-2 border-blue-25 border-bottom d-flex align-items-center font-weight-bold">
                                {{ model.translations.generateCertificate }}
                                <app-icon-chevron class="ml-auto" [direction]="chevronDirection" [color]="chevronColor">
                                </app-icon-chevron>
                            </a>
                            <a [href]="model.links.faq"
                                class="text-dark p-2 d-flex align-items-center font-weight-bold">
                                {{ model.translations.checkoutFAQ }}
                                <app-icon-chevron class="ml-auto" [direction]="chevronDirection" [color]="chevronColor">
                                </app-icon-chevron>
                            </a>
                        </div>
                    </div>
                    <div class="w-100 mr-4 mt-2">
                        <div class="text-overline mb-4 text-uppercase text-center">{{ model.translations.yourFileManager
                            }}</div>
                        <div class="bg-white p-3">
                            <div class="text-capitalize pt-2 pb-3 border-blue-25 border-bottom font-weight-semi-bold">
                                {{ model.selectedFile.fileManager.firstname | lowercase}}
                                {{ model.selectedFile.fileManager.lastname | lowercase }}
                            </div>
                            <div class="py-2 my-1 text-medium font-weight-semi-bold">
                                <div class="d-flex" *ngIf="model.selectedFile.fileManager.phone">
                                    <app-icon-phone [color]="chevronColor" class="mr-2"></app-icon-phone>
                                    <a [href]="'tel:' + model.selectedFile.fileManager.phone" class="text-underline">{{
                                        model.selectedFile.fileManager.phone }}</a>
                                </div>
                                <div class="d-flex">
                                    <app-icon-email [color]="chevronColor" class="mr-2"></app-icon-email>
                                    <a (click)="scrollToElement(contactform)" class="text-secondary cursor-pointer">{{
                                        model.translations.askAQuestionAboutFile }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-100 mt-2">
                        <div class="text-overline mb-4 text-uppercase text-center">{{ model.translations.yourOffice }}
                        </div>
                        <div class="bg-white p-3">
                            <div class="border-blue-25 border-bottom">
                                <div class="text-capitalize pt-2 pb-3 font-weight-semi-bold">
                                    Infino <span class="text-capitalize">{{ model.selectedFile.fileManager.office.city |
                                        lowercase }}</span>
                                </div>
                                <div class="text-medium">
                                    {{ model.selectedFile.fileManager.office.street }}
                                </div>
                                <div class="text-medium mb-3">
                                    {{ model.selectedFile.fileManager.office.zipcode }}
                                    {{ model.selectedFile.fileManager.office.city }}
                                </div>
                                <div class="d-flex mb-4 text-medium font-weight-semi-bold">
                                    <app-icon-map [color]="chevronColor" class="mr-2"></app-icon-map>
                                    <a [href]="routeLink(model.selectedFile)" target="_blank">Route</a>
                                </div>
                            </div>
                            <div class="py-2 my-1 text-medium font-weight-semi-bold">
                                <div class="d-flex" *ngIf="model.selectedFile.fileManager.phone">
                                    <app-icon-phone [color]="chevronColor" class="mr-2"></app-icon-phone>
                                    <a [href]="'tel:' + model.selectedFile.fileManager.phone">{{
                                        model.selectedFile.fileManager.phone }}</a>
                                </div>
                                <div class="d-flex">
                                    <app-icon-email [color]="chevronColor" class="mr-2"></app-icon-email>
                                    <a [href]="'mailto:' + model.selectedFile.fileManager.email">{{
                                        model.translations.sendMail }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div #contactform class="container">
            <div class="pt-5 mb-5 mw-lg mx-auto" *ngIf="model.isLoaded">
                <div class="d-flex align-items-center pb-4 flex-column">
                    <div class="text-larger font-weight-semi-bold mt-2 mb-3 text-center">
                        {{model.translations.sendAMessageTitle}}
                    </div>
                    <p class="mb-3 text-medium text-center">
                        {{ model.translations.sendAMessageDescription }}
                    </p>
                    <div class="bg-white rounded w-100 p-5">
                        <form (submit)="onSubmit()" *ngIf="!messageSent">
                            <app-dropdown
                                [label]="model.translations.messageType"
                                [placeholder]="model.translations.messageTypePlaceholder"
                                [options]="model.contactFormTypes" name="form-type" id="form-type"
                                [(ngModel)]="formModel.type" [validationErrors]="validationErrors['type']"
                                (validate)="validateItem('type')" (resetValidate)="resetItemValidation('type')">
                            </app-dropdown>

                            <app-textarea-input
                                [label]="model.translations.message"
                                name="form-message"
                                id="form-message"
                                [placeholder]="model.translations.messagePlaceholder"
                                [(ngModel)]="formModel.message"
                                [validationErrors]="validationErrors['message']"
                                (validate)="validateItem('message')"
                                (resetValidate)="resetItemValidation('message')">
                            </app-textarea-input>

                            <app-text-input
                                *ngIf="!model.email"
                                [label]="model.translations.email"
                                name="form-email"
                                id="form-email"
                                [placeholder]="model.translations.emailPlaceholder"
                                [(ngModel)]="formModel.email"
                                [validationErrors]="validationErrors['email']"
                                (validate)="validateItem('email')"
                                (resetValidate)="resetItemValidation('email')">
                            </app-text-input>

                            <app-file-upload
                                [label]="model.translations.attachment"
                                name="form-attachment"
                                id="form-attachment"
                                [allowedFormatsLabel]="model.translations.allowedFormats"
                                [(ngModel)]="formModel.files"
                                (validate)="validateItem('files')"
                                (resetValidate)="resetItemValidation('files')"
                                [allowedExtensions]="allowedExtensions"
                                [filesTooLargeValidationMessage]="'Infino.ClientPortal.Validation.TooLarge' | translate"
                                [extensionNotAllowedValidationMessage]="'Infino.ClientPortal.Validation.ExtensionNotAllowed' | translate"
                                [labelAddFile]="model.translations.attachmentPlaceholderAddFile"
                                [labelDragFile]="model.translations.attachmentPlaceholderOrDragFile">
                            </app-file-upload>

                            <button type="submit" class="btn btn-primary w-100" [disabled]="formModel.submitted">{{ model.translations.send }}</button>
                            <small>{{ model.translations.sendDescription }}</small>
                        </form>

                        <div *ngIf="messageSent" class="d-flex flex-column align-items-center">
                            <div class="p-2">{{ model.translations.thankYouMessage }}</div>
                            <button class="btn text-primary" (click)="resetForm()">{{
                                model.translations.sendAnotherMessage }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
