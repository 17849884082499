import { CertificateApiService } from './certificate-api.service';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [],
  providers: [
    CertificateApiService,
  ]
})
export class CertificateModule { }
