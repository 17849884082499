import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { PortalConfigurationStore } from './../../../../core/configuration/data/portal-configuration.store';
import { ChildBenefitsState } from './../../../../core/files/models/child-benefits-state.model';
import { PortalFilesMapper } from './portal-files.mapper';
import { PortalHelpViewModel } from './../viewmodels/portal-help.viewmodel';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { FileViewModel } from '../viewmodels/portal-files-file.viewmodel';
import { capitalize } from 'src/app/shared/helpers/string.helper';
import { UserInfoModel } from 'src/app/core/portal-user/models/user-info.model';

@Injectable()
export class PortalHelpMapper {
    private translationKey = 'Infino.Portal.Help.';

    constructor(
        private translate: TranslateService,
        private mapper: PortalFilesMapper,
        private configStore: PortalConfigurationStore,
        private localize: LocalizeRouterService
    ) { }

    public mapEmpty(): PortalHelpViewModel {
        const viewModel = new PortalHelpViewModel({
            translations: this.mapTranslations(null),
            links: {
                faq: this.configStore.getState().faqPageUrl,
                certificate: this.localize.translateRoute(
                    '/portal/certificate'
                ),
            },
            contactFormTypes: [
                {
                    label: this.translate.instant(this.translationKey + 'Question.Request.Label'),
                    value: this.translate.instant(this.translationKey + 'Question.Request.Label'),
                    key: 'Request',
                },
                {
                    label: this.translate.instant(this.translationKey + 'Question.Surcharges.Label'),
                    value: this.translate.instant(this.translationKey + 'Question.Surcharges.Label'),
                    key: 'Surcharges',
                },
                {
                    label: this.translate.instant(this.translationKey + 'Question.Payment.Label'),
                    value: this.translate.instant(this.translationKey + 'Question.Payment.Label'),
                    key: 'Payment',
                },
                {
                    label: this.translate.instant(this.translationKey + 'Question.File.Label'),
                    value: this.translate.instant(this.translationKey + 'Question.File.Label'),
                    key: 'File',
                },
                {
                    label: this.translate.instant(this.translationKey + 'Question.Complaint.Label'),
                    value: this.translate.instant(this.translationKey + 'Question.Complaint.Label'),
                    key: 'Complaint',
                },
                {
                    label: this.translate.instant(this.translationKey + 'Question.Other.Label'),
                    value: this.translate.instant(this.translationKey + 'Question.Other.Label'),
                    key: 'Other',
                },
            ]
        });

        return viewModel;
    }

    public map(state: ChildBenefitsState, info: UserInfoModel): PortalHelpViewModel {
        const viewmodel = this.mapEmpty();
        viewmodel.isLoaded = !state.loading;
        if (viewmodel.isLoaded) {
            viewmodel.files = state.files.map(file => this.mapper.mapFile(file));
            if (state.selectedId) {
                viewmodel.selectedFile = this.mapper.mapFile(state.files.find(file => file.fileId === state.selectedId));
                viewmodel.translations = this.mapTranslations(viewmodel.selectedFile);
            }
        }
        if (info?.email) {
            viewmodel.email = info.email;
        }
        return viewmodel;
    }

    public mapTranslations(viewModel: FileViewModel) {
        const needHelp = this.translate.instant(this.translationKey + 'NeedHelp');

        return {
            needHelp: viewModel?.fileId ? `${needHelp} (${viewModel.fileId})?` : `${needHelp}?`,
            whatYouCanDo: this.translate.instant(this.translationKey + 'WhatYouCanDo'),
            yourFileManager: this.translate.instant(this.translationKey + 'YourFileManager'),
            yourOffice: this.translate.instant(this.translationKey + 'YourOffice'),
            generateCertificate: this.translate.instant(this.translationKey + 'GenerateCertificate'),
            checkoutFAQ: this.translate.instant(this.translationKey + 'CheckoutFAQ'),
            askAQuestionAboutFile: this.translate.instant(this.translationKey + 'AskAQuestionAboutFile'),
            directions: this.translate.instant(this.translationKey + 'Directions'),
            sendMail: this.translate.instant(this.translationKey + 'SendMail'),
            sendAMessageTitle: this.translate.instant(this.translationKey + 'SendAMessageTitle', {
                name: capitalize(viewModel?.fileManager?.firstname?.toLocaleLowerCase()) || ''
            }),
            sendAMessageDescription: this.translate.instant(this.translationKey + 'SendAMessageDescription'),
            message: this.translate.instant(this.translationKey + 'Message'),
            messagePlaceholder: this.translate.instant(this.translationKey + 'MessagePlaceholder'),
            messageType: this.translate.instant(this.translationKey + 'MessageType'),
            messageTypePlaceholder: this.translate.instant(this.translationKey + 'MessageTypePlaceholder'),
            attachment: this.translate.instant(this.translationKey + 'Attachment'),
            attachmentPlaceholderAddFile: this.translate.instant(this.translationKey + 'AttachmentPlaceholder.AddFile'),
            attachmentPlaceholderOrDragFile: this.translate.instant(this.translationKey + 'AttachmentPlaceholder.OrDragFile'),
            allowedFormats: this.translate.instant(this.translationKey + 'AllowedFormats'),
            send: this.translate.instant(this.translationKey + 'Send'),
            sendDescription: this.translate.instant(this.translationKey + 'SendDescription'),
            thankYouMessage: this.translate.instant(this.translationKey + 'ThankYouMessage'),
            sendAnotherMessage: this.translate.instant(this.translationKey + 'SendAnotherMessage'),
            email: this.translate.instant(this.translationKey + 'Email'),
            emailPlaceholder: this.translate.instant(this.translationKey + 'EmailPlaceholder'),
        };
    }
}
