<div class="bg-white border-top border-bottom border-yellow-dark" *ngIf="isLoaded">
    <div class="pt-1 pb-2 px-3 d-flex justify-content-between m-2 align-items-center">
        <div class="d-flex flex-column">
            <div class="font-weight-semi-bold line-height-normal">{{file.primaryBeneficiary.firstname}} {{file.primaryBeneficiary.lastname}}</div>
            <small class="text-muted line-height-normal">{{file.primaryBeneficiary.socialSecurityNumber | socialSecurityNumberFormat}}</small>
        </div>
        <div class="text-large">
            {{file.primaryBeneficiary.bankAccount}}
        </div>
    </div>
    <div class="bg-ecru text-medium rounded p-3 d-flex m-2 font-weight-semi-bold flex-column flex-md-row">
        <div class="col d-flex flex-column line-height-normal">
            <span>{{file.street}}</span>
            <span>{{file.zipcode}} {{file.city}}</span>
            <span>{{file.country}}</span>
        </div>
        <div class="col d-flex flex-column line-height-normal">
            <div>{{file.primaryBeneficiary.phone}}</div>
            <div>{{file.primaryBeneficiary.mobilePhone}}</div>
            <div *ngIf="primaryEmail">{{primaryEmail}}</div>
        </div>
        <div class="col d-flex flex-column line-height-normal">
            <div>{{file.primaryBeneficiary.languageCode}}</div>
            <div>{{file.primaryBeneficiary.birthDate | localizedDate: 'dd/MM/y'}}</div>
        </div>
    </div>
    <div class="border-top border-yellow" *ngIf="file.hasSecondaryBeneficiary()">
        <div class="m-2 pt-1 pb-1 px-3 d-flex justify-content-between ">
            <div class="d-flex flex-column">
                <div class="font-weight-semi-bold line-height-normal">{{file.secondaryBeneficiary.firstname}}
                    {{file.secondaryBeneficiary.lastname}}</div>
                <small class="text-muted line-height-normal">{{file.secondaryBeneficiary.socialSecurityNumber | socialSecurityNumberFormat}}</small>
            </div>
        </div>
    </div>
</div>

<div class="bg-white border-top border-bottom border-yellow-dark loading" *ngIf="!isLoaded">
    <div class="pt-2 pb-2 px-4 d-flex justify-content-between m-2">
        <div>
            <div class="h5"><span></span></div>
            <div><span></span></div>
        </div>
        <div>
            <span></span>
        </div>
    </div>
    <div class="bg-ecru text-medium rounded p-3 d-flex m-2">
        <div class="col">
            <span>
            </span>
        </div>
        <div class="col">
            <div><span></span></div>
            <div><span></span></div>
        </div>
        <div class="col">
            <div><span></span></div>
            <div><span></span></div>
        </div>
    </div>
</div>