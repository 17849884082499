import { SmoothHeightComponent } from './smooth-height.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [SmoothHeightComponent],
  imports: [
    CommonModule,
  ],
  exports: [SmoothHeightComponent]
})
export class SmoothHeightModule { }
