import { ViewChild, Component, AfterViewInit, ElementRef, Input, EventEmitter, Output, HostBinding, NgZone } from '@angular/core';
import { fromEvent, Observable, Observer } from 'rxjs';
import { switchMap, takeUntil, pairwise, tap, throwIfEmpty } from 'rxjs/operators';

@Component({
    selector: 'app-drawing-canvas',
    templateUrl: './drawing-canvas.component.html',
})
export class DrawingCanvasComponent implements AfterViewInit {

    @ViewChild('canvas') public canvas: ElementRef;
    @ViewChild('wrapper') public wrapper: ElementRef;

    @Input() public width = 500;
    @Input() public height = 300;
    @Input() public placeholder: string;
    @Input() public enableSkip = true;
    @Input() public skipText: string;

    public totalLine = 0;
    public minLineRequired = 150;

    public isSkipped = false;

    private cx: CanvasRenderingContext2D;

    public hasStartedDrawing: boolean;

    @Output() public onStartDrawing: EventEmitter<void> = new EventEmitter<void>();
    @Output() public onClearDrawing: EventEmitter<void> = new EventEmitter<void>();
    @Output() public onSkipChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    @HostBinding('class') classes = 'h-100';

    constructor(private zone: NgZone) { }

    public ngAfterViewInit() {
        // Set timeout is hack to make sure the elements above the drawing canvas are rendered before this runs
        // as this sets the size of the canvas which depends on the elements above
        setTimeout(() => {
            const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
            this.cx = canvasEl.getContext('2d');

            const widthAvailable = window.outerWidth;

            if (widthAvailable < 768) {
                canvasEl.width = widthAvailable - 40;
                const wrapperEl: HTMLDivElement = this.wrapper.nativeElement;
                canvasEl.height = wrapperEl.clientHeight - 20;
            } else {
                const wrapperEl: HTMLDivElement = this.wrapper.nativeElement;
                canvasEl.width = wrapperEl.clientWidth;
                canvasEl.height = this.height;
            }

            this.cx.lineWidth = 4;
            this.cx.lineCap = 'round';
            this.cx.strokeStyle = '#147995';

            this.captureEvents(canvasEl);
            this.captureGestureEvents(canvasEl);
        });
    }

    public getFile(fileName: string): Observable<File> {
        return new Observable((observer: Observer<File>) => {
            const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
            canvasEl.toBlob((blob: Blob) => {
                observer.next(new File([blob], fileName));
                observer.complete();
            });
        });
    }

    public clearDrawing(): void {
        this.onClearDrawing.emit();
        const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
        const context = canvasEl.getContext('2d');
        context.clearRect(0, 0, canvasEl.width, canvasEl.height);
        this.zone.run(() => {
            this.hasStartedDrawing = false;
            this.totalLine = 0;
        });

    }

    public updatedSkip(isSkipped: boolean): void {
        this.onSkipChanged.emit(isSkipped);
    }

    private captureEvents(canvasEl: HTMLCanvasElement): void {
        // this will capture all mousedown events from the canvas element
        fromEvent(canvasEl, 'mousedown')
            .pipe(
                tap(() => {
                    this.hasStartedDrawing = true;
                    this.isSkipped = false;
                }),
                switchMap((e) => {
                    // after a mouse down, we'll record all mouse moves
                    return fromEvent(canvasEl, 'mousemove')
                        .pipe(
                            // we'll stop (and unsubscribe) once the user releases the mouse
                            // this will trigger a 'mouseup' event
                            takeUntil(fromEvent(canvasEl, 'mouseup')),
                            // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
                            takeUntil(fromEvent(canvasEl, 'mouseleave')),
                            // pairwise lets us get the previous value to draw a line from
                            // the previous point to the current point
                            pairwise()
                        );
                })
            )
            .subscribe((res: [MouseEvent, MouseEvent]) => {
                const rect = canvasEl.getBoundingClientRect();

                // previous and current position with the offset
                const prevPos = {
                    x: res[0].clientX - rect.left,
                    y: res[0].clientY - rect.top
                };

                const currentPos = {
                    x: res[1].clientX - rect.left,
                    y: res[1].clientY - rect.top
                };

                // this method we'll implement soon to do the actual drawing
                this.drawOnCanvas(prevPos, currentPos);
                this.updateTotalPath(prevPos.x, currentPos.x, prevPos.y, currentPos.y);
                if (this.totalLine > this.minLineRequired) {
                    this.onStartDrawing.emit();
                }
            });
    }

    private captureGestureEvents(canvasEl: HTMLCanvasElement): void {
        // this will capture all mousedown events from the canvas element
        fromEvent(canvasEl, 'touchstart')
            .pipe(
                tap(() => {

                    this.hasStartedDrawing = true;
                    this.isSkipped = false;
                }),
                switchMap((e) => {
                    // after a mouse down, we'll record all mouse moves
                    return fromEvent(canvasEl, 'touchmove')
                        .pipe(
                            // we'll stop (and unsubscribe) once the user releases the mouse
                            // this will trigger a 'mouseup' event
                            takeUntil(fromEvent(canvasEl, 'touchend')),
                            // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
                            takeUntil(fromEvent(canvasEl, 'touchcancel')),
                            // pairwise lets us get the previous value to draw a line from
                            // the previous point to the current point
                            pairwise()
                        );
                })
            )
            .subscribe((res: [TouchEvent, TouchEvent]) => {
                const rect = canvasEl.getBoundingClientRect();

                // previous and current position with the offset
                const prevPos = {
                    x: res[0].changedTouches[0].clientX - rect.left,
                    y: res[0].changedTouches[0].clientY - rect.top
                };

                const currentPos = {
                    x: res[1].changedTouches[0].clientX - rect.left,
                    y: res[1].changedTouches[0].clientY - rect.top
                };

                // this method we'll implement soon to do the actual drawing
                this.drawOnCanvas(prevPos, currentPos);
                this.updateTotalPath(prevPos.x, currentPos.x, prevPos.y, currentPos.y);
                if (this.totalLine > this.minLineRequired) {
                    this.onStartDrawing.emit();
                }
            });
    }


    private drawOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }): void {
        if (!this.cx) { return; }

        this.cx.beginPath();

        if (prevPos) {
            this.cx.moveTo(prevPos.x, prevPos.y); // from
            this.cx.lineTo(currentPos.x, currentPos.y);
            this.cx.stroke();
        }
    }

    private updateTotalPath(prevPosx: number, currentPosx: number, prevPosy: number, currentPosy: number): void {
        this.totalLine += Math.abs(currentPosx - prevPosx) + Math.abs(currentPosy - prevPosy);
    }
}
