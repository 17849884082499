import { RegionEnum } from './../../payments/models/region-enum';
import { ChildBenefitsBeneficiary } from './child-benefits-beneficiary.model';
import { ChildBenefitsChild } from './child-benefits-child.model';

export class ChildBenefitsFile {
    public region: RegionEnum;
    public fileId: string;

    public city: string;
    public country: string;
    public street: string;
    public zipcode: string;

    public beneficiaries: ChildBenefitsBeneficiary[];
    public children: ChildBenefitsChild[];

    public fileManagerEmail: string;
    public fileManagerFirstName: string;
    public fileManagerLastName: string;
    public fileManagerPhone: string;
    public officeAddressCity: string;
    public officeAddressCountry: string;
    public officeAddressInfo: string;
    public officeAddressStreet: string;
    public officeAddressZipCode: string;

    public mediatorAccount: string;
    public mediatorAddressCity: string;
    public mediatorAddressCountry: string;
    public mediatorAddressStreet: string;
    public mediatorAddressZipCode: string;
    public mediatorFirstName: string;
    public mediatorLastName: string;

    public administratorAccount: string;
    public administratorAddressCity: string;
    public administratorAddressCountry: string;
    public administratorAddressStreet: string;
    public administratorAddressZipCode: string;
    public administratorFirstName: string;
    public administratorLastName: string;

    constructor(init?: Partial<ChildBenefitsFile>) {
        Object.assign(this, init);
    }
}
