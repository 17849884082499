import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { PortalLogoutGuard } from './authentication/services/portal-logout.guard';
import { PortalNoAuthGuard } from './services/portal-no-auth-guard/portal-no-auth-guard.service';
import { PortalOverviewComponent } from './overview/pages/overview/portal-overview.component';
import { PortalHelpComponent } from './child-benefits-files/pages/portal-help.component';
import { PortalDocumentsComponent } from './documents/pages/portal-documents.component';
import { PortalPaymentsComponent } from './payments/pages/portal-payments.component';
import { PortalAuthGuard } from './services/portal-auth-guard/portal-auth-guard.service';
import { PortalFilesComponent } from './child-benefits-files/pages/portal-files.component';
import { PortalCsamAuthComponent } from './authentication/components/portal-csam-auth/portal-csam-auth.component';
import { PortalAuthenticationComponent } from './authentication/pages/overview/portal-authentication.component';
import { PortalForeignAuthenticationComponent } from './authentication/pages/foreign/portal-foreign-authentication.component';
import { PortalBackofficeAuthenticationComponent } from './authentication/pages/backoffice/portal-backoffice-authentication.component';
import { PortalRootComponent } from './pages/portal-wrapper/portal-root.component';
import { PortalForgotPasswordComponent } from './authentication/pages/forgotPassword/portal-forgot-password.component';
import { PortalResetPasswordComponent } from './authentication/pages/resetPassword/portal-reset-password.component';
import { PortalCertificateComponent } from './certificate/pages/portal-certificate.component';
import { PortalSeparFilesComponent } from './separ/pages/separ-files.component';

const routes: Routes = [
  {
    path: '', pathMatch: 'full', redirectTo: 'overview',
  },
  {
    path: '',
    component: PortalRootComponent,
    children: [
      // USER CONTENT PAGES
      {
        path: 'overview',
        component: PortalOverviewComponent,
        canActivate: [PortalAuthGuard],
      },
      {
        path: 'payments',
        component: PortalPaymentsComponent,
        canActivate: [PortalAuthGuard],
      },
      {
        path: 'dossier',
        component: PortalFilesComponent,
        canActivate: [PortalAuthGuard],
      },
      {
        path: 'certificate',
        component: PortalCertificateComponent,
        canActivate: [PortalAuthGuard],
      },
      {
        path: 'help',
        component: PortalHelpComponent,
        canActivate: [PortalAuthGuard],
      },
      {
        path: 'documents',
        component: PortalDocumentsComponent,
        canActivate: [PortalAuthGuard],
      },
      {
        path: 'separ',
        component: PortalSeparFilesComponent,
        canActivate: [PortalAuthGuard],
      },

      // AUTH
      {
        path: 'auth',
        component: PortalAuthenticationComponent,
        canActivate: [PortalNoAuthGuard],
      },
      {
        path: 'auth/foreign',
        component: PortalForeignAuthenticationComponent,
        canActivate: [PortalNoAuthGuard],
      },
      {
        path: 'auth/backoffice',
        component: PortalBackofficeAuthenticationComponent,
        canActivate: [PortalNoAuthGuard],
      },
      {
        path: 'auth/forgotPassword',
        component: PortalForgotPasswordComponent,
        canActivate: [PortalNoAuthGuard],
      },
      {
        path: 'auth/resetPassword',
        component: PortalResetPasswordComponent,
        canActivate: [PortalNoAuthGuard],
      },
      {
        path: 'auth/csam',
        component: PortalCsamAuthComponent,
        canActivate: [PortalNoAuthGuard],
      },
      {
        path: 'auth/logout',
        children: [],
        canActivate: [PortalLogoutGuard]
      }
    ]
  },
  {
    path: 'backoffice',
    loadChildren: () => import('./backoffice/portal-backoffice.module').then((m) => m.PortalBackofficeModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    LocalizeRouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ]
})
export class PortalRoutingModule { }
