import { Injectable } from '@angular/core';
import { DigitalSignatureModel, SignatureDataModel } from './digital-signature.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DigitalSignatureMapper {

    constructor(private translateService: TranslateService) { }

    public map(isPartnerKnown: boolean): DigitalSignatureModel {
        const digitalSignatureModel = new DigitalSignatureModel();
        const translationKeyPrefix = 'Infino.ClientPortal.Onboarding.DigitalSignature';

        digitalSignatureModel.motherSignature = new SignatureDataModel(
            {
                isMother: true,
                title: this.translateService.instant(`${translationKeyPrefix}.Mother.Title`),
                subText: this.translateService.instant(`${translationKeyPrefix}.Mother.SubText`),
                placeholder: this.translateService.instant(`${translationKeyPrefix}.Placeholder`),
                nextText: isPartnerKnown
                    ? this.translateService.instant(`${translationKeyPrefix}.Mother.Next`)
                    : this.translateService.instant(`${translationKeyPrefix}.Complete`),
                    cancelText: this.translateService.instant(`Infino.ClientPortal.cancel`),
                showNextChevron: isPartnerKnown,
                showPreviousChevron: false,
                isSkipable: false,
                optionalLabel: this.translateService.instant(`${translationKeyPrefix}.Mother.Title`)
            }
        );

        if (isPartnerKnown) {
            digitalSignatureModel.partnerSignature = new SignatureDataModel(
                {
                    isMother: false,
                    title: this.translateService.instant(`${translationKeyPrefix}.Father.Title`),
                    subText: this.translateService.instant(`${translationKeyPrefix}.Father.SubText`),
                    placeholder: this.translateService.instant(`${translationKeyPrefix}.Placeholder`),
                    nextText: this.translateService.instant(`${translationKeyPrefix}.Complete`),
                    cancelText: this.translateService.instant(`Infino.ClientPortal.cancel`),
                    showNextChevron: false,
                    showPreviousChevron: false,
                    isSkipable: true,
                    skipText: this.translateService.instant(`${translationKeyPrefix}.Father.Skip`),
                    optionalLabel: this.translateService.instant(`Infino.ClientPortal.Validation.optional`)
                }
            );
        }

        return digitalSignatureModel;
    }
}
