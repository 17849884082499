<div class='mx-0 mt-1 mb-2 my-md-3'>
  <div class="d-flex flex-column flex-md-row">
      <div class='d-flex align-items-start'>
          <ng-container *ngIf="cookieCategory.isRequired">
              <button class='btn btn-primary btn-sm mr-2' disabled>
                  {{cookieBannerConfiguration.acceptCategoryButtonText}}
              </button>
              <button class='btn btn-sm mr-2 visibility-hidden' disabled>
                  {{cookieBannerConfiguration.declineCategoryButtonText}}
              </button>
          </ng-container>
          <ng-container *ngIf="!cookieCategory.isRequired">
              <button [class]="getButtonClass(false)" (click)="changeConsent(true)" [disabled]="cookieCategory.isRequired">
                  {{cookieBannerConfiguration.acceptCategoryButtonText}}
              </button>
              <button [class]="getButtonClass(true)" (click)="changeConsent(false)" [disabled]="cookieCategory.isRequired">
                  {{cookieBannerConfiguration.declineCategoryButtonText}}
              </button>
          </ng-container>
      </div>
      <div>
          <p class='font-weight-bolder text-small mb-0'>{{cookieCategory.title}}</p>
          <span [innerHTML]="cookieCategory.description" class='d-block text-md-small text-smaller u-mb-gamma u-ms-1'></span>
      </div>
  </div>
</div>
