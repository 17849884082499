import { Input, Component, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IValidator } from '../../infrastructure/validation/validator.interface';
import { IValidationField } from '../../infrastructure/validation/validation-field.interface';

@Component({
    template: '',
})
export class TwoWayBindingInputComponent<TInputType> implements ControlValueAccessor {

    @Input() public validationErrors: any = [];
    @Input() public label: string;
    @Input() public autocomplete?: string;
    @Input() public placeholder = '';
    @Input() public subText: string;
    @Input() public id: string;
    @Input() public disabled = false;
    @Input() public required = true;
    @Input() public validators: IValidationField[] = [];
    @Output() public validate = new EventEmitter<void>();
    @Output() public resetValidate = new EventEmitter<void>();

    public value: TInputType;

    public isValid(): boolean {
        return (this.validationErrors !== undefined && this.validationErrors.length === 0);
    }

    public writeValue(newValue: any): void {
        if (newValue || newValue === '') {
            this.value = newValue;
        }
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public propagateChange = (_: any) => { };

    public registerOnTouched(fn: any): void { }

    public setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public startValidation(): void {
        this.validate.emit();
    }

    public resetValidation(): void {
        this.resetValidate.emit();
    }

}
