import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SeparFile } from 'src/app/core/separ/models/separ-file.model';
import { SeparFileViewModel } from './../../viewmodels/separ-file.viewmodel';
import { SeparStateStore } from './../../../../../core/separ/data/separ-state.store';
import { RegionEnum } from './../../../../../core/payments/models/region-enum';

@Component({
  selector: 'app-separ-file-tabs',
  templateUrl: './separ-file-tabs.component.html',
})
export class SeparFileTabsComponent implements OnInit {

  @Input() public model: SeparFileViewModel[];

  public translations: {
    region: (region: RegionEnum) => string
  };

  public hovering: string;

  constructor(private translate: TranslateService, public store: SeparStateStore) {
  }

  ngOnInit(): void {
    this.translations = {
      region: (region: RegionEnum) => this.translate.instant(`Infino.Portal.Regions.${region}.Label`)
    };
  }

  children(file: SeparFile): string {
    if (file === undefined || file == null || file?.schoolYearDetails.length === 0) {
      return '';
    }

    return file.schoolYearDetails[0].children
      .sort((a, b) => new Date(a.dateOfBirth).getTime() - new Date(b.dateOfBirth).getTime())
      .map(child => child.firstname).join(', ');
  }

  onSelect(fileId: string) {
    this.store.select(fileId, undefined);
  }

  hover(active: string) {
    this.hovering = active;
  }
}
