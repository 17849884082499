import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconsModule } from '../../icons/icons.module';
import { SimpleInfoModalComponent } from './simple-info-modal.component';

@NgModule({
    declarations: [SimpleInfoModalComponent],
    imports: [CommonModule, IconsModule]
})
export class SimpleInfoModalModule { }
