import { Router } from '@angular/router';
import { ChildBenefitsStateStore } from './../../../../core/files/data/child-benefits-state.store';
import { TranslateService } from '@ngx-translate/core';
import { IconColorEnum } from 'src/app/shared/components/icons/enums/icon-color.enum';
import { IconDirectionEnum } from 'src/app/shared/components/icons/enums/icon-direction.enum';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-quick-link-filemanager',
    templateUrl: './quick-link-filemanager.component.html',
})
export class QuickLinkFileManagerComponent implements OnInit {
    @Input() firstname: string;
    @Input() lastname: string;
    @Input() phone: string;
    @Input() link: string | string[];
    @Input() loading: boolean;
    @Input() horizontal: boolean;
    @Input() kids: string[];
    @Input() id: string;
    @Input() show: boolean;

    public chevronDirection = IconDirectionEnum.Right;
    public chevronColor = IconColorEnum.Blue40;

    public translations: {
        askQuestionAboutYourFile: string
        checkoutFile: string,
        helps: string,
    };

    constructor(private translate: TranslateService, private store: ChildBenefitsStateStore) { }

    public get fullname(): string {
        return [this.firstname, this.lastname].filter(v => !!v).join(' ');
    }

    public nameHelpsKids(): string {
        return `${this.fullname} ${this.translations.helps} ${this.kids.join(', ')}`;
    }

    ngOnInit() {
        this.translations = {
            askQuestionAboutYourFile: this.translate.instant('Infino.Portal.Help.AskQuestionAboutYourFile'),
            checkoutFile: this.translate.instant('Infino.Portal.Help.CheckoutFile'),
            helps: this.translate.instant('Infino.Portal.Help.Helps'),
        };
    }

    setActiveFile() {
        this.store.select(this.id);
    }
}
