import { Injectable } from '@angular/core';
import { PortalChildStatusLinksStore } from './../data/portal-childstatuslinks.store';

@Injectable()
export class PortalChildStatusLinksService {
    constructor(private store: PortalChildStatusLinksStore) {}

    public getKeyLink(key: string): string | null | undefined {
        const result = this.store
            .getState()
            .find((i) => !!i.keys.some(item => item.endsWith(`.${key}`)))?.link;

        return result;
    }
}
