import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SingletonModule } from '../../shared/modules/singleton.module';
import { SeparApiService } from './services/separ-files-api.service';
import { SeparStateService } from './services/separ-state.service';
import { SeparFileStore } from './data/separ-file.store';
import { SeparStateStore } from './data/separ-state.store';
import { SeparFileMapper } from './mappers/separ-file.mapper';
import { SeparSchoolYearDetailsMapper } from './mappers/separ-school-year-details.mapper';
import { SeparChildMapper } from './mappers/separ-child.mapper';

@NgModule({
    providers: [
        SeparApiService,
        SeparFileMapper,
        SeparSchoolYearDetailsMapper,
        SeparChildMapper,
        SeparFileStore,
        SeparStateStore,
        SeparStateService
    ]
})
export class SeparFilesDataModule extends SingletonModule {
    constructor(@Optional() @SkipSelf() module: SeparFilesDataModule) {
        super(module);
    }
}
