import { Component, Input } from '@angular/core';
import { ModalService } from 'src/app/shared/infrastructure/modals/modal.service';
import { SeparSchoolYearDetailsLabels } from './../../viewmodels/separ-school-year-details.labels';
import { SeparSchoolYearDetailsViewModel } from './../../viewmodels/separ-school-year-details.viewmodel';
import { SimpleInfoModalComponent } from 'src/app/shared/components/modals/simple-info/simple-info-modal.component';
import { SimpleInfoModalConfig } from 'src/app/shared/components/modals/simple-info/simple-info-config.model';
import { PortalSeparConfiguration } from 'src/app/core/configuration/models/portal-separ-configuration.model';

@Component({
  /* tslint:disable-next-line */
  selector: 'separ-school-year-detail',
  templateUrl: './separ-school-year-detail.component.html',
})
export class SeparSchoolYearDetailComponent {
  @Input() model: SeparSchoolYearDetailsViewModel;
  @Input() labels: SeparSchoolYearDetailsLabels;
  @Input() configuration: PortalSeparConfiguration;

  constructor(
    private modalService: ModalService
  ) {
  }

  public get isLoaded(): boolean {
    return this.model != null && this.labels != null;
  }

  public getIncomeLimitInvalidMessage(): string {
    switch (this.model.incomeLimit) {
      case null:
      case 'UNKNOWN':
        return this.configuration.incomeLimitErrorUnknown;
      case 'ABOVE_UPPER_THRESHOLD':
        return this.configuration.incomeLimitErrorUpperTreshold;
      default:
        return '';
    }
  }

  public getRealEstateIncomeInvalidMessage(): string {
    switch (this.model.realEstateIncomeCheck) {
      case null:
      case 'UNKNOWN':
        return this.configuration.cadastralIncomeErrorUnknown;
      case 'REAL_ESTATE_INCOME_ABOVE_THRESHOLD':
        return this.configuration.cadastralIncomeErrorUpperTreshold;
      default:
        return '';
    }
  }

  public openInfoModal(property: string) {
    const modalInstance = this.modalService.openModal(SimpleInfoModalComponent, true) as SimpleInfoModalComponent;
    let config: SimpleInfoModalConfig;
    switch (property) {
      case 'familyIncomeCompleted':
        config = new SimpleInfoModalConfig(this.labels?.familyIncomeCompletedLabel, this.configuration?.familyIncomeCompletedInfo);
        break;
      case 'realEstateIncomeCheck':
        config = new SimpleInfoModalConfig(this.labels?.realEstateIncomeCheckLabel, this.configuration?.cadastralIncomeInfo);
        break;
      case 'incomeLimit':
        config = new SimpleInfoModalConfig(this.labels?.incomeLimitLabel, this.configuration?.incomeLimitInfo);
        break;
      case 'separCalculationBlocked':
        config = new SimpleInfoModalConfig(this.labels?.separCalculationBlockedLabel, this.configuration?.separCalculationBlockedInfo);
        break;

      default:
      // do nothing
    }

    modalInstance.initialize(config);
  }
}
