import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationConfiguration } from 'src/app/core/configuration/models/authentication-configuration.model';
import { PortalResetPasswordModel } from './portal-reset-password.model';

@Injectable()
export class PortalResetPasswordMapper {

    constructor(
        private translateService: TranslateService,
    ) { }

    public map(configuration: AuthenticationConfiguration): PortalResetPasswordModel {

        return new PortalResetPasswordModel({
            authenticateWithLoginEmailLabel: configuration.authenticateWithLoginEmailLabel,
            authenticateWithLoginEmailPlaceholder: configuration.authenticateWithLoginEmailPlaceholder,
            resetPasswordNewPasswordTitle: configuration.resetPasswordNewPasswordTitle,
            resetPasswordNewPasswordLabel: configuration.resetPasswordNewPasswordLabel,
            resetPasswordNewPasswordPlaceholder: configuration.resetPasswordNewPasswordPlaceholder,
            resetPasswordConfirmPasswordLabel: configuration.resetPasswordConfirmPasswordLabel,
            resetPasswordConfirmPasswordPlaceholder: configuration.resetPasswordConfirmPasswordPlaceholder,
            resetPasswordSubmitLabel: this.translateService.instant('Infino.Portal.ResetPassword.Submit'),
        });
    }
}
