<form [ngClass]="[isValid() ? '': 'is-invalid' ]">
    <div class="form-group">
        <div class="custom-control custom-checkbox my-2" 
            [ngClass]="[isValid() ? '': 'is-invalid' ]">
            <input class="custom-control-input"
             type="checkbox" 
             id="{{id}}"
             [name]="id" 
             [ngModel]="value"
             [ngModelOptions]="{standalone: true}" 
             (ngModelChange)="valueChanged($event)" 
             [ngClass]="[isValid() ? '': 'is-invalid' ]"
             />
            <label class="cursor-pointer custom-control-label font-weight-normal" for="{{id}}" [ngClass]="labelClass">{{label}}</label>
        </div>
        <div class="invalid-feedback" *ngIf="showValidationErrors">
            <span *ngFor="let error of validationErrors">
                {{'Infino.ClientPortal.Validation.' + error | translate}}
            </span>
        </div>
    </div>
</form>