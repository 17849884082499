import { TranslateService } from '@ngx-translate/core';
import { PaymentsCacheStore } from './../../../../../core/payments/data/payments-cache.store';
import { map, flatMap, startWith } from 'rxjs/operators';
import { ChildBenefitsPayment } from './../../../../../core/payments/models/child-benefits-payment';
import { Observable } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-portal-future-payments',
  templateUrl: './portal-future-payments.component.html',
})
export class PortalFuturePaymentsComponent implements OnInit {
  @Output() selected: EventEmitter<ChildBenefitsPayment> = new EventEmitter();
  @Input() active: ChildBenefitsPayment;
  @Input() slope: boolean;
  futurePayments$: Observable<ChildBenefitsPayment[]>;

  public translations: {
    nextPayment: string
  };

  constructor(private store: PaymentsCacheStore, private translate: TranslateService) { }

  ngOnInit(): void {
    this.futurePayments$ = this.store.state$.pipe(flatMap(state => state.futurePayments));

    this.translations = {
      nextPayment: this.translate.instant('Infino.Portal.Payments.NextPayment')
    };
  }
}
