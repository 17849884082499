import { RegionEnum } from '../../../../core/payments/models/region-enum';
import { SeparChildViewModel } from './separ-child.viewmodel';
import { SeparSchoolYearDetailsViewModel } from './separ-school-year-details.viewmodel';


export class SeparFileViewModel {
    public region: RegionEnum;
    public fileId: string;

    public schoolYearDetails: SeparSchoolYearDetailsViewModel[];
    public selectedSchoolYear: SeparSchoolYearDetailsViewModel;
    public selectedChild: SeparChildViewModel;

    public hasNextChild: boolean;
    public hasPreviousChild: boolean;

    public schoolYearDropdownValues: { value: any; label: string; }[];

    constructor(init?: Partial<SeparFileViewModel>) {
        Object.assign(this, init);

        this.schoolYearDropdownValues = this.getSchoolYearDropdownValues();
    }

    public getSchoolYearDropdownValues(): { value: any; label: string; }[] {
        return this.schoolYearDetails.map(schoolYearDetail => {
            return {
                label: schoolYearDetail.schoolYear,
                value: schoolYearDetail,
            };
        });
    }

    public validateSchoolYearSelectedChild(): void {
        if (this.selectedChild) {
            const index = this.indexOfChild(this.selectedChild);
            if (index >= 0) {
                this.selectedChild = this.selectedSchoolYear.children[index];
            }
        }
    }

    public selectChild(child: SeparChildViewModel) {
        this.selectedChild = child;
        if (this.selectedChild != null) {
            const index = this.indexOfChild(this.selectedChild);
            this.hasNextChild = (index + 1) < this.selectedSchoolYear.children.length;
            this.hasPreviousChild = index > 0;
        }
    }

    public unselectChild(): void {
        this.selectedChild = null;
    }

    public nextChildDetails() {
        let newChild: SeparChildViewModel = null;

        if (this.selectedChild != null) {
            const nextIndex = this.indexOfChild(this.selectedChild) + 1;

            if ((nextIndex) < this.selectedSchoolYear.children.length) {
                newChild = this.selectedSchoolYear.children[nextIndex];
            }
        }
        this.selectChild(newChild);
    }

    public previousChildDetails() {
        let newChild: SeparChildViewModel;
        if (this.selectedChild != null) {
            const previousIndex = this.selectedSchoolYear.children.map((child) => child.fullName).indexOf(this.selectedChild.fullName) - 1;
            if (previousIndex >= 0) {
                newChild = this.selectedSchoolYear.children[previousIndex];
            }
        }
        this.selectChild(newChild);
    }

    private indexOfChild(selectedChild: SeparChildViewModel): number {
        return this.selectedSchoolYear.children.map((child) => child.fullName).indexOf(selectedChild.fullName);
    }
}
