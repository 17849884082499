<div class="d-flex flex-column" style="height: 100vh">
    <!-- Regular menu -->
    <app-simple-navigation [showShadow]="false" class="d-none d-md-block">
        <ng-container *ngIf="auth.authenticated()">
            <ul class="nav nav-pills list-unstyled">
                <li class="nav-item mx-1">
                    <a class="nav-link text-large font-weight-semi-bold" [routerLink]="'/portal/overview' | localize"
                        routerLinkActive="active">{{'Infino.Portal.Navigation.Overview' | translate }}</a>
                </li>
                <li class="nav-item mx-1">
                    <a class="nav-link text-large font-weight-semi-bold" [routerLink]="'/portal/payments' | localize"
                        routerLinkActive="active">{{'Infino.Portal.Navigation.Payments' | translate }}</a>
                </li>
                <li class="nav-item mx-1">
                    <a class="nav-link text-large font-weight-semi-bold" [routerLink]="'/portal/dossier' | localize"
                        routerLinkActive="active">{{'Infino.Portal.Navigation.Dossier' | translate }}</a>
                </li>
                <li class="nav-item mx-1">
                    <a class="nav-link text-large font-weight-semi-bold" [routerLink]="'/portal/certificate' | localize"
                        routerLinkActive="active">{{'Infino.Portal.Navigation.Certificate' | translate }}</a>
                </li>
                <li class="nav-item mx-1" *ngIf="hasSeparFiles">
                    <a class="nav-link text-large font-weight-semi-bold" [routerLink]="'/portal/separ' | localize"
                        routerLinkActive="active">{{'Infino.Portal.Navigation.Separ' | translate }}</a>
                </li>
                <li class="nav-item mx-1">
                    <a class="nav-link text-large font-weight-semi-bold" [routerLink]="'/portal/help' | localize"
                        routerLinkActive="active">{{'Infino.Portal.Navigation.Help' | translate }}</a>
                </li>
                 <li class="nav-item mx-1">
                    <a class="nav-link text-large font-weight-semi-bold" [routerLink]="'/portal/documents' | localize"
                        routerLinkActive="active">{{'Infino.Portal.Navigation.Documents' | translate }}</a>
                </li>
            </ul>
        </ng-container>

        <ng-container actions>
            <button class="btn btn-secondary d-flex align-items-center text-large text-secondary"
                *ngIf="auth.authenticated()" (click)="logout()">
                {{ 'Infino.Portal.Logout' | translate }}
                <i class="bi bi-box-arrow-in-right"></i>
                <img class="ml-2" src="assets/svg/icon-box-arrow-in-right.svg" />
            </button>
            <app-language-toggle *ngIf="!auth.authenticated()"></app-language-toggle>
        </ng-container>
    </app-simple-navigation>

    <!-- Mobile Language -->
    <div class="d-md-none bg-white w-100" *ngIf="!auth.authenticated()">
        <app-language-toggle></app-language-toggle>
    </div>

    <div class="bg-white px-md-2 pb-5 pb-md-2 flex-grow-1">
        <div class="bg-ecru rounded h-100 w-100 overflow-hidden">
            <router-outlet></router-outlet>
        </div>
    </div>

    <!-- Mobile menu -->
    <div class="fixed-bottom d-md-none bg-white border-top border-ecru-dark" *ngIf="auth.authenticated()" style="z-index: 9999">
        <ul class="nav nav-pills list-unstyled d-flex flex-row justify-content-between p-2">
            <li class="nav-item">
                <a class="nav-link bg-transparent px-0 py-0 small d-flex flex-column align-items-center "
                   [class.stroke-brown-40]="showMoreMenu"
                   [class.text-primary]="showMoreMenu"
                   (click)="closeMoreMenu()"
                   [routerLink]="'/portal/overview' | localize"
                   routerLinkActive="text-secondary stroke-secondary">
                    <app-icon-grid class="icon-24 mx-3"></app-icon-grid>
                    {{ 'Infino.Portal.Navigation.Overview' | translate }}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link bg-transparent px-0 py-0 small d-flex flex-column align-items-center"
                   [class.stroke-brown-40]="showMoreMenu"
                   [class.text-primary]="showMoreMenu"
                   (click)="closeMoreMenu()"
                   [routerLink]="'/portal/payments' | localize"
                   routerLinkActive="text-secondary stroke-secondary">
                    <app-icon-box class="icon-24 mx-3"></app-icon-box>
                    {{'Infino.Portal.Navigation.Payments' | translate }}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link bg-transparent px-0 py-0 small d-flex flex-column align-items-center"
                   [class.stroke-brown-40]="showMoreMenu"
                   [class.text-primary]="showMoreMenu"
                   (click)="closeMoreMenu()"
                   [routerLink]="'/portal/dossier' | localize"
                   routerLinkActive="text-secondary stroke-secondary">
                    <app-icon-folder class="icon-24 mx-3"></app-icon-folder>
                    {{'Infino.Portal.Navigation.Dossier' | translate }}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link bg-transparent px-0 py-0 small d-flex flex-column align-items-center"
                   [class.stroke-brown-40]="showMoreMenu"
                   [class.text-primary]="showMoreMenu"
                   (click)="closeMoreMenu()"
                   [routerLink]="'/portal/certificate' | localize"
                   routerLinkActive="text-secondary stroke-secondary">
                    <app-icon-file-text class="icon-24 mx-3"></app-icon-file-text>
                    {{'Infino.Portal.Navigation.Certificate' | translate }}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link bg-transparent px-0 py-0 small d-flex flex-column align-items-center"
                   [class.text-secondary]="showMoreMenu"
                   (click)="openMoreMenu()" >
                    <app-icon-3-dots class="icon-24 mx-3" [class.fill-secondary]="showMoreMenu"></app-icon-3-dots>
                    {{'Infino.Portal.Navigation.More' | translate }}
                </a>
            </li>
        </ul>
    </div>
    <app-sidepanel [open]="showMoreMenu" [backgroundColorClass]="'bg-ecru'">
        <div class="border-bottom m-3 align-items-center font-weight-semi-bold mt-3 pb-2 text-center">
            {{'Infino.Portal.Navigation.More' | translate }}
        </div>
        <ul class="nav nav-pills list-unstyled d-flex flex-column p-2">
            <li class="nav-item" *ngIf="hasSeparFiles">
                <a class="nav-link bg-transparent px-0 py-0 d-flex align-items-center stroke-black" (click)="closeMoreMenu()"
                    [routerLink]="'/portal/separ' | localize" routerLinkActive="text-secondary stroke-secondary">
                    <app-icon-academy [color]="iconColorMoreMenu" class="icon-24 m-3"></app-icon-academy>
                    {{'Infino.Portal.Navigation.Separ' | translate }}
                </a>
            </li>
             <li class="nav-item">
                <a class="nav-link bg-transparent px-0 py-0 d-flex align-items-center stroke-black" (click)="closeMoreMenu()"
                    [routerLink]="'/portal/documents' | localize" routerLinkActive="text-secondary stroke-secondary">
                    <app-icon-document-bold [color]="iconColorMoreMenu" class="icon-24 m-3"></app-icon-document-bold>
                    {{'Infino.Portal.Navigation.Documents' | translate }}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link bg-transparent px-0 py-0 d-flex align-items-center stroke-black" (click)="closeMoreMenu()"
                    [routerLink]="'/portal/help' | localize" routerLinkActive="text-secondary stroke-secondary">
                    <app-icon-help-circle [color]="iconColorMoreMenu" class="icon-24 m-3" ></app-icon-help-circle>
                    {{'Infino.Portal.Navigation.Help' | translate }}
                </a>
            </li>
        </ul>
   </app-sidepanel>
</div>

<div @fade *ngIf="model.loading"
    class="position-fixed sticky-top h-100 w-100 d-flex flex-column justify-content-center align-items-center bg-ecru">
    <div class="forwards-backwards mb-5">
        <app-fancy-rocket></app-fancy-rocket>
    </div>
    <div class="mw-small text-secondary text-center font-weight-semi-bold text-medium">{{
        model.translations.gettingPortalReady }}</div>
</div>

<app-portal-notifications></app-portal-notifications>
