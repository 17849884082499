import { PortalSeparConfiguration } from 'src/app/core/configuration/models/portal-separ-configuration.model';
import { SeparChildDetailLabelsViewModel } from './separ-child.labels';
import { SeparFileViewModel } from './separ-file.viewmodel';
import { SeparSchoolYearDetailsLabels } from './separ-school-year-details.labels';

export class SeparPageViewModel {
    public isLoaded: boolean;

    public files: SeparFileViewModel[];
    public selectedFile: SeparFileViewModel;
    public configuration: PortalSeparConfiguration;

    public translations: {
        title: string,
        childrenTitle: string,
        childTranslations: SeparChildDetailLabelsViewModel,
        schoolYearSelectionLabel: string,
        schoolYearDetailTranslations: SeparSchoolYearDetailsLabels,
    };

    constructor(init?: Partial<SeparPageViewModel>) {
        Object.assign(this, init);

        if (this.files && this.files.length > 0) {
            this.selectedFile = this.files[0];

            if (this.selectedFile.schoolYearDetails.length > 0) {
                this.selectedFile.selectedSchoolYear = this.selectedFile.schoolYearDetails[0];
            }
        }
    }

    public isFileSelected(): boolean {
        return this.selectedFile != null;
    }
}
