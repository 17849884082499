import { TranslateService } from '@ngx-translate/core';
import { TokenIssuer, TokenStorageService } from './../../../services/token-storage/token-storage.service';
import { PortalAuthenticationService } from '../../services/portal-authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { first, flatMap, map, } from 'rxjs/operators';
import { PortalNotificationsService } from 'src/app/core/notifications/services/portal-notification.service';

@Component({
  selector: 'app-portal-csam-auth',
  templateUrl: './portal-csam-auth.component.html',
})
export class PortalCsamAuthComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localize: LocalizeRouterService,
    private auth: PortalAuthenticationService,
    private notificationService: PortalNotificationsService,
    private tokenService: TokenStorageService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    const overviewRoute = this.localize.translateRoute('') as string;
    const authRoute = this.localize.translateRoute('/portal/auth') as string;

    if (this.auth.authenticated()) {
      this.router.navigate([overviewRoute]);
      return;
    }

    this.route.queryParams.pipe(
      first(),
      map(params => ({ code: params['code'], type: params['type'] })),
      map(({ code, type }) => {
        if (code) {
          if (type === 'acm') {
            return this.auth.loginAcm(code);
          }
          return this.auth.loginCsam(code);
        }
      }),
      flatMap(response => response)
    ).subscribe(response => {
      if (response.signInResult.succeeded) {
        this.router.navigateByUrl(overviewRoute);
        return;
      }

      if (response.token) {
        const token = this.tokenService.parseToken(response.token);
        const issuer = this.tokenService.parseTokenIssuer(token);
        // dont sign out of ACM if we didnt find a user
        if (issuer !== TokenIssuer.ACM) {
          this.auth.endCsamSession(token?.id_token, this.tokenService.parseTokenIssuer(token));
        }
      }

      setTimeout(() => {
        const message = this.translate.instant('Infino.Portal.Authentication.Errors.' + response.error);
        this.notificationService.addDanger(message);
      }, 250);

      this.router.navigate([authRoute], {
        queryParams: {
          error: response.error
        }
      });
    }, err => {
      const message = this.translate.instant('Infino.Portal.Authentication.Errors.Other');
      this.notificationService.addDanger(message);
      this.router.navigateByUrl(authRoute, {
        queryParams: {
          error: err
        }
      });
    });
  }
}
