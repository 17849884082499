import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FullScreenHandlerService } from '../../../../core/presentation/full-screen-handler.service';
import { IconColorEnum } from '../../icons/enums/icon-color.enum';
import { SimpleInfoModalConfig } from './simple-info-config.model';

@Component({
    selector: 'app-simple-info-modal',
    templateUrl: './simple-info-modal.component.html',
})
export class SimpleInfoModalComponent {
    public config$: BehaviorSubject<SimpleInfoModalConfig>;

    public onClose$: Observable<void>;
    private onCloseSubject: Subject<void> = new Subject<void>();

    public iconColorWhite: IconColorEnum = IconColorEnum.White;

    // @todo remove reference of NgbActiveModal and wrap it in another service so it can be swapped out more easily with another library
    constructor(
        private ngbActiveModal: NgbActiveModal,
        private fullScreenHandlerService: FullScreenHandlerService
    ) {
        this.fullScreenHandlerService.blockBody();
        this.onClose$ = this.onCloseSubject.asObservable();
        this.config$ = new BehaviorSubject<SimpleInfoModalConfig>(null);
    }

    public initialize(config: SimpleInfoModalConfig) {
        this.config$.next(config);
    }

    public close(): void {
        this.onCloseSubject.next();
        this.ngbActiveModal.close();
        this.fullScreenHandlerService.unblockBody();
        this.config$.unsubscribe();
    }
}
