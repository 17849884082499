<div class="form-group" [class.form-group-no-margin]="feedbackSpacing">
    <label [for]="id" [ngClass]="labelClass">
        {{label}}
        <span *ngIf="!required" class="text-danger">{{'Infino.ClientPortal.Validation.optional' | translate}}</span>
    </label>

    <input class="form-control input w-100"
        #input
        type="text"
        [id]="id"
        [attr.autocomplete]="autocomplete ? autocomplete : null"
        inputmode="text"
        [placeholder]="placeholder"
        [disabled]="disabled"
        [required]="required"
        [ngModel]="value"
        (ngModelChange)="valueChanged($event)"
        [ngModelOptions]="{standalone: true}"
        (blur)="startValidation()"
        (keydown)="resetValidation()"
        [ngClass]="[validationErrors ? 'is-invalid': '' ]"
    />

    <div class="invalid-feedback" [class.d-block]="feedbackSpacing">
        <span *ngFor="let error of validationErrors">
            {{'Infino.ClientPortal.Validation.' + error | translate}}
        </span>
        <span *ngIf="feedbackSpacing && (!validationErrors || !validationErrors.length)" class="height-normal d-inline-block"></span>
    </div>
    <small class="form-text">{{subText}}</small>
</div>
