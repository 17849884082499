import { ModifyUserDataModalComponent } from './../../user/components/modify-user-data/modify-user-data-modal.component';
import { ModalService } from './../../../../shared/infrastructure/modals/modal.service';
import { map } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import { ChildBenefitsStateStore } from './../../../../core/files/data/child-benefits-state.store';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { PortalFilesMapper } from '../mappers/portal-files.mapper';
import { PortalFilesViewModel } from '../viewmodels/portal-files.viewmodel';
import { PortalUserStore } from 'src/app/core/portal-user/data/portal-user.store';
import { ChildBasicAllowanceStatusEnum } from 'src/app/core/files/models/child-basic-allowance-status-enum';

@Component({
  selector: 'app-portal-files',
  templateUrl: './portal-files.component.html',
})
export class PortalFilesComponent implements OnInit {
  public model$: Observable<PortalFilesViewModel>;
  public childBasicAllowanceStatusEnum = ChildBasicAllowanceStatusEnum;

  constructor(
    private userInfoStore: PortalUserStore,
    private store: ChildBenefitsStateStore,
    private mapper: PortalFilesMapper,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.model$ = combineLatest([this.store.state$, this.userInfoStore.state$]).pipe(map(([state, userInfo]) => this.mapper.map(state, userInfo)));
  }

  public openChangeDataModal() {
    this.modalService.openModal(ModifyUserDataModalComponent);
  }
}
