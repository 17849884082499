<div class="h-100 d-flex justify-content-center align-items-center">
    <div class="col-md-3 py-4 py-lg-0 d-flex flex-column align-items-stretch">
        <p class="h4">{{model.backofficeAuthenticationTitle}}</p>
        <form name="login" (ngSubmit)="login()">
            <app-text-input [id]="'socialSecurityNumber'" [name]="'socialSecurityNumber'"
                [placeholder]="model.backofficeAuthenticationSocialSecurityNumberPlaceholder"
                [label]="model.backofficeAuthenticationSocialSecurityNumberLabel"
                [(ngModel)]="formModel.socialSecurityNumber"
                [validationErrors]="validationErrors['socialSecurityNumber']"
                (validate)="validateItem('socialSecurityNumber')"
                (resetValidate)="resetItemValidation('socialSecurityNumber')">
            </app-text-input>
            <app-text-input [id]="'email'" [name]="'email'" [placeholder]="model.backofficeAuthenticationAccountPlaceholder"
                [label]="model.backofficeAuthenticationAccountLabel" [(ngModel)]="formModel.email"
                [validationErrors]="validationErrors['email']" (validate)="validateItem('email')"
                (resetValidate)="resetItemValidation('email')">
            </app-text-input>
            <app-password-input [id]="'password'" [name]="'password'"
                [placeholder]="model.backofficeAuthenticationPasswordPlaceholder"
                [label]="model.backofficeAuthenticationPasswordLabel" [(ngModel)]="formModel.password"
                [validationErrors]="validationErrors['password']" (validate)="validateItem('password')"
                (resetValidate)="resetItemValidation('password')">
            </app-password-input>
            <div *ngIf="this.errors && this.errors.length > 0">
                <div class="alert alert-danger" *ngFor="let error of errors" [innerHTML]="this.errors">
                </div>
            </div>
            <button type="submit" class="btn btn-primary my-3 text-white form-control" [disabled]="loading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                {{model.authenticateWithLoginButton}}
            </button>
            <a [routerLink]="model.forgotPasswordUrl">{{ model.forgotPassword }}</a>
        </form>
    </div>
</div>