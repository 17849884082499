<app-smooth-height *ngIf="store.state$ | async as model" [trigger]="model.loading ? '1' : '0'">
    <div class="bg-blue-light" *ngIf="!model.loading && model?.files?.length !== 1">
        <div class="container-sm d-flex">
            <div *ngFor="let file of model.files" (click)="onSelect(file.fileId)"
                class="col-4 col-md-2 px-3 py-1 mt-2 rounded-top cursor-pointer" 
                (mouseenter)="hover(file.fileId)" (mouseleave)="hover(undefined)"
                [ngClass]="model.selectedId === file.fileId ? 'bg-yellow' : (hovering === file.fileId ? 'bg-blue-dark text-white' : 'bg-blue-light text-white')">
                <div class="font-weight-bold text-large">{{translations.region(file.region)}}</div>
                <div class="text-medium text-truncate" [ngClass]="model.selectedId === file.fileId ? 'text-black-50' : 'text-white-60'">
                    <span class="d-block d-md-none">{{ file.fileId }}</span>
                    <span class="d-none d-md-block">{{ children(file) }}</span>
                </div>
            </div>
        </div>
    </div>
</app-smooth-height>