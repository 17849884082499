import { SocialSecurityNumberPipe } from './social-security-number.pipe';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [
    ],
    declarations: [
        SocialSecurityNumberPipe
    ],
    exports: [
        SocialSecurityNumberPipe
    ]
})
export class SocialSecurityNumberModule { }
