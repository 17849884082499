import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NationalInsuranceNumberFormatHelper } from '../../../helpers/national-insurance-number-format.helper';
import { TwoWayBindingInputComponent } from '../two-way-binding-input.component';

@Component({
    selector: 'app-national-id-input',
    templateUrl: './national-id-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NationalIdInputComponent),
            multi: true
        }
    ]
})
export class NationalIdInputComponent extends TwoWayBindingInputComponent<string>  {
    @ViewChild('input') public input: ElementRef;

    @HostListener('keyup', ['$event.target.value', '$event.keyCode'])
    public onKeyup(value: string, keyCode: number): void {
        const formattedValue = this.format(value, keyCode);
        this.valueChanged(formattedValue);
    }

    public valueChanged($event: any) {
        this.value = $event;
        this.propagateChange($event);
    }

    private format(value: string, keyCode: number) {
        const deleteButton = keyCode === 8;
        return NationalInsuranceNumberFormatHelper.transform(value, deleteButton);
    }
}
