import { Location } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
  ManualParserLoader,
} from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { RedirectWithQueryParamsComponent } from './shared/components/redirect-with-query-params/redirect-with-query-params.component';

const routes: Routes = [
  {
    path: '', pathMatch: 'full', redirectTo: 'portal'
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./modules/onboarding/onboarding.module').then((m) => m.OnboardingModule)
  },
  {
    path: 'transfer',
    loadChildren: () => import('./modules/transfer/transfer.module').then((m) => m.TransferModule)
  },

  /// Portal routes
  {
    path: 'portal',
    loadChildren: () => import('./modules/portal/portal.module').then((m) => m.PortalModule)
  },


  /// Portal root intercept routes
  {
    // CSAM: this is an unprefixed (non-localized) route to intercept CSAM redirect
    path: 'csam',
    component: RedirectWithQueryParamsComponent,
    data: { skipRouteLocalization: true, redirectTo: '/portal/auth/csam' },
  },
  {
    // ACM: this is an unprefixed (non-localized) route to intercept ACM redirect
    path: 'acm',
    component: RedirectWithQueryParamsComponent,
    data: { skipRouteLocalization: true, redirectTo: '/portal/auth/csam' },
  },
  {
    // ACMLOGOUT: this is an unprefixed (non-localized) route to intercept ACM Logout redirect
    path: 'acmlogout',
    component: RedirectWithQueryParamsComponent,
    data: { skipRouteLocalization: true, redirectTo: '/portal/auth/logout' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: (translate, location, settings) =>
          new ManualParserLoader(translate, location, settings, ['nl-BE', 'fr-BE', 'en-GB']),
        deps: [TranslateService, Location, LocalizeRouterSettings]
      }
    }),
  ],
  exports: [
    RouterModule,
    LocalizeRouterModule,
  ],
})
export class AppRoutingModule { }
