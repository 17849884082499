import { TranslateModule } from '@ngx-translate/core';
import { InputsModule } from './../../../shared/components/inputs/inputs.module';
import { FormsModule } from '@angular/forms';
import { PortalCertificateModelMapper } from './mappers/portal-certificate-model.mapper';
import { ChildBenefitsFilesModule } from './../child-benefits-files/child-benefits-files.module';
import { CertificatePdfModelMapper } from './mappers/certificate-pdf-model.mapper';
import { CertificateModelMapper } from './mappers/certificate-model.mapper';
import { CertificateModule } from '../../../core/certificate/certificate.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalCertificateComponent } from './pages/portal-certificate.component';

@NgModule({
  declarations: [PortalCertificateComponent],
  imports: [
    CommonModule,
    CertificateModule,
    ChildBenefitsFilesModule,
    FormsModule,
    InputsModule,
    TranslateModule
  ],
  providers: [
    CertificateModelMapper,
    CertificatePdfModelMapper,
    PortalCertificateModelMapper
  ]
})
export class PortalCertificateModule { }
