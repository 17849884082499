import * as dayjs from 'dayjs';

export class SeparChildViewModel {
    public dateOfBirth: string;
    public firstname: string;
    public lastname: string;
    public meetingAgeRequirements: boolean;
    public meetingNationalityRequirements: boolean;
    public meetingPedagogicRequirements: boolean;
    public socialSecurityNumber: string;

    constructor(
        init?: Partial<SeparChildViewModel>
    ) {
        Object.assign(this, init);
    }

    public getAge(): number {
        let age: number;
        const parsedDate = dayjs(this.dateOfBirth);

        if (this.dateOfBirth && parsedDate.isValid()) {
            age = dayjs().diff(parsedDate, 'year', false);
        }

        if (isNaN(age)) {
            return undefined;
        }

        return age;
    }


    public get fullName(): string {
        return `${this.firstname} ${this.lastname}`;
    }

    public get hasErrors(): boolean {
        return !(this.meetingAgeRequirements && this.meetingNationalityRequirements && this.meetingPedagogicRequirements);
    }
}
