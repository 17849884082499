import { Icon3DotsComponent } from './3-dots/3-dots.component';
import { IconAcademyComponent } from './academy/academy.component';
import { IconAlertTriangleComponent } from './alert-triangle/alert-triangle.component';
import { IconBoxComponent } from './box/box.component';
import { IconCheckComponent } from './check/check.component';
import { IconChevronComponent } from './chevron/chevron.component';
import { IconCloseComponent } from './x/icon-close.component';
import { IconDocumentComponent } from './document/document.component';
import { IconDocumentBoldComponent } from './document-bold/document-bold.component';
import { IconDownloadComponent } from './download/download.component';
import { IconEmailComponent } from './email/email.component';
import { IconFileTextComponent } from './file-text/file-text.component';
import { IconFolderComponent } from './folder/folder.component';
import { IconGridComponent } from './grid/grid.component';
import { IconHelpCircleComponent } from './help-circle/help-circle.component';
import { IconInfoCircleComponent } from './info-circle/info-circle.component';
import { IconMapComponent } from './map/map.component';
import { IconPaperclipComponent } from './paperclip/paperclip.component';
import { IconPhoneComponent } from './phone/phone.component';
import { IconWarningRoundComponent } from './warning-round/warning-round.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [
    Icon3DotsComponent,
    IconAcademyComponent,
    IconAlertTriangleComponent,
    IconBoxComponent,
    IconCheckComponent,
    IconChevronComponent,
    IconCloseComponent,
    IconDownloadComponent,
    IconDocumentComponent,
    IconDocumentBoldComponent,
    IconEmailComponent,
    IconFileTextComponent,
    IconFolderComponent,
    IconGridComponent,
    IconHelpCircleComponent,
    IconInfoCircleComponent,
    IconMapComponent,
    IconPaperclipComponent,
    IconPhoneComponent,
    IconWarningRoundComponent
  ],
  exports: [
    Icon3DotsComponent,
    IconAlertTriangleComponent,
    IconAcademyComponent,
    IconBoxComponent,
    IconCheckComponent,
    IconChevronComponent,
    IconCloseComponent,
    IconDownloadComponent,
    IconDocumentComponent,
    IconDocumentBoldComponent,
    IconEmailComponent,
    IconFileTextComponent,
    IconFolderComponent,
    IconGridComponent,
    IconHelpCircleComponent,
    IconInfoCircleComponent,
    IconMapComponent,
    IconPaperclipComponent,
    IconPhoneComponent,
    IconWarningRoundComponent
  ]
})
export class IconsModule { }
