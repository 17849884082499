export enum IconColorEnum {
    White = 'stroke-white',
    Black = 'stroke-black',
    Blue40 = 'stroke-blue-40',
    Blue100 = 'stroke-blue-100',
    Brown40 = 'stroke-brown-40',
    EcruDark = 'stroke-ecru-dark',
    Brown100 = 'stroke-brown-100',
    Yellow100 = 'stroke-yellow-100'
}
