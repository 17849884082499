import { IValidator } from '../validator.interface';
import { ValidationError } from '../validation-error.enum';

/**
 * Validates if the input is a string (or convertible to a string) and checks if the string has a certain maximum length
 */
export class MaxLenghtValidator implements IValidator {

    constructor(private maxLength: number) {}

    public validate(value: any): string {
        try {
            const valueString = value.toString();
            if (valueString.length > this.maxLength) {
                return ValidationError.MaxLength;
            }

            return undefined;
        } catch (error) {
            return ValidationError.MaxLength;
        }
    }

}
