import { Component, Input, OnInit } from '@angular/core';
import { IconColorEnum } from '../enums/icon-color.enum';

@Component({
  selector: 'app-icon-phone',
  templateUrl: './phone.component.html',
})
export class IconPhoneComponent {
  @Input() color: IconColorEnum = IconColorEnum.Blue40;

  public get classes(): string {
    return `${this.color}`;
  }
}
