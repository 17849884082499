import * as dayjs from 'dayjs';
import { DocumentDetailLabelsViewModel } from './portal-documents-document-detail-labels.viewmodel';
import { DocumentViewModel } from './portal-documents-document.viewmodel';
import { IValidationField } from 'src/app/shared/infrastructure/validation/validation-field.interface';
import { Validators } from 'src/app/shared/infrastructure/validation/validators';

export class PortalDocumentsViewModel {
    public isLoaded: boolean;

    public myDocumentsTitle: string;
    public documentsLabel: string;
    public searchTitle: string;
    public filesLabel: string;
    public filesPlaceholderLabel: string;
    public statusLabel: string;
    public statusPlaceholderLabel: string;
    public fromLabel: string;
    public toLabel: string;
    public clearButton: string;
    public searchButton: string;

    public documentDetailLabels: DocumentDetailLabelsViewModel;

    public documents: DocumentViewModel[];
    public filteredDocuments: DocumentViewModel[];
    public selectedDocument: DocumentViewModel;

    public hasNextDocument: boolean;
    public hasPreviousDocument: boolean;

    public files: { label: string, value: string, key: string }[];
    public statuses: { label: string, value: string, key: string }[];

    public loading: boolean;

    form: {
        from?: string,
        to?: string,
        fileId?: string,
        statusId?: string,
    };

    public formIsLoading: boolean;

    constructor(init?: Partial<PortalDocumentsViewModel>) {
        Object.assign(this, init);
    }

    public selectDocument(Document: DocumentViewModel) {
        this.selectedDocument = Document;
        if (Document != null) {
            const index = this.indexOfDocument(Document);
            this.hasNextDocument = (index + 1) < this.documents.length;
            this.hasPreviousDocument = index > 0;
        }
    }

    public hideDetail() {
        this.selectedDocument = null;
    }

    public nextDocumentDetails() {
        let newDocument: DocumentViewModel = null;

        if (this.selectedDocument != null) {
            const nextIndex = this.indexOfDocument(this.selectedDocument) + 1;

            if ((nextIndex) < this.documents.length) {
                newDocument = this.documents[nextIndex];
            }
        }
        this.selectDocument(newDocument);
    }

    public previousDocumentDetails() {
        let newDocument: DocumentViewModel;
        if (this.selectedDocument != null) {
            const previousIndex = this.documents.indexOf(this.selectedDocument) - 1;
            if (previousIndex >= 0) {
                newDocument = this.documents[previousIndex];
            }
        }
        this.selectDocument(newDocument);
    }

    private indexOfDocument(document: DocumentViewModel): number {
        return this.documents.indexOf(document);
    }

    public applyFilters() {
        this.filteredDocuments = this.documents;

        if (this.form.fileId != null && this.form.fileId !== 'any') {
            this.filteredDocuments = this.filteredDocuments.filter(doc => doc.fileId === this.form.fileId);
        }

        if (this.form.statusId != null && this.form.statusId !== 'any') {
            this.filteredDocuments = this.filteredDocuments.filter(doc => doc.statusId === this.form.statusId);
        }

        if (this.form.from != null && dayjs(this.form.from).isValid()) {
            this.filteredDocuments = this.filteredDocuments.filter(doc =>
                // Document period should overlap with the given filter
                dayjs(doc.periodTo) > dayjs(this.form.from)
                || // OR
                // Date sent must be after the given start date
                dayjs(doc.dateSent) > dayjs(this.form.from)
            );

        }

        if (this.form.to != null && dayjs(this.form.to).isValid()) {
            this.filteredDocuments = this.filteredDocuments.filter(doc =>
                // Document period should overlap with the given filter
                dayjs(doc.periodFrom) < dayjs(this.form.to)
                || // OR
                // Date sent must be before the given end date
                dayjs(doc.dateSent) < dayjs(this.form.to)
            );

        }
    }

    public getValidators(): IValidationField[] {
        return [
            {
                name: 'documentFrom',
                value: this.form.from,
                validators: [
                    // Validators.required()
                ]
            },
            {
                name: 'documentTo',
                value: this.form.to,
                validators: [
                    // Validators.required()
                ]
            },
            {
                name: 'documentFile',
                value: this.form.fileId,
                validators: [
                    // Validators.required()
                ]
            },
            {
                name: 'documentStatus',
                value: this.form.statusId,
                validators: [
                    // Validators.required()
                ]
            },
        ];
    }
}
