import { map, tap, startWith } from 'rxjs/operators';
import { interval, Observable, Subject, ReplaySubject } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-fancy-rocket',
    templateUrl: './fancy-rocket.component.html',
})
export class FancyRocketComponent implements OnInit {
    @Input() interval = 300;

    public state$: Observable<number>;

    ngOnInit(): void {
        this.state$ = interval(this.interval, ).pipe(map(x => x % 3), startWith(2));
    }
}
