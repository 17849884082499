import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TwoWayBindingInputComponent } from '../two-way-binding-input.component';

@Component({
    selector: 'app-attachment',
    templateUrl: './attachment.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AttachmentComponent),
            multi: true
        }
    ]
})
export class AttachmentComponent extends TwoWayBindingInputComponent<File[]>  {
    @Input() labelClass = '';
    @ViewChild('input') input: ElementRef<HTMLInputElement>;

    public valueChanged($event: any, ...args) {
        this.value = $event;
        this.propagateChange($event);
    }

    public handleFileInput(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            this.addFile(files.item(i));
        }
    }

    public addFile(file: File) {
        this.value = (this.value || []).concat(file);
    }

    public removeFile(file: File) {
        if (this.value) {
            this.value = this.value.filter(v => v !== file);
        }
    }

    onDrop = (event) => {
        if (event && event.dataTransfer && event.dataTransfer.files) {
            this.handleFileInput(event.dataTransfer.files);
        }
        event.preventDefault();
    }
}
