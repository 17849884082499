import { IValidator } from '../validator.interface';
import { ValidationError } from '../validation-error.enum';
import { ValidatorError } from '../validator-errors';
import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Validates if the input is not empty and checks if the string is a telephone number
 */
export class TelephoneValidator implements IValidator {

    private telephoneValid: RegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[- \.0-9]*$/;

    public validate(value: string): string {
        if (value === undefined || value === null || value.length === 0 || typeof value === 'object') {
            return undefined;
        }

        if (value.length < 9 || value.length > 20) {
            return ValidationError.Telephone;
        }

        const matches = value.match(this.telephoneValid);

        if (matches && matches.length) {
            return undefined;
        }

        return ValidationError.Telephone;
    }
}

export const ReactiveTelephoneValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const result = new TelephoneValidator().validate(control.value);
    return result ? { 'telephone': result } : null;
};
