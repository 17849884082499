export class TransferFormModel {
    public children: ChildFormModel[] = [];
    public mother: MotherFormModel = new MotherFormModel();
    public guardian: GuardianFormModel = new GuardianFormModel();
    public includeOtherChildren = false;
    public privacyConsent = false;
    public brusselsLegal = false;
    public referrer?: string;
    emailConcent = false;
    public utmSource = '';
    public utmTerm = '';
    public utmCampaign = '';
    public utmContent = '';
    public utmMedium = '';
    public gclid = '';


    constructor(init?: Partial<TransferFormModel>) {
        Object.assign(this, init);
    }
}

export class PersonFormModel {
    public firstName = '';
    public lastName = '';
    public dateOfBirth = '';

    constructor(init?: Partial<PersonFormModel>) {
        Object.assign(this, init);
    }
}

export class AdultFormModel extends PersonFormModel {
    public nationalID = '';
    public nationalIDUnknown = false;
    public worksAbroad = false;
    public workCountry = '';
    public worksInternational = false;
    public internationalWork = '';
    public signature: File;

    constructor(init?: Partial<AdultFormModel>) {
        super(init);
        Object.assign(this, init);
    }
}

export class GuardianFormModel extends AdultFormModel {
    public unknown = false;

    constructor(init?: Partial<AdultFormModel>) {
        super(init);
        Object.assign(this, init);
    }
}

export class MotherFormModel extends AdultFormModel {
    public postalCode = '';
    public email = '';
    public telephone = '';

    constructor(init?: Partial<AdultFormModel>) {
        super(init);
        Object.assign(this, init);
    }
}

export class ChildFormModel extends PersonFormModel {
    constructor(init?: Partial<PersonFormModel>) {
        super(init);
        Object.assign(this, init);
    }
}
