import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { PortalAuthenticationService } from '../../services/portal-authentication.service';
import { PortalApiService } from '../../services/portal-api.service';
import { Observable } from 'rxjs';
import { delay, first, shareReplay } from 'rxjs/operators';
import { SignInResult } from '../../services/portal-api.service.models';
import { PortalBackofficeAuthenticationModel } from './portal-backoffice-authentication.model';
import { PortalBackofficeAuthenticationFormModel } from './portal-backoffice-authentication-form.model';
import { PortalBackofficeAuthenticationMapper } from './portal-backoffice-authentication.mapper';
import { ValidationHelper } from 'src/app/shared/infrastructure/validation/validation.helper';
import { AuthenticationConfigurationStore } from 'src/app/core/configuration/data/authentication-configuration.store';
import { AuthenticationConfiguration } from 'src/app/core/configuration/models/authentication-configuration.model';
import { TranslateService } from '@ngx-translate/core';
import { keepOnlyNumbers } from 'src/app/shared/helpers/string.helper';

@Component({
  selector: 'app-portal-authentication',
  templateUrl: './portal-backoffice-authentication.component.html',
})
export class PortalBackofficeAuthenticationComponent implements OnInit {

  public validationErrors = {};

  public model: PortalBackofficeAuthenticationModel;
  public formModel: PortalBackofficeAuthenticationFormModel = new PortalBackofficeAuthenticationFormModel();

  public submitted = true;
  public loading = false;
  public errors: string[];

  constructor(
    private fb: FormBuilder,
    private authService: PortalAuthenticationService,
    private apiService: PortalApiService,
    private router: Router,
    private localize: LocalizeRouterService,
    private authenticationConfigurationStore: AuthenticationConfigurationStore,
    private mapper: PortalBackofficeAuthenticationMapper,
    private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.authenticationConfigurationStore.state$.subscribe((configuration: AuthenticationConfiguration) => {
      this.model = this.mapper.map(configuration);
    });
  }

  login() {
    this.submitted = true;
    this.loading = true;
    this.errors = [];

    this.formModel.email = this.formModel.email?.trim();
    this.formModel.socialSecurityNumber = keepOnlyNumbers(this.formModel.socialSecurityNumber || '');

    if (this.formModel.email && this.formModel.password && this.formModel.socialSecurityNumber) {
      this.authService.loginBackoffice(this.formModel.email, this.formModel.password, this.formModel.socialSecurityNumber)
        .pipe(first())
        .subscribe({
          next: (response) => {
            if (response.signInResult.succeeded) {
              const url = this.localize.translateRoute('/') as string;
              this.router.navigateByUrl(url);
            } else {
              this.loading = false;
              this.errors.push(this.translateService.instant('Infino.Portal.Authentication.Errors.' + response.error));
            }
          },
          error: (error) => {
            console.error(error);
            this.loading = false;
            this.errors.push(this.translateService.instant('Infino.Portal.Authentication.Errors.Other'));
          },
        });
    } else {
      this.loading = false;
    }
  }

  public validateItem(fieldName: string) {
    const validationResult = ValidationHelper.validateField(fieldName, this.formModel.getValidators());
    this.validationErrors = validationResult.extendErrorObject(this.validationErrors);
  }

  public resetItemValidation(fieldName: string) {
    this.validationErrors = ValidationHelper.removeFromErrorObject(fieldName, this.validationErrors);
  }
}
