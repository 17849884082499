import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { RouterModule } from '@angular/router';
import { ClientAdvisorCtaComponent } from './client-advisor-cta.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '../../../../shared/components/icons/icons.module';

@NgModule({
  declarations: [
    ClientAdvisorCtaComponent,
  ],
  imports: [
    CommonModule,
    IconsModule,
    RouterModule,
    LocalizeRouterModule
  ],
  exports: [
    ClientAdvisorCtaComponent
  ]
})
export class ClientAdvisorCtaModule { }
