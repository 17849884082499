import { NgModule, Optional, SkipSelf } from '@angular/core';
import { TransferStore } from './transfer.store';
import { SingletonModule } from '../../../shared/modules/singleton.module';

@NgModule({
    providers: [TransferStore]
})

// seperated store module to make sure this is defined at app module level
// so every component can make use of the store
export class TransferStoreModule extends SingletonModule {
    constructor(@Optional() @SkipSelf() module: TransferStoreModule) {
        super(module);
    }
}
