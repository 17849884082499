import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PortalAuthenticationService } from '../../services/portal-authentication.service';
import { first } from 'rxjs/operators';
import { ValidationHelper } from '../../../../../shared/infrastructure/validation/validation.helper';
import { PortalForeignAuthenticationModel } from './portal-foreign-authentication.model';
import { PortalForeignAuthenticationFormModel } from './portal-foreign-authentication-form.model';
import { PortalForeignAuthenticationMapper } from './portal-foreign-authentication.mapper';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AuthenticationConfiguration } from 'src/app/core/configuration/models/authentication-configuration.model';
import { AuthenticationConfigurationStore } from 'src/app/core/configuration/data/authentication-configuration.store';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-portal-authentication',
  templateUrl: './portal-foreign-authentication.component.html',
})
export class PortalForeignAuthenticationComponent implements OnInit {
  public validationErrors = {};

  public model: PortalForeignAuthenticationModel;
  public formModel: PortalForeignAuthenticationFormModel = new PortalForeignAuthenticationFormModel();

  public submitted = false;
  public loading = false;
  public errors: string[];

  constructor(
    private fb: FormBuilder,
    private authService: PortalAuthenticationService,
    private router: Router,
    private localize: LocalizeRouterService,
    private authenticationConfigurationStore: AuthenticationConfigurationStore,
    private mapper: PortalForeignAuthenticationMapper,
    private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.authenticationConfigurationStore.state$.subscribe((configuration: AuthenticationConfiguration) => {
      this.model = this.mapper.map(configuration);
    });
  }

  login() {

    this.submitted = true;
    this.loading = true;
    this.errors = [];

    this.formModel.email = this.formModel.email?.trim();

    const validationResult = ValidationHelper.validate(this.formModel.getValidators());
    if (validationResult.isValid) {
      this.authService.login(this.formModel.email, this.formModel.password)
        .pipe(first())
        .subscribe({
          next: (response) => {
            if (response.signInResult.succeeded) {
              const url = this.localize.translateRoute('/') as string;
              this.router.navigateByUrl(url);
            } else {
              this.loading = false;
              this.errors.push('Infino.Portal.Authentication.Errors.' + response.error);
            }
          },
          error: (error) => {
            this.loading = false;
            this.errors.push('Infino.Portal.Authentication.Errors.Other');
          },
        });
    } else {
      this.loading = false;
      this.validationErrors = validationResult.extendErrorObject(this.validationErrors);
    }
  }

  public showResetPassword(): void {
    const resetPasswordLink: string = this.localize.translateRoute('/portal/auth/forgotPassword') as string;

    this.router.navigate([resetPasswordLink]);
  }

  public getAuthenticationOverviewUrl(): string {
    const link: string = this.localize.translateRoute('/portal/auth') as string;

    return link;
  }

  public validateItem(fieldName: string) {
    const validationResult = ValidationHelper.validateField(fieldName, this.formModel.getValidators());
    this.validationErrors = validationResult.extendErrorObject(this.validationErrors);
  }

  public resetItemValidation(fieldName: string) {
    this.validationErrors = ValidationHelper.removeFromErrorObject(fieldName, this.validationErrors);
  }
}
