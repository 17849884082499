import { PortalChildStatusLinksService } from './../../../../core/configuration/services/portal-childstatuslinks.service';
import { RegionEnum } from './../../../../core/payments/models/region-enum';
import { ChildBenefitsState } from './../../../../core/files/models/child-benefits-state.model';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChildBenefitsBeneficiary } from 'src/app/core/files/models/child-benefits-beneficiary.model';
import { ChildBenefitsChild } from 'src/app/core/files/models/child-benefits-child.model';
import { ChildBenefitsFile } from 'src/app/core/files/models/child-benefits-file.model';
import { LanguageCodeMapper } from '../../mappers/language-code.mapper';
import { BeneficiaryViewModel } from '../viewmodels/portal-files-beneficiary.viewmodel';
import { ChildDetailLabelsViewModel } from '../viewmodels/portal-files-child-detail-labels.viewmodel';
import { ChildViewModel } from '../viewmodels/portal-files-child.viewmodel';
import { FileViewModel } from '../viewmodels/portal-files-file.viewmodel';
import { PortalFilesViewModel } from './../viewmodels/portal-files.viewmodel';
import { pluralizedTranslation } from 'src/app/shared/helpers/translations';
import { UserInfoModel } from 'src/app/core/portal-user/models/user-info.model';

@Injectable()
export class PortalFilesMapper {
    private translationKey = 'Infino.Portal.Files.';

    constructor(
        private translateService: TranslateService,
        private languageCodeMapper: LanguageCodeMapper,
        private childStatusService: PortalChildStatusLinksService,
    ) {

    }

    public mapEmpty(): PortalFilesViewModel {
        const viewmodel = new PortalFilesViewModel({
            fileLabel: this.translate(`FileLabel`),
            beneficiariesTitle: this.translate(`BeneficiariesTitle`),
            mediatorTitle: this.translate(`MediatorTitle`),
            administratorTitle: this.translate(`AdministratorTitle`),
            changeDataLabel: this.translate(`ChangeDataLabel`),
            childrenActiveTitle: this.translate(`ChildrenActiveTitle`),
            childrenSuspendedTitle: this.translate(`ChildrenSuspendedTitle`),
            childrenClosedTitle: this.translate(`ChildrenClosedTitle`),
            childOnTheWay: this.translate(`ChildOnTheWay`),
            openOnboadingFlowButton: this.translate(`OpenOnboadingFlowButton`),
            childDetailLabels: new ChildDetailLabelsViewModel({
                socialSecurityNumberLabel: this.translate(`SocialSecurityNumberLabel`),
                birthDateNumberLabel: this.translate(`BirthDateLabel`),
                typeLabel: this.translate(`TypeLabel`),
                activeLabel: this.translate(`Active`),
                inactiveLabel: this.translate(`Inactive`),
                suspendedLabel: this.translate('Suspended'),
                childBasicAllowanceSuspendedDateLabel: this.translate(`ChildBasicAllowanceSuspendedDateLabel`),
                childBasicAllowanceSuspendedReasonLabel: this.translate(`ChildBasicAllowanceSuspendedReasonLabel`),
                childBasicAllowanceClosedDateLabel: this.translate(`ChildBasicAllowanceClosedDateLabel`),
                childBasicAllowanceClosedReasonLabel: this.translate(`ChildBasicAllowanceClosedReasonLabel`),
                allowanceType: (name: string) => this.translateService.instant(`Infino.Portal.Allowance.Type.${name}`),
            }),
            yearLabel: pluralizedTranslation(this.translateService, 'Infino.Portal.Years'),
        });

        return viewmodel;
    }

    public map(state: ChildBenefitsState, userInfo: UserInfoModel): PortalFilesViewModel {
        const viewmodel = this.mapEmpty();
        viewmodel.isLoaded = !state.loading;

        if (viewmodel.isLoaded) {
            viewmodel.files = state.files.map(file => this.mapFile(file));
            if (state.selectedId) {
                viewmodel.selectedFile = this.mapFile(state.files.find(file => file.fileId === state.selectedId));
            }
        }

        if (userInfo?.email) {
            viewmodel.primaryEmail = userInfo.email;
        }

        return viewmodel;
    }

    public mapFile(file: ChildBenefitsFile): FileViewModel {
        return new FileViewModel({
            region: file.region,
            fileId: file.fileId,
            city: file.city,
            country: file.country,
            street: file.street,
            zipcode: file.zipcode,
            primaryBeneficiary: this.mapBeneficiary(file.beneficiaries[0]),
            secondaryBeneficiary: file.beneficiaries.length > 1 ? this.mapBeneficiary(file.beneficiaries[1]) : null,
            children: file.children.map(child => this.mapChild(child, file.region))
                .sort(function (a, b) {
                    if (a?.childBasicAllowance?.status > b?.childBasicAllowance?.status) {
                        return 1;
                    }
                    if (a?.childBasicAllowance?.status < b?.childBasicAllowance?.status) {
                        return -1;
                    }
                    return new Date(b.dateOfBirth || 0).getTime() - new Date(a.dateOfBirth || 0).getTime();
                }),
            fileManager: {
                firstname: file.fileManagerFirstName,
                lastname: file.fileManagerLastName,
                email: file.fileManagerEmail,
                phone: file.fileManagerPhone,
                office: {
                    info: file.officeAddressInfo,
                    street: file.officeAddressStreet,
                    city: file.officeAddressCity,
                    zipcode: file.officeAddressZipCode,
                    country: file.officeAddressCountry,
                }
            },
            mediator: file.mediatorLastName != null && file.mediatorLastName !== ''
                ? {
                    firstName: file.mediatorFirstName,
                    lastName: file.mediatorLastName,
                    account: file.mediatorAccount,
                    street: file.mediatorAddressStreet,
                    city: file.mediatorAddressCity,
                    zipCode: file.mediatorAddressZipCode,
                    country: file.mediatorAddressCountry
                }
                : null,
            administrator: file.administratorLastName != null && file.administratorLastName !== ''
                ? {
                    firstName: file.administratorFirstName,
                    lastName: file.administratorLastName,
                    account: file.administratorAccount,
                    street: file.administratorAddressStreet,
                    city: file.administratorAddressCity,
                    zipCode: file.administratorAddressZipCode,
                    country: file.administratorAddressCountry
                }
                : null,
        });
    }

    private mapBeneficiary(beneficiary: ChildBenefitsBeneficiary): BeneficiaryViewModel {
        const language = this.languageCodeMapper.getLanguage(beneficiary.languageCode);

        return new BeneficiaryViewModel({
            firstname: beneficiary.firstname,
            lastname: beneficiary.lastname,
            socialSecurityNumber: beneficiary.socialSecurityNumber,
            bankAccount: beneficiary.bankAccount,
            birthDate: beneficiary.birthDate,
            phone: beneficiary.phone,
            mobilePhone: beneficiary.mobilePhone,
            languageCode: language,
        });
    }

    private mapChild(child: ChildBenefitsChild, region: RegionEnum): ChildViewModel {
        return new ChildViewModel({
            firstname: child.firstname,
            lastname: child.lastname,
            socialSecurityNumber: child.socialSecurityNumber,
            dateOfBirth: child.dateOfBirth,
            capacityLabel: this.translate(`Capacity.${region}.Label`),
            childBenefitsTypeLabel: this.translate(`ChildBenefitsType.${region}.Label`),
            medicalLabel: this.translate(`Medical.${region}.Label`),
            allowances: child.allowances,
            status: child.childStatus,
            statusLink: this.childStatusService.getKeyLink(child.childStatus),
            points: child.childPoints,
            childBasicAllowance: child.childBasicAllowance
        });
    }

    private translate(key: string): string {
        return this.translateService.instant(this.translationKey + key);
    }
}
